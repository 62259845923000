import React from 'react';
import {
  GridUI,
  SkeletonCustomerMenuItemUI,
  SkeletonCustomerCategoryTitleUI,
} from '@next-order/next-order-components';

export default function MenuItemsLoadingSkeleton({ noOfItems = 15 }) {
  return (
    <>
      <SkeletonCustomerCategoryTitleUI extendedClassName='max-w-[360px]' />
      <GridUI align='start' gap='none' colsTemplate='cw-grid'>
        {[...Array(noOfItems)].map((_, index) => {
          return <SkeletonCustomerMenuItemUI key={index} displayType='grid' />;
        })}
      </GridUI>
    </>
  );
}
