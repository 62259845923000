import React, { useCallback, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { firebaseAnalyticsNO } from '../../firebase';
import amexsvg from '../../assets/images/amexsvg.svg';
import visasvg from '../../assets/images/visasvg.svg';
import mastercardsvg from '../../assets/images/mastercardsvg.svg';
import { Elements } from '@stripe/react-stripe-js';
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import getStripePromise from '../../configuration/getStripePromise';
import { selectStoreConfig } from '../../containers/Layout/selectors';

import { ReactComponent as IconReceipt } from 'assets/images/icon-receipt.svg';
import { eventNames } from 'configuration/eventNames';
import { getPlatformAndCardCharges } from 'helpers/getPlatformAndCardCharges';
import getOrderObjectIfDinein from 'helpers/getOrderObjectIfDinein';
import {
  MENU_ORDER_TYPE,
  ORDER_PAYMENT_TYPE,
  ORDER_STAGE_TYPES,
} from 'helpers/enums';
import { BackButtonCWUI, OrderUI } from '@next-order/next-order-components';
import { useHistory } from 'react-router-dom';
import getCardCharges from 'helpers/getCardCharges';
import store from 'store';
import { ArrowRightIcon, LoadingIcon } from 'nexticons/outline';
import useRetryUntilResolved from 'helpers/hooks/useRetryUntilResolved';

const getCurrentOrder = () => store.getState().homeReducer.currentOrder;

export default function OrderPayment({
  orderSetup,
  updateOrderStage,
  updatePaymentTypeForCash,
  updatePaymentTypeForWallet,
  userDetails,
  fetchOrderHandler,
  pickUpTimes,
  deliveryTimes,
  updateDeliveryPickupTime,
  updateOrderStageBack,
  sidebarAnimationClass,
}) {
  const history = useHistory();

  const storeConfig = useSelector(selectStoreConfig);
  const { currentOrder, notes } = useSelector((state) => state.homeReducer);
  const creditCards = useSelector((state) => state.layoutReducer.creditCards);

  const [localState, setLocalState] = useReducer((s, a) => ({ ...s, ...a }), {
    moveNextDetail: 0,
    paymentInProcess: false,
    keyBoard: false,
    placeholderValue: 'MM/YY',
    isError: false,
    errorMessage: '',
    clientSecret: '',
  });

  // componentDidMount
  useEffect(() => {
    window.navigator.userAgent.match(/android/i) &&
      window.addEventListener('resize', onElementHeightChange);
    if (
      orderSetup &&
      orderSetup.enableApplePay &&
      orderSetup.enableApplePay === true
    ) {
      getStripePromise(storeConfig.publishableKey).then((stripe) => {
        const orderObj = JSON.parse(JSON.stringify(currentOrder));
        const { payableAmount } = orderObj;

        let creditCardCharges = 0;
        let platformSurchargeAmt = 0;

        if (orderSetup.isCustomerLiableForOnlineCharges) {
          const { percentageCharge, fixedCharge } = getCardCharges({
            cards: creditCards,
          });
          creditCardCharges =
            parseFloat(payableAmount) * (percentageCharge / 100) + fixedCharge;
          creditCardCharges = Number(creditCardCharges).toFixed(2);
        }

        const payableAmountWithCreditCardCharges =
          +payableAmount + +creditCardCharges;

        // Platform charges processing
        if (
          orderSetup.isPlatformSurchargeLevied &&
          orderSetup.platformSurchargeAmt &&
          orderObj.orderType !== MENU_ORDER_TYPE.dinein
        ) {
          const platformSurchargePercentage =
            Number(orderSetup.platformSurchargePercentage) || 0;
          platformSurchargeAmt =
            parseFloat(payableAmountWithCreditCardCharges) *
              (platformSurchargePercentage / 100) +
            Number(orderSetup.platformSurchargeAmt);
        }

        if (
          orderObj?.isGfoOrder &&
          orderSetup.gfoSurchargeEnabled &&
          orderObj.orderType !== MENU_ORDER_TYPE.dinein
        ) {
          const gfoSurchargePercentage =
            Number(orderSetup.gfoSurchargeRate) || 0;
          const gfoSurchargeAmt =
            (parseFloat(payableAmountWithCreditCardCharges) +
              parseFloat(platformSurchargeAmt)) *
            (gfoSurchargePercentage / 100);
          platformSurchargeAmt = platformSurchargeAmt + gfoSurchargeAmt;
        }

        platformSurchargeAmt = Number(platformSurchargeAmt).toFixed(2);

        const toPay =
          +payableAmount + +creditCardCharges + +platformSurchargeAmt;

        const paymentRequest = stripe.paymentRequest({
          country: storeConfig.countryIdentifier.toUpperCase(),
          currency: storeConfig.currencyCode.toLowerCase(),
          total: {
            label: 'Amount',
            amount: parseInt((Number(toPay) * 100).toFixed(2), 10) || 0,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });

        paymentRequest.on('cancel', (ev) => {
          setLocalState({
            paymentInProcess: false,
          });
        });

        paymentRequest.canMakePayment().then((result) => {
          if (result) {
            setLocalState({
              stripe,
              canMakePayment: !!result,
              paymentRequest: paymentRequest,
              onlyGooglePay: result.googlePay && !result.applePay,
            });
          }
        });
      });
    }
    return () => {
      window.navigator.userAgent.match(/android/i) &&
        window.removeEventListener('resize', onElementHeightChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (localState.clientSecret) {
      localState.paymentRequest.on('paymentmethod', async (ev) => {
        const stripeResponse = await localState.stripe.confirmCardPayment(
          localState.clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );
        const { error: confirmError, paymentIntent } = stripeResponse || {};
        console.log('paymentIntent: ', paymentIntent);

        if (confirmError) {
          let message = confirmError.message
            ? confirmError.message
            : 'Cannot Process Card. Something went wrong.';

          if (
            confirmError.code &&
            confirmError.code === 'card_declined' &&
            confirmError.decline_code &&
            confirmError.decline_code === 'insufficient_funds'
          ) {
            message = 'Your card has been declined.';
          }

          setLocalState({
            paymentInProcess: false,
            isError: true,
            errorMessage: message,
          });
          console.error('In Failed condition', confirmError);
          ev.complete('fail');
        } else {
          fetchOrderHandler(localState.preOrderId);
          history.replace(
            `/order-tracking/${localState.preOrderId}/${currentOrder?.orderType}`
          );
          ev.complete('success');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState.clientSecret]);

  const updateSelectedTimeIfItIsInPast = useCallback(
    (order, availableTimes) => {
      if (order && order.deliveryDate && availableTimes && orderSetup) {
        let today = moment(
          moment().tz(storeConfig.timeZone).format('YYYY-MM-DD HH:mm'),
          'YYYY-MM-DD HH:mm'
        );
        if (order.orderType === MENU_ORDER_TYPE.pickup)
          today.add(
            orderSetup?.pickUpTime ? Number(orderSetup.pickUpTime) : 0,
            'minutes'
          );
        else
          today.add(
            orderSetup?.deliveryTime ? Number(orderSetup.deliveryTime) : 0,
            'minutes'
          );
        let delDate = moment(order.deliveryDate, 'YYYY-MM-DD HH:mm');

        //Detect if update required
        if (today.isAfter(delDate)) {
          if (
            availableTimes.length > 0 &&
            availableTimes[0].times &&
            availableTimes[0].times.length > 0
          ) {
            let timeObj = availableTimes[0].times[0];
            let deliveryDate = moment(
              `${timeObj.timeObjFinal} ${timeObj.displayForUse}`,
              'YYYY-MM-DD HH:mm A'
            );
            if (deliveryDate.isAfter(today)) {
              updateDeliveryPickupTime(
                deliveryDate,
                timeObj.shiftId,
                false,
                0,
                false
              );
            } else {
              updateDeliveryPickupTime(today, timeObj.shiftId, false, 0, false);
            }
          } else {
            // No time slot available
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      orderSetup?.pickUpTime,
      orderSetup?.deliveryTime,
      updateDeliveryPickupTime,
      storeConfig.timeZone,
    ]
  );

  // UNSAFE_componentWillReceiveProps
  useEffect(() => {
    updateSelectedTimeIfItIsInPast(
      currentOrder,
      currentOrder.orderType === MENU_ORDER_TYPE.delivery
        ? deliveryTimes
        : pickUpTimes
    );
  }, [
    currentOrder,
    deliveryTimes,
    pickUpTimes,
    updateSelectedTimeIfItIsInPast,
  ]);

  const markAsCashPayment = () => {
    setLocalState({
      moveNextDetail: 0,
    });
    updatePaymentTypeForCash();
  };

  const markAsCardPaymentOptions = () => {
    updateOrderStage(ORDER_STAGE_TYPES.PAYMENT_CARD);
  };

  const fetchPlaceOrder = async (orderObj3d, isAppleGooglePay = false) => {
    const cloudFunction = isAppleGooglePay
      ? 'placeOrderApplePay'
      : 'placeOrder3DSecure';
    const response = await fetch(
      `${storeConfig.cloudFunctionsUrl}/${cloudFunction}`,
      {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...orderObj3d,
        }),
      }
    );
    const data = await response.json();
    return { ...data, isAppleGooglePay };
  };

  const { data, isLoading, retry } = useRetryUntilResolved({
    conditionForRetry: (data) => data?.code === 'invalid_iteration',
  });

  const placeOrder = async (currOrder = currentOrder) => {
    if (
      !(currOrder && currOrder.paymentType && userDetails && userDetails._id) ||
      !(
        currOrder.paymentType === ORDER_PAYMENT_TYPE.cash &&
        !localState.paymentInProcess
      )
    ) {
      return;
    }

    setLocalState({
      paymentInProcess: true,
    });

    let orderObj = JSON.parse(JSON.stringify(currOrder));
    if (orderObj.isAllowToConfirm !== undefined)
      delete orderObj.isAllowToConfirm;
    if (orderObj.offers) delete orderObj.offers;
    if (orderObj.stage) delete orderObj.stage;
    if (typeof orderObj.isFreeItemsAvailable !== 'undefined')
      delete orderObj.isFreeItemsAvailable;

    let requestObj = {
      order: {
        ...orderObj,
        gst: currOrder.gst || '0.00',
        isPriceIncludesGST: currOrder.isPriceIncludesGST || false,
        notes: notes || currOrder.notes,
        userId: userDetails._id,
        firstName: userDetails.firstName ? userDetails.firstName : '',
        lastName: userDetails.lastName ? userDetails.lastName : '',
        email: userDetails.email ? userDetails.email : '',
        storeId: storeConfig.storeId,
        restaurantId: storeConfig.restaurantId,
        paymentType: ORDER_PAYMENT_TYPE.unpaid, //unpaid cash
        paymentCollectionType: '1', //in-cash
        minOrderValue: `${orderObj.minOrderValue || 0}`,
      },
      payment: {
        type: 0,
        amount: parseInt((Number(orderObj.payableAmount) * 100).toFixed(2), 10),
      },
    };

    retry(async () => {
      const orderObj3d = await getOrderObjectIfDinein(requestObj);
      const fetchOrder = await fetchPlaceOrder(orderObj3d);
      return { ...fetchOrder, orderType: orderObj.orderType };
    });
  };

  useEffect(() => {
    if (!isLoading) {
      if (data?.success) {
        if (data.isAppleGooglePay) {
          setLocalState({
            clientSecret: data.payload?.secret,
            preOrderId: data.payload?.preOrder?.preOrderId,
          });
        } else {
          fetchOrderHandler(data.orderId);
          history.replace(`/order-tracking/${data.orderId}/${data.orderType}`);
        }
      } else {
        setLocalState({
          isError: true,
          errorMessage: data?.message,
          paymentInProcess: false,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  const onElementHeightChange = (height) => {
    let lastHeight = height;
    let newHeight = window.innerHeight;
    if (lastHeight - newHeight > 125) {
      setLocalState({ keyBoard: true, moveNextDetail: 11 });
    } else {
      if (localState.keyBoard === true && lastHeight - newHeight === 0) {
        setLocalState({ keyBoard: false, moveNextDetail: 0 });
      }
    }
  };

  const onPaymentButtonClick = async (e, currOrder = currentOrder) => {
    e.preventDefault();

    // Create a payment Intent and then Show
    setLocalState({
      paymentInProcess: true,
      isError: false,
      errorMessage: '',
    });

    let orderObj = JSON.parse(JSON.stringify(currOrder));
    if (orderObj.isAllowToConfirm !== undefined)
      delete orderObj.isAllowToConfirm;
    if (orderObj.offers) delete orderObj.offers;
    if (orderObj.stage) delete orderObj.stage;
    if (typeof orderObj.isFreeItemsAvailable !== 'undefined')
      delete orderObj.isFreeItemsAvailable;

    let { payableAmount, creditCardCharges } = orderObj;

    if (
      !creditCardCharges ||
      (creditCardCharges && !Number(creditCardCharges))
    ) {
      if (orderSetup.isCustomerLiableForOnlineCharges) {
        const { percentageCharge, fixedCharge } = getCardCharges({
          cards: creditCards,
          cardType: orderObj?.cardType,
          isInternationCard: orderObj?.isInternationCard,
        });
        creditCardCharges =
          parseFloat(payableAmount) * (percentageCharge / 100) + fixedCharge;
        creditCardCharges = Number(creditCardCharges).toFixed(2);
      }
    }

    const toPay = +payableAmount + +creditCardCharges;

    let requestObj = {
      order: {
        ...orderObj,
        creditCardCharges,
        payableAmount: toPay.toFixed(2),
        paymentType: ORDER_PAYMENT_TYPE.card_online,
        minOrderValue: `${orderObj.minOrderValue || 0}`,
        userId: userDetails._id,
        firstName: userDetails.firstName ? userDetails.firstName : '',
        lastName: userDetails.lastName ? userDetails.lastName : '',
        email: userDetails.email ? userDetails.email : '',
        storeId: storeConfig.storeId,
        restaurantId: storeConfig.restaurantId,
      },
      payment: {
        type: 2,
        amount: parseInt((Number(toPay) * 100).toFixed(2), 10),
      },
    };

    try {
      localState.paymentRequest.show();
    } catch (e) {
      console.log(`paymentRequest show error`);
      console.error(e);
    }

    console.log(`placeOrderApplePay stage`);

    // Check if its a dinein order
    console.log('place order apple pay');

    retry(async () => {
      const orderObj3d = await getOrderObjectIfDinein(requestObj);
      const fetchOrder = await fetchPlaceOrder(orderObj3d, true);
      return { ...fetchOrder, orderType: orderObj.orderType };
    });
  };

  const { cardChargesTitle, cardCharges } =
    getPlatformAndCardCharges(currentOrder);

  const isDisabled =
    localState.paymentInProcess ||
    !(userDetails && userDetails._id) ||
    !currentOrder.paymentType ||
    currentOrder.paymentType !== ORDER_PAYMENT_TYPE.cash ||
    localState.paymentInProcess;

  const isCardPaymentAvailable =
    orderSetup?.isCardPaymentAccepted &&
    !(
      currentOrder?.orderType === MENU_ORDER_TYPE.dinein &&
      orderSetup?.isCardPaymentDisabledInDinein
    );
  const isAppleGooglePayAvailable = !(
    currentOrder?.orderType === MENU_ORDER_TYPE.dinein &&
    orderSetup?.isCardPaymentDisabledInDinein
  );

  return (
    <Elements stripe={getStripePromise(storeConfig.publishableKey)}>
      <div
        className={`${sidebarAnimationClass} cash detailsConfirm detailFinal active`}
      >
        <div
          className={`moveNext${localState.moveNextDetail} detailsConfirm detailFinal detailPayment active`}
        >
          <BackButtonCWUI onClick={updateOrderStageBack} />
          <div>
            <div className='detailsTitle' style={{ marginTop: 0 }}>
              Payment Method
            </div>

            {isCardPaymentAvailable && (
              <div
                className={
                  currentOrder &&
                  currentOrder.paymentType &&
                  currentOrder.paymentType === ORDER_PAYMENT_TYPE.card_online
                    ? 'cardCommon cash'
                    : 'cardCommon cash'
                }
                onClick={markAsCardPaymentOptions}
              >
                <span className='credit-card-span' style={{ display: 'block' }}>
                  Credit Card
                </span>
                {orderSetup && orderSetup.isCardPaymentAccepted && (
                  <div className='img'>
                    {orderSetup &&
                      orderSetup.isCustomerLiableForOnlineCharges && (
                        <div className='surcharge-container mr-2.5'>
                          <span className='surcharge-text'>
                            Surcharge Applies
                          </span>
                        </div>
                      )}
                    <img src={amexsvg} alt='' />
                    <img src={visasvg} alt='' />
                    <img src={mastercardsvg} alt='' />
                  </div>
                )}
              </div>
            )}
            {orderSetup &&
              currentOrder &&
              currentOrder.orderType &&
              ((currentOrder.orderType === MENU_ORDER_TYPE.pickup &&
                orderSetup.isCashPaymentAccepted) ||
                (currentOrder.orderType === MENU_ORDER_TYPE.delivery &&
                  orderSetup.isCashPaymentAcceptedDelivery) ||
                (currentOrder.orderType === MENU_ORDER_TYPE.dinein &&
                  orderSetup.isCashPaymentAcceptedDineIn)) &&
              ((orderSetup.maxCashOrderValue &&
                Number(orderSetup.maxCashOrderValue) === 0) ||
                (orderSetup.maxCashOrderValue &&
                  Number(orderSetup.maxCashOrderValue) !== 0 &&
                  Number(currentOrder.payableAmount) <=
                    Number(orderSetup.maxCashOrderValue))) && (
                <div
                  className={
                    currentOrder &&
                    currentOrder.paymentType &&
                    currentOrder.paymentType === ORDER_PAYMENT_TYPE.cash
                      ? 'cardCommon cash active'
                      : 'cardCommon cash'
                  }
                  onClick={() => {
                    markAsCashPayment();
                    if (currentOrder.orderType === MENU_ORDER_TYPE.dinein) {
                      setTimeout(() => {
                        const latestCurrentOrder = getCurrentOrder();
                        firebaseAnalyticsNO.logEvent(
                          eventNames.SELECTED_PAYMENT_METHOD
                        );
                        updateSelectedTimeIfItIsInPast(
                          latestCurrentOrder,
                          latestCurrentOrder.orderType ===
                            MENU_ORDER_TYPE.delivery
                            ? deliveryTimes
                            : pickUpTimes
                        );
                        setTimeout(() => {
                          const latestCurrentOrder2 = getCurrentOrder();
                          placeOrder(latestCurrentOrder2);
                        }, 0);
                      }, 0);
                    }
                  }}
                >
                  <span>
                    {currentOrder.orderType === MENU_ORDER_TYPE.dinein
                      ? 'Pay at end of Sitting'
                      : 'Cash'}
                  </span>{' '}
                  {currentOrder.orderType === MENU_ORDER_TYPE.dinein ? (
                    <i className='icon-Receipt'>
                      <IconReceipt />
                    </i>
                  ) : (
                    <i className='icon-Cash' />
                  )}
                </div>
              )}

            {isAppleGooglePayAvailable && (
              <>
                {localState.canMakePayment &&
                  localState.onlyGooglePay === false && (
                    <React.Fragment>
                      <PaymentRequestButtonElement
                        options={{
                          paymentRequest: localState.paymentRequest,
                        }}
                        className='PaymentRequestButton'
                        onClick={(evt) => {
                          updatePaymentTypeForWallet();
                          updateSelectedTimeIfItIsInPast(
                            currentOrder,
                            currentOrder.orderType === MENU_ORDER_TYPE.delivery
                              ? deliveryTimes
                              : pickUpTimes
                          );
                          setTimeout(() => {
                            const latestCurrentOrder = getCurrentOrder();
                            onPaymentButtonClick(evt, latestCurrentOrder);
                          }, 0);
                        }}
                        disabled={!(userDetails && userDetails._id)}
                      />
                    </React.Fragment>
                  )}

                {localState.canMakePayment &&
                  localState.onlyGooglePay === true && (
                    <React.Fragment>
                      {/* Show Custom Google pay Button */}
                      <div style={{ height: '64px', width: '100%' }}>
                        <button
                          type='button'
                          aria-label='Google Pay'
                          className='gpay-button black long'
                          onClick={(evt) => {
                            updatePaymentTypeForWallet();
                            updateSelectedTimeIfItIsInPast(
                              currentOrder,
                              currentOrder.orderType ===
                                MENU_ORDER_TYPE.delivery
                                ? deliveryTimes
                                : pickUpTimes
                            );
                            setTimeout(() => {
                              const latestCurrentOrder = getCurrentOrder();
                              onPaymentButtonClick(evt, latestCurrentOrder);
                            }, 0);
                          }}
                          disabled={!(userDetails && userDetails._id)}
                        ></button>
                      </div>
                    </React.Fragment>
                  )}
              </>
            )}
            {localState.isError && (
              <div className='cardCommon cash active'>
                <span className='redSpan'>
                  {localState.errorMessage ? localState.errorMessage : ''}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className='totalbottom'>
          <div className='totalInner'>
            {currentOrder.publicHolidaySurcharge &&
            Number(currentOrder.publicHolidaySurcharge) !== 0 ? (
              <div className='spWrap'>
                <span className='spName'>
                  Public Holiday Surcharge
                  {`${
                    currentOrder &&
                    currentOrder.publicHolidaySurchargePer &&
                    Number(currentOrder.publicHolidaySurchargePer) !== 0
                      ? `(${currentOrder.publicHolidaySurchargePer}%)`
                      : null
                  }`}
                </span>
                <span className='spPrice'>{`${storeConfig.currency}${currentOrder.publicHolidaySurcharge}`}</span>
              </div>
            ) : (
              <div />
            )}
            {currentOrder.rewards && currentOrder.rewards.length > 0 ? (
              <div className='spWrap'>
                <span className='spName'>Loyalty</span>
                <span className='spPrice'>{`- ${storeConfig.currency}${currentOrder.loyaltyDiscount}`}</span>
              </div>
            ) : (
              <div />
            )}
            {currentOrder.specials &&
            currentOrder.specials.length > 0 &&
            Number(currentOrder.specialDiscount) > 0 ? (
              <div className='spWrap'>
                <span className='spName'>Specials</span>
                <span className='spPrice'>{`- ${storeConfig.currency}${currentOrder.specialDiscount}`}</span>
              </div>
            ) : (
              <div />
            )}
            {currentOrder.voucherId &&
            currentOrder.voucherId !== '' &&
            Number(currentOrder.voucherDiscount) !== 0 ? (
              <div className='spWrap'>
                <span className='spName'>Voucher</span>
                <span className='spPrice'>{`- ${storeConfig.currency}${currentOrder.voucherDiscount} `}</span>
              </div>
            ) : (
              <div />
            )}
            {currentOrder.isDisplayGST &&
            !currentOrder.isPriceIncludesGST &&
            Number(currentOrder.gst) !== 0 ? (
              <div className='spWrap'>
                <span className='spName'>GST</span>
                <span className='spPrice'>{` ${storeConfig.currency}${currentOrder.gst} `}</span>
              </div>
            ) : (
              <div />
            )}
            {cardCharges ? (
              <div className='spWrap'>
                <span className='spName'>{cardChargesTitle}</span>
                <span className='spPrice'>{` ${storeConfig.currency}${cardChargesTitle} `}</span>
              </div>
            ) : (
              <div />
            )}
            <OrderUI.FooterButton
              extendedClassName='z-[1003]'
              rightIcon={
                localState.paymentInProcess ? (
                  <LoadingIcon strokeWidth={3} width={16} />
                ) : (
                  <ArrowRightIcon strokeWidth={3} width={16} />
                )
              }
              onClick={() => {
                firebaseAnalyticsNO.logEvent(
                  eventNames.SELECTED_PAYMENT_METHOD
                );
                updateSelectedTimeIfItIsInPast(
                  currentOrder,
                  currentOrder.orderType === MENU_ORDER_TYPE.delivery
                    ? deliveryTimes
                    : pickUpTimes
                );
                setTimeout(() => {
                  const latestCurrentOrder = getCurrentOrder();
                  placeOrder(latestCurrentOrder);
                }, 0);
              }}
              disabled={isDisabled}
            >
              {localState.paymentInProcess
                ? 'Wait while we are processing'
                : `Confirm and Pay ${
                    currentOrder && currentOrder.payableAmount
                      ? `${storeConfig.currency}${currentOrder.payableAmount}`
                      : `${storeConfig.currency}0.00`
                  }`}
            </OrderUI.FooterButton>
          </div>
        </div>
      </div>
    </Elements>
  );
}
