export default function getMenuItemIngredients(
  value, // string
) {
  return value && value.length > (window.innerWidth > 340 ? 40 : 25)
    ? value
        .substr(0, window.innerWidth > 340 ? 36 : 25)
        .substr(
          0,
          Math.min(
            value.substr(0, window.innerWidth > 340 ? 36 : 25).length,
            value.substr(0, window.innerWidth > 340 ? 36 : 25).lastIndexOf(' ')
          )
        ) + '...'
    : value;
}
