import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { API_URL } from 'configuration/urls';
import { useGlobalModalContext } from 'modal/useGlobalModalContext';

function StoreClosedModal({ timeZone, orderSetup }) {
  const { handleGlobalModal } = useGlobalModalContext();

  const handleActionButton = () => {
    // here will be possible action handler
  };

  const formatOpenTime = useCallback(
    (date) => {
      if (!date) return;

      const timeA = moment();
      const timeB = moment(date);
      const diff = timeA.diff(timeB, 'days');

      // show calendar format if less than a week difference
      if (Math.abs(diff) >= 7) {
        return moment(date).tz(timeZone).format('DD/MM/YYYY');
      } else {
        return moment(date).tz(timeZone).calendar();
      }
    },
    [timeZone]
  );

  const defaultClosedMessage = (
    time,
    preordering,
    showCustomClosedStoreMessage,
    customClosedStoreMessage,
    hideDefaultCloseMessage
  ) => {
    return (
      <>
        {showCustomClosedStoreMessage && customClosedStoreMessage && (
          <>
            {customClosedStoreMessage}
            <br />
          </>
        )}
        {(!hideDefaultCloseMessage || !showCustomClosedStoreMessage) && (
          <>
            {time && (
              <>
                We will re-open{' '}
                <strong>
                  {time}.
                  <br />
                </strong>
              </>
            )}
            {preordering ? (
              <>
                You can place a pre-order in advance <br />
                or view our menu.
              </>
            ) : (
              <>
                You can still view our menu and <br />
                order when we re-open again.
              </>
            )}
          </>
        )}
      </>
    );
  };

  const showClosedStoreModal = useCallback(
    (
      time,
      preordering,
      showCustomClosedStoreMessage,
      customClosedStoreMessage,
      hideDefaultCloseMessage
    ) => {
      handleGlobalModal({
        title: 'We are closed!',
        message: defaultClosedMessage(
          time,
          preordering,
          showCustomClosedStoreMessage,
          customClosedStoreMessage,
          hideDefaultCloseMessage
        ),
        actionButtonLabel: preordering
          ? 'Place a Pre Order'
          : 'Browse Menu for later',
        actionButtonHandler: handleActionButton,
      });
    },
    [handleGlobalModal]
  );

  const showDeliveryClosedModal = useCallback(() => {
    handleGlobalModal({
      title: 'Delivery is closed.',
      message:
        'We are still open for Pickup.\n\r We have been affected by driver shortages. \n\rYou can still place an order or view our menu.',
      actionButtonLabel: 'Place a Pickup Order',
      actionButtonHandler: handleActionButton,
    });
  }, [handleGlobalModal]);

  useEffect(() => {
    let storeShiftInfo = window.StoreShiftInfo;
    async function getShiftsInfo() {
      let result = null;
      if (!storeShiftInfo?.success) {
        const params = new URLSearchParams({
          storeId: orderSetup.storeId,
          timezone: timeZone,
        });

        const response = await fetch(
          `${API_URL}stores/v1/shifts/info?` + params,
          {
            method: 'get',
          }
        );

        result = await response.json();
      }

      if (storeShiftInfo?.success || result?.code === 200) {
        storeShiftInfo = storeShiftInfo || result?.data?.storeShiftInfo;
        const { isOpen, nextOpenAt, isDeliveryEnabled } = storeShiftInfo;

        if (isOpen) {
          if (!isDeliveryEnabled) {
            showDeliveryClosedModal();
          }
        } else {
          showClosedStoreModal(
            nextOpenAt && formatOpenTime(nextOpenAt),
            orderSetup?.isPreOrderingEnabled,
            orderSetup?.showCustomClosedStoreMessage,
            orderSetup?.customClosedStoreMessage,
            orderSetup?.hideDefaultCloseMessage
          );
        }
      }
    }
    if ((orderSetup?.storeId && timeZone) || storeShiftInfo?.success) {
      getShiftsInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderSetup?.storeId, timeZone, orderSetup?.isPreOrderingEnabled]);

  return null;
}

function mapStateToProps(state) {
  const {
    homeReducer: { orderSetup },
  } = state;

  return {
    orderSetup,
  };
}

export default connect(mapStateToProps, null)(StoreClosedModal);
