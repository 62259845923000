import classNames from 'classnames';
import {
  setState as setPropsState,
  toggleMobileCart,
} from 'containers/Home/actions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const onClickDefaultState = {
  loyaltyOpen: false,
  aboutOpen: false,
  bookingOpen: false,
};
export default function FloatingNavbar() {
  const { offers } = useSelector((state) => state.layoutReducer);
  const {
    orderSetup: {
      isTableBookingOn = false,
      useCustomTableBooking = false,
      customBookingURL = '',
    } = {},
  } = useSelector((state) => state.homeReducer);

  const dispatch = useDispatch();
  let bubbleClass = classNames('bubble white visible-xs visible-sm', {
    'only-about': !isTableBookingOn && !offers?.length,
    'table-booking': isTableBookingOn === true && offers && offers.length === 0,
    loyalty: !isTableBookingOn && offers && offers.length > 0,
    'table-booking-and-loyalty':
      isTableBookingOn === true && offers && offers.length > 0,
  });
  let loyaltyList = classNames({
    'loyalty-list': isTableBookingOn === true && offers && offers.length > 0,
  });

  const onButtonClick = (key) => {
    dispatch(toggleMobileCart(true));
    dispatch(setPropsState({ ...onClickDefaultState, [key]: true }));
  };

  return (
    <div
      className={bubbleClass}
      style={{ position: 'fixed', bottom: '20px', zIndex: '9999' }}
    >
      <ul>
        <li onClick={() => onButtonClick('aboutOpen')}>
          About
          <i className='icon-Store2' />
        </li>
        {offers && offers.length > 0 && (
          <li onClick={() => onButtonClick('loyaltyOpen')}>
            Loyalty
            <i className='icon-Cash' />
          </li>
        )}
        {isTableBookingOn && (
          <li
            onClick={() => {
              if (useCustomTableBooking === true && customBookingURL !== '') {
                window.open(customBookingURL, '_blank');
              } else {
                onButtonClick('bookingOpen');
              }
            }}
            className={loyaltyList}
          >
            Booking
            <i className='icon-Calendar-Time' />
          </li>
        )}
      </ul>
    </div>
  );
}
