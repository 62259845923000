/* eslint-disable react-hooks/exhaustive-deps */
import { API_URL } from 'configuration/urls';
import { getMinifiedMenuItemForInstore } from 'helpers/getMenuItemForInstore';
import { useEffect, useState } from 'react';
import { _auth } from '../../firebase';
import getOriginalMenuItems from 'helpers/getOriginalMenuItems';
import {
  isObjectNotEmpty,
  performMergeToModify,
} from '@next-order/next-order-engine';

const useOrderAgain = ({
  orderSetup = {},
  userDetails = {},
  currentOrder = {},
  categoriesList = [],
  menuItems = [],
  menuItemSizes,
  menuItemSubModifiers,
  ordersLimit = '15',
}) => {
  const [orderAgainItems, setOrderAgainItems] = useState(null);

  const updatePrices = (orderAgainMenuItems) => {
    return [...orderAgainMenuItems]
      ?.filter(
        (f) =>
          f.orderTypeKeys?.includes(currentOrder.orderType) && f.isPublished
      )
      ?.map((menuItem) => {
        if (menuItemSizes?.length || menuItemSubModifiers?.length) {
          const newMenuItemSizes = menuItemSizes.filter(
            (f) => f.menuItemId === menuItem._id
          );
          const newMenuItemSubModifiers = menuItemSubModifiers.filter(
            (f) => f.menuItemId === menuItem._id
          );

          const updatedMenuItem = getMinifiedMenuItemForInstore(
            menuItem,
            newMenuItemSizes,
            newMenuItemSubModifiers,
            currentOrder.orderType
          );

          return updatedMenuItem;
        } else {
          return menuItem;
        }
      });
  };

  const generateOrderAgainItems = (orders) => {
    const orderAgainMenuItemsMap = {};
    let categoriesIds = categoriesList.map(({ _id }) => _id);
    orders?.forEach((order, idx) => {
      order.menuItems?.forEach((menuItem) => {
        if (
          !menuItem.isHalf &&
          menuItem._id &&
          !(menuItem._id in orderAgainMenuItemsMap) &&
          categoriesIds.includes(menuItem.categoryId)
        ) {
          const originalMenuItems = getOriginalMenuItems(menuItem, menuItems);
          if (isObjectNotEmpty(menuItem) && originalMenuItems?.length > 0) {
            const newMenuItem = {
              ...performMergeToModify(menuItem, originalMenuItems[0]),
            };
            orderAgainMenuItemsMap[menuItem._id] = {
              ...newMenuItem,
              mItemOrder: idx,
            };
          }
        }
      });
    });

    let orderAgainMenuItemsMapKeys = Object.keys(orderAgainMenuItemsMap);
    orderAgainMenuItemsMapKeys = orderAgainMenuItemsMapKeys
      ?.sort((a, b) => a.mItemOrder - b.mItemOrder)
      ?.slice(0, 8);

    let orderAgainMenuItems = orderAgainMenuItemsMapKeys.map(
      (key) => orderAgainMenuItemsMap[key]
    );

    orderAgainMenuItems = updatePrices(orderAgainMenuItems);
    setOrderAgainItems(orderAgainMenuItems);
  };

  const getOrders = async () => {
    const accessToken = await _auth.currentUser.getIdToken(true);
    const params = new URLSearchParams({
      'filter.storeId': orderSetup.storeId,
      'filter.userId': userDetails?._id,
      offset: '0',
      limit: ordersLimit,
      sortField: 'dateTimestamp',
      sortOrder: 'desc',
    });
    const response = await fetch(`${API_URL}orders/v1/orders?${params}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    });
    const result = await response.json();

    generateOrderAgainItems(result?.data?.orders);
  };

  useEffect(() => {
    if (
      userDetails?._id &&
      currentOrder?.orderType &&
      menuItems.length &&
      categoriesList?.length &&
      (menuItemSizes.length || menuItemSubModifiers.length)
    ) {
      getOrders();
    }
  }, [
    userDetails?._id,
    currentOrder?.orderType,
    menuItemSizes?.length,
    menuItemSubModifiers?.length,
    menuItems?.length,
    categoriesList?.length,
  ]);

  return orderAgainItems;
};

export default useOrderAgain;
