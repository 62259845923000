import { MENU_ORDER_TYPE } from 'helpers/enums';
import moment from 'moment-timezone';
import { getCurrentDayFlag, getDayFlag } from '../../helpers/timeHelper';

const voucherDeclinedReasons = {
  Expired: 'Voucher has expired.', //If a voucher has expired and past the valid date or reached the max. amount of uses:
  NewCustomer: 'Voucher is only available for new customers.', //If a voucher is only for new customers
  OnlyPickup: 'Voucher can only be used for pickup orders.', //If voucher in only for pickup
  OnlyDelivery: 'Voucher can only be used for delivery orders.', //If voucher in only for pickup
  certainDays: 'Voucher can only be used from',
  AlreadyUsed: 'Voucher can be used only once per user',
  NotValidUser: 'Voucher is not available for logged in user',
  Future: 'Voucher is not yet available for use',
};

export const fetchVoucher = (storeConfig, requestObj) => {
  return new Promise((resolve, reject) => {
    fetch(`${storeConfig.cloudFunctionsUrl}/fetchVoucher`, {
      method: 'post',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...requestObj,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result);
      });
  });
};

export const validateVoucher = (
  storeConfig,
  voucher,
  order,
  payableAmount,
  user
) => {
  let response = {
    success: true,
    voucherErrorMessage: '',
  };
  let usages = [];
  if (voucher.users) {
    usages = voucher.users;
  }
  let userId = user && user._id ? user._id : '';
  if (
    Number(voucher.voucherUsageCount ? voucher.voucherUsageCount : 0) >=
      Number(voucher.maxOrders ? voucher.maxOrders : 0) ||
    voucher.isActive === false
  ) {
    //Voucher expiry check
    response.success = false;
    response.voucherErrorMessage = voucherDeclinedReasons.Expired;
  } else if (
    voucher.type !== '3' &&
    isOrderDateIsAfterVoucherDateRange(storeConfig, voucher, order.deliveryDate)
  ) {
    response.success = false;
    response.voucherErrorMessage = voucherDeclinedReasons.Expired;
  } else if (
    voucher.type !== '3' &&
    isOrderDateIsBeforeVoucherDateRange(
      storeConfig,
      voucher,
      order.deliveryDate
    )
  ) {
    response.success = false;
    response.voucherErrorMessage = voucherDeclinedReasons.Future;
  } else if (
    (!voucher.isAvailablePickup &&
      order.orderType === MENU_ORDER_TYPE.pickup) ||
    (!voucher.isAvailableDelivery &&
      order.orderType === MENU_ORDER_TYPE.delivery)
  ) {
    //Order type check, voucher could be available for one of the order type
    response.success = false;
    response.voucherErrorMessage =
      order.orderType === MENU_ORDER_TYPE.pickup
        ? voucherDeclinedReasons.OnlyDelivery
        : voucherDeclinedReasons.OnlyPickup;
  } else if (
    isVoucherUnAvailableForWeekDay(storeConfig, voucher, order.deliveryDate)
  ) {
    // Check voucher availability for the day
    response.success = false;
    response.voucherErrorMessage = `${
      voucherDeclinedReasons.certainDays
    } ${getVoucherAvailabilityString(voucher)}`;
  } else if (
    voucher.isExusiveForNewCustomers === true &&
    user &&
    user.isNewCustomer === false
  ) {
    // Check if voucher is available for new customers only
    response.success = false;
    response.voucherErrorMessage = voucherDeclinedReasons.NewCustomer;
  } else if (
    voucher.isExusiveForSingleTimeUse === true &&
    usages.includes(userId)
  ) {
    // If voucher is single time use only
    response.success = false;
    response.voucherErrorMessage = voucherDeclinedReasons.AlreadyUsed;
  } else if (Number(voucher.minOrderValue) > Number(payableAmount)) {
    //Voucher have set minimum order value
    response.success = false;
    response.voucherErrorMessage = `Voucher only valid for a miniumum spend of ${storeConfig.currency}${voucher.minOrderValue} or above.`;
  } else if (
    (voucher.type === '1' || voucher.type === '3') &&
    !isVoucherAvailableFullDayOrTimeSlot(
      storeConfig,
      voucher,
      order.deliveryDate
    )
  ) {
    response.success = false;
    response.voucherErrorMessage = `Voucher only valid between ${voucher.startTimeDisplay} - ${voucher.endTimeDisplay} `;
  } else if (
    !voucher.isAvailableWithSpecialDiscount &&
    order.specials.length > 0
  ) {
    response.success = false;
    response.voucherErrorMessage = `Voucher only valid without specials`;
  }
  return response;
};

function getVoucherAvailabilityString(item) {
  let returnString = '';
  let dayStringArray = [];
  if (item.Monday) dayStringArray.push('Mon');
  if (item.Tuesday) dayStringArray.push('Tue');
  if (item.Wednesday) dayStringArray.push('Wed');
  if (item.Thursday) dayStringArray.push('Thu');
  if (item.Friday) dayStringArray.push('Fri');
  if (item.Saturday) dayStringArray.push('Sat');
  if (item.Sunday) dayStringArray.push('Sun');

  if (dayStringArray.length !== 7) {
    returnString =
      returnString.length !== 0
        ? returnString +
          dayStringArray[0] +
          ' to ' +
          dayStringArray[dayStringArray.length - 1]
        : returnString +
          dayStringArray[0] +
          ' to ' +
          dayStringArray[dayStringArray.length - 1];
  }
  return returnString;
}

function isVoucherAvailableFullDayOrTimeSlot(storeConfig, voucher, delDate) {
  if (voucher.isAvailableFullDay) return true;
  else {
    let todayDate = moment(
      moment().tz(storeConfig.timeZone).format('YYYY-MM-DD HH:mm'),
      'YYYY-MM-DD HH:mm'
    );
    if (delDate) {
      todayDate = moment(delDate, 'YYYY-MM-DD HH:mm');
    }
    let startObj = moment(
      `${todayDate.format('YYYY-MM-DD')} ${convertSecondsTo12Hour(
        voucher.startTime
      )}`,
      'YYYY-MM-DD HH:mm'
    );
    let endObj = moment(
      `${todayDate.format('YYYY-MM-DD')} ${convertSecondsTo12Hour(
        voucher.endTime
      )}`,
      'YYYY-MM-DD HH:mm'
    );
    if (startObj.isSameOrBefore(todayDate) && endObj.isSameOrAfter(todayDate)) {
      return true;
    } else {
      return false;
    }
  }
}

function isOrderDateIsAfterVoucherDateRange(storeConfig, voucher, delDate) {
  let todayDate = moment(
    moment().tz(storeConfig.timeZone).format('YYYY-MM-DD HH:mm'),
    'YYYY-MM-DD HH:mm'
  );
  if (delDate) {
    todayDate = moment(delDate, 'YYYY-MM-DD HH:mm');
  }
  if (
    moment(voucher.startDate)
      .tz(storeConfig.timeZone)
      .isBefore(todayDate, 'day') &&
    moment(voucher.endDate).tz(storeConfig.timeZone).isBefore(todayDate, 'day')
  ) {
    return true;
  } else return false;
}

function isOrderDateIsBeforeVoucherDateRange(storeConfig, voucher, delDate) {
  let todayDate = moment(
    moment().tz(storeConfig.timeZone).format('YYYY-MM-DD HH:mm'),
    'YYYY-MM-DD HH:mm'
  );
  if (delDate) {
    todayDate = moment(delDate, 'YYYY-MM-DD HH:mm');
  }
  if (
    moment(voucher.startDate)
      .tz(storeConfig.timeZone)
      .isAfter(todayDate, 'day') &&
    moment(voucher.endDate).tz(storeConfig.timeZone).isAfter(todayDate, 'day')
  ) {
    return true;
  } else return false;
}

export const isVoucherUnAvailableForWeekDay = (
  storeConfig,
  voucher,
  delDate
) => {
  if (delDate) {
    if (
      voucher.hasOwnProperty(getDayFlag(delDate, 'YYYY-MM-DD HH:mm').dayText) &&
      voucher[getDayFlag(delDate, 'YYYY-MM-DD HH:mm').dayText] === false
    )
      return true;
    else return false;
  } else {
    if (
      voucher.hasOwnProperty(getCurrentDayFlag(storeConfig.timeZone).dayText) &&
      voucher[getCurrentDayFlag(storeConfig.timeZone).dayText] === false
    )
      return true;
    else return false;
  }
};

function convertSecondsTo12Hour(timeStamp) {
  let hours = timeStamp / 3600;
  let minutes = (timeStamp / 60) % 60;
  let ampm = '';
  if (hours === 24) {
    hours = 23;
    minutes = 59;
  }
  hours >= 12 ? (ampm = 'PM') : (ampm = 'AM');
  return moment(`${hours}:${minutes} ${ampm}`, 'HH:mm A').format('HH:mm');
}
