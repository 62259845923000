import {
  getItemsInCart,
  getVoucherDiscountedAmount,
  updateItemToOrder,
  getItemPrice,
} from '@next-order/next-order-engine';
import NewMenuItems from 'components/MenuItems/NewMenuItems';
import { eventNames } from 'configuration/eventNames';
import { defaultHomePropsState } from 'constants/common';
import Order from 'containers/Order';
import MenuProvider from 'contexts/MenuProvider';
import SafariAddressBarDetector from 'detect-ios-address-bar';
import {
  LOGIN_FROM_TYPE,
  MENU_ITEM_OBJ_TYPE,
  MENU_ORDER_FROM,
  MENU_ORDER_STATUS,
  MENU_ORDER_TYPE,
  ORDER_PAYMENT_TYPE,
  ORDER_STAGE_TYPES,
  VISIBILITY_INDEX_TYPE,
} from 'helpers/enums';
import { getMinifiedMenuItemForInstore } from 'helpers/getMenuItemForInstore';
import {
  engineAddHalfHalfToOrder,
  engineAddHalfItemsToOrder,
  engineAddItemsToOrder,
  engineAddTableToOrder,
  engineAddToHalfHalf,
  engineAddToOrder,
  engineAddToSpecial,
  engineClearOrChangeSize,
  engineDecrementExtraQuantity,
  engineDecrementItemQuantity,
  engineDoASelfMerge,
  engineGetOrderCalculations,
  engineIncrementExtraQuantity,
  engineIncrementItemQuantity,
  engineMultipleAddToSpecial,
  engineRemoveItemFromOrder,
  engineRemoveOtherThanSelectedOrderTypeItems,
  engineRemoveOutOfScheduleItems,
  engineRemoveSoldOutItems,
  engineSetItemQuantity,
  engineShowHalfItem,
  engineShowMenuItemHandler,
  engineShowSpecialItem,
  engineUpdateDeliveryPickupTime,
  engineUpdateOrderDeliveryAddress,
  engineUpdatePaymentTypeForNewCard,
  engineUpdatePaymenyType,
} from 'helpers/order-engine';
import localStorage, { set } from 'local-storage';
import moment from 'moment-timezone';

import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Layout from '../../containers/Layout';
import { firebaseAnalyticsNO, _auth } from '../../firebase';
import { getDineInObjects } from '../../helpers/getDineInObjects';
import getFormattedMobileNumber from '../../helpers/getFormattedMobileNumber';
import { getCurrentTimestamp } from '../../helpers/timeHelper';
import {
  addReservation,
  deleteAccountAction,
  filterView,
  getCurrentUseDetails,
  initHome,
  setLayoutFlow,
  setState as setPropsState,
  setUserSelectedVoucherItems,
  takeSignOutAction,
  toggleMobileCart,
  voucherFilterView,
} from './actions';
import { loadCurrentOrderMethod } from './methods';
import store from '../../store';
import Smartlook from 'smartlook-client';
import useSpecialItem from 'components/ItemView/useSpecialItem';
import { setState as setLayoutState } from 'containers/Layout/actions';
import { getDeliveryCostFromApi } from 'helpers/addressHelper';
import { canSkipPaymentMethod } from 'helpers/canSkipPaymentMethod';

const query = new URLSearchParams(window.location.search);
const qrRedirect = query.get('qrRedirect');
const qrTracking = query.get('qrTracking');

//ℹ️ We will only use getProps inside _setTimeout_ functions in order
// to make sure that we get latest values from the store.
function getProps() {
  const {
    orderReducer: order,
    layoutReducer: {
      storeConfig,
      halfHalfSetup,
      offers,
      autoVouchers,
      publicHolidays,
      creditCards,
    },
    homeReducer: {
      currentOrder,
      userDetails,
      currentUser,
      pathwayToOffers,
      orderSetup,
      allSpecials,
      selectedSpecialId,
    },
  } = store.getState();

  return {
    storeConfig,
    orderSetup,
    order,
    autoVouchers,
    publicHolidays,
    currentOrder,
    halfHalfSetup,
    offers,
    creditCards,

    pathwayToOffers,
    allSpecials,
    userDetails,
    currentUser,
    selectedSpecialId,
  };
}

export default function Home() {
  const [localState, setLocalState] = useReducer((s, a) => ({ ...s, ...a }), {
    rowItems: [],
    selectedHalfItems: [],
    toggleAnimation: false,
    toggleSpecials: false,
    toggleSpeacialItem: false,
    halfItem: {},
    specialItem: {},
    voucherItem: {},
    arrKey: '',
    halfAnimation: false,
    rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.NONE,
    itemVariants: [],
    whichHalf: 0,
    itemExist: -1,
    inLayoutFlow: false,
    loyalty: false,
    dineInObjects: [],
    floors: {},
    loginType: LOGIN_FROM_TYPE.MAIN,
    isAutoSelectPopulated: false,
  });
  const history = useHistory();
  const location = useLocation();

  const {
    soldOutMenuItems,
    currentOrder,
    specialFilter: {
      isSpecialFilterApplied,
      spMenuSizes,
      spSubModifiers,
      boxIndex,
    },
    voucherFilter: {
      isVoucherFilterApplied,
      voucherMenuSizes,
      voucherSubModifiers,
      voucherBoxIndex,
    },
    menuItemSizes,
    menuItemSubModifiers,
    userDetails,
    currentUser,
    pathwayToOffers,
    ui: { mobileCart },
    orderSetup,
    menuItems,
    allSpecials,
    selectedSpecialId,
    isMinifiedVersion,
    userSelectedVoucherItems,
  } = useSelector((state) => state.homeReducer);
  const {
    storeConfig,
    halfHalfSetup,
    offers,
    autoVouchers,
    publicHolidays,
    creditCards,
  } = useSelector((state) => state.layoutReducer);
  const order = useSelector((state) => state.orderReducer);
  const dispatch = useDispatch();
  const { addToOrderItem } = useSpecialItem();

  const props = useMemo(
    () => ({
      storeConfig,
      orderSetup,
      order,
      soldOutMenuItems,
      autoVouchers,
      publicHolidays,
      currentOrder,
      halfHalfSetup,
      offers,
      creditCards,

      pathwayToOffers,
      allSpecials,
      userDetails,
      currentUser,
      selectedSpecialId,
      userSelectedVoucherItems,
    }),
    [
      storeConfig,
      orderSetup,
      order,
      soldOutMenuItems,
      autoVouchers,
      publicHolidays,
      currentOrder,
      halfHalfSetup,
      offers,
      creditCards,

      pathwayToOffers,
      allSpecials,
      userDetails,
      currentUser,
      selectedSpecialId,
      userSelectedVoucherItems,
    ]
  );

  useEffect(() => {
    dispatch(initHome(storeConfig.storeId, storeConfig.restaurantId));

    _auth.onAuthStateChanged((user) => {
      if (user) {
        dispatch(
          getCurrentUseDetails(
            user,
            null,
            storeConfig.storeId,
            storeConfig.restaurantId
          )
        );
      }
    });
    if (currentOrder && currentOrder._id && currentOrder.orderStatus !== '-1') {
      redirectAndCleanupOrder();
    } else {
      const loadedOrder = loadCurrentOrderMethod(storeConfig, currentOrder);

      if (loadedOrder.type === '1') {
        loadedOrder.address = '';
        loadedOrder.addressLocation = '';
        loadedOrder.placeId = '';
        loadedOrder.suburbId = '';
        loadedOrder.minOrderValue = '';
        loadedOrder.areaCode = '';
        loadedOrder.area = '';
        loadedOrder.deliveryCost = 0;
        loadedOrder.deliveryDate = undefined;
        loadedOrder.deliveryData = {};
      } else {
        loadedOrder.deliveryDate = undefined;
      }
      const { currentOd, rowItems } = engineGetOrderCalculations(
        loadedOrder,
        props
      );
      setLocalState({ rowItems });
      dispatch(setPropsState({ currentOrder: currentOd }));

      if (qrRedirect === 'true' || qrTracking === 'close') {
        goHome();
      }
    }

    // eslint-disable-next-line
  }, [storeConfig.storeId, storeConfig.restaurantId]);

  useEffect(() => {
    firebaseAnalyticsNO.logEvent(eventNames.HOME_PAGE_VISITED);
    if (orderSetup?.storeId) {
      handleRedirectionFromMultistore();
    }

    // Initialize instance
    const safariAddressBar = new SafariAddressBarDetector();
    if (safariAddressBar && safariAddressBar.isTargetDevice) {
      document.body.style.marginBottom = `40px`;
    }
    // eslint-disable-next-line
  }, [orderSetup?.storeId]);

  const addTableToOrder = useCallback(
    (table) => {
      const { currentOd, rowItems } = engineAddTableToOrder(table, props);

      setLocalState({ rowItems });
      dispatch(setPropsState({ currentOrder: currentOd }));
    },
    [dispatch, props]
  );

  const isGfoOrderFun = () => {
    const search = location?.search;
    const params = new URLSearchParams(search);
    const isGfoOrder = params.get('source')?.toLowerCase() === 'gfo';

    return isGfoOrder;
  };

  const checkForGFO = () => {
    const isGfoOrder = isGfoOrderFun();
    if (isGfoOrder) {
      dispatch(
        setPropsState({
          currentOrder: {
            ...currentOrder,
            isGfoOrder,
          },
        })
      );
    }
  };

  const checkForDineIn = useCallback(async () => {
    const { storeId } = storeConfig;
    const pathname = window.location.pathname;
    const isDineIn = pathname.includes('dine-in');
    const type = 'dine-in';
    let dineInObject = null;

    if (
      (isDineIn || currentOrder?.orderType === MENU_ORDER_TYPE.dinein) &&
      orderSetup
    ) {
      const isDineInDisabled = !orderSetup.isDineInOn;

      if (isDineInDisabled) {
        history.push('/');
        return;
      }
      const { tables: dineInObjects, floors } = await getDineInObjects(storeId);
      setLocalState({ type, dineInObjects, floors });

      // pre-select the table if it's in the url
      const urlPaths = pathname.split('/').filter(Boolean);
      const isTableNumber = isDineIn && urlPaths.length === 3;
      const floorLayoutId = isTableNumber
        ? urlPaths[urlPaths.length - 2]
        : false;
      const number = isTableNumber
        ? urlPaths[urlPaths.length - 1]?.replace('table-', '')
        : false;

      if (dineInObjects?.length > 0) {
        if (number) {
          dineInObject = dineInObjects.find(
            (item) =>
              (item.name === number || item.number === number) &&
              (item.floorLayoutId === floorLayoutId ||
                floors[item.floorLayoutId].name.toLowerCase() ===
                  floorLayoutId.toLowerCase())
          );
          if (dineInObject) {
            addTableToOrder(dineInObject);
          } else {
            dispatch(
              setPropsState({
                currentOrder: {
                  ...currentOrder,
                  orderType: MENU_ORDER_TYPE.dinein,
                },
              })
            );
          }
        } else {
          dispatch(
            setPropsState({
              currentOrder: {
                ...currentOrder,
                orderType: MENU_ORDER_TYPE.dinein,
              },
            })
          );
        }
      }
    }
  }, [
    addTableToOrder,
    currentOrder,
    dispatch,
    history,
    orderSetup,
    storeConfig,
  ]);

  // UNSAFE_componentWillReceiveProps
  useEffect(() => {
    if (
      orderSetup &&
      Object.keys(orderSetup).length &&
      !localState.orderSetup
    ) {
      setLocalState({ orderSetup });
      checkForDineIn();
      checkForGFO();
    }

    if (
      orderSetup &&
      !orderSetup.isDeliveryOn &&
      orderSetup.isPickupOn &&
      currentOrder.orderType !== MENU_ORDER_TYPE.pickup &&
      currentOrder.orderType !== MENU_ORDER_TYPE.dinein
    ) {
      let loadedOrder = loadCurrentOrderMethod(storeConfig, currentOrder);
      loadedOrder.orderType = MENU_ORDER_TYPE.pickup;
      dispatch(setPropsState({ currentOrder: loadedOrder }));
    } else if (
      orderSetup &&
      !orderSetup.isPickupOn &&
      orderSetup.isDeliveryOn &&
      currentOrder.orderType !== MENU_ORDER_TYPE.delivery &&
      currentOrder.orderType !== MENU_ORDER_TYPE.dinein
    ) {
      let loadedOrder = loadCurrentOrderMethod(storeConfig, currentOrder);
      loadedOrder.orderType = MENU_ORDER_TYPE.delivery;
      dispatch(setPropsState({ currentOrder: loadedOrder }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkForDineIn,
    currentOrder,
    dispatch,
    localState.orderSetup,
    orderSetup,
    storeConfig,
  ]);

  useEffect(() => {
    if (Smartlook.initialized() && userDetails?.userId) {
      Smartlook.identify(userDetails.userId, {
        name: `${userDetails?.firstName} ${userDetails?.lastName}`,
        email: userDetails?.email || '',
        phone: userDetails?.mobileNumber || '',
      });
    }
  }, [
    userDetails?.userId,
    userDetails?.email,
    userDetails?.mobileNumber,
    userDetails?.firstName,
    userDetails?.lastName,
  ]);

  const updateOnce = useRef(false);
  useEffect(() => {
    // TODO: No need to do this, test before removing. On reload half half extra charges should not get removed
    // if (!halfHalfSetup || !currentOrder || localState.halfHalfSetup) return;
    // setLocalState({ halfHalfSetup });

    if (updateOnce.current || !halfHalfSetup || !currentOrder) return;
    updateOnce.current = true;
    const { currentOd, rowItems } = engineGetOrderCalculations(
      currentOrder,
      props
    );
    setLocalState({ rowItems });
    dispatch(setPropsState({ currentOrder: currentOd }));
  }, [halfHalfSetup, currentOrder, props, dispatch]);

  useEffect(() => {
    if (
      currentOrder &&
      currentOrder._id &&
      currentOrder.orderStatus !== MENU_ORDER_STATUS.unknown
    ) {
      redirectAndCleanupOrder();
    }
    // eslint-disable-next-line
  }, [currentOrder]);

  useEffect(() => {
    if (
      location?.pathname?.includes('booking') &&
      orderSetup?.isTableBookingOn
    ) {
      if (
        orderSetup.useCustomTableBooking === true &&
        orderSetup.customBookingURL !== ''
      ) {
        window.open(orderSetup.customBookingURL, '_blank');
      } else {
        dispatch(toggleMobileCart(true));
        dispatch(setPropsState({ bookingOpen: true }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderSetup, location?.pathname]);

  useEffect(() => {
    const currentOrder = localStorage.get('currentOrder');
    if (currentOrder.voucherId) {
      const voucherItems = localStorage.get('userSelectedVoucherItems');
      if (!voucherItems || (voucherItems && voucherItems.length === 0)) {
        removeVoucher(currentOrder);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirectionFromMultistore = () => {
    const query = queryString.parse(window.location.search);
    if (query?.lat) {
      query.lat = Number(query.lat);
    }
    if (query?.lng) {
      query.lng = Number(query?.lng);
    }

    let {
      type,
      address,
      lat,
      lng,
      placeId,
      suburbId,
      deliveryCost,
      minOrderValue,
      areaCode,
      area,
    } = query;

    if (type) setLocalState({ type });
    if (
      address &&
      lat &&
      lng &&
      placeId &&
      suburbId &&
      deliveryCost &&
      minOrderValue
    ) {
      setLocalState({
        address,
        lat,
        lng,
        placeId,
        suburbId,
        deliveryCost,
        minOrderValue,
        ...(areaCode && area
          ? {
              areaCode,
              area,
            }
          : {}),
      });
      dispatch(
        setPropsState({
          currentOrder: {
            ...currentOrder,
            areaCode,
            area,
          },
        })
      );
      openAppSectionForType(query);
    } else if (type) {
      openAppSectionForType(query);
    }
  };

  const openAppSectionForType = async ({
    type,
    address,
    lat,
    lng,
    placeId,
    suburbId,
    deliveryCost,
    minOrderValue,
    areaCode,
  }) => {
    switch (type) {
      case 'dine-in':
      case 'pickup':
        let loadedOrder = loadCurrentOrderMethod(storeConfig, currentOrder);
        loadedOrder.orderType =
          type === 'dine-in' ? MENU_ORDER_TYPE.dinein : MENU_ORDER_TYPE.pickup;

        const { currentOd, rowItems } = engineGetOrderCalculations(
          loadedOrder,
          props
        );

        // ========================><========================
        dispatch(setPropsState({ currentOrder: currentOd }));
        setLocalState({ type: ``, rowItems });
        break;
      case 'delivery': {
        let loadedOrder = loadCurrentOrderMethod(storeConfig, currentOrder);
        loadedOrder.orderType = MENU_ORDER_TYPE.delivery;
        loadedOrder.address = address || '';
        loadedOrder.addressLocation = lat && lng ? { lat, lng } : {};
        loadedOrder.placeId = placeId || '';
        loadedOrder.suburbId = suburbId || '';
        loadedOrder.deliveryCost = deliveryCost || '0';
        loadedOrder.minOrderValue = minOrderValue || '0';
        loadedOrder.areaCode = areaCode || '';
        if (props?.orderSetup?.useRadiusBasedDeliveryAreaCalulation) {
          const { isDeliverable, deliveryCost, minOrderValue } =
            await getDeliveryCostFromApi(storeConfig.storeId, { lat, lng });
          if (isDeliverable) {
            loadedOrder.deliveryData = {
              deliveryCost: deliveryCost,
              minOrderValue: minOrderValue || 0,
              suburbId: '',
            };
            loadedOrder.deliveryCost = '0';
            loadedOrder.minOrderValue = minOrderValue || 0;
            loadedOrder.suburbId = '';
          } else {
            loadedOrder.deliveryData = {};
            loadedOrder.minOrderValue = '0';
            loadedOrder.address = '';
            loadedOrder.addressLocation = {};
            loadedOrder.area = '';
            loadedOrder.areaCode = '';
            loadedOrder.suburbId = '';
            loadedOrder.isAllowToConfirm = false;
          }
        }

        let { currentOd, rowItems } = engineGetOrderCalculations(
          loadedOrder,
          props
        );
        dispatch(setPropsState({ currentOrder: currentOd, type: '' }));
        setLocalState({ rowItems });
        break;
      }
      default:
        break;
    }
  };

  const redirectAndCleanupOrder = () => {
    if (window.localStorage) {
      const isGfoOrder = isGfoOrderFun();
      const cachedOrder = localStorage.get('currentOrder');
      if (cachedOrder) {
        const initOrderData = {
          address: '',
          addressLocation: {},
          orderStatus: '0',
          orderFrom: MENU_ORDER_FROM.web,
          orderType: MENU_ORDER_TYPE.pickup,
          specials: [],
          menuItems: [],
          stage: ORDER_STAGE_TYPES.NONE,
          totalCost: 0,
          payableAmount: 0,
          isAllowToConfirm: false,
          ...(isGfoOrder && { isGfoOrder }),
        };
        localStorage.set('currentOrder', {
          ...initOrderData,
        });
        dispatch(setPropsState({ currentOrder: initOrderData }));
      }
    }
    firebaseAnalyticsNO.logEvent(eventNames.PAID_FOR_ORDER);
  };

  const addReservationhandler = (obj) => {
    if (userDetails && userDetails._id) {
      if (isNaN(obj.reservationDate) === true) {
        let reservationInterimDate = new Date(obj.reservationDate);
        obj.reservationDateTimestamp = reservationInterimDate.getTime();
      }
      dispatch(addReservation(obj, userDetails));
    }
  };

  // ~updaters~

  const updateCurrentUser = (user, details, stage) => {
    let orderObj = JSON.parse(JSON.stringify(currentOrder));
    let newUser = {
      date: moment().tz(storeConfig.timeZone).format(),
      dateTimestamp: getCurrentTimestamp(),
      deviceToken: '',
      email: details.userEmail ? details.userEmail : '',
      firstName: details.firstName,
      isActive: true,
      lastName: details.lastName,
      mobileNumber: getFormattedMobileNumber(details.mobileNumber),
      password: '',
      streetAddress: currentOrder.address ? currentOrder.address : '',
      addressLocation: currentOrder.addressLocation
        ? currentOrder.addressLocation
        : '',
      suburbId: currentOrder.suburbId ? currentOrder.suburbId : '',
      type: '3',
      userRole: '3',
      userId: user.uid,
      ratings: 0,
      totalRatings: 0,
      averageRating: 0,
      totalOrders: 0,
      totalRevenue: 0,
      totalDeliveryOrders: 0,
      totalDeliveryOrdersNow: 0,
      totalPickupOrders: 0,
      deliveryRevenue: 0,
      pickupRevenue: 0,
      totalDeliveryTime: 0,
      averageDeliveryTime: 0,
      storeId: storeConfig.storeId,
      restaurantId: storeConfig.restaurantId,
    };

    if (
      stage === ORDER_STAGE_TYPES.REVIEW &&
      (localState.reservation || localState.loginType !== LOGIN_FROM_TYPE.MAIN)
    ) {
      orderObj.stage = ORDER_STAGE_TYPES.NONE;
    } else {
      if (localState.reservation) {
        orderObj.stage = ORDER_STAGE_TYPES.NONE;
      } else if (localState.initiateDesktopLoyaltyFlow || localState.loyalty) {
        orderObj.stage = ORDER_STAGE_TYPES.NONE;
      } else {
        orderObj.stage = stage;
      }
    }

    const { currentOd, rowItems } = engineGetOrderCalculations(orderObj, props);

    setLocalState({ userDetails: newUser, rowItems });

    dispatch(
      setPropsState({
        ...defaultHomePropsState,
        currentOrder: currentOd,
        bookingOpen: localState.loginType === LOGIN_FROM_TYPE.BOOKING,
        loyaltyOpen: localState.loginType === LOGIN_FROM_TYPE.LOYALTY,
      })
    );

    dispatch(
      getCurrentUseDetails(
        user,
        newUser,
        storeConfig.storeId,
        storeConfig.restaurantId,
        true
      )
    );
  };

  const updateOrderType = (type, flag) => {
    let orderObj = JSON.parse(JSON.stringify(currentOrder));
    if (type === '1') {
      orderObj.orderType = type;
      orderObj.address = '';
      orderObj.addressLocation = '';
      orderObj.placeId = '';
      orderObj.suburbId = '';
      orderObj.minOrderValue = '';
      orderObj.areaCode = '';
      orderObj.area = '';
      orderObj.deliveryCost = 0;
      orderObj.deliveryDate = undefined;
      orderObj.deliveryData = {};
    } else {
      orderObj.orderType = type;
      orderObj.deliveryDate = undefined;
    }

    if (orderObj?.menuItems && orderObj?.menuItems.length > 0) {
      orderObj?.menuItems?.map((menuItem) => {
        if (menuItemSizes?.length || menuItemSubModifiers?.length) {
          const newMenuItemSizes = menuItemSizes?.filter(
            (f) => f.menuItemId === menuItem._id
          );
          const newMenuItemSubModifiers = menuItemSubModifiers?.filter(
            (f) => f.menuItemId === menuItem._id
          );
          return getMinifiedMenuItemForInstore(
            menuItem,
            newMenuItemSizes,
            newMenuItemSubModifiers,
            type,
            false
          );
        } else {
          return menuItem;
        }
      });
    }

    const { currentOd, rowItems } = engineGetOrderCalculations(orderObj, props);
    dispatch(
      setPropsState({
        currentOrder: {
          ...currentOd,
          notes: orderObj.notes,
        },
      })
    );
    setLocalState({
      rowItems,
      type: flag ? localState.type : undefined,
      address: flag ? localState.address : undefined,
      lat: flag ? localState.lat : undefined,
      lng: flag ? localState.lng : undefined,
      placeId: flag ? localState.placeId : undefined,
      suburbId: flag ? localState.suburbId : undefined,
      deliveryCost: flag ? localState.deliveryCost : undefined,
      minOrderValue: flag ? localState.minOrderValue : undefined,
      areaCode: flag ? localState.areaCode : undefined,
    });
  };

  const updateOrderStage = (stage, loginType) => {
    let orderObj = JSON.parse(JSON.stringify(currentOrder));

    if (
      stage === ORDER_STAGE_TYPES.REVIEW &&
      (currentUser?.phoneNumber || userDetails?.mobileNumber)
    ) {
      dispatch(
        setPropsState({
          ...defaultHomePropsState,
          currentOrder: { ...currentOrder, stage: ORDER_STAGE_TYPES.REVIEW },
        })
      );
    } else if (stage === ORDER_STAGE_TYPES.REVIEW) {
      if (orderObj.stage === ORDER_STAGE_TYPES.PAYMENT_CARD) {
        dispatch(
          setPropsState({
            ...defaultHomePropsState,
            currentOrder: { ...currentOrder, stage: ORDER_STAGE_TYPES.PAYMENT },
          })
        );
      }
    } else {
      orderObj.stage = stage;
      if (stage === ORDER_STAGE_TYPES.PAYMENT) {
        orderObj.paymentType = ORDER_PAYMENT_TYPE.unpaid;
      }
      if (stage === ORDER_STAGE_TYPES.PAYMENT_CARD) {
        orderObj.paymentType = ORDER_PAYMENT_TYPE.card_online;
      }

      const { currentOd, rowItems } = engineGetOrderCalculations(
        orderObj,
        props
      );

      setLocalState({
        rowItems,
        ...(loginType ? { loginType } : {}),
      });
      dispatch(
        setPropsState({ ...defaultHomePropsState, currentOrder: currentOd })
      );
    }
  };

  // update order stage back is used in _setTimeout_ so we pass props as a parameter
  // ℹ️ we will only pass params if we are calling this function from _setTimeout_
  const updateOrderStageBack = (
    e,
    propsParam = props,
    localStateParam = localState
  ) => {
    e?.preventDefault();

    let orderObj = JSON.parse(JSON.stringify(propsParam.currentOrder));
    if (propsParam.currentOrder.stage === ORDER_STAGE_TYPES.REVIEW) {
      dispatch(
        setPropsState({
          ...defaultHomePropsState,
          currentOrder: {
            ...propsParam.currentOrder,
            stage: ORDER_STAGE_TYPES.NONE,
          },
        })
      );
    } else if (
      propsParam.currentOrder.stage === ORDER_STAGE_TYPES.VERIFY_FROM
    ) {
      dispatch(
        setPropsState({
          ...defaultHomePropsState,
          currentOrder: {
            ...propsParam.currentOrder,
            stage: ORDER_STAGE_TYPES.MOBILE_FORM,
          },
        })
      );
    } else if (
      propsParam.currentOrder.stage === ORDER_STAGE_TYPES.MOBILE_FORM
    ) {
      dispatch(
        setPropsState({
          ...defaultHomePropsState,
          currentOrder: {
            ...propsParam.currentOrder,
            stage: ORDER_STAGE_TYPES.NAME_FORM,
          },
        })
      );
    } else if (
      propsParam.currentOrder.stage === ORDER_STAGE_TYPES.PAYMENT_CARD
    ) {
      const canSkipPaymentScreen = canSkipPaymentMethod({
        orderSetup: props.orderSetup,
        currentOrder: propsParam.currentOrder,
      });
      const orderStage = canSkipPaymentScreen
        ? ORDER_STAGE_TYPES.REVIEW
        : ORDER_STAGE_TYPES.PAYMENT;
      dispatch(
        setPropsState({
          ...defaultHomePropsState,
          currentOrder: {
            ...propsParam.currentOrder,
            stage: orderStage,
            paymentType: ORDER_PAYMENT_TYPE.unpaid,
          },
        })
      );
    } else {
      if (
        propsParam.currentOrder.stage === ORDER_STAGE_TYPES.NAME_FORM ||
        propsParam.currentOrder.stage === ORDER_STAGE_TYPES.PAYMENT
      )
        orderObj.stage = orderObj.stage - 1;
      if (
        (localStateParam.initiateDesktopLoyaltyFlow ||
          localStateParam.loyalty) &&
        propsParam.currentOrder.stage === ORDER_STAGE_TYPES.NAME_FORM
      ) {
        dispatch(setLayoutFlow(false));
      }

      const { currentOd, rowItems } = engineGetOrderCalculations(
        orderObj,
        propsParam
      );
      setLocalState({ rowItems });
      dispatch(
        setPropsState({ ...defaultHomePropsState, currentOrder: currentOd })
      );
    }
  };

  const updateOrderDeliveryAddress = (addressObj, suburb, areaCode, area) => {
    const calculations = engineUpdateOrderDeliveryAddress(
      addressObj,
      suburb,
      areaCode,
      area,
      props
    );

    if (!calculations) return;
    const { currentOd, rowItems } = calculations;
    setLocalState({ rowItems });
    dispatch(setPropsState({ currentOrder: currentOd }));
  };

  const updateDeliveryPickupTime = (
    deliveryDate,
    shiftId,
    isCartConfirm,
    stage,
    isFutureDeliveryDate
  ) => {
    const calculations = engineUpdateDeliveryPickupTime(
      deliveryDate,
      shiftId,
      isCartConfirm,
      stage,
      isFutureDeliveryDate,
      props
    );

    if (!calculations) return;
    const { currentOd, rowItems } = calculations;
    setLocalState({ rowItems });
    dispatch(setPropsState({ currentOrder: currentOd }));
  };

  const updateVoucher = (voucher) => {
    if (!voucher?._id) return;
    let orderObj = JSON.parse(JSON.stringify(currentOrder));
    orderObj.menuItems = orderObj.menuItems.map(
      ({ voucherId, isVoucherItem, ...rest }) => {
        return rest;
      }
    );
    orderObj.voucherId = voucher._id;
    orderObj.selectedVoucher = voucher;
    orderObj.voucherName = voucher.name;

    if (Number(voucher.type) !== 3) {
      const { currentOd, rowItems } = engineGetOrderCalculations(
        orderObj,
        props
      );
      setLocalState({ rowItems });
      dispatch(setPropsState({ currentOrder: currentOd }));
    } else {
      let overallMenuItems = [];
      let overallMenuItemsMap = {};
      let voucherQuantity = 0;

      const menuItemsMap = menuItems.reduce(
        (acc, i) => ({ ...acc, [i._id]: i }),
        {}
      );
      const { menuSizes: voucherMenuSizes, subModifiers: voucherSubModifiers } =
        voucher?.selectedCategories[0];

      let rowQuantity = 0;
      let voucherRowItems = [];

      voucher?.selectedCategories.forEach((category) => {
        let activeSizes = category.menuSizes.filter((o) => o.isActive === true);
        let activeSubModifiers = category.subModifiers.filter(
          (o) => o.isActive === true
        );
        let tempVoucherQuantity = category.quantity;
        category.menuItems.forEach((menuItem) => {
          if (menuItem.isActive) {
            overallMenuItems.push(menuItemsMap[menuItem._id]);
            overallMenuItemsMap[menuItem._id] = menuItemsMap[menuItem._id];
          }
        });

        orderObj.menuItems?.forEach((item) => {
          if (item.voucherId) return;
          let isModifierMatches = false;
          item.selectedSizes.forEach((item) => {
            const matchingActiveSizeExists = activeSizes.some(
              (s) => s._id === item._id && s.isActive
            );
            const matchingActiveSubModifierExists = activeSubModifiers.some(
              (s) => s._id === item._id && s.isActive
            );
            if (matchingActiveSizeExists || matchingActiveSubModifierExists) {
              isModifierMatches = true;
            }
          });
          const itemPrice = Number(getItemPrice(item, halfHalfSetup));
          if (overallMenuItemsMap[item._id] && isModifierMatches) {
            if (tempVoucherQuantity > 0) {
              let price =
                (item.quantity > tempVoucherQuantity
                  ? tempVoucherQuantity
                  : item.quantity) *
                (itemPrice / item.quantity);
              rowQuantity +=
                item.quantity > tempVoucherQuantity
                  ? tempVoucherQuantity
                  : item.quantity;
              item.voucherId = voucher._id;
              item.isVoucherItem = true;
              voucherRowItems.push({
                ...item,
                quantity:
                  item.quantity > tempVoucherQuantity
                    ? tempVoucherQuantity
                    : item.quantity,
                itemPrice: price,
              });
              tempVoucherQuantity -= item.quantity;
            }
          }
        });
        voucherQuantity += Number(category.quantity);
      });

      if (rowQuantity >= voucherQuantity) {
        const { currentOd, rowItems } = engineGetOrderCalculations(orderObj, {
          ...props,
          userSelectedVoucherItems: voucherRowItems,
        });
        dispatch(setUserSelectedVoucherItems(voucherRowItems));
        dispatch(setPropsState({ currentOrder: currentOd }));
        setLocalState({ rowItems });
      } else {
        orderObj.menuItems = orderObj.menuItems.map(
          ({ voucherId, isVoucherItem, ...rest }) => {
            return rest;
          }
        );
        if (
          overallMenuItems &&
          overallMenuItems.length === 1 &&
          overallMenuItems[0].selectedExtraIngredients.length === 0 &&
          overallMenuItems[0].selectedIngredients.length === 0 &&
          overallMenuItems[0].selectedModifiers.length === 0
        ) {
          const chosenSize = overallMenuItems[0].selectedSizes?.[0] ?? {};
          const obj = engineShowMenuItemHandler(
            overallMenuItems[0],
            soldOutMenuItems
          );
          const itemObj = addToOrderItem(obj, halfHalfSetup);
          itemObj.isVoucherItem = true;
          itemObj.voucherId = voucher._id;
          const { currentOd } = engineAddToOrder(itemObj, {
            ...props,
            userSelectedVoucherItems: [itemObj],
            currentOrder: orderObj,
          });
          const { currentOd: finalCurrentOd } = engineSetItemQuantity(
            chosenSize,
            itemObj,
            Number(voucherQuantity),
            {
              ...props,
              userSelectedVoucherItems: [itemObj],
              currentOrder: currentOd,
            }
          );
          finalCurrentOd.voucherId = voucher._id;
          finalCurrentOd.selectedVoucher = voucher;
          finalCurrentOd.voucherName = voucher.voucherCode;
          dispatch(setUserSelectedVoucherItems([itemObj]));
          dispatch(setPropsState({ currentOrder: finalCurrentOd }));
        } else if (overallMenuItems && overallMenuItems.length === 1) {
          const { currentOd, rowItems } = engineGetOrderCalculations(
            orderObj,
            props
          );
          setLocalState({ rowItems });
          dispatch(setPropsState({ currentOrder: currentOd }));
          const voucherItem = engineShowSpecialItem(
            overallMenuItems[0],
            voucherMenuSizes,
            voucherSubModifiers
          );
          voucherItem.isVoucherItem = true;
          voucherItem.voucherId = voucher._id;
          showVoucherItem(voucherItem, voucherMenuSizes, voucherSubModifiers);
        } else {
          const { currentOd, rowItems } = engineGetOrderCalculations(
            orderObj,
            props
          );
          setLocalState({ rowItems });
          dispatch(setPropsState({ currentOrder: currentOd }));
          showVoucherMenuItemsHandler(
            voucher,
            voucherRowItems,
            overallMenuItemsMap,
            voucher
          );
        }
      }
    }
  };

  const removeVoucher = (curOrd) => {
    if (!curOrd && currentOrder.voucherId && autoVouchers.length > 0) {
      const otMap = { 1: 'Pickup', 2: 'Delivery', 3: 'DineIn' };
      const { orderType } = currentOrder;
      const suffix = otMap[orderType];
      let matchingVouchers = autoVouchers.filter(
        (f) => f[`isAvailable${suffix}`]
      );
      if (matchingVouchers.length > 0) {
        dispatch(setLayoutState({ autoVouchers: [] }));
      }
    }

    let orderObj = curOrd
      ? JSON.parse(JSON.stringify(curOrd))
      : JSON.parse(JSON.stringify(currentOrder));
    orderObj.voucherDiscount = '0';
    orderObj.voucherId = '';
    orderObj.voucherName = '';
    orderObj.selectedVoucher = {};
    const { currentOd, rowItems } = engineGetOrderCalculations(orderObj, {
      ...props,
      ...(currentOrder.voucherId && autoVouchers.length > 0
        ? { autoVouchers: [] }
        : null),
    });
    setLocalState({ rowItems });
    dispatch(setUserSelectedVoucherItems([]));
    dispatch(setPropsState({ currentOrder: currentOd }));
  };

  const saveUnit = (unit) => {
    if (!unit) return;

    let orderObj = JSON.parse(JSON.stringify(currentOrder));
    orderObj.unit = unit;
    const { currentOd, rowItems } = engineGetOrderCalculations(orderObj, props);
    setLocalState({ rowItems });
    dispatch(setPropsState({ currentOrder: currentOd }));
  };

  const updatePaymenyType = (
    cardDescription,
    cardNumber,
    cardType,
    isSameCard,
    isCardDetailsValid,
    token,
    cardCountry
  ) => {
    const { currentOd, rowItems } = engineUpdatePaymenyType(
      cardDescription,
      cardNumber,
      cardType,
      isSameCard,
      isCardDetailsValid,
      token,
      cardCountry,
      props
    );

    setLocalState({ rowItems });
    dispatch(setPropsState({ currentOrder: currentOd }));
  };

  const updatePaymentTypeForNewCard = (isCardDetailsValid, card) => {
    let { currentOd, rowItems } = engineUpdatePaymentTypeForNewCard(
      isCardDetailsValid,
      card,
      props
    );
    setLocalState({ rowItems });
    dispatch(setPropsState({ currentOrder: currentOd }));
  };

  const updatePaymentTypeForCash = () => {
    let orderObj = JSON.parse(JSON.stringify(currentOrder));
    orderObj.paymentType = ORDER_PAYMENT_TYPE.cash;
    orderObj.cardNumber = '';
    orderObj.cardType = '';
    orderObj.isInternationCard = false;
    const { currentOd, rowItems } = engineGetOrderCalculations(orderObj, props);
    setLocalState({ rowItems });
    dispatch(setPropsState({ currentOrder: currentOd }));
  };

  const updatePaymentTypeForWallet = () => {
    let orderObj = JSON.parse(JSON.stringify(currentOrder));
    orderObj.paymentType = ORDER_PAYMENT_TYPE.card_online;
    orderObj.cardNumber = '';
    orderObj.cardType = '';
    orderObj.isInternationCard = false;
    const { currentOd, rowItems } = engineGetOrderCalculations(orderObj, props);
    setLocalState({ rowItems });
    dispatch(setPropsState({ currentOrder: currentOd }));
  };

  const updatePaymentTypeForCard = () => {
    const { currentOrder } = props;
    let orderObj = JSON.parse(JSON.stringify(currentOrder));
    if (orderObj.paymentType === ORDER_PAYMENT_TYPE.card_online)
      orderObj.paymentType = ORDER_PAYMENT_TYPE.card_online;
    else orderObj.paymentType = ORDER_PAYMENT_TYPE.card_online;
    orderObj.stage = ORDER_STAGE_TYPES.PAYMENT_CARD;
    const { currentOd, rowItems } = engineGetOrderCalculations(orderObj, props);
    setLocalState({ rowItems });
    dispatch(setPropsState({ currentOrder: currentOd }));
  };

  // ~combiners~

  const addToOrder = (item) => {
    dispatch(toggleMobileCart(false));

    if (item.inEditMode) {
      updateToOrder(item);
      delete item.inEditMode;
      return;
    }

    const calculations = engineAddToOrder(
      item,
      item.isVoucherItem
        ? { ...props, userSelectedVoucherItems: [item] }
        : props
    );

    if (!calculations) return;

    const { currentOd, rowItems } = calculations;
    dispatch(setPropsState({ currentOrder: currentOd }));
    if (item.voucherId) dispatch(setUserSelectedVoucherItems([item]));
    setLocalState({
      rowItems,
      rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.NONE,
      itemInProgress: null,
    });
  };

  const updateToOrder = (item) => {
    dispatch(toggleMobileCart(false));

    const {
      currentOrder,
      allSpecials,
      orderSetup,
      productSetup,
      allSuburbs,
      publicHolidays,
      storeConfig,
    } = props;

    const orderObj = updateItemToOrder(
      currentOrder,
      item,
      allSpecials,
      orderSetup,
      productSetup,
      allSuburbs,
      publicHolidays,
      storeConfig
    );

    const { currentOd, rowItems } = engineGetOrderCalculations(orderObj, props);

    dispatch(setPropsState({ currentOrder: currentOd }));
    setLocalState({
      rowItems,
      rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.NONE,
      itemInProgress: null,
    });
  };

  // we use addItemsToOrder in _setTimeout_ therefore passing props as param
  const addItemsToOrder = (items, propsParam = props) => {
    const { currentOd, rowItems } = engineAddItemsToOrder(items, propsParam);

    setLocalState({ rowItems });
    dispatch(setPropsState({ currentOrder: currentOd }));
  };

  const addHalfItemsToOrder = (
    itemFH,
    itemSH,
    halfHalfAdditionalCharges,
    propsParam = props
  ) => {
    const { currentOd, rowItems } = engineAddHalfItemsToOrder(
      itemFH,
      itemSH,
      halfHalfAdditionalCharges,
      propsParam
    );

    setLocalState({ rowItems });
    dispatch(setPropsState({ currentOrder: currentOd }));
  };

  const addToSpecial = (item, givenBoxIndex) => {
    if (!localState.specialItem?._id) return;

    const { selectedSpecials, specialItem } = localState;

    const newList = engineAddToSpecial(
      item,
      { selectedSpecials, specialItem },
      givenBoxIndex ? givenBoxIndex : boxIndex
    );

    setLocalState({
      rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.SPECIAL_VIEW,
      itemExist: -1,
      selectedSpecials: newList,
      itemInProgress: { ...localState.itemInProgress, _id: 'specialTest' },
      orderItem: { ...localState.orderItem, _id: 'specialTest' },
    });
  };

  const addToVoucher = (item, givenBoxIndex) => {
    if (!localState.voucherItem?._id) {
      addToOrder(item);
      return;
    }

    const { selectedSpecials, voucherItem } = localState;

    const newList = engineAddToSpecial(
      item,
      { selectedSpecials, voucherItem },
      givenBoxIndex ? givenBoxIndex : voucherBoxIndex
    );

    setLocalState({
      rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.VOUCHER_VIEW,
      itemExist: -1,
      selectedSpecials: newList,
      itemInProgress: { ...localState.itemInProgress, _id: 'specialTest' },
      orderItem: { ...localState.orderItem, _id: 'specialTest' },
    });
  };

  const addMultipleToSpecials = (items) => {
    if (!localState.specialItem?._id) return;

    const { selectedSpecials, specialItem } = localState;

    const tempArray = [...items, ...selectedSpecials];

    // const newUniqueItems = uniqBy(tempArray, '_id');

    const newList = engineMultipleAddToSpecial(tempArray, specialItem);

    setLocalState({
      rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.SPECIAL_VIEW,
      itemExist: -1,
      selectedSpecials: newList,
      itemInProgress: { ...localState.itemInProgress, _id: 'specialTest' },
      orderItem: { ...localState.orderItem, _id: 'specialTest' },
    });
  };

  const addToHalfHalf = (item) => {
    const { selectedHalfItems, whichHalf } = localState;

    const itemsPresent = engineAddToHalfHalf(
      item,
      { selectedHalfItems, whichHalf },
      halfHalfSetup
    );

    setLocalState({
      rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.HALF_HALF_VIEW,
      whichHalf: localState.whichHalf === 1 ? 2 : 1,
      selectedHalfItems: itemsPresent,
      itemInProgress: null,
    });
  };

  const addSpecialToOrder = (specialItem) => {
    if (
      specialItem &&
      specialItem.selectedCategories &&
      localState.selectedSpecials
    ) {
      batch(() => {
        dispatch(dispatch(toggleMobileCart(false)));
        dispatch(
          filterView({
            isSpecialFilterApplied: false,
            spMenuItems: [],
            spMenuSizes: [],
            spSubModifiers: [],
            spMenuCategories: [],
            boxIndex: -1,
          })
        );
      });

      setLocalState({
        rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.NONE,
        specialItem: {},
        isAutoSelectPopulated: false,
      });
      const menuUnderline = document.getElementsByClassName(
        'tabsSlider--menuUnderline'
      );
      if (menuUnderline[0]) {
        menuUnderline[0].style.width = '53px';
      }

      const itemsToBeAdded = engineDoASelfMerge(localState.selectedSpecials);
      addItemsToOrder(itemsToBeAdded.filter(({ isHalf }) => !isHalf));

      setTimeout(() => {
        const halfItems = localState.selectedSpecials.filter(
          ({ isHalf }) => isHalf
        );

        if (halfItems) {
          const grouped = Object.values(
            halfItems.reduce(
              (acc, item) => ({
                ...acc,
                [item.boxIndex]: [
                  ...(acc[item.boxIndex] || []),
                  {
                    ...item,
                    halfIndex: item.halfIndex + item.boxIndex,
                  },
                ],
              }),
              {}
            )
          );
          for (const [FH, SH] of grouped) {
            // This will give the latest props
            const propsParam = getProps();
            if (FH && SH) {
              addHalfItemsToOrder(FH, SH, 0, propsParam);
            } else if (!SH) {
              const items = [{ ...FH, quantity: 1, isHalf: false }];
              addItemsToOrder(items, propsParam);
            } else if (!FH && SH) {
              const items = [{ ...SH, quantity: 1, isHalf: false }];
              addItemsToOrder(items, propsParam);
            }
          }
        }
      }, 300);
    }
  };

  const addVoucherItemsToOrder = (specialItem) => {
    if (
      specialItem &&
      specialItem.selectedCategories &&
      localState.selectedSpecials
    ) {
      batch(() => {
        dispatch(dispatch(toggleMobileCart(false)));
        dispatch(
          voucherFilterView({
            isVoucherFilterApplied: false,
            voucherMenuItems: [],
            voucherMenuSizes: [],
            voucherSubModifiers: [],
            voucherMenuCategories: [],
            voucherBoxIndex: -1,
          })
        );
      });

      setLocalState({
        rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.NONE,
        voucherItem: {},
        isAutoSelectPopulated: false,
      });
      const menuUnderline = document.getElementsByClassName(
        'tabsSlider--menuUnderline'
      );
      if (menuUnderline[0]) {
        menuUnderline[0].style.width = '53px';
      }

      const itemsToBeAdded = engineDoASelfMerge(localState.selectedSpecials);
      dispatch(setUserSelectedVoucherItems(itemsToBeAdded));
      setLocalState({
        userAddedVoucherItems: itemsToBeAdded,
      });
      addItemsToOrder(
        itemsToBeAdded
          .map((item) => ({ ...item, isVoucherItem: true }))
          .filter(({ isHalf }) => !isHalf),
        {
          ...props,
          userSelectedVoucherItems: itemsToBeAdded,
        }
      );
    }
  };

  const incrementItemQuantity = (sizeObj, itemObj) => {
    const calculations = engineIncrementItemQuantity(sizeObj, itemObj, props);
    if (!calculations) return;
    const { currentOd, rowItems } = calculations;

    setLocalState({ rowItems });
    dispatch(setPropsState({ currentOrder: currentOd }));
  };

  const incrementExtraQuantity = (extraObj, itemObj, whichHalf) => {
    const calculations = engineIncrementExtraQuantity(
      extraObj,
      itemObj,
      whichHalf,
      props
    );
    if (!calculations) return;
    const { currentOd, rowItems } = calculations;

    setLocalState({ rowItems });
    dispatch(setPropsState({ currentOrder: currentOd }));
  };

  const decrementItemQuantity = (sizeObj, itemObj) => {
    const calculations = engineDecrementItemQuantity(sizeObj, itemObj, props);
    if (!calculations) return;
    const { currentOd, rowItems } = calculations;
    const hasVoucherItem = currentOd.menuItems.some((item) =>
      Boolean(item.isVoucherItem)
    );
    if (!hasVoucherItem) {
      currentOd.voucherDiscount = '0';
      currentOd.voucherId = '';
      currentOd.voucherName = '';
      currentOd.selectedVoucher = {};
      const { currentOd: newCurrentOrder, rowItems } =
        engineGetOrderCalculations(currentOd, props);
      setLocalState({ rowItems });
      dispatch(setPropsState({ currentOrder: newCurrentOrder }));
      dispatch(setUserSelectedVoucherItems([]));
    } else {
      setLocalState({ rowItems });
      dispatch(setPropsState({ currentOrder: currentOd }));
    }
  };

  const removeItemFromOrder = (itemObj) => {
    const calculations = engineRemoveItemFromOrder(itemObj, props);
    if (!calculations) return;
    const { currentOd, rowItems } = calculations;
    const hasVoucherItem = currentOd.menuItems.some((item) =>
      Boolean(item.isVoucherItem)
    );
    if (!hasVoucherItem) {
      currentOd.voucherDiscount = '0';
      currentOd.voucherId = '';
      currentOd.voucherName = '';
      currentOd.selectedVoucher = {};
      const { currentOd: newCurrentOrder, rowItems } =
        engineGetOrderCalculations(currentOd, props);
      setLocalState({ rowItems });
      dispatch(setPropsState({ currentOrder: newCurrentOrder }));
    } else {
      setLocalState({ rowItems });
      dispatch(setPropsState({ currentOrder: currentOd }));
    }
  };

  const decrementExtraQuantity = (extraObj, itemObj, whichHalf) => {
    const calculations = engineDecrementExtraQuantity(
      extraObj,
      itemObj,
      whichHalf,
      props
    );
    if (!calculations) return;
    const { currentOd, rowItems } = calculations;

    setLocalState({ rowItems });
    dispatch(setPropsState({ currentOrder: currentOd }));
  };

  // ~half/half~

  const clearItemsNotAvailableForVariants = (itemVariants) => {
    setLocalState({ selectedHalfItems: [], itemVariants });
  };

  const addHalfHalfToOrder = () => {
    const { selectedHalfItems, itemVariants } = localState;
    if (!(selectedHalfItems && selectedHalfItems.length >= 2)) return;

    const { firstItem, secondItem, newCharges } = engineAddHalfHalfToOrder(
      { selectedHalfItems, itemVariants },
      { currentOrder, halfHalfSetup }
    );

    if (isSpecialFilterApplied) {
      setLocalState({
        rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.SPECIAL_VIEW,
        halfItem: {},
        itemVariants: [],
      });
      addToSpecial([firstItem, secondItem]);
    } else {
      dispatch(toggleMobileCart(false));

      setLocalState({
        rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.NONE,
        halfItem: {},
        itemVariants: [],
      });

      let menuUnderline = document.getElementsByClassName(
        'tabsSlider--menuUnderline'
      );
      if (menuUnderline[0]) {
        menuUnderline[0].style.width = '53px';
      }

      // add global menu item quantity field
      firstItem.quantity = 0.5;
      secondItem.quantity = 0.5;

      addHalfItemsToOrder(firstItem, secondItem, newCharges);
      history.replace('/category/popular');
    }
  };

  // ~mergers~

  const removeOutOfScheduleItems = () => {
    const { currentOd, rowItems } = engineRemoveOutOfScheduleItems(props);
    setLocalState({ rowItems });
    dispatch(setPropsState({ currentOrder: currentOd }));
  };

  const removeOtherThanSelectedOrderTypeItems = () => {
    const { currentOd, rowItems } =
      engineRemoveOtherThanSelectedOrderTypeItems(props);
    setLocalState({ rowItems });
    dispatch(setPropsState({ currentOrder: currentOd }));
  };

  const removeSoldOutItems = () => {
    const { currentOd, rowItems } = engineRemoveSoldOutItems(props);
    setLocalState({ rowItems });
    dispatch(setPropsState({ currentOrder: currentOd }));
  };

  const showSpecialsHandler = (arrObj) => {
    setLocalState({
      arrKey: arrObj._id,
      rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.SPECIAL_VIEW,
      toggleSpecials:
        localState.arrKey === arrObj._id
          ? localState.toggleSpecials
          : !localState.toggleSpecials,
      specialItem: arrObj,
      selectedSpecials: [],
    });
    dispatch(
      setPropsState({
        ...defaultHomePropsState,
        selectedSpecialId: arrObj?._id,
      })
    );
  };

  const showVoucherMenuItemsHandler = (
    arrObj,
    voucherItems,
    overallMenuItemsMap,
    voucher
  ) => {
    let allItems = [];
    let lastIndex = 0;
    if (voucher.selectedCategories?.length === 1)
      voucherItems.forEach((item) => {
        for (let i = 0; i < item.quantity; i++) {
          let eachMenuItem = overallMenuItemsMap[item._id];
          const obj = engineShowSpecialItem(
            eachMenuItem,
            voucherMenuSizes,
            voucherSubModifiers
          );
          const finalResult = addToOrderItem(obj, halfHalfSetup);
          allItems.push({
            ...finalResult,
            boxIndex: lastIndex,
            voucherId: voucher._id,
          });
          lastIndex++;
        }
      });

    setLocalState({
      arrKey: arrObj._id,
      rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.VOUCHER_VIEW,
      voucherItem: arrObj,
      selectedSpecials: allItems && allItems.length ? allItems : [],
    });
  };

  const showVoucherItem = (
    arrObj,

    // if showSpecialItem is called from setTimeout then
    // we need to pass specialMenuSizes and specialSubModifiers
    // to get the latest props
    specialMenuSizes = voucherMenuSizes,
    specialSubModifiers = voucherSubModifiers
  ) => {
    const obj = engineShowSpecialItem(
      arrObj,
      specialMenuSizes,
      specialSubModifiers
    );
    if (!obj) return;
    setLocalState({
      arrKey: arrObj.name,
      rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.VOUCHER_ORDER_VIEW,
      toggleSpeacialItem:
        localState.arrKey === arrObj.name
          ? localState.toggleSpeacialItem
          : !localState.toggleSpeacialItem,
      orderItem: obj,
      itemInProgress: obj,
    });
    dispatch(setPropsState({ ...defaultHomePropsState }));
  };

  const showSpecialItem = (
    arrObj,

    // if showSpecialItem is called from setTimeout then
    // we need to pass specialMenuSizes and specialSubModifiers
    // to get the latest props
    specialMenuSizes = spMenuSizes,
    specialSubModifiers = spSubModifiers
  ) => {
    const obj = engineShowSpecialItem(
      arrObj,
      specialMenuSizes,
      specialSubModifiers
    );
    if (!obj) return;
    setLocalState({
      arrKey: arrObj.name,
      rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.SPECIAL_ORDER_VIEW,
      toggleSpeacialItem:
        localState.arrKey === arrObj.name
          ? localState.toggleSpeacialItem
          : !localState.toggleSpeacialItem,
      orderItem: obj,
      itemInProgress: obj,
    });
    dispatch(setPropsState({ ...defaultHomePropsState }));
  };

  const showHalfItem = (arrObj) => {
    const { itemVariants, hhActiveMenuSizes } = localState;
    const obj = engineShowHalfItem(arrObj, { itemVariants, hhActiveMenuSizes });
    setLocalState({
      arrKey: arrObj.name,
      rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.HALF_ORDER_VIEW,
      toggleSpeacialItem:
        localState.arrKey === arrObj.name
          ? localState.toggleSpeacialItem
          : !localState.toggleSpeacialItem,
      orderItem: obj,
      itemInProgress: obj,
    });
    dispatch(setPropsState({ ...defaultHomePropsState }));
  };

  const showHalfViewHandler = (arrObj) => {
    const isMobile =
      window.innerWidth < 992 ||
      (window.navigator.userAgent.match(/iPad/i) && window.innerHeight > 1250);
    setLocalState({
      rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.HALF_HALF_VIEW,
      halfAnimation:
        localState.arrKey === arrObj.name
          ? localState.halfAnimation
          : !localState.halfAnimation,
      arrKey: arrObj.name,
      selectedHalfItems: [],
      halfItem: arrObj,
      whichHalf: isMobile ? null : 1,
    });
    dispatch(setPropsState({ ...defaultHomePropsState }));
  };

  const halfFilter = (whichHalf, itemVariants, hhActiveMenuSizes) => {
    dispatch(toggleMobileCart(false));
    setLocalState({
      whichHalf,
      itemVariants,
      hhActiveMenuSizes,
    });
    if (
      window.innerWidth < 992 ||
      (window.navigator.userAgent.match(/iPad/i) && window.innerHeight > 1250)
    ) {
      setLocalState({
        rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.NONE,
      });
    }
  };

  const clearOrChangeSize = (hhActiveMenuSizes, itemVariants) => {
    const selectedHalfItems = localState.selectedHalfItems;
    const obj = engineClearOrChangeSize(
      hhActiveMenuSizes,
      itemVariants,
      selectedHalfItems,
      menuItems
    );
    if (obj?.selectedHalfItems?.length > 1) {
      obj.selectedHalfItems = engineAddToHalfHalf(
        obj.selectedHalfItems[0],
        {
          selectedHalfItems: obj.selectedHalfItems,
          whichHalf: obj.selectedHalfItems[0]?.whichHalf,
        },
        halfHalfSetup
      );
    }
    setLocalState({ ...obj });
  };

  const showMenuItemHandler = (arrObj) => {
    if (!arrObj) return;
    const obj = engineShowMenuItemHandler(arrObj, soldOutMenuItems);
    setLocalState({
      arrKey: arrObj._id,
      rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.ORDER_VIEW,
      toggleAnimation:
        localState.arrKey === arrObj._id
          ? localState.toggleAnimation
          : !localState.toggleAnimation,
      orderItem: obj,
      itemInProgress: obj,
    });
    dispatch(setPropsState({ ...defaultHomePropsState }));
  };

  const hideOrder = () => {
    dispatch(toggleMobileCart(false));
    const { halfItem, specialItem, voucherItem } = localState;

    const isVoucherOpen = Object.keys(voucherItem).length > 0;
    const isSpecialOpen = Object.keys(specialItem).length > 0;
    const isHalfOpen = Object.keys(halfItem).length > 0;

    if (isSpecialOpen && isHalfOpen) {
      setLocalState({
        halfItem: {},
        rightSideViewVisibilityIndex: isSpecialOpen
          ? VISIBILITY_INDEX_TYPE.SPECIAL_VIEW
          : VISIBILITY_INDEX_TYPE.NONE,
        itemInProgress: null,
      });
    } else if (isSpecialOpen) {
      setLocalState({
        specialItem: {},
        rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.NONE,
        itemInProgress: null,
        isAutoSelectPopulated: false,
      });

      dispatch(
        filterView({
          isSpecialFilterApplied: false,
          spMenuItems: [],
          spMenuSizes: [],
          spSubModifiers: [],
          spMenuCategories: [],
          boxIndex: -1,
          itemExist: -1,
        })
      );
    } else if (isVoucherOpen) {
      setLocalState({
        voucherItem: {},
        rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.NONE,
        itemInProgress: null,
        isAutoSelectPopulated: false,
      });

      dispatch(
        voucherFilterView({
          isVoucherFilterApplied: false,
          voucherMenuItems: [],
          voucherMenuSizes: [],
          voucherSubModifiers: [],
          voucherMenuCategories: [],
          voucherBoxIndex: -1,
          itemExist: -1,
        })
      );
      removeVoucher();
    } else if (isHalfOpen) {
      setLocalState({
        halfItem: {},
        rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.NONE,
        itemInProgress: null,
      });
    } else {
      setLocalState({
        rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.NONE,
        itemInProgress: null,
      });
    }
  };

  const hideSpecialItem = () => {
    setLocalState({
      rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.SPECIAL_VIEW,
      itemInProgress: null,
    });
  };

  const hideVoucherItem = () => {
    if (!localState.voucherItem?._id) {
      removeVoucher();
      setLocalState({
        rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.NONE,
        itemInProgress: null,
      });
      return;
    }
    setLocalState({
      rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.VOUCHER_VIEW,
      itemInProgress: null,
    });
  };

  const hideHalfItem = () => {
    setLocalState({
      rightSideViewVisibilityIndex: VISIBILITY_INDEX_TYPE.HALF_HALF_VIEW,
      itemInProgress: null,
    });
  };

  const signOut = () => {
    _auth.signOut().then(() => {
      setLocalState({ currentUser: {}, userDetails: {} });
      dispatch(takeSignOutAction());
    });
  };

  const deleteAccount = () => {
    dispatch(deleteAccountAction(signOut));
  };

  const goHome = () => {
    const { orderStatus, id, orderType } = currentOrder;
    if (
      orderStatus === MENU_ORDER_STATUS.placed ||
      orderStatus === MENU_ORDER_STATUS.delivered ||
      orderStatus === MENU_ORDER_STATUS.cancelled ||
      qrRedirect === 'true' ||
      qrTracking === 'close'
    ) {
      const ctOrder = {
        paymentType: ORDER_PAYMENT_TYPE.unpaid,
        orderStatus: MENU_ORDER_STATUS.unconfirmed,
        orderFrom: MENU_ORDER_FROM.web,
        orderType:
          orderType === MENU_ORDER_TYPE.dinein
            ? MENU_ORDER_TYPE.dinein
            : MENU_ORDER_TYPE.pickup,
        specials: [],
        menuItems: [],
        stage: ORDER_STAGE_TYPES.NONE,
        totalCost: 0,
        payableAmount: 0,
        date: '',
        deliveryCode: '',
        deliveryCost: '0',
        deliveryDate: '',
        id,
        index: 68,
        shiftId: '',
        voucherId: '',
        userId: '',
        driverId: '',
        suburbId: '',
        txnId: '',
        transactionReference: '',
        cardNumber: '',
        cardType: '',
      };
      const { currentOd, rowItems } = engineGetOrderCalculations(
        ctOrder,
        props
      );
      setLocalState({ driver: {}, rowItems });
      if (mobileCart) dispatch(toggleMobileCart());
      currentOd.floorLayoutId = currentOrder.floorLayoutId;
      currentOd.tableNumber = currentOrder.tableNumber;
      if (
        orderType === MENU_ORDER_TYPE.dinein ||
        qrRedirect === 'true' ||
        qrTracking === 'close'
      ) {
        history.replace({
          query: null,
        });
      }
      dispatch(setPropsState({ currentOrder: currentOd }));
    }
  };

  const getDiscountedAmount = (
    selectedVoucher,
    currOrder,
    rowItems,
    specialDiscount
  ) => {
    const currentOd = currOrder || currentOrder;
    const rItems = rowItems || getItemsInCart(currentOd);
    const resObj = getVoucherDiscountedAmount(
      selectedVoucher,
      currentOd,
      rItems,
      specialDiscount,
      allSpecials,
      orderSetup,
      publicHolidays,
      storeConfig
    );
    return resObj;
  };

  // This method is responsile for all click events on all types of menu items
  const onClickItemHandler = (itemObjType, item, eventName) => {
    item = { ...item };
    delete item.itemObjType;
    delete item.unitPrice;

    dispatch(toggleMobileCart(true));

    const newItem = {
      ...item,
    };

    if (halfView) {
      showHalfItem(newItem);
    } else if (isSpecialFilterApplied) {
      if (itemObjType === MENU_ITEM_OBJ_TYPE.halfhalf) {
        showHalfViewHandler(newItem);
      } else {
        showSpecialItem(newItem);
      }
    } else if (isVoucherFilterApplied) {
      showVoucherItem(newItem);
    } else {
      // Normal Flow
      firebaseAnalyticsNO.logEvent(eventName ?? eventNames.MENU_ITEM_OPENED, {
        name: item.name,
      });

      if (itemObjType === MENU_ITEM_OBJ_TYPE.special) {
        showSpecialsHandler(newItem);
      } else if (itemObjType === MENU_ITEM_OBJ_TYPE.halfhalf) {
        showHalfViewHandler(newItem);
      } else {
        showMenuItemHandler(newItem);
      }
    }
  };

  useEffect(() => {
    if (!isMinifiedVersion && localState?.itemInProgress?._id) {
      const selectedMenuItem = menuItems.find(
        (mI) => mI._id === localState?.itemInProgress?._id
      );
      onClickItemHandler(
        MENU_ITEM_OBJ_TYPE.menu,
        { ...selectedMenuItem },
        eventNames.MENU_ITEM_OPENED
      );
    }
    if (!isMinifiedVersion && localState.specialItem) {
      const selectedSpecial = allSpecials.find(
        (s) => s._id === localState?.specialItem?._id
      );

      if (selectedSpecial) showSpecialsHandler(selectedSpecial);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMinifiedVersion]);

  const setLoginType = (loginType = LOGIN_FROM_TYPE.BOOKING) => {
    setLocalState({ loginType });
  };

  const updateEngineOrderCalculations = (orderObj) => {
    const { currentOd, rowItems } = engineGetOrderCalculations(orderObj, props);
    setLocalState({ rowItems });
    dispatch(setPropsState({ currentOrder: currentOd }));
  };

  const halfView = Object.keys(localState.halfItem).length > 0;

  const getOrderBar = () => {
    return (
      <Order
        hideSpecialItem={hideSpecialItem}
        showSpecialItem={showSpecialItem}
        showVoucherItem={showVoucherItem}
        hideOrder={hideOrder}
        halfFilter={halfFilter}
        updateOrderType={updateOrderType}
        addToOrder={addToOrder}
        incrementItemQuantity={incrementItemQuantity}
        decrementItemQuantity={decrementItemQuantity}
        incrementExtraQuantity={incrementExtraQuantity}
        decrementExtraQuantity={decrementExtraQuantity}
        updateOrderDeliveryAddress={updateOrderDeliveryAddress}
        updateDeliveryPickupTime={updateDeliveryPickupTime}
        updateOrderStage={updateOrderStage}
        updateOrderStageBack={updateOrderStageBack}
        updateCurrentUser={updateCurrentUser}
        updateVoucher={updateVoucher}
        updatePaymenyType={updatePaymenyType}
        updatePaymentTypeForCash={updatePaymentTypeForCash}
        updatePaymentTypeForWallet={updatePaymentTypeForWallet}
        updatePaymentTypeForCard={updatePaymentTypeForCard}
        updatePaymentTypeForNewCard={updatePaymentTypeForNewCard}
        addToSpecial={addToSpecial}
        addSpecialToOrder={addSpecialToOrder}
        addToHalfHalf={addToHalfHalf}
        hideHalfItem={hideHalfItem}
        addHalfHalfToOrder={addHalfHalfToOrder}
        clearItemsNotAvailableForVariants={clearItemsNotAvailableForVariants}
        clearOrChangeSize={clearOrChangeSize}
        saveUnit={saveUnit}
        addReservationhandler={addReservationhandler}
        removeSoldOutItems={removeSoldOutItems}
        removeOutOfScheduleItems={removeOutOfScheduleItems}
        removeOtherThanSelectedOrderTypeItems={
          removeOtherThanSelectedOrderTypeItems
        }
        getDiscountedAmount={getDiscountedAmount}
        addTableToOrder={addTableToOrder}
        onClickItemHandler={onClickItemHandler}
        removeItemFromOrder={removeItemFromOrder}
        setLoginType={setLoginType}
        updateEngineOrderCalculations={updateEngineOrderCalculations}
        homeState={localState}
        halfView={halfView}
        addMultipleToSpecials={addMultipleToSpecials}
        setHomeState={setLocalState}
        deleteAccount={deleteAccount}
        removeVoucher={removeVoucher}
        hideVoucherItem={hideVoucherItem}
        addToVoucher={addToVoucher}
        addVoucherItemsToOrder={addVoucherItemsToOrder}
      />
    );
  };

  return (
    <Layout>
      <MenuProvider>
        <NewMenuItems
          halfView={halfView}
          halfItem={localState.halfItem}
          hhActiveMenuSizes={localState.hhActiveMenuSizes}
          itemVariants={localState.itemVariants}
          selectedHalfItems={localState.selectedHalfItems}
          getOrderBar={getOrderBar}
          onClickItemHandler={onClickItemHandler}
          updateOrderDeliveryAddress={updateOrderDeliveryAddress}
          saveUnit={saveUnit}
          signOut={signOut}
          hideOrder={hideOrder}
        />
      </MenuProvider>
    </Layout>
  );
}
