import React, { createContext, useEffect, useReducer } from 'react';

const initialState = {
  itemVariants: [],
  hhActiveMenuSizes: [],
  clicked: 0,
  flag: false,
  halfItem: undefined,
};

export const HalfHalfViewContext = createContext([initialState, () => {}]);

export default function HalfHalfViewProvider({ halfView, children }) {
  const value = useReducer(
    (s, a) => ({
      ...s,
      ...a,
    }),
    initialState
  );

  useEffect(() => {
    if (!halfView) {
      value[1](initialState);
    }
    // eslint-disable-next-line
  }, [halfView]);

  return (
    <HalfHalfViewContext.Provider value={value}>
      {children}
    </HalfHalfViewContext.Provider>
  );
}
