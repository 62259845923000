import { _firestore } from '../../firebase';
import ActionTypes from './constants';
import {
  getReviewStatsPromise,
  getReviewStatsByRatingPromise,
} from './methods';
import {
  getHalfHalfPromise,
  getCreditCardsPromise,
  getStoreOffersPromise,
  getPublicHolidaysPromise,
  getAutoVouchersPromise,
} from '../Home/methods';

export function setState(payload) {
  return {
    type: `@layout/setState`,
    payload,
  };
}

export function initLayout(storeConfig) {
  const { storeId } = storeConfig;

  return async function (dispatch) {
    const offers = window.Offers || [];
    dispatch({
      type: ActionTypes.InitializeLayout,
      payload: { offers },
    });

    const [
      reviewStats,
      reviewStatsByRating,
      halfHalfSetup,
      creditCards,
      publicHolidays,
      autoVouchers,
    ] = await Promise.all([
      getReviewStatsPromise(storeId),
      getReviewStatsByRatingPromise(storeId),
      getHalfHalfPromise(storeId),
      getCreditCardsPromise(storeId),
      getPublicHolidaysPromise(storeConfig),
      getAutoVouchersPromise(storeId),
    ]);

    if (!offers?.length) {
      const storeOffers = await getStoreOffersPromise(storeId);
      dispatch({
        type: ActionTypes.InitializeLayout,
        payload: { offers: storeOffers },
      });
    }

    const payload = {
      reviewStats,
      reviewStatsByRating,
      halfHalfSetup,
      creditCards,
      publicHolidays,
      autoVouchers,
    };
    dispatch({
      type: ActionTypes.InitializeLayout,
      payload,
    });
  };
}

export function recordPhoneClick(obj) {
  return () => {
    _firestore
      .collection('/PhoneNumberClicked')
      .add(obj)
      .then(() => {});
  };
}
