import React, { Component } from 'react';
import mobiscroll from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';

class Select extends Component {
  constructor(props) {
    super(props);
    this.value = -1;
  }
  shouldComponentUpdate(nextProps) {
    if (
      nextProps.data.times &&
      nextProps.data.times.length > 0 &&
      nextProps.data.times[1] &&
      this.props.data.times[1] &&
      nextProps.data.times[1].displayForUse !==
        this.props.data.times[1].displayForUse
    ) {
      return true;
    } else if (
      nextProps.data.times &&
      nextProps.data.times.length > 0 &&
      nextProps.data.times[0] &&
      this.props.data.times[0] &&
      nextProps.data.times[0].displayForUse !==
        this.props.data.times[0].displayForUse
    ) {
      return true;
    }
    return false;
  }
  handleChange = (event, inst, obj) => {
    if (this.value !== inst.getVal()) {
      this.props.handleChange(obj.times[inst.getVal()]);
      this.value = inst.getVal();
    }
  };

  handleInit = (event, inst, obj) => {
    setTimeout(() => {
      this.props.handleChange(this.props.data.times[0]);
      this.value = 0;
    }, 100);
  };
  render() {
    return (
      <mobiscroll.Select
        theme='mobiscroll'
        circular={false}
        display='inline'
        height={this.props.height ? this.props.height : 43}
        // value={this.state.value} keep this
        layout='liquid'
        showInput={false}
        onSet={(event, inst) => this.handleChange(event, inst, this.props.data)}
        onInit={(event, inst) => this.handleInit(event, inst, this.props.data)}
      >
        {this.props.data.times.length > 0 &&
          this.props.data.times.map((time, index1) => (
            <option value={index1} key={index1}>
              {time.display}
            </option>
          ))}
      </mobiscroll.Select>
    );
  }
}
export default Select;
