import ActionTypes from './constants';

const ReviewOrderReducer = (state = { fetched: false }, payload) => {
  switch (payload.type) {
    case ActionTypes.FetchedCreditCards:
      return { ...state, fetched: true, paymentCards: payload.cards };
    default:
      return state;
  }
};

export default ReviewOrderReducer;
