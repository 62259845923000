/* eslint-disable eqeqeq */
import useSpecialItem from 'components/ItemView/useSpecialItem';
import MobileNumberComponent from 'components/UserLoginRegistration/MobileNumberComponent';
import NameFormComponent from 'components/UserLoginRegistration/NameFormComponent';
import VerificationCodeComponent from 'components/UserLoginRegistration/VerificationCodeComponent';
import VoucherOrderView from 'components/VoucherOrderView';
import VoucherView from 'components/VoucherView';
import { eventNames } from 'configuration/eventNames';
import SidebarProviders from 'contexts/SidebarProviders';
import { detectOutOfScheduleItemsInCart } from 'helpers/detectOutOfScheduleItemsInCart';
import {
  LOGIN_FROM_TYPE,
  MENU_ORDER_TYPE,
  ORDER_STAGE_TYPES,
  SOLD_OUT_ITEM_PRICE,
  VISIBILITY_INDEX_TYPE,
} from 'helpers/enums';
import { getMinifiedMenuItemForInstore } from 'helpers/getMenuItemForInstore';
import getPriceForDisplay from 'helpers/getPriceForDisplay';
import { engineShowSpecialItem } from 'helpers/order-engine';
import { orderBy } from 'lodash';
import moment from 'moment-timezone';
import { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AboutDesktop from '../../components/AboutDesktop';
import HalfHalfView from '../../components/HalfHalfView';
import HalfOrderView from '../../components/HalfOrderView';
import LoyaltyDesktop from '../../components/LoyaltyDesktop';
import OrderPayment from '../../components/OrderPayment';
import OrderPaymentCards from '../../components/OrderPaymentCards';
import OrderReview from '../../components/OrderReview';
import OrderView from '../../components/OrderView';
import SpecialOrderView from '../../components/SpecialOrderView';
import SpecialView from '../../components/SpecialView';
import TableBookingView from '../../components/TableBookingView';
import { firebaseAnalyticsNO } from '../../firebase';
import { detectSoldOutItemsInCart } from '../../helpers/detectSoldOutItemsInCart';
import {
  getCurrentShift,
  getDeliveryTimeArrays,
  getPickupTimeArrays,
} from '../../helpers/getTimeSlots';
import { fetchOrder } from '../Home/actions';
import { selectStoreConfig } from '../Layout/selectors';
import { initOrder } from './actions';
import Cart from './Cart';
import { detectOtherThanSelectedOrderTypeItemsInCart } from 'helpers/detectOtherThanSelectedOrderTypeItemsInCart';

const orderLabels = {
  1: `Pickup`,
  2: `Delivery`,
  3: `Dine-in`,
};

export default function Order({
  hideSpecialItem,

  showSpecialItem,
  hideOrder,

  halfFilter,
  updateOrderType,
  addToOrder,
  incrementItemQuantity,
  decrementItemQuantity,
  incrementExtraQuantity,
  decrementExtraQuantity,
  updateOrderDeliveryAddress,
  updateDeliveryPickupTime,
  updateOrderStage,
  updateOrderStageBack,
  updateCurrentUser,
  updateVoucher,
  updatePaymenyType,
  updatePaymentTypeForCash,
  updatePaymentTypeForWallet,
  updatePaymentTypeForCard,
  updatePaymentTypeForNewCard,
  addToSpecial,
  addSpecialToOrder,

  addToHalfHalf,
  hideHalfItem,
  addHalfHalfToOrder,
  clearItemsNotAvailableForVariants,

  clearOrChangeSize,
  saveUnit,
  addReservationhandler,
  removeSoldOutItems,
  removeOutOfScheduleItems,
  removeOtherThanSelectedOrderTypeItems,
  getDiscountedAmount,
  addTableToOrder,
  onClickItemHandler,
  removeItemFromOrder,

  setLoginType,
  updateEngineOrderCalculations,
  homeState,
  halfView,
  addMultipleToSpecials,
  setHomeState,
  deleteAccount,
  removeVoucher,
  hideVoucherItem,
  addToVoucher,
  addVoucherItemsToOrder,
  showVoucherItem,
}) {
  const history = useHistory();
  const {
    currentUser,
    userDetails,
    orderSetup,
    menuItems,
    pathwayToOffers,
    soldOutMenuItems,
    loyaltyOpen,
    aboutOpen,
    bookingOpen,
    menuItemSchedules,
    categories,
    menuItemSizes,
    menuItemSubModifiers,
    currentOrder,
    specialFilter: { isSpecialFilterApplied },
    // ui: { mobileCart },
  } = useSelector((state) => state.homeReducer);

  const order = useSelector((state) => state.orderReducer);
  const reviewOrder = useSelector((state) => state.reviewOrderReducer);
  const layout = useSelector((state) => state.layoutReducer);
  const storeConfig = useSelector(selectStoreConfig);
  const { halfHalfSetup, offers } = layout;
  const [localState, setLocalState] = useReducer((s, a) => ({ ...s, ...a }), {
    active: 0,
    selectedTime: '',
    moveNext: 0,
    deliveryDateLater: '',
    isFutureDeliveryDate: false,
    shiftIdLater: '',
    showErrorInTableSelector: false,
    activeIndex: -1,
    specialBoxes: null,
  });

  const dispatch = useDispatch();

  const { addToOrderItem } = useSpecialItem();

  useEffect(() => {
    if (
      !storeConfig.storeId ||
      !storeConfig.restaurantId ||
      !storeConfig.timeZone
    )
      return;
    dispatch(
      initOrder(
        storeConfig.storeId,
        storeConfig.restaurantId,
        storeConfig.timeZone
      )
    );
  }, [
    dispatch,
    storeConfig.storeId,
    storeConfig.restaurantId,
    storeConfig.timeZone,
  ]);

  useEffect(() => {
    if (
      storeConfig &&
      order?.allShifts &&
      order?.closeDates &&
      Object.keys(orderSetup ?? {}).length
    ) {
      let currentShift = getCurrentShift(
        storeConfig ? storeConfig : {},
        order.allShifts ? order.allShifts : [],
        order.closeDates ? order.closeDates : []
      );

      let pickUpTimes = getPickupTimeArrays(
        storeConfig ?? {},
        order.allShifts ?? [],
        orderSetup ?? {},
        order.closeDates ?? []
      );

      let deliveryTimes = getDeliveryTimeArrays(
        storeConfig ?? {},
        order.allShifts ?? [],
        orderSetup ?? {},
        order.closeDates ?? []
      );

      let timeArray = [];
      let deliveryDateLater = '';
      let isFutureDeliveryDate = false;
      let shiftIdLater = '';
      if (currentOrder.orderType === MENU_ORDER_TYPE.pickup)
        timeArray = pickUpTimes;
      else if (currentOrder.orderType === MENU_ORDER_TYPE.delivery)
        timeArray = deliveryTimes;
      if (timeArray.length > 0) {
        deliveryDateLater = moment(
          `${timeArray[0].times[0].timeObjFinal} ${timeArray[0].times[0].displayForUse}`,
          'YYYY-MM-DD HH:mm A'
        );
        isFutureDeliveryDate =
          timeArray[0].times[0].alternateDisplay === 'ASAP' ? false : true;
        shiftIdLater = timeArray[0].times[0].shiftId;
      }

      setLocalState({
        deliveryTimes,
        currentShift,
        pickUpTimes,
        deliveryDateLater,
        isFutureDeliveryDate,
        shiftIdLater,
      });
    }
  }, [
    currentOrder.orderType,
    order.allShifts,
    order.closeDates,
    orderSetup,
    storeConfig,
  ]);

  useEffect(() => {
    if (homeState.isAutoSelectPopulated) return;
    const cachedCategories = categories;
    if (!localState.specialBoxes) return;
    if (!cachedCategories || !menuItems || !currentOrder.orderType) return;
    setHomeState({ isAutoSelectPopulated: true });
    let newCategories = cachedCategories.map((m) => {
      m.menuItems = menuItems.filter(
        (a) =>
          a.categoryId === m._id &&
          a.orderFromKeys.includes('1') &&
          a.orderTypeKeys.includes(currentOrder.orderType) &&
          a.isPublished
      );

      // fetch in-store prices for menu items
      m.menuItems = m.menuItems.map((menuItem) => {
        if (
          menuItem.orderFromKeys.includes('2') &&
          menuItem.orderTypeKeys.includes(currentOrder.orderType) &&
          menuItem.isPublished &&
          (menuItemSizes?.length || menuItemSubModifiers?.length)
        ) {
          const newMenuItemSizes = menuItemSizes.filter(
            (f) => f.menuItemId === menuItem._id
          );
          const newMenuItemSubModifiers = menuItemSubModifiers.filter(
            (f) => f.menuItemId === menuItem._id
          );
          return getMinifiedMenuItemForInstore(
            menuItem,
            newMenuItemSizes,
            newMenuItemSubModifiers,
            currentOrder.orderType
          );
        } else {
          return menuItem;
        }
      });

      return m;
    });
    let specials = [];
    orderBy(localState.specialBoxes, ['index'], ['asc']).forEach(
      (sp, index) => {
        let filteredCategories = [];
        const spMenuItems = sp.menuItems || [],
          spMenuSizes = sp.menuSizes || [],
          spSubModifiers = sp.subModifiers || [],
          spMenuCategories = sp.categories || [];
        filteredCategories = newCategories?.filter((obj) =>
          spMenuCategories
            ?.filter((f) => f.isActive)
            ?.map((m) => m._id)
            ?.includes(obj._id)
        );
        filteredCategories.forEach((item) => {
          item.filteredMenuItems = item.menuItems.filter((obj) => {
            let i = 0;
            if (
              spMenuItems &&
              spMenuItems.length > 0 &&
              ((spMenuSizes && spMenuSizes.length > 0) ||
                (spSubModifiers && spSubModifiers.length > 0))
            ) {
              let matchedItems = spMenuItems.filter(
                (item) => obj._id === item._id
              );
              if (matchedItems.length > 0) {
                if (matchedItems[0].isActive) {
                  obj.selectedSizes.forEach((m) => {
                    spMenuSizes?.forEach((c) => {
                      if (c._id === m._id && c.isActive) i++;
                    });
                    spSubModifiers?.forEach((c) => {
                      if (c._id === m._id && c.isActive) i++;
                    });
                  });
                  if (matchedItems[0].price) obj.upsale = matchedItems[0].price;
                }
              } else {
                obj.selectedSizes.forEach((m) => {
                  spMenuSizes?.forEach((c) => {
                    if (c._id === m._id && c.isActive) i++;
                  });
                  spSubModifiers?.forEach((c) => {
                    if (c._id === m._id && c.isActive) i++;
                  });
                });
                obj.upsale = 0;
              }
            }
            obj.showName = !obj.isNameHidden;
            return i !== 0;
          });
          if (
            item.filteredMenuItems &&
            item.filteredMenuItems.length === 1 &&
            item.filteredMenuItems[0].selectedExtraIngredients.length === 0 &&
            item.filteredMenuItems[0].selectedIngredients.length === 0 &&
            (!item.filteredMenuItems[0].selectedModifiers ||
              item.filteredMenuItems[0].selectedModifiers?.length === 0)
          ) {
            const unitPrice = getPriceForDisplay(
              item.filteredMenuItems[0],
              isSpecialFilterApplied,
              soldOutMenuItems,
              spMenuSizes,
              { orderType: currentOrder?.orderType }
            );
            const isSoldOut = unitPrice === SOLD_OUT_ITEM_PRICE;
            item.filteredMenuItems[0] = {
              ...item.filteredMenuItems[0],
              boxIndex: index,
              isSoldOut,
            };
            const obj = engineShowSpecialItem(
              item.filteredMenuItems[0],
              spMenuSizes,
              spSubModifiers
            );
            const finalResult = addToOrderItem(obj, halfHalfSetup);
            specials.push(finalResult);
          }
          return item;
        });
      }
    );
    addMultipleToSpecials(specials);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState.specialBoxes]);

  const checkIfTimeInFutureSelected = () => {
    if (
      localState.currentShift &&
      orderSetup.isPreOrderingEnabled === true &&
      ((currentOrder.orderType === MENU_ORDER_TYPE.delivery &&
        localState.currentShift.isDeliveryOpen === false) ||
        (currentOrder.orderType === MENU_ORDER_TYPE.pickup &&
          localState.currentShift.isPickupOpen === false)) &&
      (!currentOrder.deliveryDate ||
        !currentOrder.shiftId ||
        currentOrder.shiftId.length <= 0)
    ) {
      setLocalState({
        showErrorInTimeSelector: true,
      });
      return false;
    } else {
      return true;
    }
  };

  const checkIfTableSelected = () => {
    if (
      currentOrder.orderType === MENU_ORDER_TYPE.dinein &&
      currentOrder.tableId
    ) {
      return true;
    } else {
      setLocalState({
        showErrorInTableSelector: true,
      });
      return false;
    }
  };

  const updateOrderStageHandler = (stage) => {
    const m = moment;
    const newCurrentOrder = JSON.parse(JSON.stringify(currentOrder));
    firebaseAnalyticsNO.logEvent(eventNames.SELECTED_ORDER_BUTTON);
    if (
      newCurrentOrder &&
      newCurrentOrder.isAllowToConfirm &&
      getStoreStatus()?.isDisplay &&
      checkIfTimeInFutureSelected()
    ) {
      const { useRadiusBasedDeliveryAreaCalulation = false } = orderSetup ?? {};
      if (
        (useRadiusBasedDeliveryAreaCalulation
          ? true
          : newCurrentOrder.suburbId) &&
        newCurrentOrder.orderType === MENU_ORDER_TYPE.delivery
      ) {
        if (
          !newCurrentOrder.deliveryDate ||
          !newCurrentOrder.shiftId ||
          newCurrentOrder.shiftId.length <= 0
        ) {
          let timeObj =
            localState.deliveryTimes && localState.deliveryTimes[0].times[0];
          let deliveryDate = moment(
            `${timeObj.timeObjFinal} ${timeObj.displayForUse}`,
            'YYYY-MM-DD HH:mm A'
          );
          updateDeliveryPickupTime(
            deliveryDate,
            timeObj.shiftId,
            true,
            stage,
            timeObj.alternateDisplay === 'ASAP' ? false : true
          );
        }
        // Pickup
      } else if (
        newCurrentOrder.orderType === MENU_ORDER_TYPE.pickup &&
        localState.pickUpTimes.length
      ) {
        if (
          !newCurrentOrder.deliveryDate ||
          !newCurrentOrder.shiftId ||
          newCurrentOrder.shiftId.length <= 0
        ) {
          let [
            {
              times: [
                { shiftId, alternateDisplay, timeObjFinal, displayForUse },
              ],
            },
          ] = localState.pickUpTimes;
          const date = `${timeObjFinal} ${displayForUse}`;
          updateDeliveryPickupTime(
            m(date),
            shiftId,
            true,
            stage,
            alternateDisplay === 'ASAP' ? false : true
          );
        }
        // Dine-in
      } else if (
        newCurrentOrder.orderType === MENU_ORDER_TYPE.dinein &&
        checkIfTableSelected()
      ) {
        if (!newCurrentOrder.deliveryDate) {
          const deliveryDate = moment();

          updateDeliveryPickupTime(deliveryDate, false, true, stage, true);
        }
      }
      updateOrderStage(stage, LOGIN_FROM_TYPE.MAIN);
    } else if (
      newCurrentOrder.orderType === MENU_ORDER_TYPE.delivery &&
      newCurrentOrder.address === ''
    ) {
      // order address error
      setLocalState({ deliveryAddressMissing: true });
      setTimeout(() => {
        setLocalState({ deliveryAddressMissing: false });
      }, 3000);
    } else if (
      newCurrentOrder.orderType === MENU_ORDER_TYPE.dinein &&
      !newCurrentOrder.tableId
    ) {
      // order table error
      setLocalState({ showErrorInTableSelector: true });
      setTimeout(() => {
        setLocalState({ showErrorInTableSelector: false });
      }, 3000);
    }
  };

  const switchSections = (e, type) => {
    e.preventDefault();
    if (currentOrder.orderType !== type) {
      updateOrderType(type);
      toggleErrorStateForFutureTimeSelection(false);
    }
  };

  const getStoreStatus = () => {
    const now = moment().tz(storeConfig.timeZone);

    const currentShifts = order.allShifts.filter((s) => {
      return s.day === now.format(`E`);
    });

    if (order.allShifts.length === 0 || !orderSetup) {
      return {
        isDisplay: false,
        text: `Loading...`,
      };
    }

    const seconds = now.second() + now.minute() * 60 + now.hour() * 60 * 60;
    const dineInSeconds = seconds;
    const pickupSeconds = !orderSetup.isOrderAllowedTillClosing
      ? Number(orderSetup?.pickUpTime) * 60 + seconds
      : seconds;
    const deliverySeconds = !orderSetup?.isOrderAllowedTillClosing
      ? Number(orderSetup?.deliveryTime) * 60 + seconds
      : seconds;
    let currentShift = currentShifts[0];

    if (currentShifts.length > 1) {
      const fittingShift = currentShifts.find(
        (s) => s.openingStart <= seconds && s.openingEnd >= seconds
      );
      if (fittingShift) {
        currentShift = fittingShift;
      }
    }

    const {
      noPickup,
      noDelivery,
      noDineIn,
      pickup24,
      delivery24,
      restaurant24: dineIn24,
      deliveryStart,
      deliveryEnd,
      openingStart: pickupStart,
      openingEnd: pickupEnd,
      restaurantStart: dineInStart,
      restaurantEnd: dineInEnd,
    } = currentShift || {};

    const closed = order.closeDates?.find(
      (d) => d.closeDate === now.format(`YYYY-MM-DD`)
    );

    const deliveryPossible =
      !noDelivery &&
      ((deliverySeconds < deliveryEnd && deliverySeconds > deliveryStart) ||
        delivery24) &&
      (!closed || !(closed.isAllClosed || closed.isDeliveryClosed));
    const pickupPossible =
      !noPickup &&
      ((pickupSeconds < pickupEnd && pickupSeconds > pickupStart) ||
        pickup24) &&
      (!closed || !closed.isAllClosed);
    const dineInPossible =
      !noDineIn &&
      ((dineInSeconds < dineInEnd && dineInSeconds > dineInStart) ||
        dineIn24) &&
      (!closed || !closed.isAllClosed);

    if (currentOrder.orderType === MENU_ORDER_TYPE.dinein) {
      if (dineInPossible) {
        return {
          isDisplay: true,
          text: `Order Dine-in`,
        };
      } else {
        return {
          isDisplay: false,
          text: `Dine-in Closed`,
        };
      }
    }

    const minOrderPrice = getMinOrderPrice() !== 0 ? getMinOrderPrice() : '';

    if (!deliveryPossible || !pickupPossible) {
      if (!orderSetup.isPreOrderingEnabled) {
        if (!pickupPossible && !deliveryPossible) {
          return {
            isDisplay: false,
            text: `Store Closed`,
          };
        }
        if (
          deliveryPossible &&
          currentOrder.orderType === MENU_ORDER_TYPE.pickup
        ) {
          return {
            isDisplay: false,
            text: `Pickup Closed`,
          };
        }
        if (
          deliveryPossible &&
          currentOrder.orderType === MENU_ORDER_TYPE.delivery
        ) {
          return {
            isDisplay: true,
            text: `Order Delivery ${minOrderPrice}`,
          };
        }
        if (
          pickupPossible &&
          currentOrder.orderType === MENU_ORDER_TYPE.delivery
        ) {
          return {
            isDisplay: false,
            text: `Delivery Closed`,
          };
        }
        if (
          pickupPossible &&
          currentOrder.orderType === MENU_ORDER_TYPE.pickup
        ) {
          return {
            isDisplay: true,
            text: `Order Pickup ${minOrderPrice}`,
          };
        }
      } else {
        if (
          !pickupPossible &&
          currentOrder.orderType === MENU_ORDER_TYPE.pickup &&
          localState.pickUpTimes?.length
        ) {
          return {
            isDisplay: true,
            text: `Pre-Order Pickup ${minOrderPrice}`,
            isPreOrder: true,
          };
        }
        if (
          !deliveryPossible &&
          currentOrder.orderType === MENU_ORDER_TYPE.delivery &&
          localState.deliveryTimes?.length
        ) {
          return {
            isDisplay: true,
            text: `Pre-Order Delivery ${minOrderPrice}`,
            isPreOrder: true,
          };
        }
        if (
          !localState.pickUpTimes?.length &&
          !localState.deliveryTimes?.length
        ) {
          return {
            isDisplay: `false`,
            text: `Store Closed`,
          };
        }
        return {
          isDisplay: true,
          text: `Order ${orderLabels[currentOrder.orderType]} ${minOrderPrice}`,
        };
      }
    } else {
      return {
        isDisplay: true,
        text: `Order ${orderLabels[currentOrder.orderType]} ${minOrderPrice}`,
      };
    }
  };

  const getMinOrderPrice = () => {
    const { useRadiusBasedDeliveryAreaCalulation = false } = orderSetup ?? {};
    if (
      useRadiusBasedDeliveryAreaCalulation &&
      !currentOrder?.areaCode &&
      !currentOrder?.deliveryCost &&
      currentOrder?.orderType === MENU_ORDER_TYPE.delivery
    ) {
      return 0;
    }
    let resTring = 0;
    let excludedDiscountFromMinOrderValue = 0;
    if (
      Number(currentOrder?.selectedVoucher?.excludeDiscountFromMinOrderValue) >
      0
    ) {
      excludedDiscountFromMinOrderValue = Number(currentOrder?.voucherDiscount);
    }
    if (Number(currentOrder?.loyaltyDiscount) > 0) {
      const exculdedOffersFromMinOrderValue = [];
      currentOrder?.offers.forEach((offer) => {
        if (offer?.excludeDiscountFromMinOrderValue) {
          exculdedOffersFromMinOrderValue.push(offer?._id);
        }
      });
      if (exculdedOffersFromMinOrderValue?.length > 0) {
        currentOrder?.rewards.forEach((reward) => {
          if (exculdedOffersFromMinOrderValue.includes(reward?._id)) {
            excludedDiscountFromMinOrderValue += Number(reward?.discount);
          }
        });
      }
    }

    const totalCostForDelivery = (
      Number(currentOrder.payableAmount) +
      excludedDiscountFromMinOrderValue -
      Number(currentOrder.deliveryCost ? currentOrder.deliveryCost : 0)
    ).toFixed(2);

    const totalCostForPickup = (
      Number(currentOrder.payableAmount) + excludedDiscountFromMinOrderValue
    ).toFixed(2);

    const suburb = order?.allSuburbs?.find((s) => {
      return s._id === currentOrder.suburbId;
    });

    const minDeliveryValue = orderSetup?.useRadiusBasedDeliveryAreaCalulation
      ? Number(currentOrder.minOrderValue)
      : Number(suburb?.minOrderValue) ||
        Number(orderSetup?.minDeliveryOrderValue);
    if (
      currentOrder &&
      currentOrder.orderType === MENU_ORDER_TYPE.pickup &&
      orderSetup &&
      orderSetup.minPickupOrderValue &&
      totalCostForPickup < Number(orderSetup.minPickupOrderValue)
    ) {
      resTring = ` - Min. ${storeConfig.currency}${
        Number(orderSetup.minPickupOrderValue) -
        excludedDiscountFromMinOrderValue
      }`;
    } else if (
      currentOrder &&
      currentOrder.orderType === MENU_ORDER_TYPE.delivery &&
      orderSetup &&
      orderSetup.minDeliveryOrderValue &&
      totalCostForDelivery < minDeliveryValue
    ) {
      resTring = ` - Min. ${storeConfig.currency}${(
        Number(minDeliveryValue) +
        Number(currentOrder.deliveryCost) -
        excludedDiscountFromMinOrderValue
      ).toFixed(2)}`;
    }
    return resTring;
  };

  const toggleErrorStateForFutureTimeSelection = (flag) => {
    setLocalState({
      showErrorInTimeSelector: flag,
    });
  };

  const toggleErrorStateForTableSelection = (flag) => {
    setLocalState({
      showErrorInTableSelector: flag,
    });
  };

  const isSoldOutPresent = detectSoldOutItemsInCart(
    currentOrder,
    soldOutMenuItems
  );

  const isOutOfSchedulePresent = detectOutOfScheduleItemsInCart(
    currentOrder,
    menuItemSchedules
  );

  const isOtherThanSelectedOrderTypePresent =
    detectOtherThanSelectedOrderTypeItemsInCart(currentOrder);

  const getMainComponents = () => {
    // =========================>>><<<=========================
    if (bookingOpen) {
      return (
        <TableBookingView
          userDetails={userDetails}
          allShifts={order.allShifts}
          closeDates={order.closeDates}
          orderSetup={orderSetup}
          updateOrderStage={updateOrderStage}
          addReservationhandler={addReservationhandler}
          loginType={homeState.loginType}
          setLoginType={setLoginType}
          sidebarAnimationClass={''}
        />
      );
    } else if (aboutOpen) {
      return (
        <AboutDesktop
          userDetails={userDetails}
          allSuburbs={order.allSuburbs}
          allShifts={order.allShifts}
          currentShift={localState.currentShift}
          reviewStats={layout.reviewStats}
          reviewStatsByRating={layout.reviewStatsByRating}
          isDeliveryOn={
            orderSetup && orderSetup.isDeliveryOn
              ? orderSetup.isDeliveryOn
              : false
          }
          sidebarAnimationClass={''}
          deleteAccount={deleteAccount}
        />
      );
    } else if (loyaltyOpen) {
      return (
        <LoyaltyDesktop
          userDetails={userDetails}
          updateOrderStage={updateOrderStage}
          currentUser={currentUser}
          offers={offers}
          pathwayToOffers={pathwayToOffers}
          storeConfig={storeConfig}
          sidebarAnimationClass={''}
        />
      );
    }

    // =========================>>><<<=========================
    if (!homeState.rightSideViewVisibilityIndex && !currentOrder?.stage) {
      return (
        <Cart
          incrementItemQuantity={incrementItemQuantity}
          decrementItemQuantity={decrementItemQuantity}
          incrementExtraQuantity={incrementExtraQuantity}
          decrementExtraQuantity={decrementExtraQuantity}
          updateOrderDeliveryAddress={updateOrderDeliveryAddress}
          updateDeliveryPickupTime={updateDeliveryPickupTime}
          cartState={localState}
          setCartState={setLocalState}
          toggleErrorStateForFutureTimeSelection={
            toggleErrorStateForFutureTimeSelection
          }
          toggleErrorStateForTableSelection={toggleErrorStateForTableSelection}
          dineInObjects={homeState.dineInObjects}
          floors={homeState.floors}
          addTableToOrder={addTableToOrder}
          switchSections={switchSections}
          updateOrderStageHandler={updateOrderStageHandler}
          onClickItemHandler={onClickItemHandler}
          getStoreStatus={getStoreStatus}
          getMinOrderPrice={getMinOrderPrice}
          isSoldOutPresent={isSoldOutPresent}
          isOutOfSchedulePresent={isOutOfSchedulePresent}
          isOtherThanSelectedOrderTypePresent={
            isOtherThanSelectedOrderTypePresent
          }
          sidebarAnimationClass={''}
          removeItemFromOrder={removeItemFromOrder}
          deliveryAddressMissing={localState.deliveryAddressMissing}
          updateVoucher={updateVoucher}
          getDiscountedAmount={getDiscountedAmount}
          removeVoucher={removeVoucher}
        />
      );
    }

    // =========================>>><<<=========================
    switch (homeState.rightSideViewVisibilityIndex) {
      case VISIBILITY_INDEX_TYPE.HALF_ORDER_VIEW:
        return (
          <HalfOrderView
            key={homeState.itemInProgress?._id}
            ingredientCategories={order.ingredientCategories}
            rightSideViewVisibilityIndex={
              homeState.rightSideViewVisibilityIndex
            }
            orderItem={homeState.orderItem}
            itemInProgress={homeState.itemInProgress}
            closeOrder={hideHalfItem}
            addToOrder={addToHalfHalf}
            sidebarAnimationClass={''}
          />
        );
      case VISIBILITY_INDEX_TYPE.HALF_HALF_VIEW:
        return (
          <HalfHalfView
            toggleAnimation={homeState.halfAnimation}
            hideOrder={hideOrder}
            halfItem={homeState.halfItem}
            halfFilter={halfFilter}
            selectedHalfItems={homeState.selectedHalfItems}
            addHalfHalfToOrder={addHalfHalfToOrder}
            clearItemsNotAvailableForVariants={
              clearItemsNotAvailableForVariants
            }
            whichHalf={homeState.whichHalf}
            menuItems={menuItems}
            clearOrChangeSize={clearOrChangeSize}
            sidebarAnimationClass={''}
          />
        );

      case VISIBILITY_INDEX_TYPE.SPECIAL_VIEW:
        return (
          <SpecialView
            showSpecialItem={showSpecialItem}
            menuItems={menuItems}
            toggleAnimation={homeState.toggleSpecials}
            specialItem={homeState.specialItem}
            hideOrder={hideOrder}
            selectedSpecials={homeState.selectedSpecials}
            addSpecialToOrder={addSpecialToOrder}
            itemExist={homeState.itemExist}
            storeConfig={storeConfig}
            sidebarAnimationClass={''}
            addToOrder={addToSpecial}
            addMultipleToSpecials={addMultipleToSpecials}
            activeIndex={localState.activeIndex}
            specialBoxes={localState.specialBoxes}
            setOrderState={setLocalState}
          />
        );
      case VISIBILITY_INDEX_TYPE.VOUCHER_VIEW:
        return (
          <VoucherView
            showSpecialItem={showVoucherItem}
            menuItems={menuItems}
            toggleAnimation={homeState.toggleSpecials}
            specialItem={homeState.specialItem}
            voucherItem={homeState.voucherItem}
            hideOrder={hideOrder}
            selectedSpecials={homeState.selectedSpecials}
            addSpecialToOrder={addVoucherItemsToOrder}
            itemExist={homeState.itemExist}
            storeConfig={storeConfig}
            sidebarAnimationClass={''}
            addToOrder={addToVoucher}
            addMultipleToSpecials={addMultipleToSpecials}
            activeIndex={localState.activeIndex}
            specialBoxes={localState.specialBoxes}
            setOrderState={setLocalState}
          />
        );
      case VISIBILITY_INDEX_TYPE.SPECIAL_ORDER_VIEW:
        return (
          <SpecialOrderView
            key={homeState.itemInProgress?._id}
            ingredientCategories={order.ingredientCategories}
            rightSideViewVisibilityIndex={
              homeState.rightSideViewVisibilityIndex
            }
            toggleAnimation={homeState.toggleSpeacialItem}
            orderItem={homeState.orderItem}
            itemInProgress={homeState.itemInProgress}
            openedItemId={homeState.itemInProgress?._id}
            closeOrder={hideSpecialItem}
            addToOrder={addToSpecial}
            sidebarAnimationClass={''}
          />
        );
      case VISIBILITY_INDEX_TYPE.VOUCHER_ORDER_VIEW:
        return (
          <VoucherOrderView
            key={homeState.itemInProgress?._id}
            ingredientCategories={order.ingredientCategories}
            rightSideViewVisibilityIndex={
              homeState.rightSideViewVisibilityIndex
            }
            toggleAnimation={homeState.toggleSpeacialItem}
            orderItem={homeState.orderItem}
            itemInProgress={homeState.itemInProgress}
            openedItemId={homeState.itemInProgress?._id}
            closeOrder={hideVoucherItem}
            addToOrder={addToVoucher}
            sidebarAnimationClass={''}
          />
        );
      case VISIBILITY_INDEX_TYPE.ORDER_VIEW:
        return (
          <OrderView
            key={homeState.itemInProgress?._id}
            ingredientCategories={order.ingredientCategories}
            rightSideViewVisibilityIndex={
              homeState.rightSideViewVisibilityIndex
            }
            orderItem={homeState.orderItem}
            itemInProgress={homeState.itemInProgress}
            openedItemId={homeState.itemInProgress?._id}
            closeOrder={hideOrder}
            addToOrder={addToOrder}
            sidebarAnimationClass={''}
          />
        );
      default:
        break;
    }

    switch (currentOrder?.stage) {
      case ORDER_STAGE_TYPES.NAME_FORM:
        return (
          <NameFormComponent
            inLayoutFlow={homeState.inLayoutFlow}
            updateOrderStageBack={updateOrderStageBack}
            sidebarAnimationClass={''}
            initiateDesktopLoyaltyFlow={homeState.initiateDesktopLoyaltyFlow}
            orderSetup={orderSetup}
            updateCurrentUser={updateCurrentUser}
            loyalty={homeState.loyalty}
          />
        );

      case ORDER_STAGE_TYPES.MOBILE_FORM:
        return (
          <MobileNumberComponent
            orderSetup={orderSetup}
            updateOrderStageBack={updateOrderStageBack}
            sidebarAnimationClass={''}
          />
        );

      case ORDER_STAGE_TYPES.VERIFY_FROM:
        return (
          <VerificationCodeComponent
            currentOrder={currentOrder}
            inLayoutFlow={homeState.inLayoutFlow}
            updateOrderStageBack={updateOrderStageBack}
            storeConfig={storeConfig}
            sidebarAnimationClass={''}
          />
        );

      case ORDER_STAGE_TYPES.REVIEW:
        return (
          <OrderReview
            currentOrder={currentOrder}
            menuItems={menuItems}
            removeSoldOutItems={removeSoldOutItems}
            removeOutOfScheduleItems={removeOutOfScheduleItems}
            removeOtherThanSelectedOrderTypeItems={
              removeOtherThanSelectedOrderTypeItems
            }
            userDetails={userDetails}
            updateOrderStage={updateOrderStage}
            allVouchers={reviewOrder.allVouchers}
            updateVoucher={updateVoucher}
            history={history}
            saveUnit={saveUnit}
            halfHalfSetup={halfHalfSetup}
            pickUpTimes={localState.pickUpTimes}
            deliveryTimes={localState.deliveryTimes}
            updateDeliveryPickupTime={updateDeliveryPickupTime}
            getDiscountedAmount={getDiscountedAmount}
            isSoldOutPresent={isSoldOutPresent}
            isOutOfSchedulePresent={isOutOfSchedulePresent}
            isOtherThanSelectedOrderTypePresent={
              isOtherThanSelectedOrderTypePresent
            }
            updateOrderStageBack={updateOrderStageBack}
            sidebarAnimationClass={''}
            orderSetup={orderSetup}
          />
        );

      case ORDER_STAGE_TYPES.PAYMENT:
        return (
          <OrderPayment
            orderSetup={orderSetup}
            updateOrderStage={updateOrderStage}
            updatePaymentTypeForCash={updatePaymentTypeForCash}
            updatePaymentTypeForWallet={updatePaymentTypeForWallet}
            userDetails={userDetails}
            fetchOrderHandler={(orderId) => dispatch(fetchOrder(orderId))}
            pickUpTimes={localState.pickUpTimes}
            deliveryTimes={localState.deliveryTimes}
            updateDeliveryPickupTime={updateDeliveryPickupTime}
            getStoreStatus={getStoreStatus}
            updateOrderStageBack={updateOrderStageBack}
            sidebarAnimationClass={''}
          />
        );

      case ORDER_STAGE_TYPES.PAYMENT_CARD:
        return (
          <OrderPaymentCards
            orderSetup={orderSetup}
            paymentCards={reviewOrder.paymentCards}
            paymentCardsFetched={reviewOrder.fetched}
            updateOrderStage={updateOrderStage}
            updatePaymenyType={updatePaymenyType}
            updatePaymentTypeForNewCard={updatePaymentTypeForNewCard}
            updatePaymentTypeForCard={updatePaymentTypeForCard}
            fetchOrderHandler={(orderId) => dispatch(fetchOrder(orderId))}
            history={history}
            userDetails={userDetails}
            pickUpTimes={localState.pickUpTimes}
            deliveryTimes={localState.deliveryTimes}
            updateDeliveryPickupTime={updateDeliveryPickupTime}
            getStoreStatus={getStoreStatus}
            updateOrderStageBack={updateOrderStageBack}
            sidebarAnimationClass={''}
            updateEngineOrderCalculations={updateEngineOrderCalculations}
          />
        );

      default:
        break;
    }

    return null;
  };

  const MainComponent = getMainComponents();

  return (
    <>
      <div
        id='main-order-container'
        style={{
          transform: 'translateX(0%)',
          width: '100%',
          padding: '0px',
          zIndex: 100,
        }}
        className={
          currentOrder &&
          currentOrder.stage &&
          currentOrder.stage >= ORDER_STAGE_TYPES.ALL_DONE
            ? 'col-md-4 customBar tempClass bg-white hideIt'
            : homeState.rightSideViewVisibilityIndex >=
              VISIBILITY_INDEX_TYPE.ORDER_VIEW
            ? 'col-md-4 customBar tempClass bg-white ordercartActive'
            : 'col-md-4 customBar tempClass bg-white '
        }
        data-sticky='data-sticky'
      >
        <SidebarProviders
          updateOrderStage={updateOrderStage}
          updateCurrentUser={updateCurrentUser}
          initiateDesktopLoyaltyFlow={homeState.initiateDesktopLoyaltyFlow}
          loyalty={homeState.loyalty}
          halfView={halfView}
        >
          {MainComponent}
        </SidebarProviders>
      </div>
    </>
  );
}
