export const validateName = (firstName, lastName) => {
  const nameErrors = {
    firstName: firstName.length === 0,
    lastName: lastName.length === 0,
  };
  const isDisabled = Object.keys(nameErrors).some((x) => nameErrors[x]);
  return {
    nameErrors,
    isDisabled,
  };
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
