const getFormattedMobileNumber = mobileNumber => {
  let number = mobileNumber;
  if (number[0] === '0') number = `${number}`;
  else if (number[0] === '6' && number[1] === '1')
    number = `0${number.substr(2)}`;
  else if (number[0] === '+') number = `0${number.substr(3)}`;
  else number = `0${number}`;
  return number;
};

export default getFormattedMobileNumber;
