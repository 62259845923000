import { loadStripe } from '@stripe/stripe-js';

let stripePromise;
const getStripePromise = (stripeKey) => {
  if (!stripePromise) {
    stripePromise = loadStripe(stripeKey);
  }
  return stripePromise;
};

export default getStripePromise;
