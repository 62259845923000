/* NOTE: 
    This helper function should be called just before 
    requesting the placeOrder3DSecure api with orderObject
*/

import {
  bookDineinTable,
  editDineinOrderObject,
  fetchDineinTableAndOrder,
  setActiveSeatId,
  setDineinOrderObject,
} from './dineinHelpers';
import { MENU_ORDER_TYPE } from './enums';

/*
Objectives of this helper function:
    1 - It will check if the order to placed is a dine-in order or not
    2 - If it is not a dine-in order, then it will just return the original order
    3 - If it is a dine-in order, then the helper function is reponsible for 
        fetching the latest status of dine-in table selected
    
*/

export default async function getOrderObjectIfDinein(orderObj3d) {
  const { floorLayoutId, dineInObjectId, orderType, storeId } =
    orderObj3d?.order || {};
  if (
    // eslint-disable-next-line eqeqeq
    orderType != MENU_ORDER_TYPE.dinein ||
    !floorLayoutId ||
    !dineInObjectId ||
    !storeId
  )
    return orderObj3d;

  const { dineInObjectStatus, dineInOrder } = await fetchDineinTableAndOrder({
    floorLayoutId,
    dineInObjectId,
    storeId,
  });

  let newOrderObj3d = orderObj3d;

  if (dineInObjectStatus?.isOccupied) {
    const prevOrder = dineInOrder || {};
    newOrderObj3d = editDineinOrderObject(
      {
        ...prevOrder,
        occupancyId: dineInObjectStatus.currentOccupancy,
      },
      orderObj3d
    );
    console.log('Is Occupied ✅');
  } else {
    const dineInOccupancy = await bookDineinTable({
      floorLayoutId,
      dineInObjectId,
      storeId,
    });
    console.log('Not Occupied ⛔️');
    newOrderObj3d = setDineinOrderObject(orderObj3d, dineInOccupancy, 0);
  }

  console.log(newOrderObj3d);

  if (newOrderObj3d?.order?._id?.trim?.() === '') {
    delete newOrderObj3d?.order?._id;
  }

  newOrderObj3d = setActiveSeatId(newOrderObj3d);
  return newOrderObj3d;
}
