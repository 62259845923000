const useSpecialItem = () => {
  const getItemPrice = (itemInProgress, halfHalfSetup) => {
    let itemPrice = 0;
    let quantity = 0;
    let extraPrice = 0;
 
    if (itemInProgress) {
      let currentItem = JSON.parse(JSON.stringify(itemInProgress));
      if (currentItem?.isSoldOut) {
        return { itemPrice, extraPrice };
      } else if (!currentItem.itemType || currentItem.itemType === 0) {
        currentItem?.selectedSizes?.forEach((item) => {
          if (item.quantity > 0) {
            quantity = quantity + item.quantity;
            itemPrice = itemPrice + item.quantity * Number(item.price);
          }
 
          item.variants?.forEach((v) => {
            if (v.quantity && Number(v.quantity) > 0)
              itemPrice = itemPrice + item.quantity * Number(v.price);
          });
        });
        if (quantity > 0) {
          itemPrice =
            extraPrice *
              (halfHalfSetup?.chargeFullHalfExtraPrice
                ? Math.max(quantity, 1)
                : quantity) +
            itemPrice;
        }
      } 
    }
 
    return { itemPrice, extraPrice };
  };

  const addToOrderItem = (item, halfHalfSetup) => {
    let itemObj = JSON.parse(JSON.stringify(item));
    let quantity = 0;
    let sBaseSubModifier = null
    
    let matchingBaseModifiers = itemObj?.modifiers?.filter((p) => {
      return p._id === itemObj.baseModifierId;
    });
    let matchedModifier = matchingBaseModifiers
      ? matchingBaseModifiers[0]
      : undefined;
    let matchedSubModifiers = matchedModifier?.subModifiers.filter((f) => {
      return f.quantity > 0;
    });

    itemObj?.modifiers?.map((m) => {
      if (m._id === 'xxx-xxx-xxx' && matchedSubModifiers) {
        m.subModifiers = matchedSubModifiers;
        sBaseSubModifier = matchedSubModifiers[0];
      }
      return m;
    });

    if (!itemObj.itemType || itemObj.itemType === 0) {
      itemObj.selectedSizes = itemObj.selectedSizes.filter((s) => {
        if (s.variants) {
          s.variants = s.variants.filter(
            (v) => v.quantity && Number(v.quantity) > 0
          );
        }
 
        if (s.quantity && Number(s.quantity) > 0) {
          quantity += s.quantity;
        }
 
        return s.quantity && Number(s.quantity) > 0;
      });
    } else {
      // Fillup data
      itemObj.selectedModifiers.forEach((m) => {
        let matchingModifiers = itemObj?.modifiers?.filter(
          (fm) => fm._id === m._id
        );

        if (matchingModifiers?.length > 0) {
          const isSingleSize = m.subModifiers?.length === 1;
          let matchedModifier = matchingModifiers[0];
          m.subModifiers.forEach((s) => {
            let matchingSubModifiers = matchedModifier?.subModifiers.filter(
              (fs) => fs._id === s._id && fs.quantity > 0
            );
            if (matchingSubModifiers.length > 0) {
              let matchedSubModifier = matchingSubModifiers[0];
              if (m._id === itemObj.baseModifierId) {
                s.quantity = matchedSubModifier.quantity;
                s.isSelected = matchedSubModifier.quantity > 0 ? true : false;

                s.variants?.forEach((v) => {
                  if (v._id === matchedSubModifier._vId) {
                    v.quantity = matchedSubModifier.quantity;
                    v.isSelected = true;
                  } else {
                    v.quantity = 0;
                    v.isSelected = false;
                  }
                });
              } else {
                s.isSelected = true;

                s.prices?.forEach((p) => {
                  if (p?._id === sBaseSubModifier?._id) {
                    p.isSelected = true;
                    p.quantity = matchedSubModifier.quantity;

                    p.variants?.forEach((v) => {
                      if (v._id === matchedSubModifier._vId) {
                        v.quantity = matchedSubModifier.quantity;
                        v.isSelected = true;
                      } else {
                        v.quantity = 0;
                        v.isSelected = false;
                      }
                    });
                  } else {
                    p.isSelected = false;
                    p.quantity = 0;
                  }
                });
              }
            } else {
              s.isSelected = false;
            }
            s.isSingleSize = isSingleSize;
          });
        }
      });

      // Step 1: Remove not selected sub modifiers
      itemObj.selectedModifiers.forEach((m) => {
        m.subModifiers = m.subModifiers.filter((s) => s.isSelected === true);
      });

      // Step 2: Cleanup submodifiers
      itemObj.selectedModifiers.forEach((m) => {
        m.subModifiers = m.subModifiers.map((s) => {
          if (m._id === itemObj.baseModifierId) {
            s.variants = s.variants?.filter((f) => f.isSelected);
          } else {
            s.prices = s.prices.filter((f) => f.isSelected);
            s.prices.forEach((p) => {
              if (p.variants) {
                p.variants = p.variants.filter((f) => f.isSelected);
              }
            });
          }
          return s;
        });
        if (m._id === itemObj.baseModifierId)
          itemObj.selectedSizes = m.subModifiers;
      });

      // Step 3: Composite name for size
      let subsMs = [];
      itemObj.selectedModifiers?.forEach((m) => {
        subsMs = [...subsMs, ...m.subModifiers];
      });

      itemObj.selectedSizes.forEach((s) => {
        quantity += s.quantity;
        s.cName = subsMs
          .filter((f) => f.name !== s.name)
          .map((m) => m.name)
          .join(',');
      });
    }

        // Extra Ingredients
    if (itemObj.selectedExtraIngredients) {
      itemObj.selectedExtraIngredients =
        itemObj.selectedExtraIngredients.filter(
          (m) => m.quantity && Number(m.quantity) > 0
        );
      if (itemObj.itemType === 1) {
        itemObj.selectedExtraIngredients = itemObj.selectedExtraIngredients.map(
          (m) => {
            m.finalPrices?.forEach((p) => {
              if (
                p._id === sBaseSubModifier._id &&
                p._vId === sBaseSubModifier._vId
              ) {
                m.price = p.price;
                m.sizeId = p._id;
                m.variants = p._id;
              }
            });

            m.finalPrices && delete m.finalPrices;
            m.prices && delete m.prices;
            return m;
          }
        );
      } else {
        itemObj.selectedExtraIngredients = itemObj.selectedExtraIngredients.map(
          (m) => {
            let price = 0;
            itemObj.selectedSizes.forEach((s) => {
              m.prices?.forEach((p) => {
                if (p._id === s._id) {
                  price = price + Number(p.price);
                  m.sizeId = s._id;
                  if (s.variants && p.variants) {
                    s.variants.forEach((v) => {
                      if (Number(v.quantity) > 0) {
                        let matchingVariants = p.variants.filter(
                          (f) => f._id === v.Id
                        );
                        if (matchingVariants.length > 0) {
                          price = price + Number(matchingVariants[0].price);
                          m.variantId = matchingVariants[0]._id;
                        }
                      }
                    });
                  }
                }
              });
            });
            m.price = price;
            m.finalPrices && delete m.finalPrices;
            m.prices && delete m.prices;
            return m;
          }
        );
      }
    }

    // Default Ingredients
    if (itemObj.selectedIngredients) {
      let ings = itemObj.selectedIngredients;
      itemObj.selectedIngredients = ings.filter((m) => m.inOrder);
      itemObj.removedIngredients = ings.filter((m) => !m.inOrder);
    }
 
    // Resetting Item view to normacy
    delete itemObj.showPrice;
    delete itemObj.modifiers;
    delete itemObj.orders;
    delete itemObj.orderCount;
 
    // Add general additional fields
    const { itemPrice, extraPrice } = getItemPrice(itemObj, halfHalfSetup);
    itemObj.itemPrice = itemPrice - extraPrice;
    itemObj.extraPrice = extraPrice;
    itemObj.payablePrice = itemPrice;
    itemObj.quantity = quantity > 1 ? quantity : 1;
 
    if (itemObj.itemVariants) delete itemObj.itemVariants;
 
    return itemObj;
  };
 
  return {
    addToOrderItem,
    getItemPrice
  }
}


export default useSpecialItem;