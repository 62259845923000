/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import {
  setBookingNotes,
  setState as setPropsState,
  toggleMobileCart,
} from 'containers/Home/actions';
import { Transition } from 'react-transition-group';
import TableBookingButton from '../../components/TableBookingButton';
import Select from '../../components/Select';
import classNames from 'classnames';
import {
  getCurrentShift,
  getRestauranTimeArrays,
} from '../../helpers/getTimeSlots';
import calendarTime from '../TableBookingMobile/img/calendar-time.svg';
import notes from '../TableBookingMobile/img/notes.svg';
import users from '../TableBookingMobile/img/users.svg';
import { useDispatch, useSelector } from 'react-redux';
import { selectStoreConfig } from '../../containers/Layout/selectors';
import nextLoading from '../../assets/images/next-loading.svg';
import Map from 'components/Map';
import storeMarkerGenerator from '../../helpers/generateStoreMarker';
import { useHistory } from 'react-router-dom';
import { LOGIN_FROM_TYPE, ORDER_STAGE_TYPES } from '../../helpers/enums';
import { TableBookingViewContext } from 'contexts/TableBookingViewProvider';
import { BackButtonCWUI } from '@next-order/next-order-components';
import { defaultHomePropsState } from 'constants/common';

export default function TableBookingView({
  userDetails,
  allShifts,
  closeDates,
  orderSetup,
  updateOrderStage,
  addReservationhandler,
  sidebarAnimationClass,
  loginType,
  setLoginType,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const home = useSelector((state) => state.homeReducer);
  const storeConfig = useSelector(selectStoreConfig);

  const [localState, setLocalState] = useContext(TableBookingViewContext);

  useEffect(() => {
    // We look for this state when we login to choose whether we want to show booking screen or review screen
    // ==================== //
    return () => {
      window.removeEventListener('click', handleOutSideTime);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      loginType === LOGIN_FROM_TYPE.BOOKING &&
      userDetails?.mobileNumber &&
      localState.allowNewBooking &&
      !localState.showConfirmationScreen
    ) {
      setLoginType(LOGIN_FROM_TYPE.MAIN);
      setLocalState({
        showConfirmationScreen: true,
      });
    }
    // eslint-disable-next-line
  }, [userDetails?.mobileNumber, localState.allowNewBooking]);

  useEffect(() => {
    if (!allShifts) return;
    let obj = getCurrentShift(
      storeConfig,
      allShifts,
      closeDates ? closeDates : []
    );
    let restaurantTimes = getRestauranTimeArrays(
      storeConfig,
      allShifts,
      closeDates ? closeDates : []
    );
    setLocalState({
      currentShift: obj,
      currentLatestShift: allShifts,
      restaurantTimes,
    });
    // eslint-disable-next-line
  }, [allShifts, closeDates, storeConfig]);

  const increaseChildrenQuantity = () => {
    setLocalState({
      reservation: {
        ...localState.reservation,
        children: localState.reservation.children + 1,
      },
      shake1: false,
    });
  };

  const handleOutSideTime = (e) => {
    setLocalState({
      showCalendarCloseAnimation: true,
    });
    setTimeout(() => {
      setLocalState({
        timeSlot: false,
      });
    }, 300);
  };

  const handleOutSideTimeSwiper = (e) => {
    setLocalState({
      showSwiperCloseAnimation: true,
    });
    setTimeout(() => {
      setLocalState({
        swiper: false,
        showCalendarAnimation: false,
        showSwiperAnimation: false,
      });
    }, 300);
  };

  const calendarClick = (e) => {
    setLocalState({
      showCalendar: true,
    });
    e.stopPropagation();
  };

  const select = (day, monthStatus) => {
    const selected = day.date;
    setLocalState({
      showCalendarCloseAnimation: true,
      selected,
      [monthStatus]: selected.clone(),
      swiper: true,
      timeSlot: false,
      showSwiperAnimation: true,
    });

    let restaurantTimes = localState.restaurantTimes;
    let selectedDay = selected.day();

    let selectedWeekDay = restaurantTimes.filter(
      (restaurantDay) => restaurantDay.day === selectedDay.toString()
    );

    if (selectedWeekDay.length > 1) {
      // Check if Selected Day is Today.
      if (selected.isSame(new Date(), 'day')) {
        selectedWeekDay = selectedWeekDay.filter((day) => {
          return day.isToday === true;
        });
      } else {
        //Not Same as Selected Day
        selectedWeekDay = selectedWeekDay.filter(
          (day) => day.isToday === false
        );
      }
    }
    if (selectedWeekDay.length !== 0) {
      setLocalState({
        selectedWeekDay,
        selectedTime: selectedWeekDay[0]?.times[0]?.display,
      });
    }
  };

  const goBackToCalendar = () => {
    setLocalState({
      swiper: false,
      timeSlot: true,
      showCalendarCloseAnimation: false,
      showSwiperAnimation: false,
    });
  };

  const openTimeBar = (e) => {
    setLocalState({
      timeSlot: true,
      showCalendarAnimation: true,
      showCalendarCloseAnimation: false,
      showSwiperCloseAnimation: false,
    });
    e.stopPropagation();
  };

  const renderMonths = (monthNameString, monthName) => {
    let weeks = [];
    let done = false;
    let date = monthName
      .clone()
      .startOf('month')
      .add('w' - 1)
      .day('Sunday');

    let count = 0;
    let monthIndex = date.month();

    const { selected } = localState;
    while (!done) {
      weeks.push(
        <Week
          key={date}
          date={date.clone()}
          month={localState[monthNameString]}
          select={(day) => select(day, monthNameString)}
          selected={selected}
          closeDates={closeDates}
          restaurantTimes={localState.restaurantTimes}
          orderSetup={orderSetup}
        />
      );

      date.add(1, 'w');

      done = count++ > 2 && monthIndex !== date.month();
      monthIndex = date.month();
    }

    return weeks;
  };

  const handleChange = (timeObj) => {
    let reservationDateDisplay = moment(
      `${timeObj.timeObjFinal} ${timeObj.displayForUse}`,
      'YYYY-MM-DD hh:mm A'
    );
    setLocalState({
      selectedTime: timeObj.displayForUse,
      reservationDateDisplay,
    });
  };

  const getReservationStatus = () => {
    if (home && home.currentReservations) {
      if (home.currentReservations.reservationStatus == 2) {
        return 'CONFIRMED';
      } else if (home.currentReservations.reservationStatus == 3) {
        return 'DECLINED';
      } else if (home.currentReservations.reservationStatus == 1) {
        return 'AWAITING CONFIRMATION';
      } else {
        return 'AWAITING CONFIRMATION';
      }
    } else {
      return 'AWAITING CONFIRMATION';
    }
  };

  const setReservationDate = () => {
    if (localState.reservationDateDisplay !== undefined) {
      let resDate = moment(localState.selected);
      let reservationDateNew = moment(localState.reservationDateDisplay);
      reservationDateNew.year(resDate.year());
      reservationDateNew.month(resDate.month());
      reservationDateNew.date(resDate.date());
      setLocalState({
        showSwiperCloseAnimation: true,
      });
      setTimeout(() => {
        setLocalState({
          reservationDate: reservationDateNew,
          reservation: {
            ...localState.reservation,
            reservationDate: reservationDateNew.format('YYYY-MM-DD HH:mm'),
          },
          timeSlot: false,
          shake: false,
          allowNewBooking: localState.reservation.adults > 0,
          swiper: false,
          showCalendarAnimation: false,
          showSwiperAnimation: false,
          isToday: reservationDateNew.isSame(new Date(), 'day'),
          isTomorrow: reservationDateNew.isSame(
            moment(new Date()).add(1, 'day'),
            'day'
          ),
        });
      }, 300);
    } else {
      let resDate = moment(localState.selected);

      let reservationDateDisplay = moment(
        `${localState.selectedWeekDay[0].times[0].timeObjFinal} ${localState.selectedWeekDay[0].times[0].displayForUse}`,
        'YYYY-MM-DD hh:mm A'
      );
      let reservationDateNew = moment(reservationDateDisplay);
      reservationDateNew.year(resDate.year());
      reservationDateNew.month(resDate.month());
      reservationDateNew.date(resDate.date());
      setLocalState({
        showSwiperCloseAnimation: true,
      });
      setTimeout(() => {
        setLocalState({
          reservationDate: reservationDateNew,
          reservation: {
            ...localState.reservation,
            reservationDate: reservationDateNew.format('YYYY-MM-DD HH:mm'),
          },
          reservationDateDisplay: reservationDateNew,
          timeSlot: false,
          shake: false,
          allowNewBooking: localState.reservation.adults > 0 ? true : false,
          swiper: false,
          showCalendarAnimation: false,
          showSwiperAnimation: false,
          isToday: reservationDateNew.isSame(new Date(), 'day'),
          isTomorrow: reservationDateNew.isSame(
            moment(new Date()).add(1, 'day'),
            'day'
          ),
        });
      }, 300);
    }
  };

  const decreaseChildrenQuantity = () => {
    const reservationChildren = localState.reservation.children - 1;
    setLocalState({
      reservation: {
        ...localState.reservation,
        ...(reservationChildren <= 0 && localState.reservation.adults <= 0
          ? { allowNewBooking: false }
          : {}),
        children: reservationChildren,
      },
    });
  };

  const increaseAdultQuantity = () => {
    setLocalState({
      reservation: {
        ...localState.reservation,
        adults: localState.reservation.adults + 1,
      },
      allowNewBooking: true,
      shake1: false,
    });
  };

  const decreaseAdultQuantity = () => {
    const adults = localState.reservation.adults - 1;
    setLocalState({
      reservation: {
        ...localState.reservation,
        ...(adults <= 0 ? { allowNewBooking: false } : {}),
        adults,
      },
    });
  };

  const increaseInfantsQuantity = () => {
    setLocalState({
      reservation: {
        ...localState.reservation,
        infants: localState.reservation.infants + 1,
      },
    });
  };

  const decreaseInfantsQuantity = () => {
    setLocalState({
      reservation: {
        ...localState.reservation,
        ...(localState.reservation.adults <= 0
          ? { allowNewBooking: false }
          : {}),
        infants: localState.reservation.infants - 1,
      },
    });
  };

  const updateConfirmationFlag = () => {
    if (
      !localState.allowNewBooking &&
      localState.reservationDate === undefined
    ) {
      setLocalState({ shake: true, shake1: true });
    } else if (
      !localState.allowNewBooking &&
      localState.reservationDate !== undefined
    ) {
      setLocalState({ shake1: true });
    } else if (
      localState.allowNewBooking &&
      localState.reservationDate === undefined
    ) {
      setLocalState({ shake: true });
    } else if (
      localState.allowNewBooking &&
      userDetails &&
      userDetails.mobileNumber &&
      localState.reservationDate !== undefined
    ) {
      /* When user is logged in */

      setLocalState({
        showConfirmationScreen: true,
        showAnimation: false,
      });
      history.push('/booking-confirmation');
    } else if (
      localState.reservationDate !== undefined &&
      localState.allowNewBooking
    ) {
      /* When user is not logged in*/
      history.push('/username');
      updateOrderStage(ORDER_STAGE_TYPES.NAME_FORM, LOGIN_FROM_TYPE.BOOKING);
    }
  };

  const closeConfirmationScreen = () => {
    setLocalState({
      showAnimation: true,
    });
    history.push('/booking');
    setTimeout(() => {
      setLocalState({
        showConfirmationScreen: false,
      });
    }, 300);
  };

  const placeBooking = () => {
    if (
      !localState.allowNewBooking &&
      localState.reservationDate === undefined
    ) {
      setLocalState({ shake: true, shake1: true });
    } else if (
      !localState.allowNewBooking &&
      localState.reservationDate !== undefined
    ) {
      setLocalState({ shake1: true });
    } else if (
      localState.allowNewBooking &&
      localState.reservationDate === undefined
    ) {
      setLocalState({ shake: true });
    } else if (
      localState.allowNewBooking &&
      userDetails &&
      userDetails.mobileNumber &&
      localState.reservationDate !== undefined
    ) {
      addReservationhandler(localState.reservation);

      setLocalState({
        allowNewBooking: false,
        showConfirmationScreen: true,
        showWaitingScreen: true,
        showSlideInAnimation: true,
      });
    } else if (
      localState.reservationDate !== undefined &&
      localState.allowNewBooking
    ) {
      history.push('/username');
      updateOrderStage(1);
    }
  };

  const renderConfirmationScreen = () => {
    let reviewsClass = classNames({
      'confirmation-screen-container true about-slide-in-animation':
        localState.showConfirmationScreen,
      'confirmation-screen-container': !localState.showConfirmationScreen,
      'about-slide-out-animation': localState.showAnimation,
    });

    return (
      <div className={reviewsClass}>
        <div
          className='booking-main-section'
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div className='close-book-back padding-20'>
            <BackButtonCWUI onClick={closeConfirmationScreen} />
          </div>
          <div className='stickyTitle padding-20 ligature-fix'>
            Confirm Booking
          </div>

          <div className='addressMobileBar address-booking-bar'>
            <div className='restaurant-address'>
              <i className='icon-Location-Pin zoom-icon margin-2-rem' />
              <div className='align-with-label align-address'>
                {storeConfig.address}
              </div>
            </div>
            <div className={`address-time-bar pick`}>
              <i className='icon-Clock zoom-icon margin-2-rem' />
              <div className='align-with-label align-address'>
                {localState.isToday ? (
                  <React.Fragment>
                    {`Today, ${localState.reservationDate.format(
                      'Do MMM, hh:mm A'
                    )}`}
                  </React.Fragment>
                ) : localState.isTomorrow ? (
                  <React.Fragment>
                    {`Tomorrow, ${localState.reservationDate.format(
                      'Do MMM, hh:mm A'
                    )}`}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {localState.reservationDate
                      ? localState.reservationDate.format(
                          'dddd, Do MMM, hh:mm A'
                        )
                      : 'Select Time'}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>

          <div
            className='resCat persons-selection-section'
            style={{
              flex: 1,
            }}
          >
            {localState.reservation.adults !== 0 && (
              <div
                className={`resTable align-persons-section align-confirmation-labels`}
              >
                <div className='typeOfPep persons-selection-label'>
                  {localState.reservation.adults === 1 ? 'Adult' : 'Adults'}
                </div>
                <div className='typeOfPep persons-selection-label'>
                  {localState.reservation.adults}
                </div>
              </div>
            )}
            {localState.reservation.children !== 0 && (
              <div className='resTable align-persons-section align-confirmation-labels'>
                <div className='typeOfPep persons-selection-label'>
                  {localState.reservation.children === 1 ? 'Child' : 'Children'}
                  <span>Ages 2-12</span>
                </div>
                <div className='typeOfPep persons-selection-label'>
                  {localState.reservation.children}
                </div>
              </div>
            )}
            {localState.reservation.infants !== 0 && (
              <div className='resTable align-persons-section align-confirmation-labels'>
                <div className='typeOfPep persons-selection-label'>
                  {localState.reservation.infants === 1 ? 'Infant' : 'Infants'}
                  <span>Ages 0-2</span>
                </div>
                <div className='typeOfPep persons-selection-label'>
                  {localState.reservation.infants}
                </div>
              </div>
            )}
          </div>

          <div
            className='totalbottom'
            style={{
              padding: '0 20px',
            }}
          >
            <div className='book-views-note-box'>
              <div className='inputGroup'>
                <span>Notes</span>
                <textarea
                  placeholder='Any occasion or special requests?'
                  autoComplete='off'
                  type='text'
                  name='bookingNotes'
                  value={home.bookingNotes}
                  onChange={(e) => {
                    dispatch(setBookingNotes(e.target.value));
                    setLocalState({
                      ...localState,
                      reservation: {
                        ...localState.reservation,
                        notes: e.target.value,
                      },
                    });
                  }}
                ></textarea>
              </div>
            </div>
            <div className='totalInner' style={{ paddingBottom: '13px' }}>
              <div
                onClick={placeBooking}
                className={
                  localState.allowNewBooking
                    ? 'confirmBtn'
                    : 'confirmBtn diableConfirm'
                }
              >
                Confirm Booking
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const closeWaitingScreen = () => {
    let reservation = {
      reservationDate: moment()
        .tz(storeConfig.timeZone)
        .format('YYYY-MM-DD HH:mm'),
      children: 0,
      adults: 0,
      infants: 0,
      reservationStatus: '1',
      date: moment().tz(storeConfig.timeZone).format('YYYY-MM-DD HH:mm'),
      notes: '',
    };
    setLocalState({
      showSlideInAnimation: false,
      showBookingCloseAnimation: true,
      allowNewBooking: false,
      showConfirmationScreen: false,
    });
    setTimeout(() => {
      setLocalState({
        reservation,
        showBookingCloseAnimation: false,
        showWaitingScreen: false,
      });
    }, 400);
  };

  const renderWaitingScreen = () => {
    let reviewsClass = classNames({
      'waiting-screen-container  true': localState.showWaitingScreen,
      'about-slide-in-animation': localState.showSlideInAnimation,
      'waiting-screen-container': !localState.showWaitingScreen,
      'about-slide-out-animation': localState.showAnimation,
      'table-booking-close-animation': localState.showBookingCloseAnimation,
    });

    return (
      <Transition
        timeout={250}
        mountOnEnter
        unmountOnExit
        in={home.bookingOpen}
      >
        {(state) => (
          <React.Fragment>
            <div className={reviewsClass}>
              <div className='wrapper' style={{ height: '100%' }}>
                <div
                  className='section-booking'
                  id='section-booking'
                  style={{ height: '100%' }}
                >
                  <div className='header-box'>
                    <div className='container'>
                      <div className='top-box mb-4'>
                        <BackButtonCWUI onClick={closeWaitingScreen} />
                      </div>
                      <h1 className='title'>Table Booking</h1>
                      <span className='subtitle'>{getReservationStatus()}</span>
                    </div>
                  </div>
                  {localState.showWaitingScreen &&
                    storeConfig?.addressLocation && (
                      <div className='map-box'>
                        <div className='map'>
                          <Map
                            lat={storeConfig.addressLocation.lat}
                            long={storeConfig.addressLocation.long}
                            customMarker={storeMarkerGenerator()?.url}
                          />
                        </div>
                      </div>
                    )}
                  <div
                    className='content-box'
                    style={{ height: `${localState.ulHeight}%` }}
                  >
                    <div className='address-box'>
                      <div className='container'>
                        <div className='address-table'>
                          <div className='left-box'>
                            <h2 className='company-name'>{storeConfig.name}</h2>
                            <p className='address'>{storeConfig.address}</p>
                          </div>
                          <div className='right-box'>
                            <a
                              href={`tel:${storeConfig.phoneNumber}`}
                              className='btn'
                              title='Call'
                            >
                              Call
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='info-box'>
                      <div className='container'>
                        <ul className='info-list'>
                          <li>
                            <div className='icon-box'>
                              <img
                                src={calendarTime}
                                alt='calendar'
                                className='img-calendar'
                              />
                            </div>
                            <div className='title-box'>
                              <p>
                                {localState.reservationDate.format(
                                  'dddd Do MMM YYYY, hh:mm A'
                                )}
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className='icon-box'>
                              <img
                                src={users}
                                alt='users'
                                className='img-users'
                              />
                            </div>
                            <div className='title-box'>
                              <p>
                                {localState.reservation.adults !== 0 && (
                                  <span>
                                    {localState.reservation.adults === 1 ? (
                                      <span>
                                        {localState.reservation.adults} Adult
                                      </span>
                                    ) : (
                                      <span>
                                        {localState.reservation.adults} Adults
                                      </span>
                                    )}
                                  </span>
                                )}
                                {localState.reservation.children !== 0 && (
                                  <span>
                                    {localState.reservation.children === 1 ? (
                                      <span>
                                        ,{localState.reservation.children} Child
                                      </span>
                                    ) : (
                                      <span>
                                        ,{localState.reservation.children}{' '}
                                        Children
                                      </span>
                                    )}
                                  </span>
                                )}
                                {localState.reservation.infants !== 0 && (
                                  <span>
                                    {localState.reservation.infants === 1 ? (
                                      <span>
                                        ,{localState.reservation.infants} Infant
                                      </span>
                                    ) : (
                                      <React.Fragment>
                                        <span>
                                          ,{localState.reservation.infants}
                                          Infants
                                        </span>
                                      </React.Fragment>
                                    )}
                                  </span>
                                )}
                              </p>
                            </div>
                          </li>
                          {localState.reservation.notes.length > 0 && (
                            <li>
                              <div className='icon-box'>
                                <img
                                  src={notes}
                                  alt='notes'
                                  className='img-notes'
                                />
                              </div>
                              <div className='title-box'>
                                <p>{localState.reservation.notes}</p>
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </Transition>
    );
  };

  const onClose = () => {
    dispatch(toggleMobileCart(false));
    dispatch(setPropsState({ ...defaultHomePropsState }));
  };

  let calendarClass = classNames({
    'about-animation': localState.showCalendarAnimation,
    calendar: true,
    'about-close-animation': localState.showCalendarCloseAnimation,
  });

  let timeSlotClass = classNames({
    'timeSlots active': localState.timeSlot || localState.swiper,
    timeSlots: !(localState.timeSlot || localState.swiper),
    'about-close-animation': localState.showSwiperCloseAnimation,
    'about-animation': localState.showSwiperAnimation,
  });

  let calendarWrapperClass = classNames({
    'calendar-wrapper': true,
    'about-close-animation': localState.showSwiperCloseAnimation,
  });

  return (
    <div
      id='booking-desktop'
      style={{
        width: '100%',
        height: '100%',
      }}
      className={sidebarAnimationClass}
    >
      {localState.showWaitingScreen ? (
        <React.Fragment>{renderWaitingScreen()}</React.Fragment>
      ) : null}
      {localState.showConfirmationScreen ? (
        <React.Fragment>{renderConfirmationScreen()}</React.Fragment>
      ) : null}
      <div
        className='book-views'
        style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
        onClick={(e) => handleOutSideTime(e)}
      >
        <BackButtonCWUI extendedClassName='ml-5 mb-3.5' onClick={onClose} />

        <div className='stickyTitle padding-20'>Book a table</div>
        <div style={{ flex: 1 }}>
          <div className='addressMobileBar address-booking-bar'>
            <div className='restaurant-address'>
              <i className='icon-Location-Pin zoom-icon' />
              <div className='align-with-label align-address'>
                {storeConfig.address}
              </div>
            </div>
            <div
              className={`address-time-bar pick ${localState.shake}`}
              onClick={openTimeBar}
            >
              <i className='icon-Clock zoom-icon margin-2-rem' />
              <div
                className='align-with-label align-address'
                style={{ cursor: 'pointer' }}
              >
                {localState.isToday ? (
                  <React.Fragment>
                    {`Today, ${localState.reservationDate.format(
                      'Do MMM, hh:mm A'
                    )}`}
                  </React.Fragment>
                ) : localState.isTomorrow ? (
                  <React.Fragment>
                    {`Tomorrow, ${localState.reservationDate.format(
                      'Do MMM, hh:mm A'
                    )}`}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {localState.reservationDate
                      ? localState.reservationDate.format(
                          'dddd, Do MMM, hh:mm A'
                        )
                      : 'Select Time'}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>

          <div className='resCat padding-20' style={{ paddingTop: '20px' }}>
            <div
              className={`resTable ${localState.shake1} align-persons-section`}
            >
              <div className='typeOfPep persons-selection-label align-adults font-100'>
                Adults
              </div>
              <TableBookingButton
                quantity={localState.reservation.adults}
                increseQuantity={increaseAdultQuantity}
                decreseQuantity={decreaseAdultQuantity}
              />
            </div>
            <div className='resTable align-persons-section'>
              <div className='typeOfPep persons-selection-label align-children'>
                <div className='children-label font-100'>Children</div>
                <span className='children-span font-100'>Ages 2-12</span>
              </div>
              <TableBookingButton
                quantity={localState.reservation.children}
                increseQuantity={increaseChildrenQuantity}
                decreseQuantity={decreaseChildrenQuantity}
              />
            </div>
            <div className='resTable align-persons-section'>
              <div className='typeOfPep persons-selection-label align-infants'>
                <div className='infants-label font-100'>Infants</div>
                <span className='font-100'>Ages 0-2</span>
              </div>
              <TableBookingButton
                quantity={localState.reservation.infants}
                increseQuantity={increaseInfantsQuantity}
                decreseQuantity={decreaseInfantsQuantity}
              />
            </div>
          </div>
        </div>
        <div className='totalbottom padding-20'>
          <div className='totalInner' style={{ paddingBottom: '13px' }}>
            <div
              onClick={updateConfirmationFlag}
              className={
                localState.allowNewBooking && !home?.isFetchingUser
                  ? 'confirmBtn'
                  : 'confirmBtn diableConfirm'
              }
            >
              {home?.isFetchingUser ? (
                <div className='flex items-center justify-center'>
                  Loading User details
                  <img className='h-7 w-7 ml-2' src={nextLoading} alt='' />
                </div>
              ) : (
                'Place Booking'
              )}
            </div>
          </div>
        </div>

        {/* Calendar and Time Components*/}
        <div
          id='timeSlot'
          className={timeSlotClass}
          style={{
            position: 'fixed',
            width: '100%',
            bottom: '-2px',
            zIndex: '1',
          }}
        >
          {localState.timeSlot === true && (
            <React.Fragment>
              <div className='wrapper'>
                <div id='calendar-box' className='calendar-wrapper'>
                  <div
                    className={calendarClass}
                    onClick={(e) => calendarClick(e)}
                  >
                    <div className='week-days-box'>
                      <ul className='week-days'>
                        <li>
                          <span>SUN</span>
                        </li>
                        <li>
                          <span>MON</span>
                        </li>
                        <li>
                          <span>TUE</span>
                        </li>
                        <li>
                          <span>WED</span>
                        </li>
                        <li>
                          <span>THU</span>
                        </li>
                        <li>
                          <span>FRI</span>
                        </li>
                        <li>
                          <span>SAT</span>
                        </li>
                      </ul>
                    </div>
                    <div className='month-list-box'>
                      <ul className='month-list'>
                        <li>
                          <div className='current-month'>
                            <div className='month-name'>
                              {moment().format('MMMM')}
                            </div>
                            <ul className='days'>
                              {renderMonths('month', localState.month)}
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div className='next-month'>
                            <div className='month-name'>
                              {moment().add(1, 'month').format('MMMM')}
                            </div>
                            <ul className='days'>
                              {renderMonths('nextMonth', localState.nextMonth)}
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div className='next-month'>
                            <div className='month-name'>
                              {moment().add(2, 'month').format('MMMM')}
                            </div>
                            <ul className='days'>
                              {renderMonths(
                                'nextMonth2',
                                localState.nextMonth2
                              )}
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div className='next-month'>
                            <div className='month-name'>
                              {moment().add(3, 'month').format('MMMM')}
                            </div>
                            <ul className='days'>
                              {renderMonths(
                                'nextMonth3',
                                localState.nextMonth3
                              )}
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div className='next-month'>
                            <div className='month-name'>
                              {moment().add(4, 'month').format('MMMM')}
                            </div>
                            <ul className='days'>
                              {renderMonths(
                                'nextMonth4',
                                localState.nextMonth4
                              )}
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div className='next-month'>
                            <div className='month-name'>
                              {moment().add(5, 'month').format('MMMM')}
                            </div>
                            <ul className='days'>
                              {renderMonths(
                                'nextMonth5',
                                localState.nextMonth5
                              )}
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div className='next-month'>
                            <div className='month-name'>
                              {moment().add(6, 'month').format('MMMM')}
                            </div>
                            <ul className='days'>
                              {renderMonths(
                                'nextMonth6',
                                localState.nextMonth6
                              )}
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div className='next-month'>
                            <div className='month-name'>
                              {moment().add(7, 'month').format('MMMM')}
                            </div>
                            <ul className='days'>
                              {renderMonths(
                                'nextMonth7',
                                localState.nextMonth7
                              )}
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div className='next-month'>
                            <div className='month-name'>
                              {moment().add(8, 'month').format('MMMM')}
                            </div>
                            <ul className='days'>
                              {renderMonths(
                                'nextMonth8',
                                localState.nextMonth8
                              )}
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div className='next-month'>
                            <div className='month-name'>
                              {moment().add(9, 'month').format('MMMM')}
                            </div>
                            <ul className='days'>
                              {renderMonths(
                                'nextMonth9',
                                localState.nextMonth9
                              )}
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div className='next-month'>
                            <div className='month-name'>
                              {moment().add(10, 'month').format('MMMM')}
                            </div>
                            <ul className='days'>
                              {renderMonths(
                                'nextMonth10',
                                localState.nextMonth10
                              )}
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div className='next-month'>
                            <div className='month-name'>
                              {moment().add(11, 'month').format('MMMM')}
                            </div>
                            <ul className='days'>
                              {renderMonths(
                                'nextMonth11',
                                localState.nextMonth11
                              )}
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {localState.swiper === true && (
            <React.Fragment>
              <div
                id='time-select-box'
                className={calendarWrapperClass}
                onClick={(e) => calendarClick(e)}
              >
                <div className='time-box'>
                  <div className='time-header-box'>
                    <div className='time-header-inner'>
                      <div className='cell'>
                        <p className='text'>
                          {localState.selected.format('dddd - Do MMMM YYYY')}
                        </p>
                      </div>
                      <div className='cell change'>
                        <a
                          className='btn-orange'
                          title='Change'
                          onClick={() => goBackToCalendar()}
                        >
                          Change
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='swiperTime'>
                {localState.selectedWeekDay?.map((item, index) => (
                  <div className='subTimeXS' key={index}>
                    <Select
                      handleChange={handleChange}
                      key={`picker${index}`}
                      data={item}
                      index={index}
                    />
                  </div>
                ))}
              </div>
              <div
                className='selectTime selectTimeDesktop'
                onClick={(e) => setReservationDate(e)}
                style={{ cursor: 'pointer' }}
              >
                Set Reservation Time
                {localState.selectedTime !== '' && (
                  <span> - {localState.selectedTime}</span>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
        <div
          className={localState.swiper ? 'overlayTime active' : 'overlayTime'}
          onClick={handleOutSideTimeSwiper}
        />
      </div>
    </div>
  );
}

function Week({
  date,
  month,
  selected,
  select,
  closeDates,
  restaurantTimes,
  orderSetup,
}) {
  const [days, setDays] = useState([]);

  useEffect(() => {
    let dateRef = date;

    const allDays = [];

    for (let i = 0; i < 7; i++) {
      const day = {
        name: dateRef.format('dd').substring(0, 1),
        number: dateRef.date(),
        isCurrentMonth: dateRef.month() === month.month(),
        isToday: dateRef.isSame(new Date(), 'day'),
        date: dateRef,
      };
      allDays.push(day);
      dateRef = dateRef.clone();
      dateRef.add(1, 'day');
    }

    setDays(allDays);
  }, [date, month]);

  return (
    <div className='row-calendar week'>
      {days.map((day) => (
        <Day
          key={day.date}
          day={day}
          selected={selected}
          select={select}
          closeDates={closeDates}
          restaurantTimes={restaurantTimes}
          orderSetup={orderSetup}
        />
      ))}
    </div>
  );
}

function Day({
  day,
  select,
  selected,
  closeDates,
  restaurantTimes,
  orderSetup,
}) {
  const { date, isCurrentMonth, isToday, number } = day;

  const isCloseDate = (date) => {
    if (closeDates) {
      const matchingDateExists = closeDates.some(
        (m) => m.closeDate === date && m.isAllClosed
      );
      return matchingDateExists;
    } else return false;
  };

  const isStoreClosed = (date) => {
    const day = date.day().toString();
    const isStoreWorkingOnDay = restaurantTimes.filter(
      (restaurantDay) => restaurantDay.day === day
    );
    return isStoreWorkingOnDay.length === 0;
  };

  const isSingleDigited = number?.toString()?.length === 1;

  return (
    <React.Fragment>
      {isCurrentMonth ? (
        <span
          key={date.toString()}
          className={
            ' day' +
            (isToday ? ' event' : '') +
            (isSingleDigited ? '' : ' two-digits') +
            (isCurrentMonth ? '' : ' different-month') +
            (date.isSame(selected) ? ' selected' : '') +
            (date.isBefore(new moment(new Date()).subtract(1, 'days')) ||
            isCloseDate(date.format('YYYY-MM-DD')) ||
            isStoreClosed(date)
              ? ' past-date'
              : '') +
            (isToday &&
            orderSetup &&
            orderSetup.isAllowedTableBookingForCurrentDay === false
              ? ' past-date'
              : '')
          }
          onClick={
            date.isBefore(new moment(new Date()).subtract(1, 'days')) ||
            isCloseDate(date.format('YYYY-MM-DD')) ||
            isStoreClosed(date) ||
            (isToday &&
              orderSetup &&
              orderSetup.isAllowedTableBookingForCurrentDay === false)
              ? () => {}
              : () => select(day)
          }
        >
          {number}
        </span>
      ) : (
        <span key={date.toString()} className={'day'}></span>
      )}
    </React.Fragment>
  );
}
