import { useState } from 'react';

function useRetryUntilResolved({
  interval = 50,
  conditionForRetry = () => false,
}) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const retry = async (callback) => {
    setIsLoading(true);
    setError(null);

    const fetchData = async () => {
      try {
        const result = await callback();
        let timeout = null;
        if (conditionForRetry(result)) {
          timeout = setTimeout(async () => {
            await fetchData();
          }, interval);
        } else {
          timeout && clearTimeout(timeout);
          setData(result);
          setIsLoading(false);
        }
      } catch (err) {
        console.log('err', err);
        setError(err);
        setIsLoading(false);
      }
    };

    await fetchData();
  };

  return {
    data,
    error,
    isLoading,
    retry,
  };
}

export default useRetryUntilResolved;
