import React, { useEffect, useState } from 'react';
import plusWhite from '../../assets/images/plus-white.svg';
import circleGreen from '../../assets/images/circle-green.svg';
import circleRight from '../../assets/images/circle-right.svg';

const Half = ({
  flag,
  itemObj,
  selectedHalfItems,
  clickHandler,
  myID,
  currentClicked,
}) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(false);
  }, [itemObj?.name]);

  const filterIt = () => {
    setActive(true);
    clickHandler(myID, itemObj?.name ? true : false);
  };

  const sectionStyle = {
    ...(itemObj?.urlS3 ? { backgroundImage: `url(${itemObj.urlS3})` } : {}),
  };

  return (
    <div className='halfDiv' onClick={filterIt}>
      <div
        className={
          itemObj?.name ? `${active} halfHalfwImage  half${currentClicked}` : ''
        }
        style={sectionStyle}
      >
        {itemObj?.name ? (
          <span>{itemObj.name}</span>
        ) : (
          <span>
            <img src={plusWhite} alt='' />
            Add
          </span>
        )}
        {active && currentClicked === myID ? (
          <img src={circleGreen} alt='' />
        ) : currentClicked !== myID &&
          itemObj === undefined &&
          currentClicked !== 0 &&
          selectedHalfItems.length > 0 &&
          selectedHalfItems[myID] === undefined &&
          flag === false ? (
          <img src={circleGreen} alt='' />
        ) : itemObj?.name ? (
          <div className='faded' />
        ) : (
          <img src={circleRight} alt='' />
        )}
      </div>
    </div>
  );
};

export default Half;
