import { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  CustomizeItemUI,
  ModalCWShellUI,
  ModalEditItemUI,
  QuantityControlUI,
  QuantityRowUI,
} from '@next-order/next-order-components';
import {
  isObjectNotEmpty,
  performMergeToModify,
} from '@next-order/next-order-engine';
import { getCustomisationsForMenuItem } from 'containers/OrderTracker/getViewOrderData';
import getOriginalMenuItems from 'helpers/getOriginalMenuItems';

export default function ItemEditModal({
  selectedMenuItems,
  editItem,
  setEditItem,
  removeItemFromOrder,
  incrementItemQuantity,
  decrementItemQuantity,
  getLocalItemPrice,
  currency,
  onClickItemHandler,
}) {
  const { menuItems } = useSelector((state) => state.homeReducer);

  const item = useMemo(() => {
    if (!editItem?._id) return;

    return selectedMenuItems.find(
      (item) =>
        item._id === editItem?._id && item.orderIndex === editItem?.orderIndex
    );
  }, [selectedMenuItems, editItem?._id, editItem?.orderIndex]);

  const open = !!item;

  const removeItem = () => {
    if (!item) return;

    removeItemFromOrder(item);
    setEditItem(null);
  };

  const modifyItem = () => {
    const originalMenuItems = getOriginalMenuItems(item, menuItems);
    if (isObjectNotEmpty(item) && originalMenuItems?.length > 0) {
      if (item?.isHalf) {
      } else {
        const newItem = {
          ...performMergeToModify(item, originalMenuItems[0]),
          orderIndex: item.orderIndex,
          itemNotes: item.itemNotes || '',
          inEditMode: true,
        };
        setEditItem(null);
        onClickItemHandler(newItem.itemType, newItem);
      }
    }
  };

  if (!item) return null;

  const { add, remove } = getCustomisationsForMenuItem(item);

  return (
    <ModalEditItemUI
      open={open}
      description={item.description}
      displayView='simple'
      footer={
        <ModalCWShellUI.Button onClick={() => setEditItem(null)}>
          Add to Order - {currency}
          {getLocalItemPrice(item)}
        </ModalCWShellUI.Button>
      }
      imgSrc={item.urlS3}
      onClickExtras={function noRefCheck() {}}
      onClickIngredients={function noRefCheck() {}}
      onClickRemove={removeItem}
      onOpenChange={(isOpen) => setEditItem(isOpen ? editItem : null)}
      showRemoveBtn
      title={item.isHalf ? `${item.name} / ${item.nameHalf}` : item.name}
      zIndex={200}
    >
      <Fragment>
        <>
          <ModalCWShellUI.TitleRow
            extendedClassName='!mb-0'
            extendedHeadingClassName='!text-xl '
            title='Customisations'
          />
          <div className='mb-3.5'>
            <CustomizeItemUI
              badgeColor='success'
              badgeText='add'
              placeholder='Add Extras...'
              text={add}
              onClick={() => {
                modifyItem();
              }}
            />
            <CustomizeItemUI
              badgeColor='danger'
              badgeText='no'
              placeholder='Remove Ingredients'
              text={remove}
              onClick={() => {
                modifyItem();
              }}
            />
          </div>
        </>
        <ModalCWShellUI.TitleRow
          extendedHeadingClassName='!text-xl'
          title='Quantity'
        />
        {item?.selectedSizes?.map((size) => (
          <QuantityRowUI
            key={size._id}
            price={`${currency}${size.price}`}
            quantityGroup={
              <QuantityControlUI
                displayType='auto-hide'
                decrement={() => decrementItemQuantity(size, item)}
                increment={() => incrementItemQuantity(size, item)}
                quantity={item.isHalf ? size.quantity * 2 : size.quantity}
              />
            }
            title={size.name}
          />
        ))}
      </Fragment>
    </ModalEditItemUI>
  );
}
