import { _firestore } from '../../firebase';

function parseMenu(querySnapshot) {
  return querySnapshot.docs
    ? querySnapshot.docs.map((doc) => ({ _id: doc.id, ...doc.data() }))
    : [];
}

function resolveParseMenu(resolve) {
  return function (data) {
    resolve(parseMenu(data));
  };
}

export function getReviewStatsPromise(storeId) {
  return new Promise((resolve, _reject) => {
    _firestore
      .collection('StoreReviewStats')
      .where('storeId', '==', storeId)
      .onSnapshot((querySnapshot) => {
        var dataArray = [];
        querySnapshot.forEach((doc) => {
          dataArray.push({ _id: doc.id, ...doc.data() });
        });
        resolve(dataArray.length > 0 ? dataArray[0] : {});
      });
  });
}

export function getReviewStatsByRatingPromise(storeId) {
  return new Promise((resolve, _reject) => {
    _firestore
      .collection('ReviewStatsByRating')
      .where('storeId', '==', storeId)
      .onSnapshot(resolveParseMenu(resolve));
  });
}
