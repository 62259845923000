import Geosuggest from 'react-geosuggest';
import { SearchIcon } from 'nexticons/outline';
import { LocationPinIcon } from 'nexticons/outline';
import classNames from 'classnames';
import { forwardRef } from 'react';
import { ErrorMessageUI } from '@next-order/next-order-components';

const GeosuggestUI = forwardRef(
  (
    {
      className = '',
      errorMessage = '',
      extendedClassName = '',
      deliveryAddrError,
      currentOrder,
      currency,
      address,
      menuItemsLength,
      deliveryCost,
      ...props
    },
    ref
  ) => {
    return (
      <>
        <div
          className={classNames(
            'relative z-1 w-full shadow-none border first:rounded-t-2.5  last:rounded-b-2.5 ',
            {
              'border-b-neutral-150 border-t-transparent border-x-transparent last:border-b-transparent':
                !errorMessage,
              'border-danger': !!errorMessage,
            }
          )}
          style={{
            zIndex: 1000,
          }}
        >
          <button
            className='overflow-hidden absolute top-6.5 z-20 flex h-8 w-8 origin-center -translate-y-1/2 items-center justify-center left-6.5 -translate-x-1/2 text-neutral-575'
            title='Search'
          >
            <SearchIcon width={22} strokeWidth={2} />
          </button>
          <Geosuggest
            className={classNames('geosuggest-ui', [extendedClassName])}
            ref={ref}
            {...props}
          />

          {address &&
          menuItemsLength > 0 &&
          deliveryCost &&
          deliveryAddrError !== true ? (
            <div
              className='deliveryCost overflow-hidden absolute top-6.5 z-20 flex h-8 w-8 origin-center -translate-y-1/2 items-center justify-center right-0 -translate-x-1/2 text-neutral-575'
              style={{
                color: '#fff',
                height: 20,
              }}
            >
              {currentOrder.deliveryCost < 1 ? (
                <span>Free</span>
              ) : (
                `${currency}${Number(currentOrder.deliveryCost).toFixed(2)}`
              )}
            </div>
          ) : null}
        </div>
        {errorMessage && (
          <ErrorMessageUI
            hideArrow
            extendedClassName='!-mt-0 rounded-none first:rounded-t-2.5 last:rounded-b-2.5'
            message={errorMessage}
          />
        )}
      </>
    );
  }
);

const GeosuggestItemUI = ({ extendedClassName = '', children }) => {
  return (
    <div
      className={classNames(
        'grid-cols-50px-1 grid min-h-12.25 w-full cursor-pointer items-center gap-x-1  py-2 text-left text-gray-500 transition-colors duration-300 ',
        'hover:bg-gray-50 hover:text-primary-title focus:bg-gray-50 focus:text-primary-title',
        extendedClassName
      )}
    >
      <span className='flex h-full w-full items-center justify-center'>
        <LocationPinIcon width={26} strokeWidth={1.5} />
      </span>
      <span className='leading-thigh text-3.75 font-normal'>{children}</span>
    </div>
  );
};

export { GeosuggestUI, GeosuggestItemUI };
