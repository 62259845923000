import {
  // ModalCWShellUI,
  OrderUI,
  // RowUI,
  // SelectUI,
} from '@next-order/next-order-components';
import ConfirmTable from 'components/ConfirmTable';
import SelectTable from 'components/SelectTable';
import { MenuContext } from 'contexts/MenuProvider';
// import { toggleMobileCart } from 'containers/Home/actions';
import { MENU_ORDER_TYPE, ORDER_STAGE_TYPES } from 'helpers/enums';
// import { CalendarModernIcon, ClockNineIcon } from 'nexticons/outline';
import React, { useContext, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BREAKPOINT_VALUES } from '../../helpers/enums';

const defaultState = {
  confirmTable: false,
  selectTable: false,
};
export default function SelectTableActionButton({
  floors,
  selectedTable,
  setSelectedTable,
  dineInObjects,
}) {
  const dispatch = useDispatch();
  const {
    currentOrder,
    ui: { mobileCart },
  } = useSelector((state) => state.homeReducer);
  const { breakPoint } = useContext(MenuContext);

  const [showTable, setShowTable] = useReducer(
    (s, a) => ({ ...defaultState, ...a }),
    defaultState
  );

  useEffect(() => {
    if (
      breakPoint < BREAKPOINT_VALUES.TABLET &&
      currentOrder.orderType === MENU_ORDER_TYPE.dinein &&
      !currentOrder.tableNumber &&
      currentOrder.stage < ORDER_STAGE_TYPES.PAYMENT &&
      mobileCart
    ) {
      setShowTable({ confirmTable: true });
    }
  }, [
    mobileCart,
    breakPoint,
    currentOrder.orderType,
    currentOrder.tableNumber,
    currentOrder.stage,
    dispatch,
  ]);

  const hideConfirmTable = () => {
    setShowTable({ confirmTable: false });
  };

  const hideSelectTable = () => {
    setShowTable({ confirmTable: true });
  };

  const confirmTable = (number) => {
    const selectedTables = dineInObjects?.filter(
      (item) => item.name === number || item.number === number
    );

    if (selectedTables?.length !== 1) {
      setShowTable({ selectTable: true });
    } else {
      setSelectedTable(selectedTables[0]);
      setShowTable({ confirmTable: false });
    }
  };

  const addSelectedTableToOrder = (table) => {
    setSelectedTable(table);
    setShowTable({ confirmTable: false });
  };

  return (
    <>
      <OrderUI.ChangeBtn
        onClick={() => setShowTable({ confirmTable: true })}
        extendedClassName='pr-3.75 py-1.25'
      />
      {/* We show confirm table message for dinein */}
      {
        <>
          {showTable.confirmTable && (
            <ConfirmTable
              confirmTable={confirmTable}
              hideModal={hideConfirmTable}
              selectedTableNumber={currentOrder.tableNumber}
            />
          )}
          {showTable.selectTable && (
            <SelectTable
              floors={floors}
              hideModal={hideSelectTable}
              addTableToOrder={addSelectedTableToOrder}
              selectedTable={selectedTable}
            />
          )}
        </>
      }
    </>
  );
}
