import { BackButtonCWUI } from '@next-order/next-order-components';
import { UserLoginRegistrationContext } from 'contexts/UserLoginRegistrationProvider';
import { ORDER_STAGE_TYPES } from 'helpers/enums';
import React, { useContext, useEffect, useRef } from 'react';
import nextLoading from '../../assets/images/next-loading.svg';

export default function MobileNumberComponent({
  orderSetup,
  updateOrderStageBack,
  sidebarAnimationClass,
}) {
  const {
    localState,
    setLocalState,
    updateOrderStageHandler,
    handleInputFieldBlur,
    validateMobileNumber,
  } = useContext(UserLoginRegistrationContext);

  const mobileNumberRef = useRef();
  const emailAddressRef = useRef();

  useEffect(() => {
    mobileNumberRef.current.focus();
  }, []);

  const handleMobileEnterPress = (e) => {
    if (e.keyCode === 13) {
      if (!orderSetup.enableUserEmail) {
        handleMobileNumberEmailProcessNextStep();
      } else {
        emailAddressRef.current.focus();
      }
    }
  };

  const handleEmailEnterPress = (e) => {
    if (e.keyCode === 13) {
      if (
        !localState.fieldErrors.mobileNumber &&
        !localState.fieldErrors.emailAddress
      ) {
        handleMobileNumberEmailProcessNextStep();
      } else {
        mobileNumberRef.current.focus();
      }
    }
  };

  const handleMobileNumberChange = (e) => {
    const mobileNumber = e.target.value;
    validateMobileNumber(mobileNumber);
  };

  const handleEmailAddressChange = (e) => {
    setLocalState({
      emailAddress: e.target.value.trim(),
    });
  };

  const handleMobileNumberEmailProcessNextStep = () => {
    if (!localState.isNextDisabled) {
      updateOrderStageHandler(ORDER_STAGE_TYPES.VERIFY_FROM);
    }
  };

  return (
    <div className={`${sidebarAnimationClass} detailsConfirm mobileScr active`}>
      <BackButtonCWUI onClick={updateOrderStageBack} />
      <div className='detailsTitle' style={{ marginTop: 0 }}>
        {orderSetup &&
        orderSetup.enableUserEmail &&
        orderSetup.enableUserEmail === true
          ? `What’s your contact details?`
          : `What’s your mobile number?`}
      </div>
      <div className={`inputGroup`}>
        <span>Mobile Number</span>
        <input
          type='tel'
          id='mobileNumber'
          name='mobileNumber'
          tabIndex='0'
          ref={mobileNumberRef}
          className={
            localState.fieldErrors?.mobileNumber &&
            localState.inputFieldBlurStatus?.mobileNumber
              ? `error`
              : ''
          }
          value={localState.displayMobileNumber}
          onKeyDown={handleMobileEnterPress}
          onChange={handleMobileNumberChange}
          onBlur={handleInputFieldBlur}
          autoFocus
        />
        {localState.fieldErrors['mobileNumber'] &&
        localState.inputFieldBlurStatus?.mobileNumber ? (
          <div className='field-errors'>
            {localState.fieldErrors['mobileNumber']}
          </div>
        ) : null}
      </div>

      {orderSetup &&
        orderSetup.enableUserEmail &&
        orderSetup.enableUserEmail === true && (
          <div className={`inputGroup`}>
            <span>Email</span>
            <input
              tabIndex='1'
              type='text'
              id='emailAddress'
              name='emailAddress'
              enterKeyHint='Go'
              ref={emailAddressRef}
              className={
                localState.fieldErrors?.emailAddress &&
                localState.inputFieldBlurStatus?.emailAddress
                  ? `error`
                  : ''
              }
              value={localState.emailAddress}
              onKeyDown={handleEmailEnterPress}
              onChange={handleEmailAddressChange}
              onBlur={handleInputFieldBlur}
              style={{ textTransform: 'none' }}
            />
            {localState.fieldErrors?.emailAddress &&
            localState.inputFieldBlurStatus?.emailAddress ? (
              <div className='field-errors'>
                {localState.fieldErrors?.emailAddress}
              </div>
            ) : null}
          </div>
        )}

      <div className='inputGroup'>
        <div id='mobile-submit' />
      </div>

      <div
        className={`nextDetails${
          localState.isLoading ||
          localState.fieldErrors?.mobileNumber ||
          localState.fieldErrors?.emailAddress ||
          !localState.displayMobileNumber ||
          (orderSetup.enableUserEmail && !localState.emailAddress)
            ? ' disabled'
            : ''
        }`}
        onClick={handleMobileNumberEmailProcessNextStep}
      >
        {localState.isLoading ? <img src={nextLoading} alt='' /> : 'Next'}
        {localState.isLoading ? null : <i className='thickcon-Right-Chevron' />}
      </div>
    </div>
  );
}
