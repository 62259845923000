const getOriginalMenuItems = (updatedMenuItem, originalMenuItems) => {
  const newMenuItems = [];
  if (
    originalMenuItems !== null &&
    originalMenuItems !== undefined &&
    !originalMenuItems.length
  )
    return;
  if (updatedMenuItem?.isHalfHalf) {
    updatedMenuItem?.items?.forEach((menuItem) => {
      const org = originalMenuItems?.find((item) => item._id === menuItem._id);
      if (org) {
        const { selectedModifiers } = org;
        const newOriginalItem =
          selectedModifiers?.length === 1 &&
          Object.keys(selectedModifiers[0]).length === 0 &&
          selectedModifiers[0].constructor === Object
            ? { ...org, selectedModifiers: [] }
            : org;
        newMenuItems.push(newOriginalItem);
      }
    });
  } else {
    const org = originalMenuItems?.find(
      (item) => item._id === updatedMenuItem?._id
    );
    if (org) {
      const { selectedModifiers } = org;
      const newOriginalItem =
        selectedModifiers?.length === 1 &&
        Object.keys(selectedModifiers[0]).length === 0 &&
        selectedModifiers[0].constructor === Object
          ? { ...org, selectedModifiers: [] }
          : org;
      newMenuItems.push(newOriginalItem);
    }
  }
  return newMenuItems;
};

export default getOriginalMenuItems;
