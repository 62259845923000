import {
  CustomerCategoryTitleUI,
  GridUI,
} from '@next-order/next-order-components';
import { MenuContext } from 'contexts/MenuProvider';
import { CATEGORY_DISPLAY_TYPE_VALUE } from 'helpers/enums';
import titleCase from 'helpers/titleCase';
import { forwardRef, useContext } from 'react';

import MenuItem from './MenuItem';
import SpecialsItem from './SpecialsItem';

const SimpleOrSpecialMenuItemsCategoryContainer = forwardRef(
  ({ category, onClickItemHandler, currency }, ref) => {
    const { mappedSelectedMenuItemQuantities } = useContext(MenuContext);

    const display = CATEGORY_DISPLAY_TYPE_VALUE[category.displayType] ?? 'grid';

    return category?.data?.length > 0 ? (
      <>
        <CustomerCategoryTitleUI ref={ref}>
          {titleCase(category.name)}
        </CustomerCategoryTitleUI>

        {category.isSpecial ? (
          <GridUI align='start' gap='none' colsTemplate='cw-specials'>
            {category.data.map((menuItem, index) => (
              <SpecialsItem
                key={index}
                menuItem={menuItem}
                currency={currency}
                onClickItemHandler={onClickItemHandler}
                count={mappedSelectedMenuItemQuantities[menuItem.item?._id]}
              />
            ))}
          </GridUI>
        ) : (
          <GridUI align='start' gap='none' colsTemplate={`cw-${display}`}>
            {category.data.map((menuItem, index) => (
              <MenuItem
                key={index}
                menuItem={menuItem}
                currency={currency}
                displayType={display}
                onClickItemHandler={onClickItemHandler}
                count={mappedSelectedMenuItemQuantities[menuItem.item?._id]}
              />
            ))}
          </GridUI>
        )}
      </>
    ) : null;
  }
);

export default SimpleOrSpecialMenuItemsCategoryContainer;
