import React, { useReducer } from 'react';

const weekDays = {
  0: "Mon",
  1: "Tue",
  2: "Wed",
  3: "Thu",
  4: "Fri",
  5: "Sat",
  6: "Sun",
}
const weekArray = Array(7).fill(0).map((_, i) => weekDays[i])
const monthsArray = Array(12).fill(0).map((_, i) => i);
const febDays = 28; // XXX: need to determine from year
const daysArray = [31, febDays, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export function Scheduler(props) {
  const openingHour = 10;
  const closingHour = 20;
  const [state, setState] = useReducer((st, ac) => ({ ...st, ...ac }), {
    currentMonth: 9,
    monthOffset: 3,
    dayExceptions: {},
    weekdayExceptions: {},
  });

  const [{ bannedWeekdays }, toggleWeekday] = useReducer((st, a) => {
    const b = st.bannedWeekdays;
    const newState = b.delete(a) ? b : b.add(a);
    return { bannedWeekdays: newState };
  } , { bannedWeekdays: new Set() });


  const { currentMonth, monthOffset, weekdayExceptions, dayExceptions } = state;


  return (
    <div className='App'>
      <div style={{ display: `flex`, width: `100%`, justifyContent: `space-between`, margin: 10}}>
        {weekArray.map((weekday, i) => (
          <div style={{ flex: `0 1 ${100 / 7}%` }} key={weekday}>
            <div onClick={() => toggleWeekday(i)} style={{ fontSize: 14 }}>
              {weekday}
            </div>
            {bannedWeekdays.has(i) ? (
              <span>
                not in schedule
              </span>
            ) : (
              <>
                <select
                  onChange={e => {
                    if (+e.target.value < weekdayExceptions[i]?.end || closingHour) {
                      const newTimes = { ...weekdayExceptions, [i]: { ...(weekdayExceptions[i] || { end: closingHour }), start: +e.target.value } };
                      setState({ weekdayExceptions: newTimes })
                    }
                  }}
                  style={{width: 50, padding: 2}}
                  value={weekdayExceptions[i]?.start || openingHour}
                >
                  {Array(24).fill(0).map((_, i) => <option value={i}>{i % 12}{i > 12 ? `pm` : `am`}</option>)}
                </select>
                –
                <select
                  onChange={e => {
                    if (+e.target.value > weekdayExceptions[i]?.start || openingHour) {
                      const newTimes = { ...weekdayExceptions, [i]: { ...(weekdayExceptions[i] || { start: openingHour }), end: +e.target.value } };
                      setState({ weekdayExceptions: newTimes })
                    }
                  }}
                  style={{width: 50, padding: 2}}
                  value={weekdayExceptions[i]?.end || closingHour}
                >
                  {Array(24).fill(0).map((_, i) => <option value={i}>{i % 12}{i > 12 ? `pm` : `am`}</option>)}
                </select>
              </>
            )}
          </div>
        ))}
      </div>
      <div style={{ display: `flex`, width: `100%`, flexFlow: `row wrap`, borderTop: `1px solid grey`, borderLeft: `1px solid grey`, margin: 10, paddingRight: 20 }}>
        {Array(monthOffset).fill(0).map((_, i) => <div key={`p-${i}`}style={{ flex: `0 1 ${100 / 7}%`, borderRight: `1px solid grey`, borderBottom: `1px solid grey`, height: `100px` }}></div>)}
        {Array(daysArray[currentMonth]).fill(0).map((_, i) => {
          const day = i + 1;
          const weekday = (i + monthOffset) % 7
          const isAvailable = !bannedWeekdays.has(weekday);
          return (
            <div
              style={{
                flex: `0 1 ${100 / 7}%`,
                height: `100px`,
                borderRight: `1px solid grey`,
                borderBottom: `1px solid grey`,
                padding: 10,
                background: isAvailable ? `white` : `#ddd`
              }}
              key={i}
            >
              <div style={{ fontSize: 14 }}>
                {day}
              </div>
              {isAvailable && (
                <div style={{ fontSize: 12 }}>
                  <select
                    onChange={e => {
                      if (+e.target.value < dayExceptions[i]?.end || closingHour) {
                        const newTimes = { ...dayExceptions, [i]: { ...(dayExceptions[i] || { end: weekdayExceptions[weekday]?.end || closingHour }), start: +e.target.value } };
                        setState({ dayTimeTules: newTimes })
                      }
                    }}
                    style={{width: 50, padding: 2, border: `none` }}
                    value={dayExceptions[i]?.start || weekdayExceptions[weekday]?.start || openingHour}
                  >
                    {Array(24).fill(0).map((_, i) => <option value={i}>{i % 12}{i > 12 ? `pm` : `am`}</option>)}
                  </select>
                  –
                  <select
                    onChange={e => {
                      if (+e.target.value > dayExceptions[i]?.start || openingHour) {
                        const newTimes = { ...dayExceptions, [i]: { ...(dayExceptions[i] || { start: weekdayExceptions[weekday]?.start || openingHour }), end: +e.target.value } };
                        setState({ dayExceptions: newTimes })
                      }
                    }}
                    style={{width: 50, padding: 2, border: `none` }}
                    value={dayExceptions[i]?.end || weekdayExceptions[weekday]?.end || closingHour}
                  >
                    {Array(24).fill(0).map((_, i) => <option value={i}>{i % 12}{i > 12 ? `pm` : `am`}</option>)}
                  </select>
                </div>
              )}
            </div>
          )
        })}
      </div>
      <div style={{ display: `flex`, flexFlow: `column`, justifyContent: `center`, alignItems: `center` }}>
        <span>
          RESULT CRON ENTRIES
        </span>
        <span style={{ fontSize: 36 }}>
          * {openingHour}-{closingHour} {currentMonth} {bannedWeekdays.size || Object.keys(weekdayExceptions).length ? weekArray.filter((_, i) => !bannedWeekdays.has(i) && !weekdayExceptions[i]).map((v) => weekArray.findIndex(l => l === v) + 1).reduce((acc, v, i, f) => {
            return acc + `${v}${i !== f.length - 1 ? `,` : ``}`;
          }, ``) : `*`}
        </span>
        {Object.keys(weekdayExceptions).map((i) => (
          <span style={{ fontSize: 36 }}>
            * {weekdayExceptions[i].start}-{weekdayExceptions[i].end} * {currentMonth} {+i + 1}
          </span>
        ))}
        {Object.keys(dayExceptions).map((i) => (
          <span style={{ fontSize: 36 }}>
            * {dayExceptions[i].start}-{dayExceptions[i].end} {+i + 1} {currentMonth} *
          </span>
        ))}
      </div>
    </div>
  );
}

export default Scheduler;
