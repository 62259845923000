import { MENU_ITEM_DATA_TYPE } from 'helpers/enums';
import { getMinifiedMenuItemForInstore } from 'helpers/getMenuItemForInstore';
import getSimpleAndSpecialMenuItems from 'helpers/getSimpleAndSpecialMenuItems';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export default function useGetPopulars() {
  const {
    categories,
    menuItemSizes,
    menuItemSubModifiers,
    currentOrder,
    populars,
    soldOutMenuItems,
    specialFilter: { isSpecialFilterApplied, spMenuSizes },
  } = useSelector((state) => state.homeReducer);
  const { halfHalfSetup = {} } = useSelector((state) => state.layoutReducer);

  const [statePopulars, setStatePopulars] = useState([]);
  const categoryIds = useMemo(() => categories.map((c) => c._id), [categories]);

  useEffect(() => {
    if (!populars || !currentOrder?.orderType) return;
    let newPopulars = populars.filter(
      (f) =>
        f.orderFromKeys?.includes('1') &&
        f.orderTypeKeys?.includes(currentOrder.orderType) &&
        f.isPublished &&
        categoryIds.includes(f.categoryId)
    );
    newPopulars = newPopulars.map((popular) => {
      if (
        popular.orderFromKeys?.includes('2') &&
        popular.orderTypeKeys?.includes(currentOrder.orderType) &&
        popular.isPublished &&
        (menuItemSizes?.length || menuItemSubModifiers?.length)
      ) {
        const newMenuItemSizes = menuItemSizes.filter(
          (f) => f.menuItemId === popular._id
        );
        const newMenuItemSubModifiers = menuItemSubModifiers.filter(
          (f) => f.menuItemId === popular._id
        );
        return getMinifiedMenuItemForInstore(
          popular,
          newMenuItemSizes,
          newMenuItemSubModifiers,
          currentOrder.orderType
        );
      }
      return popular;
    });

    setStatePopulars(newPopulars);
  }, [
    currentOrder.orderType,
    menuItemSizes,
    menuItemSubModifiers,
    populars,
    categoryIds,
  ]);

  const filteredPopulars = useMemo(
    () =>
      getSimpleAndSpecialMenuItems({
        menuItems: statePopulars.filter(
          (f) =>
            f.orderFromKeys?.includes('1') &&
            f.orderTypeKeys?.includes(currentOrder.orderType) &&
            f.isPublished
        ),
        halfHalfs: halfHalfSetup.halfHalfs,
        halfHalfPlaceholderImage: halfHalfSetup.halfHalfPlaceholderImage,
        isSpecialFilterApplied,
        soldOutMenuItems,
        spMenuSizes,
        itemType: MENU_ITEM_DATA_TYPE.menuitem,
        orderType: currentOrder?.orderType,
      }),
    [
      currentOrder.orderType,
      halfHalfSetup.halfHalfPlaceholderImage,
      halfHalfSetup.halfHalfs,
      isSpecialFilterApplied,
      soldOutMenuItems,
      spMenuSizes,
      statePopulars,
    ]
  );

  return filteredPopulars;
}
