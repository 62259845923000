import { createContext, useCallback, useState, useMemo } from 'react';
import GlobalModal from 'components/GlobalModal/GlobalModal';

let GlobalModalContext;
let { Provider } = (GlobalModalContext = createContext());

const GlobalModalProvider = ({ children }) => {
  const [globalModalActive, setGlobalModalActive] = useState(false);
  const [globalModalContent, setGlobalModalContent] = useState({});

  const handleGlobalModal = useCallback((content = false) => {
    setGlobalModalActive(!!content);
    if (content) {
      setGlobalModalContent(content);
    }
  }, []);

  const value = useMemo(
    () => ({
      globalModalActive,
      handleGlobalModal,
      globalModalContent,
      setGlobalModalActive,
    }),
    [globalModalActive, handleGlobalModal, globalModalContent]
  );

  return (
    <div>
      <Provider value={value}>
        <GlobalModal />
        {children}
      </Provider>
    </div>
  );
};

export { GlobalModalContext, GlobalModalProvider };
