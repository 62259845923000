import React, { Component } from 'react';
import { ReactComponent as Minus } from '../../assets/images/minus.svg';
import { ReactComponent as Plus } from '../../assets/images/plus.svg';
import isEqual from 'react-fast-compare';

export default class ButtonGroup extends Component {
  constructor(props) {
    super(props);
    this.state = { decState: true, incState: false };
  }

  componentDidMount() {
    if (this.props.quantity) {
      if (Number(this.props.quantity) === Number(this.props.maxQuantity)) {
        this.setState({ decState: false }, () => {
          this.setState({ incState: true });
        });
      }
      if (Number(this.props.quantity) === 0) {
        this.setState({ decState: true, incState: false });
      }
      if (
        Number(this.props.quantity) !== Number(this.props.maxQuantity) &&
        Number(this.props.quantity) !== 0
      ) {
        this.setState({ decState: false, incState: false });
      }
    } else {
      this.setState({ decState: true });
    }
    if (this.props.disable === true) {
      this.setState({ decState: true, incState: true });
    } else {
      this.setState({ incState: false });
    }
  }

  componentDidUpdate(props) {
    if (
      props.quantity !== this.props.quantity ||
      props.disable !== this.props.disable
    ) {
      if (this.props.quantity) {
        if (Number(this.props.quantity) === Number(this.props.maxQuantity)) {
          this.setState({ decState: false }, () => {
            this.setState({ incState: true });
          });
        }
        if (Number(this.props.quantity) === 0) {
          this.setState({ decState: true, incState: false });
        }
        if (
          Number(this.props.quantity) !== Number(this.props.maxQuantity) &&
          Number(this.props.quantity) !== 0
        ) {
          this.setState({ decState: false, incState: false });
        }
      } else {
        this.setState({ decState: true });
      }
      if (this.props.disable === true) {
        this.setState({ decState: true, incState: true });
      } else {
        this.setState({ incState: false });
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(isEqual(this.props, nextProps) && isEqual(this.state, nextState));
  }

  incrementQuantity = () => {
    if (!this.props.disable) {
      if (Number(this.props.quantity) === Number(this.props.maxQuantity)) {
        this.setState({ incState: true });
      } else {
        this.props.increaseBtnHandler(this.props.clickedItem);
        this.setState({ decState: false });
      }
    }
  };

  decrementQuantity = () => {
    if (!this.props.disable) {
      if (this.props.quantity <= 0) {
        this.setState({ decState: true });
      } else {
        this.props.decreaseBtnHandler(this.props.clickedItem);
        this.setState({
          incState: false,
        });
      }
    }
  };

  render() {
    return (
      <div key={this.props._id} className='btnGropWrap'>
        <button
          className={
            this.state.decState || this.props.disabled
              ? 'cMinusPlusBtn CMinusBtn disabled'
              : 'cMinusPlusBtn CMinusBtn'
          }
          onClick={() => this.decrementQuantity()}
        >
          <span className='icon-button-group'>
            <Minus />
          </span>
        </button>
        <span className='cartCount'>{this.props.quantity || 0}</span>
        <button
          className={
            this.state.incState || this.props.disabled
              ? 'cMinusPlusBtn CPlusBtn disabled'
              : 'cMinusPlusBtn CPlusBtn'
          }
          onClick={() => this.incrementQuantity()}
        >
          <span className='icon-button-group'>
            <Plus />
          </span>
        </button>
      </div>
    );
  }
}
