const {
  SkeletonCustomerCategoryTitleUI,
} = require('@next-order/next-order-components');

const ItemSkeleton = ({ name }) => {
  return (
    <>
      <div className='flex items-center justify-center space-x-2'>
        <div>
          <span className='nameView text-capitalize w-max mb-0'>{name}</span>
        </div>
        <SkeletonCustomerCategoryTitleUI extendedClassName='!mb-2' />
      </div>
      <SkeletonCustomerCategoryTitleUI extendedClassName='!mb-2 h-2.5' />
      <SkeletonCustomerCategoryTitleUI extendedClassName='!w-6/12 h-2.5 !mb-2' />
      <SkeletonCustomerCategoryTitleUI extendedClassName='!mb-2 h-4' />
      <SkeletonCustomerCategoryTitleUI extendedClassName='!mb-2 h-4' />
      <SkeletonCustomerCategoryTitleUI extendedClassName='!mb-2 h-4' />
      <div className='customiseView flex '>
        <SkeletonCustomerCategoryTitleUI extendedClassName='!m-0 !h-8' />
        <SkeletonCustomerCategoryTitleUI extendedClassName='!m-0 !h-8' />
      </div>
    </>
  );
};

export default ItemSkeleton;
