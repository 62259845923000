import { MENU_ORDER_TYPE } from './enums';

export const canSkipPaymentMethod = ({
  orderSetup = {},
  currentOrder = {},
}) => {
  const isCashOptionAvailable =
    orderSetup &&
    currentOrder &&
    currentOrder.orderType &&
    ((currentOrder.orderType === MENU_ORDER_TYPE.pickup &&
      orderSetup.isCashPaymentAccepted) ||
      (currentOrder.orderType === MENU_ORDER_TYPE.delivery &&
        orderSetup.isCashPaymentAcceptedDelivery) ||
      (currentOrder.orderType === MENU_ORDER_TYPE.dinein &&
        orderSetup.isCashPaymentAcceptedDineIn)) &&
    ((orderSetup.maxCashOrderValue &&
      Number(orderSetup.maxCashOrderValue) === 0) ||
      (orderSetup.maxCashOrderValue &&
        Number(orderSetup.maxCashOrderValue) !== 0 &&
        Number(currentOrder.payableAmount) <=
          Number(orderSetup.maxCashOrderValue)));

  const isCardPaymentAvailable =
    orderSetup?.isCardPaymentAccepted &&
    !(
      currentOrder?.orderType === MENU_ORDER_TYPE.dinein &&
      orderSetup?.isCardPaymentDisabledInDinein
    );
  const isAppleGooglePayAvailable =
    orderSetup?.enableApplePay &&
    !(
      currentOrder?.orderType === MENU_ORDER_TYPE.dinein &&
      orderSetup?.isCardPaymentDisabledInDinein
    );

  const canSkipPaymentMethod =
    isCardPaymentAvailable &&
    !isAppleGooglePayAvailable &&
    !isCashOptionAvailable;

  return canSkipPaymentMethod;
};
