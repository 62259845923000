import { CustomerMenuItemUI } from '@next-order/next-order-components';
import { SOLD_OUT_ITEM_PRICE } from 'helpers/enums';
import React from 'react';

export default function HalfHalfOrSpecialMenuItemsViewContainer({
  productName,
  bgImgSrc,
  ingredients,
  menuItem,
  onClickItemHandler,
  currency,
}) {
  const isSoldOut = menuItem.unitPrice === SOLD_OUT_ITEM_PRICE;
  const opacity = isSoldOut ? 0.5 : 1;
  const upsale =
    Number(menuItem?.upsale) > 0 && !isSoldOut ? `+${menuItem.upsale}` : false;
  // const price =
  //   menuItem.unitPrice && menuItem.type !== '2' ? menuItem.unitPrice : undefined;
  // const curr = currency && price && !isSoldOut ? currency : '';

  const setFilteredOrderItem = (e) => {
    e.preventDefault();
    if (!menuItem) return;

    const newItem = { ...menuItem, isSoldOut };
    onClickItemHandler(menuItem.itemType, newItem);
  };

  return (
    <CustomerMenuItemUI
      title={productName}
      desc={ingredients}
      currency={isSoldOut ? '' : upsale}
      price={isSoldOut ? menuItem.unitPrice : upsale ? '' : undefined} // We don't want to show the price for half/half and special menu items
      displayType={'grid'}
      imgSrc={bgImgSrc}
      onClick={setFilteredOrderItem}
      priceClass={upsale ? 'text-red-600 text-sm' : ''}
      style={{
        pointerEvents: isSoldOut ? 'none' : 'initial',
        opacity,
      }}
    />
  );
}
