import classNames from 'classnames';

import { useGlobalModalContext } from 'modal/useGlobalModalContext';
import GlobalModalInner from './GlobalModalInner';

const GlobalModal = () => {
  const { globalModalContent, handleGlobalModal, globalModalActive } =
    useGlobalModalContext();

  const wrapperClass = classNames('global-modal-wrapper', {
    'global-modal-wrapper--active': globalModalActive,
  });

  return globalModalActive ? (
    <div className={wrapperClass}>
      <GlobalModalInner {...globalModalContent} />
      <div
        className='global-modal-wrapper__overlay'
        onClick={(e) => {
          e.persist();
          handleGlobalModal();
        }}
      ></div>
    </div>
  ) : null;
};

export default GlobalModal;
