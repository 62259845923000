import { BackButtonCWUI } from '@next-order/next-order-components';
import { eventNames } from 'configuration/eventNames';
import { setLayoutFlow } from 'containers/Home/actions';
import { UserLoginRegistrationContext } from 'contexts/UserLoginRegistrationProvider';
import { ORDER_STAGE_TYPES } from 'helpers/enums';
import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { firebaseAnalyticsNO } from '../../firebase';

export default function NameFormComponent({
  inLayoutFlow,
  updateOrderStageBack,
  sidebarAnimationClass,

  initiateDesktopLoyaltyFlow,
  orderSetup,
  updateCurrentUser,
  loyalty,
}) {
  const {
    localState,
    setLocalState,
    updateOrderStageHandler,
    handleInputFieldBlur,
  } = useContext(UserLoginRegistrationContext);

  const dispatch = useDispatch();
  const firstNameRef = useRef();
  const lastNameRef = useRef();

  useEffect(() => {
    firstNameRef.current.focus();
  }, []);

  const handleNameProcessNextStep = () => {
    const stage = ORDER_STAGE_TYPES.MOBILE_FORM;
    if (!localState.isNextDisabled) {
      let isStageUpdated = false;
      if (initiateDesktopLoyaltyFlow || loyalty) {
        isStageUpdated = true;
        updateOrderStageHandler(stage);
        dispatch(setLayoutFlow(false));
      }
      if (initiateDesktopLoyaltyFlow && !loyalty) {
        let details = {
          firstName: localState.firstName.trim(),
          lastName: localState.lastName.trim(),
          mobileNumber: localState.validatedNumber,
        };
        if (orderSetup?.enableUserEmail && localState.emailAddress) {
          details.userEmail = localState.emailAddress;
        }
        updateCurrentUser(localState.userFromLogin, details, stage);
      } else if (!isStageUpdated) {
        updateOrderStageHandler(stage);
      }
    }
  };

  const handleFirstNameChange = (e) => {
    const firstName = e.target.value
      .replace(/[^a-z]/gi, '')
      .replace(/\b\w/g, (l) => l.toUpperCase());
    setLocalState({
      firstName,
    });
  };

  const handleLastNameChange = (e) => {
    const lastName = e.target.value
      .replace(/[^a-z]/gi, '')
      .replace(/\b\w/g, (l) => l.toUpperCase());

    setLocalState({
      lastName,
    });
  };

  const handleKeydown = (e) => {
    if (e.keyCode === 13) {
      if (e.target.name === 'firstName') {
        lastNameRef.current.focus();
      } else if (e.target.name === 'lastName') {
        handleNameProcessNextStep();
      }
    }
  };

  return (
    <div
      className={`${sidebarAnimationClass} detailsConfirm active`}
      style={{
        zIndex: inLayoutFlow === true ? 1001 : 1000,
      }}
    >
      <BackButtonCWUI onClick={updateOrderStageBack} />

      <div
        className='detailsTitle  hidden-xs hidden-sm'
        style={{ marginTop: 0 }}
      >
        What’s your name?
      </div>

      <div
        className='detailsTitle visible-xs visible-sm'
        style={{ marginTop: 0 }}
      >
        What’s your name?
      </div>

      <div className='inputGroup' htmlFor='firstName'>
        <label htmlFor='firstName'>First name</label>
        <input
          id='firstName'
          autoComplete='off'
          type='text'
          name='firstName'
          className={
            localState.fieldErrors.firstName &&
            localState.inputFieldBlurStatus.firstName
              ? 'error'
              : ''
          }
          value={localState.firstName}
          onChange={handleFirstNameChange}
          ref={firstNameRef}
          onBlur={handleInputFieldBlur}
          onKeyDown={handleKeydown}
          autoFocus
        />
      </div>

      <div className='inputGroup' htmlFor='lastName'>
        <label htmlFor='lastName'>Last name</label>
        <input
          id='lastName'
          autoComplete='off'
          type='text'
          name='lastName'
          className={
            localState.fieldErrors.lastName &&
            localState.inputFieldBlurStatus.lastName
              ? 'error'
              : ''
          }
          value={localState.lastName}
          onChange={handleLastNameChange}
          ref={lastNameRef}
          onKeyDown={handleKeydown}
          onBlur={handleInputFieldBlur}
        />
      </div>

      <div
        className={`nextDetails${localState.isNextDisabled ? ' disabled' : ''}`}
        onClick={() => {
          firebaseAnalyticsNO.logEvent(eventNames.ENTERED_FIRST_LAST_NAME);
          handleNameProcessNextStep();
        }}
      >
        Next
        <i className='thickcon-Right-Chevron' />
      </div>
    </div>
  );
}
