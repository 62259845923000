import { SOLD_OUT_ITEM_PRICE } from './enums';

export default function getPriceForDisplay(
  item,
  isSpecialFilterApplied,
  soldOutMenuItems,
  spMenuSizes,
  priceConfig
) {
  if (item?.abbreviationData || item?.isMinifiedSpecial) {
    const orderType = Number(priceConfig?.orderType) || 1;
    let price = Number(item?.prices?.[orderType])
      ? Number(item?.prices?.[orderType])?.toFixed(2) || ''
      : '';
    if (item?.abbreviationData?.isAbbreviationShow) {
      price = `${item?.abbreviationData?.name?.charAt(0)} ${price}`;
    }
    return price;
  } else if (item.selectedSizes && item.selectedSizes.length > 0) {
    let isSoldOut = !!soldOutMenuItems;
    if (soldOutMenuItems) {
      isSoldOut =
        item.itemType === 1
          ? item.selectedModifiers.some((j) =>
              j.subModifiers.every((sm) => {
                const basesSoldOut =
                  soldOutMenuItems.findIndex(
                    (k) =>
                      k.smId === sm._id &&
                      k.menuItemId === item._id &&
                      k.variantId === null
                  ) !== -1;

                const variants = sm.variants || [];

                const variantsSoldOut =
                  variants?.length === 0
                    ? false
                    : (sm.variants || []).every(
                        (v) =>
                          soldOutMenuItems.findIndex(
                            (l) =>
                              l.smId === sm._id &&
                              l.menuItemId === item._id &&
                              l.variantId === v._id
                          ) !== -1
                      );

                return variants.length > 0
                  ? basesSoldOut && variantsSoldOut
                  : basesSoldOut;
              })
            )
          : item.selectedSizes.every((s) => {
              const basesSoldOut =
                soldOutMenuItems.findIndex(
                  (k) =>
                    k.smId === s._id &&
                    k.menuItemId === item._id &&
                    k.variantId === null
                ) !== -1;

              const variants = s.variants || [];

              const variantsSoldOut =
                variants.length === 0
                  ? false
                  : (s.variants || []).every(
                      (v) =>
                        soldOutMenuItems.findIndex(
                          (k) =>
                            k.smId === s._id &&
                            k.menuItemId === item._id &&
                            k.variantId === v._id
                        ) !== -1
                    );

              return variants.length > 0
                ? basesSoldOut && variantsSoldOut
                : basesSoldOut;
            });
    }

    if (isSoldOut) return SOLD_OUT_ITEM_PRICE;

    if (isSpecialFilterApplied) {
      let featureSizes = item.selectedSizes.filter((obj) => {
        return spMenuSizes?.map((m) => m._id).includes(obj._id);
      });
      if (featureSizes.length > 0) {
        return (
          featureSizes[0].name.substring(0, 1) +
          ' ' +
          Number(featureSizes[0].price).toFixed(2)
        );
      } else return Number(item.selectedSizes[0].price).toFixed(2);
    } else {
      let featureSizes = item.selectedSizes.filter((obj) => {
        return obj.isFeaturedSize === true;
      });
      if (featureSizes.length > 0) {
        return featureSizes[0].isAbbreviationShow
          ? featureSizes[0].name.substring(0, 1) +
              ' ' +
              Number(featureSizes[0].price).toFixed(2)
          : Number(featureSizes[0].price).toFixed(2);
      } else return Number(item.selectedSizes[0].price).toFixed(2);
    }
  } else {
    if (item.type === '1') {
      return item.cost;
    } else {
      return '';
    }
  }
}
