import { BackButtonCWUI } from '@next-order/next-order-components';
import React from 'react';
import { useSelector } from 'react-redux';
import SpecialBox from '../../components/SpecialBox';
import { styled } from '@stitches/react';

const Div = styled('div', {
  position: 'relative',
  zIndex: 20,
  paddingTop: 20,
  paddingLeft: 20,
  '@media all and (max-width: 1023px)': {
    paddingTop: 26,
  },
});

export default function SpecialView({
  menuItems,
  showSpecialItem,
  addSpecialToOrder,
  selectedSpecials,
  specialItem,
  itemExist,
  storeConfig,
  toggleAnimation,
  hideOrder,
  sidebarAnimationClass,
  addToOrder,
  addMultipleToSpecials,
  activeIndex,
  specialBoxes,
  setOrderState
}) {
  const boxIndex = useSelector(
    (state) => state.homeReducer.specialFilter.boxIndex
  );
  const halfHalfSetup = useSelector(
    (state) => state.layoutReducer.halfHalfSetup
  );
  const currentOrder = useSelector((state) => state.homeReducer.currentOrder);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
      className={sidebarAnimationClass}
    >
      <div className="specialViewTopView">
        <BackButtonCWUI
          extendedClassName='relative -top-1.5'
          onClick={hideOrder}
        />
        <div className='specialViewTop'>
          <span
            className='voucher'
          >
            Special
          </span>
          </div>
      </div>

      <SpecialBox
        key={String(toggleAnimation)}
        boxIndex={boxIndex}
        menuItems={menuItems}
        showSpecialItem={showSpecialItem}
        addSpecialToOrder={addSpecialToOrder}
        halfHalfSetup={halfHalfSetup}
        selectedSpecials={selectedSpecials}
        specialItem={specialItem}
        itemExist={itemExist}
        storeConfig={storeConfig}
        addToOrder={addToOrder}
        addMultipleToSpecials={addMultipleToSpecials}
        activeIndex={activeIndex}
        specialBoxes={specialBoxes}
        setOrderState={setOrderState}
        currentOrder={currentOrder}
      />
    </div>
  );
}
