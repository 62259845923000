import { _firestore } from '../../firebase';
import ActionTypes from './constants';
import {
  getCurrentFormattedMomentDate,
  getCurrentTimestamp,
} from '../../helpers/timeHelper';
import getFormattedMobileNumber from '../../helpers/getFormattedMobileNumber';

import {
  getAllShiftsPromise,
  getAllSuburbsPromise,
  getCloseDatesPromise,
  getIngredientCategoriesPromise,
} from './methods';

export function initOrder(storeId, restaurantId, timeZone) {
  return async function (dispatch) {
    const allShifts = window.StoreShifts || [];
    const allSuburbs = window.Suburbs || [];
    const closeDates = window.CloseDates || [];
    dispatch({
      type: ActionTypes.InitializeOrder,
      allShifts,
      allSuburbs,
      closeDates,
    });
    const [ingredientCategories] = await Promise.all([
      getIngredientCategoriesPromise(restaurantId),
    ]);

    if (!allShifts?.length) {
      const storeShifts = await getAllShiftsPromise(storeId);
      dispatch({
        type: ActionTypes.InitializeOrder,
        allShifts: storeShifts,
      });
    }
    if (!allSuburbs?.length) {
      const storeSuburbs = await getAllSuburbsPromise(storeId);
      dispatch({
        type: ActionTypes.InitializeOrder,
        allSuburbs: storeSuburbs,
      });
    }
    if (!closeDates?.length) {
      const storeCloseDates = await getCloseDatesPromise(storeId, timeZone);
      dispatch({
        type: ActionTypes.InitializeOrder,
        closeDates: storeCloseDates,
      });
    }

    dispatch({
      type: ActionTypes.InitializeOrder,
      ingredientCategories,
    });
  };
}

export function addNotSupportedSuburbs(
  storeConfig,
  addressObj,
  suburb,
  username,
  mobileNumber,
  userId
) {
  let obj = {};
  obj.address = addressObj.description;
  obj.suburb = suburb;
  obj.storeId = storeConfig.storeId;
  obj.restaurantId = storeConfig.restaurantId;
  obj.date = getCurrentFormattedMomentDate(storeConfig.timeZone);
  obj.dateTimestamp = getCurrentTimestamp();
  obj.username = username;
  obj.mobileNumber = getFormattedMobileNumber(mobileNumber);
  obj.userId = userId;
  // firestore write
  _firestore
    .collection('NotSupportedSuburb')
    .add(obj)
    .then(() => {});
}
