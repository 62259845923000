import StoreClosedModal from 'components/StoreClosedModal';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NotificationModal from 'components/NotificationModal';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/icon-new.css';
import '../../assets/css/icon.css';
import '../../assets/css/style.css';
import '../../assets/css/responsive.css';
import '../../assets/css/swiper.min.css';

import '../../index.css'; // add tailwind
import { initLayout } from './actions';
import { selectStoreConfig } from './selectors';

export default function Layout({ children }) {
  const appContainerRef = useRef(null);
  const storeConfig = useSelector(selectStoreConfig);
  const dispatch = useDispatch();

  const handleTouch = (e) => {
    const {
      changedTouches: [{ pageX }],
    } = e;
    if (pageX < 10 || pageX > window.innerWidth - 10) e.preventDefault();
  };

  useEffect(() => {
    if (!storeConfig) return;
    dispatch(initLayout(storeConfig));
  }, [storeConfig, dispatch]);

  useEffect(() => {
    const containerRef = appContainerRef.current;
    containerRef?.addEventListener('touchstart', handleTouch);

    return () => {
      containerRef?.removeEventListener('touchstart', handleTouch);
    };
  }, []);

  // const recordPhoneClickHandler = () => {
  //   let obj = {
  //     date: moment().tz(storeConfig.timeZone).format('YYYY-MM-DD HH:mm'),
  //   };
  //   if (
  //     userDetails &&
  //     userDetails.firstName &&
  //     userDetails.lastName &&
  //     userDetails.mobileNumber
  //   ) {
  //     obj.firstName = userDetails.firstName;
  //     obj.lastName = userDetails.lastName;
  //     obj.mobileNumber = getFormattedMobileNumber(userDetails.mobileNumber);
  //   }
  //   obj.storeId = storeConfig.storeId;
  //   obj.restaurantId = storeConfig.restaurantId;
  //   dispatch(recordPhoneClick(obj));
  // };

  return (
    <div ref={appContainerRef}>
      <StoreClosedModal
        storeId={storeConfig.storeId}
        timeZone={storeConfig.timeZone}
      />
      <NotificationModal />

      {children}
    </div>
  );
}
