import { useEffect, useRef } from 'react';
import { Formik, Form, connect } from 'formik';
import cardValidator from 'card-validator';
import { CreditCardIcon } from '@heroicons/react/solid';
import amexsvg from 'assets/images/amexsvg.svg';
import visasvg from 'assets/images/visasvg.svg';
import mastercardsvg from 'assets/images/mastercardsvg.svg';
import { CreditCardIcon as CreditCardIconOutline } from '@heroicons/react/outline';

const errorOutline =
  'focus:ring-2 focus:ring-red-500/20 focus:border-red-500/20';
const validOutline = 'focus:ring-2 focus:ring-blue-200 focus:border-blue-200';

const Effect = connect(({ formik, onChange }) => {
  const ref = useRef(null);
  useEffect(() => {
    onChange(formik, ref.current);
    ref.current = formik;
  }, [formik]);
  return null;
});

const { values: _val } = Object;
const isTrue = (t) => t;

export default function CreditCardForm({
  isCardSelected,
  setCardSelected,
  initialValues = { cardNumber: '', cardExpiryDate: '', cardCVV: '' },
  onSubmit,
}) {
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validate={(values) => {
        const errors = {};
        const vccMaxLimit =
          cardValidator.number(values.cardNumber).card?.type ===
          'american-express'
            ? 4
            : 3;
        if (isCardSelected) return {};
        if (!values.cardNumber) {
          errors.cardNumber = 'Card number is required';
        } else if (
          !cardValidator.number(values.cardNumber).isPotentiallyValid
        ) {
          errors.cardNumber = 'Invalid card number';
        }

        if (!values.cardExpiryDate) {
          errors.cardExpiryDate = 'Card expiry is required';
        } else if (
          !cardValidator.expirationDate(values.cardExpiryDate).isValid
        ) {
          errors.cardExpiryDate = 'Invalid expiry date';
        }
        if (!values.cardCVV) {
          errors.cardCVV = 'Card CVV is required';
        } else if (!cardValidator.cvv(values.cardCVV, vccMaxLimit).isValid) {
          errors.cardCVV = 'Invalid CVV';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        if (!isCardSelected) {
          onSubmit({
            ...values,
            cardType: cardValidator.number(values.cardNumber).card.niceType,
          });
        }
        setSubmitting(false);
      }}
      validateOnChange
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        handleFocus,
        resetForm,
        isSubmitting,
        isValid,
        /* and other goodies */
      }) => (
        <Form>
          <Effect
            onChange={(fresh, old) => {
              if (fresh && old && fresh.isValid && !old.isValid) {
                handleSubmit();
              }
            }}
          />
          <fieldset style={{ padding: '0 8px 0 2px' }}>
            <div className='mt-1 bg-white rounded-md shadow-sm -space-y-px'>
              <div style={{ position: 'relative' }}>
                <label htmlFor='card-number' className='sr-only'>
                  Card number
                </label>
                <input
                  type='tel'
                  name='cardNumber'
                  id='cardNumber'
                  className={`text-3xl ${
                    errors.cardNumber && _val(touched).every(isTrue)
                      ? errorOutline
                      : validOutline
                  } relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 border-gray-300`}
                  style={{
                    fontSize: '1.68em',
                    lineHeight: '24px',
                    padding: '13px 10px',
                  }}
                  placeholder='Card number'
                  onChange={(e) => {
                    if (isCardSelected) {
                      setCardSelected(false);
                    }
                    if (_val(errors).length === 0) handleSubmit();
                    handleChange(e);
                  }}
                  onBlur={(e) => (isValid ? handleSubmit() : handleBlur(e))}
                  value={
                    values?.cardNumber?.length
                      ? values.cardNumber
                          ?.split(' ')
                          ?.join('')
                          ?.match(/.{1,4}/g)
                          ?.join(' ')
                      : ''
                  }
                />
                <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
                  {(() => {
                    switch (
                      cardValidator.number(values.cardNumber).card?.type
                    ) {
                      case 'visa':
                        return (
                          <img className='h-12 w-12' src={visasvg} alt='' />
                        );
                      case 'mastercard':
                        return (
                          <img
                            className='h-12 w-12'
                            src={mastercardsvg}
                            alt=''
                          />
                        );
                      case 'american-express':
                        return (
                          <img className='h-12 w-12' src={amexsvg} alt='' />
                        );
                      default:
                        return (
                          <CreditCardIconOutline
                            className='h-8 w-8 text-gray-300'
                            aria-hidden='true'
                          />
                        );
                    }
                  })()}
                </div>
              </div>
              <div className='flex -space-x-px'>
                <div className='w-1/2 flex-1 min-w-0'>
                  <label htmlFor='card-expiration-date' className='sr-only'>
                    Expiration date
                  </label>
                  <input
                    type='tel'
                    name='cardExpiryDate'
                    id='cardExpiryDate'
                    style={{
                      fontSize: '1.68em',
                      lineHeight: '24px',
                      padding: '13px 10px',
                    }}
                    className={`${
                      errors.cardExpiryDate && _val(touched).every(isTrue)
                        ? errorOutline
                        : validOutline
                    } relative block w-full rounded-none rounded-bl-md bg-transparent focus:z-10 border-gray-300`}
                    placeholder='MM / YY'
                    onChange={(e) => {
                      if (e.target.value.length < 6) handleChange(e);
                      if (_val(errors).length === 0) handleSubmit();
                      if (isCardSelected) {
                        setCardSelected(false);
                      }
                    }}
                    onBlur={(e) => (isValid ? handleSubmit() : handleBlur(e))}
                    value={
                      values?.cardExpiryDate?.length
                        ? values.cardExpiryDate
                            ?.replace(/\s*\/\s*/, '')
                            ?.match(/.{1,2}/g)
                            ?.join('/')
                        : ''
                    }
                  />
                </div>
                <div
                  className='flex-1 min-w-0'
                  style={{ position: 'relative' }}
                >
                  <label htmlFor='card-cvc' className='sr-only'>
                    CVC
                  </label>
                  <input
                    type='tel'
                    name='cardCVV'
                    id='cardCVV'
                    className={`text-3xl ${
                      errors.cardCVV && _val(touched).every(isTrue)
                        ? errorOutline
                        : validOutline
                    } relative block w-full rounded-none rounded-br-md bg-transparent focus:z-10 border-gray-300`}
                    style={{
                      fontSize: '1.68em',
                      lineHeight: '24px',
                      padding: '13px 10px',
                    }}
                    placeholder='CVV'
                    onBlur={(e) => (isValid ? handleSubmit() : handleBlur(e))}
                    onChange={(e) => {
                      const vccMaxLimit =
                        cardValidator.number(values.cardNumber).card?.type ===
                        'american-express'
                          ? 4
                          : 3;

                      if (e.target.value.length <= vccMaxLimit) handleChange(e);
                      if (_val(errors).length === 0) handleSubmit();
                      if (isCardSelected) {
                        setCardSelected(false);
                      }
                    }}
                    value={values.cardCVV}
                  />
                  <div className='absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'>
                    <p
                      className='text-white'
                      style={{
                        fontSize: 5,
                        letterSpacing: '0.024em',
                        fontWeight: 500,
                        position: 'absolute',
                        right: 0,
                        transform: 'translate(-50%, -50%)',
                        background: '#888',
                        padding: 2,
                        borderRadius: '50%',
                      }}
                    >
                      123
                    </p>
                    <CreditCardIcon
                      className='h-8 w-8 text-gray-300'
                      aria-hidden='true'
                    />
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          {_val(errors).length > 0 && _val(touched).every(isTrue) && (
            <div style={{ paddingTop: 10, fontSize: 12 }}>
              <p style={{ fontWeight: 500 }}>
                There are some issues with your card
              </p>
              <ul>
                {_val(errors).map((error, idx) => (
                  <li key={`CardError-${idx}`}>• {error}</li>
                ))}
              </ul>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
}
