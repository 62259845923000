// import storeConfig from '../configuration/storeConfig';
const getOfferUnlockedMessageFun = (storeConfig, currentOrder) => {
  let messageObjs = [];
  let groupedMessageObjs = [];
  let items = [];
  if (currentOrder.offers && currentOrder.offers.length > 0) {
    items = currentOrder.offers.filter((offer) => {
      return offer.rewards.length <= 0;
    });
    items.forEach((offer) => {
      let returnString = [];
      offer.rewardItems.map((m) => {
        let p =
          Number(m.discountedPrice) <= 0
            ? ' free'
            : ` ${storeConfig.currency}${m.discountedPrice}`;
        p =
          p +
          ` ${m.sizes
            .filter((f) => f.isActive)
            .map((m) => m.name)
            .join(' or ')} ${m.categoryDisplayName}`;
        returnString.push(p);
        return m;
      });
      messageObjs.push({
        index: messageObjs.length + 1,
        _id: offer._id,
        message: offer.isAnyOneItemAvailable
          ? returnString.join(' or ')
          : returnString.join(' and '),
      });
    });
  }
  groupedMessageObjs = messageObjs.reduce((r, a) => {
    r[a._id] = [...(r[a._id] || []), a];
    return r;
  }, {});
  return `Congratulations! You have unlocked ${getMessage(
    groupedMessageObjs
  )}. Just add to cart and claim.`;
};

function getMessage(groupedMessageObjs) {
  let messages = [];
  Object.keys(groupedMessageObjs).forEach((key) => {
    messages.push(
      `${groupedMessageObjs[key].length} ${groupedMessageObjs[key][0].message}`
    );
  });
  return messages.join(' & ');
}

export const getOfferUnlockedMessage = getOfferUnlockedMessageFun;
