import { HalfHalfViewContext } from 'contexts/HalfHalfViewProvider';
import groupBy from 'lodash/groupBy';
import { useContext, useEffect, useMemo } from 'react';
import Half from '../../components/Half';
import { ButtonUI } from '@next-order/next-order-components';
import { CheckIcon, PlusIcon } from '@heroicons/react/solid';
import { MENU_ITEM_TYPE } from 'helpers/enums';

export default function HalfBox({
  addHalfHalfToOrder,
  selectedHalfItems,
  halfItem,
  halfFilter,
  clearItemsNotAvailableForVariants,
  whichHalf,
  menuItems,
  clearOrChangeSize,
}) {
  const [localState, setLocalState] = useContext(HalfHalfViewContext);

  const localHalfItemNotAvailable = localState.halfItem === undefined;
  const propsHalfItemAvailable = !!halfItem;
  const propsMenuItemsAvailable = !!menuItems;

  // componentDidMount
  useEffect(() => {
    if (
      propsHalfItemAvailable &&
      propsMenuItemsAvailable &&
      localHalfItemNotAvailable
    ) {
      getSizeAvailability(menuItems, halfItem);
      showVariants(menuItems, halfItem);
    }

    // eslint-disable-next-line
  }, [
    propsHalfItemAvailable,
    propsMenuItemsAvailable,
    localHalfItemNotAvailable,
  ]);

  // UNSAFE_componentWillReceiveProps
  // useEffect(() => {
  // items is not a prop
  // if (halfItem && items) {
  //   getSizeAvailability(items, halfItem);
  // }
  // }, [halfItem]);
  useEffect(() => {
    if (selectedHalfItems.length >= 2 && selectedHalfItems[1]) {
      setLocalState({ flag: false });
    }
    // eslint-disable-next-line
  }, [selectedHalfItems.length >= 2]);

  const showVariants = (menuItems, hItem) => {
    if (hItem.categories) {
      let matchingMenuItems = menuItems.filter((menuItem) =>
        hItem.categories.map((m) => m._id).includes(menuItem.categoryId)
      );
      let itemVariants = [];
      if (hItem.selectedSizes || hItem.selectedModifiers) {
        matchingMenuItems.forEach((menuItem) => {
          if (menuItem.itemType === MENU_ITEM_TYPE.simple) {
            hItem.selectedSizes.forEach((size) => {
              menuItem.selectedSizes.forEach((s) => {
                if (size._id === s._id && s?.variants) {
                  s.variants?.forEach((v) => {
                    if (v.isSelected) {
                      let matchingVariants = itemVariants.filter(
                        (ss) => ss._id === v._id
                      );
                      if (matchingVariants.length <= 0) itemVariants.push(v);
                    }
                  });
                }
              });
            });
          } else {
            hItem.selectedModifiers.forEach((size) => {
              menuItem.selectedModifiers.forEach((m) => {
                m.subModifiers.forEach((sM) => {
                  if (sM._id === size._id) {
                    sM?.variants?.forEach((v) => {
                      if (v.isSelected) {
                        let matchingVariants = itemVariants.filter(
                          (ss) => ss._id === v._id
                        );
                        if (matchingVariants.length <= 0) itemVariants.push(v);
                      }
                    });
                  }
                });
              });
            });
          }
        });
        itemVariants.map((v) => {
          v.isClicked = false;
          return v;
        });
        setLocalState({ itemVariants });
      }
    }
  };

  const defaultFilter = (
    variants = localState.itemVariants,
    halfActiveMenuSizes = localState.hhActiveMenuSizes
  ) => {
    variants = variants.filter((f) => f.isClicked);
    halfFilter(whichHalf, variants, halfActiveMenuSizes);
  };

  // const getImageUrl = (whichHalf, flag) => {
  //   if (selectedHalfItems) {
  //     let halfTms = selectedHalfItems[whichHalf];
  //     let prevTms = selectedHalfItems[whichHalf - 1];
  //     if (halfTms) {
  //       const sectionStyle = {
  //         backgroundImage: `url(${halfTms.urlS3})`,
  //       };
  //       return (
  //         <div className='halfHalfwImage' style={sectionStyle}>
  //           <span>{halfTms.name}</span>
  //           <div className='faded' />
  //         </div>
  //       );
  //     } else {
  //       if (prevTms || (whichHalf === 1 && flag === true)) {
  //         return (
  //           <div>
  //             <span>
  //               <img src={plusWhite} alt='' />
  //               Add
  //             </span>
  //             <img src={circleGreen} alt='' />
  //           </div>
  //         );
  //       } else {
  //         return (
  //           <div>
  //             <span>
  //               <img src={plusWhite} alt='' />
  //               Add
  //             </span>
  //             <img src={circleRight} alt='' />
  //           </div>
  //         );
  //       }
  //     }
  //   } else {
  //     if (flag === true)
  //       return (
  //         <div>
  //           <span>
  //             <img src={plusWhite} alt='' />
  //             Add
  //           </span>
  //           <img src={circleGreen} alt='' />
  //         </div>
  //       );
  //     else
  //       return (
  //         <div>
  //           <span>
  //             <img src={plusWhite} alt='' />
  //             Add
  //           </span>
  //           <img src={circleRight} alt='' />
  //         </div>
  //       );
  //   }
  // };

  const isSubmitEnabled = () => {
    return selectedHalfItems && selectedHalfItems.length >= 2;
  };

  const callFilter = (item) => {
    let hItem = JSON.parse(JSON.stringify(localState.halfItem));
    let pairId = '-1';
    let halfActiveMenuSizes = [];
    hItem.selectedSizes &&
      hItem.selectedSizes.map((s) => {
        if (s.isAvailable && item._id === s._id) {
          pairId = item.pairId;
          halfActiveMenuSizes.push(s);
        }
        return s;
      });
    hItem.selectedModifiers &&
      hItem.selectedModifiers?.map((s) => {
        if (s.isAvailable && (item._id === s._id || s.pairId === pairId)) {
          pairId = item.pairId;
          halfActiveMenuSizes.push(s);
        }
        return s;
      });
    if (hItem.categories.length > 1) {
      hItem.selectedSizes.map((s) => {
        if (
          s.isAvailable &&
          s.pairId === pairId &&
          !halfActiveMenuSizes.map((h) => h._id).includes(s._id)
        ) {
          halfActiveMenuSizes.push(s);
        }
        return s;
      });
      hItem.selectedModifiers.map((s) => {
        if (
          s.isAvailable &&
          s.pairId === pairId &&
          !halfActiveMenuSizes.map((h) => h._id).includes(s._id)
        ) {
          halfActiveMenuSizes.push(s);
        }
        return s;
      });
    }

    setLocalState({ hhActiveMenuSizes: halfActiveMenuSizes });

    const variants = localState.itemVariants.filter((f) => f.isClicked);
    clearOrChangeSize(halfActiveMenuSizes, variants);
    if (
      window.innerWidth > 991 &&
      !(window.navigator.userAgent.match(/iPad/i) && window.innerHeight > 1250)
    ) {
      defaultFilter(localState.itemVariants, halfActiveMenuSizes);
    }
  };

  const filterIt = (whichHalf, flag) => {
    setLocalState({ clicked: whichHalf, flag });
    halfFilter(
      whichHalf,
      localState.itemVariants.filter((f) => f.isClicked),
      localState.hhActiveMenuSizes
    );
  };

  const renderAddHalfHalfToOrder = () => {
    if (isSubmitEnabled()) {
      addHalfHalfToOrder();
    }
  };

  const toggleVariant = (variant) => {
    let itemVariants = JSON.parse(JSON.stringify(localState.itemVariants));
    itemVariants.map((v) => {
      if (v._id === variant._id) v.isClicked = !v.isClicked;
      return v;
    });
    setLocalState({
      itemVariants,
    });
    clearItemsNotAvailableForVariants(itemVariants.filter((f) => f.isClicked));
    getSizeAvailability(menuItems, halfItem, itemVariants);
    // if (localState.clicked > 0) {
    //   console.log("DONE")
    //   defaultFilter(itemVariants, localState.hhActiveMenuSizes);
    // }
  };

  const getSizeAvailability = (
    itemObjs,
    halfItemObj,
    itemVariants = localState.itemVariants
  ) => {
    let menuItems = JSON.parse(JSON.stringify(itemObjs));
    let hItem = JSON.parse(JSON.stringify(halfItemObj));
    let halfActiveMenuSizes = [];
    let pairId = '-1';

    if (hItem.selectedSizes) {
      hItem.selectedSizes.map((size) => {
        let matchingMenuItems = menuItems.filter((m) =>
          hItem.categories.map((m) => m._id).includes(m.categoryId)
        );
        let count = 0;
        if (matchingMenuItems.length > 0) {
          matchingMenuItems.map((m) => {
            if (m.selectedSizes) {
              m.selectedSizes.map((s) => {
                if (
                  itemVariants &&
                  itemVariants.filter((f) => f.isClicked).length > 0
                ) {
                  let cnt = 0;
                  if (s.variants) {
                    const matchingVariants = s.variants.filter((v) => {
                      return (
                        v.isSelected &&
                        v.isAvailable &&
                        itemVariants
                          .filter((f) => f.isClicked)
                          .map((m) => m._id)
                          .includes(v._id)
                      );
                    });

                    cnt = matchingVariants.length;

                    if (
                      s._id === size._id &&
                      cnt === itemVariants.filter((f) => f.isClicked).length
                    )
                      count = count + 1;
                  }
                } else {
                  if (s._id === size._id) count = count + 1;
                }
                return s;
              });
            }
            return m;
          });
          if (count > 0) size.isAvailable = true;
          else size.isAvailable = false;
        } else {
          size.isAvailable = false;
        }
        return size;
      });

      hItem.selectedSizes.map((s) => {
        if (
          s.isAvailable &&
          (hItem.defaultSizes.map((m) => m._id).includes(s._id) ||
            s.pairId === pairId)
        ) {
          pairId = s.pairId;
          halfActiveMenuSizes.push(s);
        }
        return s;
      });
      if (hItem.categories.length > 1) {
        hItem.selectedSizes.forEach((s) => {
          if (
            s.isAvailable &&
            s.pairId === pairId &&
            !localState.hhActiveMenuSizes.map((h) => h._id).includes(s._id)
          )
            halfActiveMenuSizes.push(s);
        });
      }
    }
    if (hItem.selectedModifiers) {
      hItem.selectedModifiers.map((size) => {
        let matchingMenuItems = menuItems.filter((m) => {
          return hItem.categories.map((m) => m._id).includes(m.categoryId);
        });
        let count = 0;
        if (matchingMenuItems.length > 0) {
          matchingMenuItems.map((m) => {
            if (m.selectedSizes) {
              m.selectedSizes.map((s) => {
                if (
                  itemVariants &&
                  itemVariants.filter((f) => f.isClicked).length > 0
                ) {
                  let cnt = 0;
                  if (s.variants) {
                    s.variants.map((v) => {
                      if (
                        v.isSelected &&
                        itemVariants
                          .filter((f) => f.isClicked)
                          .map((m) => m._id)
                          .includes(v._id)
                      )
                        cnt = cnt + 1;
                      return v;
                    });
                    if (
                      s._id === size._id &&
                      cnt === itemVariants.filter((f) => f.isClicked).length
                    )
                      count = count + 1;
                  }
                } else {
                  if (s._id === size._id) count = count + 1;
                }
                return s;
              });
            }
            return m;
          });
          if (count > 0) size.isAvailable = true;
          else size.isAvailable = false;
        } else {
          size.isAvailable = false;
        }
        return size;
      });

      hItem.selectedModifiers.map((s) => {
        if (
          s.isAvailable &&
          (hItem.defaultSizes.findIndex((m) => m._id === s._id) !== -1 ||
            s.pairId === pairId)
        ) {
          pairId = s.pairId;
          halfActiveMenuSizes.push(s);
        }
        return s;
      });
      if (hItem.categories.length > 1) {
        hItem.selectedModifiers.map((s) => {
          if (
            s.isAvailable &&
            s.pairId === pairId &&
            !localState.hhActiveMenuSizes.map((h) => h._id).includes(s._id)
          ) {
            halfActiveMenuSizes.push(s);
          }
          return s;
        });
      }
    }

    if (hItem.defaultSizes && !localState.hhActiveMenuSizes) {
      halfActiveMenuSizes = hItem.defaultSizes.reduce(
        (acc, s) => [
          ...acc,
          ...hItem.selectedSizes.filter((ss) => ss.pairId === s.pairId),
          ...hItem.selectedModifiers.filter((sm) => sm.pairId === s.pairId),
        ],
        []
      );
    }

    setLocalState({ halfItem: hItem, hhActiveMenuSizes: halfActiveMenuSizes });

    if (
      window.innerWidth > 991 &&
      !(window.navigator.userAgent.match(/iPad/i) && window.innerHeight > 1250)
    ) {
      setLocalState({ clicked: 1 });
      defaultFilter(itemVariants, halfActiveMenuSizes);
    }
  };

  const sizeList = useMemo(() => {
    let list = [];

    if (
      localState.halfItem &&
      localState.halfItem.selectedSizes !== undefined
    ) {
      localState.halfItem.selectedSizes
        .filter((f) => f.isAvailable)
        .map((m) => {
          list.push(m);
          return m;
        });
    }
    if (
      localState.halfItem &&
      localState.halfItem.selectedModifiers !== undefined
    ) {
      localState.halfItem.selectedModifiers
        .filter((f) => f.isAvailable)
        .map((m) => {
          list.push(m);
          return m;
        });
    }
    list = groupBy(list, (s) => s.pairId);

    return list;
  }, [localState.halfItem]);

  return (
    <div className='specialViewI halfBox'>
      <div
        className='detailsTitle sP half'
        style={{
          marginTop: 0,
          justifyContent: 'space-between',
          paddingRight: 0,
        }}
      >
        <strong className='halfHalfTitle'>Half / Half</strong>
        <div
          style={{
            display: 'flex',
            flexFlow: 'row',
            flexWrap: 'wrap',
            marginTop: '10px',
          }}
        >
          {localState?.itemVariants?.map((v) => (
            <div key={v._id} className='gluten-free-half-special-view'>
              <div
                className={`${v.isClicked} visible-xs visible-sm visible-md visible-lg half-half-variant-button`}
              >
                <ButtonUI
                  btnType='button'
                  rounded='full'
                  variant='outlined'
                  shadow='sm'
                  leftIcon={
                    v.isClicked ? (
                      <CheckIcon className='w-[20px]' />
                    ) : (
                      <PlusIcon className='w-[20px]' />
                    )
                  }
                  onClick={() => toggleVariant(v)}
                  extendedClassName='glutenFree text-capitalize'
                >
                  {v.name.length > 12 ? v.name.substr(0, 10) + '..' : v.name}
                </ButtonUI>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='halfDivs'>
        <Half
          flag={false}
          itemObj={
            selectedHalfItems?.find((hItem) => hItem.whichHalf === 1) ?? {}
          }
          selectedHalfItems={selectedHalfItems}
          clickHandler={filterIt}
          myID={1}
          currentClicked={localState.clicked}
          key={1}
        />
        <Half
          flag={localState.flag}
          itemObj={
            selectedHalfItems?.find((hItem) => hItem.whichHalf === 2) ?? {}
          }
          selectedHalfItems={selectedHalfItems}
          clickHandler={filterIt}
          myID={2}
          currentClicked={localState.clicked}
          key={2}
        />
      </div>
      <div className='selectSizes'>
        <span className='viewTitle'>Select Size</span>
        <hr style={{ marginTop: -15 }} />
        <div className='sizesI'>
          {Object.keys(sizeList).map((pairId, index) => {
            const item = sizeList[pairId][0];
            const isSelected =
              localState.hhActiveMenuSizes.findIndex(
                (s) => s.pairId === pairId
              ) !== -1;

            return (
              item.isAvailable && (
                <div
                  key={index}
                  className={isSelected ? 'default' : ''}
                  onClick={() => callFilter(item)}
                >
                  {item.name}
                </div>
              )
            );
          })}
        </div>
        <div className='addToHalf'>
          <div
            onClick={renderAddHalfHalfToOrder}
            className={
              isSubmitEnabled() ? 'confirmBtn' : 'confirmBtn diableConfirm'
            }
          >
            Add to Order
          </div>
        </div>
      </div>
    </div>
  );
}
