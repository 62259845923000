import { CustomerMenuItemUI } from '@next-order/next-order-components';
import { eventNames } from 'configuration/eventNames';
import { SOLD_OUT_ITEM_PRICE } from 'helpers/enums';
import React from 'react';

const eventName = eventNames.MENU_ITEM_OPENED;

function MenuItem({
  menuItem,
  currency,
  displayType = 'grid',
  onClickItemHandler,
  count,
  style = {},
}) {
  const item = {
    bgImgSrc: menuItem.bgImgSrc,
    lazyLoad: menuItem.lazyLoad,
    imgSrc: menuItem.imgSrc,
    price: menuItem.price,
    productName: menuItem.productName,
    showName: menuItem.showName,
    ingredients: menuItem.ingredients,
    itemType: menuItem.itemType,
    item: menuItem.item,
  };
  const isSoldOut = item.price === SOLD_OUT_ITEM_PRICE;
  const opacity = isSoldOut ? 0.5 : 1;
  const price = item.price && item.item.type !== '2' ? item.price : undefined;
  const curr = currency && price && !isSoldOut ? currency : '';
  const productName =
    item?.item?.isNameHidden && displayType !== 'list' ? '' : item?.productName;

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClickItemHandler(item.itemType, item.item, eventName);
  };

  return (
    <CustomerMenuItemUI
      title={productName}
      desc={item.ingredients}
      currency={price?.includes(' ') ? '' : curr}
      price={price}
      displayType={displayType}
      imgSrc={item.bgImgSrc}
      onClick={onClick}
      count={count}
      style={{
        pointerEvents: isSoldOut ? 'none' : 'initial',
        opacity,
        ...style,
      }}
    />
  );
}

// export default memo(
//   MenuItem,
//   (prevProps, nextProps) =>
//     prevProps.item?._id === nextProps.item?._id &&
//     prevProps.currency === nextProps.currency
// );
export default MenuItem;
