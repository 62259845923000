import { useContext } from 'react';
import { GlobalModalContext } from './GlobalModalProvider';

export const useGlobalModalContext = () => {
  const context = useContext(GlobalModalContext);

  if (!context)
    throw new Error(
      'GlobalModalContext must be used with GlobalModalProvider!'
    );

  return context;
};
