import { useEffect, useState } from 'react';
import { TableIcon } from 'nexticons/outline';
import {
  LayoutPartUI,
  InputUI,
  ModalCWShellUI,
} from '@next-order/next-order-components';

const ConfirmTable = ({ confirmTable, hideModal, selectedTableNumber }) => {
  const [tableNo, setTableNo] = useState(selectedTableNumber, '');

  useEffect(() => {
    setTableNo(selectedTableNumber ?? '');
    // eslint-disable-next-line
  }, []);

  return (
    <ModalCWShellUI onOpenChange={hideModal} open={true} zIndex={100}>
      <ModalCWShellUI.CloseButton onClick={hideModal} />
      <ModalCWShellUI.Header title={'What’s your table number?'} />

      <ModalCWShellUI.Body>
        <LayoutPartUI.Row extendedClassName='relative'>
          <TableIcon
            width={26}
            className='absolute left-7.25 top-1/2 -translate-y-1/2 -translate-x-1/2 text-gray-500'
          />
          <InputUI
            autoFocus
            name='table_number'
            placeholder='Enter Table Number'
            size='xl'
            extendedClassName='`outline-0 focus:outline-0 shadow-cw pl-14.25'
            value={tableNo}
            onChange={(e) => setTableNo(e.target.value)}
            type='number'
          />
        </LayoutPartUI.Row>
      </ModalCWShellUI.Body>

      <ModalCWShellUI.Footer>
        <ModalCWShellUI.Button
          disabled={!tableNo}
          onClick={() => confirmTable(tableNo)}
        >
          Confirm Table {tableNo}
        </ModalCWShellUI.Button>
      </ModalCWShellUI.Footer>
    </ModalCWShellUI>
  );
};

export default ConfirmTable;
