import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import AppRoutes from './routes';
import { unregister } from './registerServiceWorker';
import Smartlook from 'smartlook-client';

import Store from './store';

unregister();

document.addEventListener(
  'touchmove',
  function (event) {
    if (event.scale !== 1) {
      event.preventDefault();
    }
  },
  false
);

if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: 'https://a26d8f875e824ea4a049abf59c75384b@o217922.ingest.sentry.io/1360235',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_DEPLOY_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  Smartlook?.init('a888bd1e7a0320e56abf60ab665aceddc92dda65', undefined, () =>
    console.log('Smartlook is now initialized')
  );
  Smartlook?.record({
    emails: true,
    forms: true,
    numbers: true,
  });
}

ReactDOM.render(
  <Provider store={Store}>
    <AppRoutes />
  </Provider>,
  document.getElementById('root')
);
