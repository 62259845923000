import { API_URL } from 'configuration/urls';

export const fetchMenuItemData = async (storeId) => {
  let menuItemSizes = [];
  let menuItemSubModifiers = [];
  const response = await fetch(
    `${API_URL}catalog/v1/menuItems?storeId=${storeId}&orderFrom=1`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  if (response?.status === 200) {
    let json = await response.json();
    const menuItems = json?.data?.menuItems || [];

    menuItems?.forEach((menuItem) => {
      if (menuItem?.sizes) {
        const sizes = menuItem?.sizes.map((size) => {
          return {
            ...size,
            menuItemId: menuItem?.menuItemId,
          };
        });
        menuItemSizes?.push(...sizes);
      }
      if (menuItem?.subModifiers) {
        const subModifiers = menuItem?.subModifiers.map((subModifier) => {
          return {
            ...subModifier,
            menuItemId: menuItem?.menuItemId,
          };
        });
        menuItemSubModifiers?.push(...subModifiers);
      }
    });
  }

  return {
    menuItemSizes,
    menuItemSubModifiers,
  };
};
