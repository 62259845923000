import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { firebaseAnalyticsNO } from '../../firebase';
import moment from 'moment-timezone';
import Cvv from 'creditcards/cvc';
import CreditCardForm from '../CreditCardForm';
import Card from 'creditcards/card';
import expiration from 'creditcards/expiration';
import cardTypes from 'creditcards-types';
import includes from 'lodash/includes';
import amexsvg from '../../assets/images/amexsvg.svg';
import visasvg from '../../assets/images/visasvg.svg';
import mastercardsvg from '../../assets/images/mastercardsvg.svg';
import getStripePromise from '../../configuration/getStripePromise';
import { eventNames } from 'configuration/eventNames';
import { getPlatformAndCardCharges } from 'helpers/getPlatformAndCardCharges';
import getOrderObjectIfDinein from 'helpers/getOrderObjectIfDinein';
import LoadingButton from 'components/LoadingButton';
import {
  MENU_ORDER_TYPE,
  ORDER_PAYMENT_TYPE,
  ORDER_STAGE_TYPES,
} from 'helpers/enums';
import { BackButtonCWUI, OrderUI } from '@next-order/next-order-components';
import { ArrowRightIcon, LoadingIcon } from 'nexticons/outline';

var lookup = require('binlookup')();

class OrderPaymentCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moveNextDetail: 0,
      cardPaymentStatus: 0, // O-pending, 1-Done, 2-Failed
      isCardSelected: false,
      isCardDetailsValid: false,
      selectedCard: {},
      paymentInProcess: false,
      keyBoard: false,
      placeholderValue: 'MM/YY',
      hyphenVisible: false,
      placeholderMonth: 'MM',
      placeholderYear: 'YY',
      actualMonth: '',
      actualYear: '',
      isFirst: false,
    };
    this.supportedType = ['Visa', 'Mastercard', 'American Express'];
  }

  componentDidMount() {
    this.receiveProps(this.props);
    window.navigator.userAgent.match(/android/i) &&
      window.addEventListener(
        'resize',
        this.onElementHeightChange.bind(this, window.innerHeight)
      );
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.receiveProps(props);
  }

  componentWillUnmount() {
    window.navigator.userAgent.match(/android/i) &&
      window.removeEventListener(
        'resize',
        this.onElementHeightChange.bind(this)
      );
  }

  receiveProps = (props) => {
    if (props.currentOrder.paymentType === ORDER_PAYMENT_TYPE.card_online) {
      this.setDefaulrCardIfSelected(props.currentOrder);
    } else {
      this.setState({
        selectedCard: {},
        isCardSelected: false,
      });
    }
    this.updateSelectedTimeIfItIsInPast(
      props.currentOrder,
      props.currentOrder.orderType === MENU_ORDER_TYPE.delivery
        ? props.deliveryTimes
        : props.pickUpTimes
    );
  };

  updateSelectedTimeIfItIsInPast = (order, availableTimes) => {
    const { storeConfig } = this.props;
    if (
      order &&
      order.deliveryDate &&
      availableTimes &&
      this.props.orderSetup
    ) {
      let today = moment(
        moment().tz(storeConfig.timeZone).format('YYYY-MM-DD HH:mm'),
        'YYYY-MM-DD HH:mm'
      );
      if (order.orderType === MENU_ORDER_TYPE.pickup)
        today.add(
          this.props.orderSetup && this.props.orderSetup.pickUpTime
            ? Number(this.props.orderSetup.pickUpTime)
            : 0,
          'minutes'
        );
      else
        today.add(
          this.props.orderSetup && this.props.orderSetup.deliveryTime
            ? Number(this.props.orderSetup.deliveryTime)
            : 0,
          'minutes'
        );
      let delDate = moment(order.deliveryDate, 'YYYY-MM-DD HH:mm');

      //Detect if update required
      if (today.isAfter(delDate)) {
        if (
          availableTimes.length > 0 &&
          availableTimes[0].times &&
          availableTimes[0].times.length > 0
        ) {
          let timeObj = availableTimes[0].times[0];
          let deliveryDate = moment(
            `${timeObj.timeObjFinal} ${timeObj.displayForUse}`,
            'YYYY-MM-DD HH:mm A'
          );
          if (deliveryDate.isAfter(today)) {
            this.props.updateDeliveryPickupTime(
              deliveryDate,
              timeObj.shiftId,
              false,
              0,
              false
            );
          } else {
            this.props.updateDeliveryPickupTime(
              today,
              timeObj.shiftId,
              false,
              0,
              false
            );
          }
        } else {
          // No time slot available
        }
      }
    }
  };

  setSelectedCard = (card) => {
    let oldCard = JSON.parse(JSON.stringify(this.state.selectedCard));
    let isSameCard = oldCard.token === card.token ? true : false;

    if (isSameCard) {
      this.setState(
        {
          selectedCard: card,
          isCardSelected: true,
        },
        () => {
          this.props.updatePaymenyType(
            card.cardDescription,
            card.cardNumber,
            card.cardType,
            false,
            this.state.isCardDetailsValid,
            card.token,
            card.country
          );
        }
      );
    } else {
      this.setState(
        {
          selectedCard: card,
          isCardSelected: true,
        },
        () => {
          this.props.updatePaymenyType(
            card.cardDescription,
            card.cardNumber,
            card.cardType,
            false,
            this.state.isCardDetailsValid,
            card.token,
            card.country
          );
        }
      );
    }
  };

  setDefaulrCardIfSelected = (currentOrder) => {
    if (this.props.paymentCards && this.props.paymentCards.length > 0) {
      let sCard = this.props.paymentCards.filter((m) => {
        return m.token === currentOrder.token;
      });
      if (sCard.length > 0) {
        this.setState({
          selectedCard: sCard[0],
          //isCardSelected: true,
        });
      } else {
        this.setState({
          selectedCard: {},
          isCardSelected: false,
        });
      }
    }
  };

  validateCardDetails = (index) => {
    let typeIndex = this.state.newCardType
      ? parseInt(this.state.newCardType)
      : 0;
    let goodCt = 0;
    if (
      this.state.newCardNumber &&
      ((typeIndex === 2 && this.state.newCardNumber.length >= 15) ||
        (typeIndex !== 2 && this.state.newCardNumber.length >= 16)) &&
      this.cardFormatter &&
      this.cardFormatter.isValid(
        this.cardFormatter.parse(this.state.newCardNumber)
      )
    ) {
      goodCt = goodCt + 1;
    } else if (
      this.state.newCardNumber &&
      ((typeIndex === 2 && this.state.newCardNumber.length >= 15) ||
        (typeIndex !== 2 && this.state.newCardNumber.length >= 16))
    ) {
      this.setState({
        isCardNumberInvalid: true,
      });
    }
    if (
      this.state.actualMonth &&
      this.state.actualMonth.length === 2 &&
      this.state.actualYear &&
      this.state.actualYear.length === 2
    ) {
      if (
        !expiration.isPast(
          Number(this.state.actualMonth),
          Number(`20${this.state.actualYear}`)
        )
      ) {
        goodCt = goodCt + 1;
        if (Number(this.state.actualMonth) > 12) {
          this.setState({
            isExError: true,
          });
        }
      } else {
        this.setState({
          isExError: true,
        });
      }
    } else {
      this.setState({
        isExError: false,
      });
    }
    if (this.state.newCardNumber && this.state.newCVV && this.cardFormatter) {
      var type = cardTypes.find((type) =>
        type.test(this.cardFormatter.parse(this.state.newCardNumber), true)
      );
      var cvv = Cvv([type]);
      if (cvv.isValid(this.state.newCVV)) {
        goodCt = goodCt + 1;
      } else {
        this.setState({
          isCVVError: this.state.newCVV.length >= 3 ? true : false,
        });
      }
    }
    if (goodCt === 3) {
      this.setState(
        {
          isCardDetailsValid: true,
          isCardNumberInvalid: false,
          isCardSelected: false,
          cardPaymentStatus: 0,
          selectedCard: {},
        },
        () => {
          // this.refs.monthNumber.blur();
          // this.refs.cvvNumber.blur();
          this.setState({ moveNextDetail: 0 });
          lookup(this.state.newCardNumber, (err, data) => {
            if (err) {
              let card = {
                number: this.cardFormatter.parse(this.state.newCardNumber),
                exp_month: parseInt(this.state.actualMonth),
                exp_year: parseInt(`20${this.state.actualYear}`),
                cvc: this.state.newCVV,
                type: this.state.newCardType,
                country: '',
              };
              this.props.updatePaymentTypeForNewCard(true, card);
            } else {
              let card = {
                number: this.cardFormatter.parse(this.state.newCardNumber),
                exp_month: parseInt(this.state.actualMonth),
                exp_year: parseInt(`20${this.state.actualYear}`),
                cvc: this.state.newCVV,
                type: this.state.newCardType,
                country:
                  data.country && data.country.alpha2
                    ? data.country.alpha2
                    : '',
              };
              this.props.updatePaymentTypeForNewCard(true, card);
            }
          });
        }
      );
    } else {
      this.setState(
        {
          isCardDetailsValid: false,
        },
        () => {
          this.props.updatePaymentTypeForNewCard(false);
        }
      );
    }
  };

  cardNumberChange = (e) => {
    var type = cardTypes.find((type) => type.test(e.target.value, true));
    if (type && type.name && includes(this.supportedType, type.name)) {
      this.cardFormatter = Card([type]);
      let valToF = e.target.value;
      this.setState(
        {
          newCardNumber: this.cardFormatter.parse(valToF),
          newCardNumberDisplay: this.cardFormatter.format(
            this.cardFormatter.parse(valToF)
          ),
          newCardType: this.getCardTypeFromName(type.name).type,
          isCardNumberInvalid: false,
        },
        () => {
          this.validateCardDetails(1);
        }
      );
    } else {
      this.setState({
        newCardNumber: !isNaN(e.target.value) ? e.target.value : '',
        newCardType: e.target.value.length === 0 ? '' : 'Not supported',
        newCardNumberDisplay: !isNaN(e.target.value) ? e.target.value : '',
        isCardNumberInvalid: e.target.value.length <= 1 ? false : true,
      });
    }
  };

  cvvChange = (e) => {
    if (e.target.value.length > 3) {
      var type = {};
      if (this.cardFormatter) {
        type = cardTypes.find((type) =>
          type.test(this.cardFormatter.parse(this.state.newCardNumber), true)
        );
        if (type && type.name === 'American Express') {
          this.setState(
            {
              newCVV: e.target.value.slice(0, 4),
              isCVVError: false,
              moveNextDetail: 0,
            },
            () => {
              // this.refs.cvvNumber.blur();
              this.validateCardDetails(3);
            }
          );
        } else {
          this.setState(
            {
              newCVV: e.target.value.slice(0, 3),
              isCVVError: false,
              moveNextDetail: 0,
            },
            () => {
              // this.refs.cvvNumber.blur();
              this.validateCardDetails(3);
            }
          );
        }
      } else {
        this.setState(
          {
            newCVV: e.target.value.slice(0, 4),
            isCVVError: false,
            moveNextDetail: 0,
          },
          () => {
            // this.refs.cvvNumber.blur();
            this.validateCardDetails(3);
          }
        );
      }
    } else {
      this.setState(
        {
          newCVV: e.target.value,
          isCVVError: false,
        },
        () => {
          this.validateCardDetails(3);
        }
      );
    }
  };

  setCaretPosition(ctrl, pos) {
    // Modern browsers
    if (ctrl.setSelectionRange) {
      ctrl.focus();
      ctrl.setSelectionRange(pos, pos);

      // IE8 and below
    } else if (ctrl.createTextRange) {
      var range = ctrl.createTextRange();
      range.collapse(true);
      range.moveEnd('character', pos);
      range.moveStart('character', pos);
      range.select();
    }
  }

  actualMonthChange = (e) => {
    var code = e.keyCode;
    var allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }

    let finalValue = e.target.value
      .replace(
        /[^\d|^\/]*/g, // eslint-disable-line
        '' // To allow only digits and `/` //UPDATED by NAVNEET
      )
      .replace(
        /\/\//g, // eslint-disable-line
        '/' // Prevent entering more than 1 `/`
      );

    if (finalValue.length === 0) {
      this.setState(
        {
          actualMonth: finalValue,
          placeholderMonth: 'MM',
        },
        () => {
          this.setState(
            {
              hyphenVisible: false,
            },
            () => {
              this.validateCardDetails(2);
            }
          );
        }
      );
    }

    if (finalValue.length === 1) {
      let actual = finalValue;
      let placeholderMonth = this.state.placeholderMonth;
      placeholderMonth = placeholderMonth.split('');
      placeholderMonth[0] = actual[0];
      placeholderMonth = placeholderMonth.join('');
      this.setState(
        {
          actualMonth: finalValue,
          placeholderMonth: placeholderMonth,
        },
        () => {
          this.validateCardDetails(2);
        }
      );
    }

    if (finalValue.length === 2) {
      let actual = finalValue;
      let placeholderMonth = this.state.placeholderMonth;
      let elem = document.getElementById('actual-date-month');
      if (elem.selectionStart === 1) {
        let placeholder = this.state.placeholderMonth;
        placeholder = placeholder.split('');
        placeholder[0] = actual[0];
        placeholder[1] = actual[1];
        placeholder = placeholder.join('');
        this.setState(
          {
            actualMonth: finalValue,
            placeholderMonth: placeholder,
          },
          () => {
            if (this.state.actualYear.length === 2) {
              this.setState(
                {
                  hyphenVisible: true,
                },
                () => {
                  this.validateCardDetails(2);
                }
              );
            }
          }
        );
      } else {
        placeholderMonth = placeholderMonth.split('');
        placeholderMonth[1] = actual[1];
        placeholderMonth = placeholderMonth.join('');
        this.setState(
          {
            actualMonth: finalValue,
            placeholderMonth: placeholderMonth,
          },
          () => {
            if (this.state.actualYear.length === 2) {
              this.setState(
                {
                  hyphenVisible: true,
                },
                () => {
                  this.validateCardDetails(2);
                }
              );
            }
          }
        );
      }
    }

    if (finalValue.length === 3) {
      let actual = finalValue;
      actual = actual.split('');
      if (actual[2] === '/') {
        this.setState(
          {
            hyphenVisible: true,
          },
          () => {
            this.validateCardDetails(2);
          }
        );
      } else {
        if (this.state.actualYear.length !== 2) {
          let actualYear = this.state.actualYear;
          actualYear = actualYear.split('');
          actualYear[0] = actual[2];
          actualYear = actualYear.join('');
          this.setState(
            {
              hyphenVisible: true,
              actualYear: actualYear,
            },
            () => {
              let placeholder = this.state.actualYear;
              placeholder = placeholder.split('');
              placeholder[1] = 'Y';
              placeholder = placeholder.join('');
              this.setState(
                {
                  placeholderYear: placeholder,
                },
                () => {}
              );
            }
          );
        }
      }
    }
  };

  actualMonthKeyUp = (e) => {
    if (e.key === 'Backspace') {
      let elem = document.getElementById('actual-date-month');
      if (elem.selectionStart === 1 || elem.selectionStart === 0) {
        let placeholder = this.state.placeholderMonth;
        placeholder = placeholder.split('');
        placeholder[1] = 'M';
        placeholder = placeholder.join('');
        this.setState({
          placeholderMonth: placeholder,
        });
      }
    }
    if (e.key === 'ArrowRight') {
      if (this.state.actualMonth.length === 2) {
        let elem = document.getElementById('actual-date-month');
        if (elem.selectionStart === 2) {
        }
        this.setState({
          hyphenVisible: true,
        });
      }
    }
  };

  actualYearKeyUp = (e) => {
    if (e.key === 'Backspace') {
      let elem = document.getElementById('actual-date-year');
      if (
        this.state.actualYear.length !== 2 &&
        (elem.selectionStart === 1 || elem.selectionStart === 0)
      ) {
        let placeholder = this.state.placeholderYear;
        placeholder = placeholder.split('');
        placeholder[1] = 'Y';
        placeholder = placeholder.join('');
        this.setState((prevState) => {
          if (prevState.actualYear.length === 1) {
            return {
              placeholderYear: placeholder,
              isFirst: true,
            };
          } else {
            return {
              placeholderYear: placeholder,
              isFirst: false,
            };
          }
        });
      }
      if (this.state.actualYear.length === 0 && this.state.isFirst === true) {
        this.setState({
          hyphenVisible: true,
        });
      }
      if (this.state.actualYear.length === 0 && this.state.isFirst === false) {
        this.setState(
          {
            hyphenVisible: false,
          },
          () => {}
        );
      }
    }
    if (e.key === 'ArrowLeft') {
      let elem = document.getElementById('actual-date-year');
      if (
        this.state.actualYear.length === 2 ||
        this.state.actualYear.length === 0
      ) {
        if (elem.selectionStart === 0) {
        }
      }
    }
  };

  actualYearChange = (e) => {
    var code = e.keyCode;
    var allowedKeys = [8];
    if (allowedKeys.indexOf(code) !== -1) {
      return;
    }

    let finalValue = e.target.value
      .replace(
        /[^\d|^/]*/g,
        '' // To allow only digits and `/` //UPDATED by NAVNEET
      )
      .replace(
        /\/\//g,
        '/' // Prevent entering more than 1 `/`
      );

    if (finalValue.length === 0) {
      this.setState(
        {
          actualYear: finalValue,
          placeholderYear: 'YY',
        },
        () => {
          this.validateCardDetails(2);
        }
      );
    }

    if (finalValue.length === 1) {
      let actual = finalValue;
      let placeholderYear = this.state.placeholderYear;
      placeholderYear = placeholderYear.split('');
      placeholderYear[0] = actual[0];
      placeholderYear = placeholderYear.join('');
      this.setState(
        {
          actualYear: finalValue,
          placeholderYear: placeholderYear,
        },
        () => {
          this.validateCardDetails(2);
        }
      );
    }

    if (finalValue.length === 2) {
      let actual = finalValue;
      let placeholderYear = this.state.placeholderYear;
      let elem = document.getElementById('actual-date-year');
      if (elem.selectionStart === 1) {
        let placeholder = this.state.placeholderYear;
        placeholder = placeholder.split('');
        placeholder[0] = actual[0];
        placeholder[1] = actual[1];
        placeholder = placeholder.join('');
        this.setState(
          {
            actualYear: finalValue,
            placeholderYear: placeholder,
            isExError: false,
          },
          () => {
            this.validateCardDetails(2);
          }
        );
      } else {
        placeholderYear = placeholderYear.split('');
        placeholderYear[1] = actual[1];
        placeholderYear = placeholderYear.join('');
        this.setState(
          {
            actualYear: finalValue,
            placeholderYear: placeholderYear,
            isExError: false,
          },
          () => {
            this.validateCardDetails(2);
          }
        );
      }
    }
  };

  markAsCardPayment = () => {
    this.setState(
      {
        cardPaymentStatus: 0,
      },
      () => {
        this.props.updatePaymentTypeForCard();
      }
    );
  };

  guidGenerator = () => {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4();
  };

  getTimeStamp = () => {
    const { storeConfig } = this.props;
    return moment().tz(storeConfig.timeZone).format('YYYYDDMMHHmmSSkkk000sOOO');
  };

  getUserName = () => {
    if (this.props.userDetails) {
      return `${this.props.userDetails.firstName} ${this.props.userDetails.lastName}`;
    } else return '';
  };

  getUserId = () => {
    if (this.props.userDetails && this.props.userDetails._id)
      return this.props.userDetails._id;
    else return '';
  };

  getUsermobileNumber = () => {
    if (this.props.userDetails && this.props.userDetails.mobileNumber)
      return this.props.userDetails.mobileNumber;
    else return '';
  };

  updateOrderStagePayHandler = () => {
    const stage = ORDER_STAGE_TYPES.ALL_DONE;
    if (
      this.props.currentOrder &&
      this.props.currentOrder.paymentType &&
      this.props.userDetails &&
      this.props.userDetails._id &&
      this.props.currentOrder.isAllowToConfirm
    ) {
      if (
        this.props.currentOrder.paymentType === ORDER_PAYMENT_TYPE.cash &&
        !this.state.paymentInProcess
      ) {
        this.setState(
          {
            paymentInProcess: true,
          },
          () => {
            this.props.updateOrderStage(stage);
          }
        );
      } else {
        if (this.state.isCardSelected && this.state.selectedCard) {
          //Process payment when previously used card is selected
          this.makeDirectPayment(false, '');
        } else if (
          this.state.isCardDetailsValid &&
          this.state.cardPaymentStatus === 0 &&
          this.props.currentOrder.paymentType === ORDER_PAYMENT_TYPE.card_online
        ) {
          //Process payment in new card
          this.makePaymentByCard();
        }
      }
    }
  };

  fetchPlaceOrder = async (requestObj) => {
    const { storeConfig } = this.props;
    const initialRequestObj = JSON.parse(JSON.stringify(requestObj));

    const orderObj3d = await getOrderObjectIfDinein(requestObj);
    fetch(`${storeConfig.cloudFunctionsUrl}/placeOrder3DSecure`, {
      method: 'post',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...orderObj3d,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.success) {
          getStripePromise(storeConfig.publishableKey).then((stripe) => {
            stripe
              .confirmCardPayment(result.payload.secret, {
                setup_future_usage: 'on_session',
              })
              .then((res) => {
                if (
                  res.paymentIntent &&
                  res.paymentIntent.status === 'succeeded'
                ) {
                  this.props.fetchOrderHandler(
                    result.payload.preOrder.preOrderId
                  );
                  firebaseAnalyticsNO.logEvent(eventNames.PAID_FOR_ORDER);
                  this.props.history.replace(
                    `/order-tracking/${result.payload.preOrder.preOrderId}/${requestObj?.order?.orderType}`
                  );
                } else if (res.error) {
                  let message = res.error.message;
                  if (
                    res.error.code &&
                    res.error.code === 'card_declined' &&
                    res.error.decline_code &&
                    res.error.decline_code === 'insufficient_funds'
                  ) {
                    message = 'Your card has been declined.';
                  }
                  this.setState({
                    cardPaymentStatus: 2,
                    paymentInProcess: false,
                    errorMessage: message,
                  });
                }
              });
          });
        } else if (result?.code === 'invalid_iteration') {
          this.fetchPlaceOrder(initialRequestObj);
        } else {
          this.setState({
            cardPaymentStatus: 2,
            paymentInProcess: false,
            errorMessage: result.message,
          });
        }
      })
      .catch(() => {
        this.setState({
          cardPaymentStatus: 2,
          paymentInProcess: false,
          errorMessage: '',
        });
      });
  };

  makePaymentByCard = () => {
    const { storeConfig } = this.props;
    if (!this.state.paymentInProcess) {
      this.setState(
        {
          paymentInProcess: true,
        },
        async () => {
          let orderObj = JSON.parse(JSON.stringify(this.props.currentOrder));
          if (orderObj.isAllowToConfirm !== undefined)
            delete orderObj.isAllowToConfirm;
          if (orderObj.offers) delete orderObj.offers;
          if (orderObj.stage) delete orderObj.stage;
          if (typeof orderObj.isFreeItemsAvailable !== 'undefined')
            delete orderObj.isFreeItemsAvailable;
          const { payableAmount, creditCardCharges } = orderObj;
          const toPay = +payableAmount + +creditCardCharges;
          let requestObj = {
            order: {
              ...orderObj,
              payableAmount: toPay.toFixed(2),
              userId: this.props.userDetails._id,
              firstName: this.props.userDetails.firstName
                ? this.props.userDetails.firstName
                : '',
              lastName: this.props.userDetails.lastName
                ? this.props.userDetails.lastName
                : '',
              email: this.props.userDetails.email
                ? this.props.userDetails.email
                : '',
              storeId: storeConfig.storeId,
              restaurantId: storeConfig.restaurantId,
              minOrderValue: `${orderObj.minOrderValue || 0}`,
              notes: this.props?.notes,
            },
            payment: {
              amount: parseInt((Number(toPay) * 100).toFixed(2), 10),
              card: {
                number: this.cardFormatter.parse(this.state.newCardNumber),
                exp_month: parseInt(this.state.actualMonth),
                exp_year: parseInt(`20${this.state.actualYear}`),
                cvc: this.state.newCVV,
              },
              isNewCardUsed: true,
            },
          };

          console.log('makePaymentByCard');
          this.fetchPlaceOrder(requestObj);
        }
      );
    }
  };

  makeDirectPayment = (isDirect, tokenValue) => {
    const { storeConfig } = this.props;
    if (!this.state.paymentInProcess) {
      this.setState(
        {
          paymentInProcess: true,
        },
        async () => {
          let orderObj = JSON.parse(JSON.stringify(this.props.currentOrder));
          if (orderObj.isAllowToConfirm !== undefined)
            delete orderObj.isAllowToConfirm;
          if (orderObj.offers) delete orderObj.offers;
          if (orderObj.stage) delete orderObj.stage;
          if (typeof orderObj.isFreeItemsAvailable !== 'undefined')
            delete orderObj.isFreeItemsAvailable;
          const { payableAmount, creditCardCharges } = orderObj;
          const toPay = +payableAmount + +creditCardCharges;
          let requestObj = {
            order: {
              ...orderObj,
              payableAmount: toPay.toFixed(2),
              userId: this.props.userDetails._id,
              firstName: this.props.userDetails.firstName
                ? this.props.userDetails.firstName
                : '',
              lastName: this.props.userDetails.lastName
                ? this.props.userDetails.lastName
                : '',
              email: this.props.userDetails.email
                ? this.props.userDetails.email
                : '',
              storeId: storeConfig.storeId,
              restaurantId: storeConfig.restaurantId,
              minOrderValue: `${orderObj.minOrderValue || 0}`,
              notes: this.props?.notes,
            },
            payment: {
              amount: parseInt((Number(toPay) * 100).toFixed(2), 10),
              source: isDirect ? tokenValue : this.state.selectedCard.token,
              isNewCardUsed: false,
            },
          };

          console.log('makeDirectPayment');
          this.fetchPlaceOrder(requestObj);
        }
      );
    }
  };

  onElementHeightChange = (height) => {
    let lastHeight = height;
    let newHeight = window.innerHeight;
    if (lastHeight - newHeight > 125) {
      this.setState({ keyBoard: true, moveNextDetail: 11 });
    } else {
      if (this.state.keyBoard === true && lastHeight - newHeight === 0) {
        this.setState({ keyBoard: false, moveNextDetail: 0 });
      }
    }
  };

  getCardTypeFromName = (c) => {
    if (c.toLowerCase() === 'mastercard')
      return { type: '5', name: 'MasterCard' };
    else if (c.toLowerCase() === 'american express')
      return { type: '2', name: 'American Express(Amex)' };
    else if (c.toLowerCase() === 'visa') return { type: '6', name: 'Visa' };
    else return { type: '1500', name: 'Unknown' };
  };

  getCardUrl = (cardType) => {
    if (cardType === '6') return `https://pics.nextorder.co/assets/visasvg.svg`;
    else if (cardType === '5')
      return `https://pics.nextorder.co/assets/mastercardsvg.svg`;
    else if (cardType === '2')
      return `https://pics.nextorder.co/assets/amexsvg.svg`;
    else return `https://pics.nextorder.co/assets/credit-card-2.svg`;
  };

  render() {
    const { storeConfig, currentOrder, sidebarAnimationClass } = this.props;
    const { cardChargesTitle, cardCharges } =
      getPlatformAndCardCharges(currentOrder);

    const { payableAmount, creditCardCharges, platformSurchargeAmt } =
      currentOrder;

    const toPay = +payableAmount + +creditCardCharges + +platformSurchargeAmt;

    const isDisabled = !(
      (this.props?.currentOrder?.paymentType === ORDER_PAYMENT_TYPE.cash ||
        (this.state.isCardDetailsValid &&
          this.state.cardPaymentStatus === 0 &&
          this.props.currentOrder.paymentType ===
            ORDER_PAYMENT_TYPE.card_online) ||
        this.state.isCardSelected) &&
      this.props?.currentOrder?.isAllowToConfirm &&
      !this.state.paymentInProcess
    );

    return (
      <div
        className={`${sidebarAnimationClass} cards detailsConfirm detailFinal active`}
      >
        <div
          className={`moveNext${this.state.moveNextDetail} detailsConfirm detailFinal detailPayment active`}
        >
          <BackButtonCWUI onClick={this.props.updateOrderStageBack} />
          <div style={{ display: 'flex', flexFlow: 'column nowrap' }}>
            <div
              className='detailsTitle'
              style={{ marginTop: 0, marginBottom: 10 }}
            >
              Credit Card
              {this.props.orderSetup &&
                this.props.orderSetup.isCardPaymentAccepted && (
                  <div
                    className='img'
                    style={{ display: 'flex', position: 'absolute' }}
                  >
                    <img src={amexsvg} alt='' />
                    <img src={visasvg} alt='' />
                    <img src={mastercardsvg} alt='' />
                  </div>
                )}
            </div>
            <CreditCardForm
              isCardSelected={this.state.isCardSelected}
              setCardSelected={(value) =>
                this.setState({ isCardSelected: value })
              }
              initialValues={
                this.state.isCardSelected
                  ? {
                      cardNumber: this.state.selectedCard.cardNumber,
                      cardExpiryDate: '••/••',
                      cardCVV: '•••',
                    }
                  : {
                      cardNumber: this.state.newCardNumber || '',
                      cardExpiryDate: this.state.actualMonth
                        ? `${this.state.actualMonth}/${
                            this.state.actualYear || ''
                          }`
                        : '',
                      cardCVV: this.state.newCVV || '',
                    }
              }
              onSubmit={({ cardNumber, cardExpiryDate, cardCVV, cardType }) => {
                const [actualMonth, actualYear] = cardExpiryDate.split('/');
                this.cardFormatter = Card([cardType]);
                this.setState({
                  actualMonth,
                  actualYear,
                  newCardNumber: cardNumber.split(' ').join(''),
                  cardPaymentStatus: 0,
                  errorMessage: '',
                  newCardNumberDisplay: cardNumber,
                  newCardType: cardType,
                  newCVV: cardCVV,
                  isCardNumberInvalid: false,
                  isCardDetailsValid: true,
                  isCVVError: false,
                  isExError: false,
                });
                const orderObj = JSON.parse(
                  JSON.stringify(this.props.currentOrder)
                );
                orderObj.cardType = cardType;
                orderObj.isInternationCard = false;
                this.props.updateEngineOrderCalculations(orderObj);
              }}
            />
            {(this.state.cardPaymentStatus === 2 ||
              this.state.cardPaymentStatus === 3) && (
              <div style={{ marginTop: 10 }}>
                <span className='text-2xl text-red-500'>
                  {this.state.errorMessage ? this.state.errorMessage : ''}
                </span>
              </div>
            )}
            <div className='detailsTitle' style={{ marginTop: 10 }}>
              <h1>Saved cards</h1>
            </div>
            <div
              style={{ overflow: 'auto', paddingRight: 10, marginBottom: 40 }}
            >
              {this.props.orderSetup &&
                this.props.orderSetup.isCardPaymentAccepted &&
                !this.props.paymentCards &&
                !this.props.paymentCardsFetched && <LoadingButton />}
              {this.props.orderSetup &&
                this.props.orderSetup.isCardPaymentAccepted &&
                this.props.paymentCards &&
                this.props.paymentCards.map((card, index) => (
                  <div
                    onClick={() => this.setSelectedCard(card)}
                    key={index}
                    className={
                      card.token === this.state.selectedCard.token &&
                      this.state.isCardSelected
                        ? 'cardCommon cash active'
                        : 'cardCommon cash'
                    }
                  >
                    <span>
                      {card.cardType === 3
                        ? [
                            card.cardNumber.slice(0, 4),
                            ' ',
                            card.cardNumber.slice(4, 10),
                            ' ',
                            card.cardNumber.slice(10),
                          ].join('')
                        : card.cardNumber
                            .replace(/(\w{4})/g, '$1 ')
                            .replace(/(^\s+|\s+$)/, '')}
                    </span>
                    <img src={this.getCardUrl(card.cardType)} alt='' />
                  </div>
                ))}
            </div>
            {this.props.orderSetup &&
              this.props.orderSetup.isCardPaymentAccepted && (
                <>
                  {/*
                  <div
                  ref='card'
                  className={
                    this.state.isCardDetailsValid &&
                      this.state.cardPaymentStatus === 0 &&
                      this.props.currentOrder.paymentType === ORDER_PAYMENT_TYPE.card_online &&
                      !this.state.isCardSelected
                        ? 'cardCommon card active'
                        : 'cardCommon card'
                  }
                  onClick={() => this.markAsCardPayment()}
                >
                  <div
                    className={
                      this.state.isCardNumberInvalid
                        ? 'cardNumber error'
                        : 'cardNumber'
                    }
                  >
                    <span className='cardLegend'>Card number</span>
                    <img src={this.getCardUrl(this.state.newCardType)} alt='' />
                    <input
                      type='tel'
                      maxLength='19'
                      onFocus={() => {
                        this.setState({ isCardSelected: false });
                        isAndroid && this.setState({ moveNextDetail: 11 });
                      }}
                      onKeyUp={(e) =>
                          e.keyCode === 13 && this.refs.monthNumber.focus()
                      }
                      value={this.state.newCardNumberDisplay}
                      onChange={this.cardNumberChange}
                      name='cardNumber'
                      autoComplete='off'
                      placeholder='0000 0000 0000 0000'
                    />
                  </div>
                  <div
                    className={
                      this.state.isExError
                        ? 'cardMonth cBot error'
                        : 'cardMonth cBot'
                    }
                  >
                    <span className='cardLegend'>Valid till</span>
                    <div className='datefield-wrapper'>
                      <div className='date-months-wrapper'>
                        <input
                          id='actual-date-month'
                          ref='monthNumber'
                          autoComplete='off'
                          name='ccmonth'
                          type='tel'
                          maxLength='3'
                          onFocus={() =>
                              isAndroid && this.setState({ moveNextDetail: 11 })
                          }
                          value={this.state.actualMonth}
                          onChange={this.actualMonthChange}
                          onKeyUp={this.actualMonthKeyUp}
                        />
                        <input
                          id='placeholder-date-month'
                          type='tel'
                          maxLength='2'
                          placeholder={this.state.placeholderMonth}
                        />
                      </div>
                      <div className={spanClass}>
                        {this.state.hyphenVisible ? (
                          <span className='actual-date-span'>/</span>
                        ) : null}
                        <span className='placeholder-date-span'>/</span>
                      </div>
                      <div className='date-years-wrapper'>
                        <input
                          id='actual-date-year'
                          type='tel'
                          ref='yearNumber'
                          maxLength='2'
                          value={this.state.actualYear}
                          onChange={this.actualYearChange}
                          onKeyUp={this.actualYearKeyUp}
                          name='ccyear'
          autoComplete='off'
                        />
        <input
          id='placeholder-date-year'
          type='tel'
          maxLength='2'
          placeholder={this.state.placeholderYear}
        />
                      </div>
                  </div>
                  </div>
                  <div
                    className={
                      this.state.isCVVError
                        ? 'cardCVV cBot error'
                        : 'cardCVV cBot'
                    }
                  >
                    <span className='cardLegend'>CVV</span>
                    <input
                      autoComplete='off'
                      ref='cvvNumber'
                      type='tel'
                      step='1'
                      name='cvvNumber'
                      value={this.state.newCVV}
                      onChange={this.cvvChange}
                      placeholder='123'
                      onFocus={() =>
                          isAndroid && this.setState({ moveNextDetail: 11 })
                      }
                      style={{
                        width: '100%',
                      }}
                    />
                  </div>
                  </div>
                  */}
                </>
              )}
          </div>
        </div>
        <div className='totalbottom'>
          <div className='totalInner'>
            {this.props.currentOrder.publicHolidaySurcharge &&
            Number(this.props.currentOrder.publicHolidaySurcharge) !== 0 ? (
              <div className='spWrap'>
                <span className='spName'>
                  Public Holiday Surcharge
                  {`${
                    this.props.currentOrder &&
                    this.props.currentOrder.publicHolidaySurchargePer &&
                    Number(
                      this.props.currentOrder.publicHolidaySurchargePer
                    ) !== 0
                      ? `(${this.props.currentOrder.publicHolidaySurchargePer}%)`
                      : null
                  }`}
                </span>
                <span className='spPrice'>{`+ ${storeConfig.currency}${this.props.currentOrder.publicHolidaySurcharge}`}</span>
              </div>
            ) : (
              <div />
            )}
            {this.props.currentOrder.rewards &&
            this.props.currentOrder.rewards.length > 0 ? (
              <div className='spWrap'>
                <span className='spName'>Loyalty</span>
                <span className='spPrice'>{`- ${storeConfig.currency}${this.props.currentOrder.loyaltyDiscount}`}</span>
              </div>
            ) : (
              <div />
            )}
            {this.props.currentOrder.specials &&
            this.props.currentOrder.specials.length > 0 &&
            Number(this.props.currentOrder.specialDiscount) > 0 ? (
              <div className='spWrap'>
                <span className='spName'>Specials</span>
                <span className='spPrice'>{`- ${storeConfig.currency}${this.props.currentOrder.specialDiscount}`}</span>
              </div>
            ) : (
              <div />
            )}
            {this.props.currentOrder.voucherId &&
            this.props.currentOrder.voucherId !== '' &&
            Number(this.props.currentOrder.voucherDiscount) !== 0 ? (
              <div className='spWrap'>
                <span className='spName'>Voucher</span>
                <span className='spPrice'>{`- ${storeConfig.currency}${this.props.currentOrder.voucherDiscount} `}</span>
              </div>
            ) : (
              <div />
            )}
            {this.props.currentOrder.isDisplayGST &&
            !this.props.currentOrder.isPriceIncludesGST &&
            Number(this.props.currentOrder.gst) !== 0 ? (
              <div className='spWrap'>
                <span className='spName'>GST</span>
                <span className='spPrice'>{` ${storeConfig.currency}${this.props.currentOrder.gst} `}</span>
              </div>
            ) : (
              <div />
            )}
            {cardCharges &&
              (this.state.isCardSelected || this.state.isCardDetailsValid) && (
                <div
                  style={{
                    position: 'relative',
                    background: 'white',
                    marginTop: 5,
                    transform: 'translateY(-14px)',
                    padding: '0px 2px',
                    zIndex: 9999,
                  }}
                >
                  <span className='spName'>{cardChargesTitle}</span>
                  <span className='spPrice'>{` ${storeConfig.currency}${Number(
                    cardCharges
                  ).toFixed(2)} `}</span>
                </div>
              )}

            <OrderUI.FooterButton
              extendedClassName='z-[1003]'
              rightIcon={
                this.state.paymentInProcess ? (
                  <LoadingIcon strokeWidth={3} width={16} />
                ) : (
                  <ArrowRightIcon strokeWidth={3} width={16} />
                )
              }
              onClick={() => {
                firebaseAnalyticsNO.logEvent(
                  eventNames.SELECTED_PAYMENT_METHOD
                );
                this.updateSelectedTimeIfItIsInPast(
                  this.props.currentOrder,
                  this.props.currentOrder.orderType === MENU_ORDER_TYPE.delivery
                    ? this.props.deliveryTimes
                    : this.props.pickUpTimes
                );
                setTimeout(() => {
                  this.updateOrderStagePayHandler();
                }, 0);
              }}
              disabled={isDisabled}
            >
              {this.state.paymentInProcess
                ? 'Wait while we are processing'
                : `Confirm and Pay ${storeConfig.currency}${toPay.toFixed(2)}`}
            </OrderUI.FooterButton>
          </div>
        </div>
      </div>
    );
  }
}

OrderPaymentCards.propTypes = {
  currentOrder: PropTypes.any,
  accessToken: PropTypes.any,
  paymentCards: PropTypes.any,
  updateOrderStage: PropTypes.any,
  history: PropTypes.any,
  updatePaymentTypeForNewCard: PropTypes.any,
  updateDeliveryPickupTime: PropTypes.any,
  storeConfig: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    storeConfig: state.layoutReducer.storeConfig,
    currentOrder: state.homeReducer.currentOrder,
    notes: state.homeReducer.notes,
  };
}

export default connect(mapStateToProps)(OrderPaymentCards);
