const SpecialBoxesSkeleton = ({ noOfItems = 4 }) => {
  return (
    <>
      {[...Array(noOfItems)].map((_, index) => {
        return (
          <div className='bSp' key={`SpecialBoxSkeleton-${index}`}>
            <div className='innerSP bg-skeleton rounded-lg animate-placeholder 2xl:rounded-2.25' />
          </div>
        );
      })}
    </>
  );
};

export default SpecialBoxesSkeleton;
