import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Scheduler from 'components/Scheduler';
import { GlobalModalProvider } from './modal/GlobalModalProvider';
import Home from './containers/Home';

const Review_lazyPromise = import('./containers/Reviews');
let Review_lazy = lazy(() => Review_lazyPromise);

const ConfirmReservation_lazyPromise = import(
  './containers/ConfirmReservation'
);
let ConfirmReservation_lazy = lazy(() => ConfirmReservation_lazyPromise);

const ConfirmOrder_lazyPromise = import('./containers/ConfirmOrder');
let ConfirmOrder_lazy = lazy(() => ConfirmOrder_lazyPromise);

const OrderTracker_lazyPromise = import('./containers/OrderTracker');
let OrderTracker_lazy = lazy(() => OrderTracker_lazyPromise);

const AppRoutes = () => {
  return (
    <GlobalModalProvider>
      <BrowserRouter>
        <Suspense fallback={<></>}>
          <Switch>
            <Route path='/scheduler' render={() => <Scheduler />} />
            <Route
              path='/order-review'
              // eslint-disable-next-line react/jsx-pascal-case
              render={(props) => <Review_lazy {...props} />}
            />
            <Route
              path='/order-tracking'
              // eslint-disable-next-line react/jsx-pascal-case
              render={(props) => <OrderTracker_lazy {...props} />}
            />
            <Route
              path='/confirm-booking'
              // eslint-disable-next-line react/jsx-pascal-case
              render={(props) => <ConfirmReservation_lazy {...props} />}
            />
            <Route
              path='/confirm-order'
              // eslint-disable-next-line react/jsx-pascal-case
              render={(props) => <ConfirmOrder_lazy {...props} />}
            />
            <Route
              path='/'
              // eslint-disable-next-line react/jsx-pascal-case
              render={(props) => <Home {...props} />}
            />
            <Route render={() => <Redirect to='/' />} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </GlobalModalProvider>
  );
};

export default AppRoutes;
