import { GridUI } from '@next-order/next-order-components';
import HalfHalfOrSpecialMenuItemsViewContainer from 'components/NewMenuItem/HalfHalfOrSpecialMenuItemsViewContainer';
import { SOLD_OUT_ITEM_PRICE } from 'helpers/enums';
import getMenuItemIngredients from 'helpers/getMenuItemIngredients';
import useGetHalfItems from 'helpers/hooks/useGetHalfItems';
import React, { useMemo } from 'react';
import SearchModal from './SearchModal';

export default function RenderHalfViewMenuItems({
  halfItem,
  hhActiveMenuSizes,
  itemVariants,
  menuItems,
  onClickItemHandler,
  currency,
}) {
  const items = useGetHalfItems({
    halfItem,
    hhActiveMenuSizes,
    itemVariants,
    menuItems,
  });

  const searchCategoriesList = useMemo(() => {
    return [
      {
        name: 'Half Half',
        data: items.filter((item) => item.unitPrice !== SOLD_OUT_ITEM_PRICE),
      },
    ].filter((category) => category.data.length > 0);
  }, [items]);

  return (
    <>
      <GridUI align='start' gap='none' colsTemplate={`cw-grid`}>
        {items?.map((menuItem, idx) => (
          <HalfHalfOrSpecialMenuItemsViewContainer
            key={`${menuItem._id}-${idx}`}
            productName={menuItem.name}
            bgImgSrc={menuItem.urlS3}
            ingredients={getMenuItemIngredients(menuItem.description)}
            menuItem={menuItem}
            onClickItemHandler={onClickItemHandler}
            currency={currency}
          />
        ))}
      </GridUI>
      <SearchModal
        searchCategoriesList={searchCategoriesList}
        onClickItemHandler={onClickItemHandler}
      />
    </>
  );
}
