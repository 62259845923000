import { captureException, captureMessage } from '@sentry/react';
import { API_URL } from 'configuration/urls';
import { setLayoutFlow } from 'containers/Home/actions';
import { _auth } from '../firebase';
import { ORDER_STAGE_TYPES } from 'helpers/enums';
import useInterval from 'helpers/hooks/useInterval';
import { isNumberMobileAndValid } from 'helpers/phoneNumberHelper';
import { validateEmail, validateName } from 'helpers/validationUtils';
import { AsYouType } from 'libphonenumber-js';
import React, { createContext, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const defaultState = {
  //
  userFromLogin: {},
  validatedNumber: '',

  //
  firstName: '',
  lastName: '',
  fieldErrors: {},
  isNextDisabled: false,
  inputFieldBlurStatus: {
    firstName: false,
    lastName: false,
    mobileNumber: false,
  },

  isLoading: false,
  displayMobileNumber: '',
  emailAddress: '',

  isCodeResent: false,
  authCodeCounter: false,
  verificationCodeError: false,
  shouldResendCodeOnNumberEdit: false,
};

export const UserLoginRegistrationContext = createContext({
  localState: defaultState,
  setLocalState: () => {},

  updateOrderStageHandler: () => {},
  handleInputFieldBlur: () => {},
  validateMobileNumber: () => {},
  handleVerificationCodeError: () => {},
});

export default function UserLoginRegistrationProvider({
  updateOrderStage,
  updateCurrentUser,
  initiateDesktopLoyaltyFlow,
  loyalty,
  orderSetup,
  storeConfig,
  children,
}) {
  const COUNTRY_IDENTIFIER = storeConfig?.countryIdentifier;

  const [localState, setLocalState] = useReducer(
    (s, a) => ({ ...s, ...a }),
    defaultState
  );

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const { nameErrors, isDisabled } = validateName(
      localState.firstName.trim(),
      localState.lastName.trim()
    );
    setLocalState({
      fieldErrors: { ...localState.fieldErrors, ...nameErrors },
      isNextDisabled: isDisabled,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState.firstName, localState.lastName]);

  useEffect(() => {
    if (orderSetup?.enableUserEmail) {
      const isValidEmail = validateEmail(localState.emailAddress);
      if (!isValidEmail) {
        setLocalState({
          fieldErrors: {
            ...localState.fieldErrors,
            emailAddress: 'Please enter a valid email address',
          },
          isNextDisabled: true,
        });
      } else {
        setLocalState({
          fieldErrors: { ...localState.fieldErrors, emailAddress: '' },
          isNextDisabled: false,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState.emailAddress, orderSetup?.enableUserEmail]);

  useEffect(() => {
    if (!localState.shouldResendCodeOnNumberEdit) return;
    signInWithPhoneNumber(localState.validatedNumber, () => {
      setLocalState({
        isCodeResent: true,
        shouldResendCodeOnNumberEdit: false,
        authCodeCounter: 30,
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState.validatedNumber]);

  const signInWithPhoneNumber = async (
    mobileNumberToSignIn,
    callBackAfterSignIn
  ) => {
    setLocalState({ isLoading: true });

    try {
      let { restaurantId, storeId, timeZone } = storeConfig;
      const requestObj = {
        firstName: localState.firstName.trim(),
        lastName: localState.lastName.trim(),
        mobileNumber: mobileNumberToSignIn,
        ...(orderSetup?.enableUserEmail && localState.emailAddress
          ? { email: localState.emailAddress }
          : ''),
        restaurantId,
        storeId,
        timeZone,
      };
      const response = await fetch(`${API_URL}api/v1/signInWithMobileNumber`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestObj),
      });
      const responseData = await response.json();
      if (responseData?.errors?.length > 0) {
        captureMessage(
          `Mobile Number Response Error: ${JSON.stringify(
            responseData?.errors
          )}`
        );
        captureException(responseData?.errors);
        setLocalState({
          fieldErrors: {
            ...localState.fieldErrors,
            mobileNumber:
              responseData?.errors[0] || 'Server error, please try again',
          },
          isLoading: false,
        });
      } else {
        callBackAfterSignIn();
        setLocalState({
          fieldErrors: {
            ...localState.fieldErrors,
            mobileNumber: '',
          },
          isLoading: false,
        });
      }
    } catch (err) {
      captureMessage(`Mobile Number Submission Error: ${JSON.stringify(err)}`);
      captureException(err);
      console.error(err);

      setLocalState({
        fieldErrors: {
          ...localState.fieldErrors,
          mobileNumber: 'Server error, please try again',
        },
        isLoading: false,
      });
    }
  };

  const verifyMobileOTP = async (validatedNumber, verificationCode) => {
    let { restaurantId, storeId } = storeConfig;
    const requestObj = {
      mobileNumber: validatedNumber,
      otp: verificationCode,
      restaurantId,
      storeId,
    };
    const response = await fetch(`${API_URL}api/v1/verifyMobileOTP`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestObj),
    });
    const data = await response.json();
    const { data: loginData } = data;
    return loginData;
  };

  const handleVerificationCodeError = (error = '') => {
    captureMessage(`Verification Code Error: ${JSON.stringify(error)}`);
    captureException(error);
    setLocalState({
      isVerificationCodeError: true,
    });

    setTimeout(() => {
      setLocalState({
        isVerificationCodeError: false,
      });
    }, 5000);
  };

  const updateOrderStageHandler = async (
    stage,
    verificationCode = '',
    loginType
  ) => {
    if (stage === ORDER_STAGE_TYPES.MOBILE_FORM) {
      history.push('/usermobile');
      updateOrderStage(stage);
    } else if (
      stage === ORDER_STAGE_TYPES.VERIFY_FROM &&
      localState.validatedNumber
    ) {
      setLocalState({
        isNextDisabled: true,
      });
      signInWithPhoneNumber(localState.validatedNumber, () => {
        updateOrderStage(stage);
        history.push('/verification');

        setLocalState({
          isNextDisabled: false,
          authCodeCounter: 30,
        });
      });
    } else if (
      stage === ORDER_STAGE_TYPES.REVIEW &&
      localState.validatedNumber &&
      verificationCode
    ) {
      setLocalState({
        isLoading: true,
      });

      const loginData = await verifyMobileOTP(
        localState.validatedNumber,
        verificationCode
      );
      const {
        token,
        user: userFromVerification,
        successful = false,
        errors = [],
      } = loginData;
      if (!successful) {
        setLocalState({
          isLoading: false,
        });

        console.error('Incorrect verification code entered');
        handleVerificationCodeError({
          err: errors[0] ? errors[0] : 'Unsuccessful Request : verify OTP',
          verificationCodeEntered: verificationCode,
          mobileNumber: localState.validatedNumber,
        });
      } else {
        _auth
          .signInWithCustomToken(token)
          .then((result) => {
            const { user } = result;
            let details = {
              firstName: localState.firstName.trim(),
              lastName: localState.lastName.trim(),
              mobileNumber: localState.validatedNumber,
            };
            if (orderSetup?.enableUserEmail && localState.emailAddress) {
              details.userEmail = localState.emailAddress;
              if (typeof window.clarity === 'function') {
                window.clarity('identify', localState.emailAddress);
              }
            } else {
              if (typeof window.clarity === 'function') {
                window.clarity('identify', localState.validatedNumber);
              }
            }

            setLocalState({
              userFromLogin: user,
            });

            if (initiateDesktopLoyaltyFlow || loyalty) {
              if (
                userFromVerification &&
                userFromVerification.firstName &&
                userFromVerification.lastName
              ) {
                details.firstName = userFromVerification.firstName;
                details.lastName = userFromVerification.lastName;

                setLocalState({
                  isLoading: false,
                });

                updateCurrentUser(user, details, stage, loginType);
              } else {
                setLocalState({
                  userFromLogin: user,
                  isLoading: false,
                });
                dispatch(setLayoutFlow(true));
                updateOrderStage(ORDER_STAGE_TYPES.NAME_FORM);
              }
            } else {
              setLocalState({
                isLoading: false,
              });
              updateCurrentUser(user, details, stage, loginType);
            }
          })
          .catch((err) => {
            setLocalState({
              isLoading: false,
            });

            console.error(err);
            handleVerificationCodeError({
              err,
              verificationCodeEntered: verificationCode,
              mobileNumber: localState.validatedNumber,
            });
          });
      }
    }
  };

  const handleInputFieldBlur = (e) => {
    setLocalState({
      inputFieldBlurStatus: {
        ...localState.inputFieldBlurStatus,
        [e.target.name]: true,
      },
    });
  };

  useInterval(
    () => {
      setLocalState({
        isCodeResent:
          localState.authCodeCounter !== 1 ? localState.isCodeResent : false,
        authCodeCounter: localState.authCodeCounter - 1,
      });
    },
    localState.authCodeCounter ? 1000 : null
  );

  const validateMobileNumber = (mobileNumber) => {
    let numberAsUserType = new AsYouType(COUNTRY_IDENTIFIER).input(
      mobileNumber
    );

    const { number, error } = isNumberMobileAndValid(
      numberAsUserType,
      COUNTRY_IDENTIFIER
    );

    setLocalState({
      displayMobileNumber: numberAsUserType,
      fieldErrors: {
        ...localState.fieldErrors,
        mobileNumber: error,
      },
      validatedNumber: number ? number : '',
    });
  };

  return (
    <UserLoginRegistrationContext.Provider
      value={{
        localState,
        setLocalState,
        updateOrderStageHandler,
        handleInputFieldBlur,
        validateMobileNumber,
        handleVerificationCodeError,
      }}
    >
      {children}
    </UserLoginRegistrationContext.Provider>
  );
}
