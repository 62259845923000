import { API_URL } from 'configuration/urls';

export const getDineInObjects = async (storeId) => {
  const response = await fetch(
    `${API_URL}stores/v1/floorLayouts?filter.storeId=${storeId}`,
    {
      method: 'get',
    }
  );
  const result = await response.json();
  const { data, errors = [] } = result;
  if (errors.length > 0) {
    return false;
  }
  if (data?.floorLayouts?.length) {
    let floors = {};
    let tables = [];
    result.data.floorLayouts.forEach(({ dineInObjects, id, name }) => {
      const dineInObjs = dineInObjects
        .map((item) => {
          return {
            id: item.id,
            number: item.number,
            name: item.name,
            floorLayoutId: id,
            floorName: name,
          };
        })
        .sort((a, b) => a.number - b.number);
      tables.push(...dineInObjs);
      floors[id] = { name, tables: dineInObjs };
    });

    // For generating QR Codes URLs
    // const CUSTOMER_WEBSITE_BASE_URL = 'https://green-olives.nextorder.com';
    // const urls = [];

    // tables.forEach(({ floorLayoutId, floorName, name, number }) => {
    //   urls.push({
    //     'QR Code Name': `${floorName} - Table-${name || number}`,
    //     'QR Code Description': `${floorName} - Table-${name || number}`,
    //     'Redirection URL': `${CUSTOMER_WEBSITE_BASE_URL}/dine-in/${floorLayoutId}/table-${
    //       name || number
    //     }`,
    //   });
    // });
    // console.log('URLs: ', urls);
    return { floors, tables };
  }
};
