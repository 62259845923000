import { LAYOUT_REDUCER_INITIAL_STATE } from 'containers/Layout/reducer';
import moment from 'moment';
import React, { createContext, useEffect, useReducer } from 'react';

const defaultTimeZone = LAYOUT_REDUCER_INITIAL_STATE.storeConfig?.timeZone ?? 'Australia/Melbourne';

const getDefaultState = (timeZone) => ({
  reservation: {
    reservationDate: moment().tz(timeZone).format('YYYY-MM-DD HH:mm'),
    children: 0,
    adults: 0,
    infants: 0,
    reservationStatus: '1',
    date: moment().tz(timeZone).format('YYYY-MM-DD HH:mm'),
    notes: '',
  },
  allowNewBooking: false,
  active: 0,
  timeSlot: false,
  selectedTime: '',
  shake: false,
  shake1: false,
  showConfirmationScreen: false,
  addNotes: false,
  month: moment().tz(timeZone),
  nextMonth: moment().tz(timeZone).add(1, 'months'),
  nextMonth2: moment().tz(timeZone).add(2, 'months'),
  nextMonth3: moment().tz(timeZone).add(3, 'months'),
  nextMonth4: moment().tz(timeZone).add(4, 'months'),
  nextMonth5: moment().tz(timeZone).add(5, 'months'),
  nextMonth6: moment().tz(timeZone).add(6, 'months'),
  nextMonth7: moment().tz(timeZone).add(7, 'months'),
  nextMonth8: moment().tz(timeZone).add(8, 'months'),
  nextMonth9: moment().tz(timeZone).add(9, 'months'),
  nextMonth10: moment().tz(timeZone).add(10, 'months'),
  nextMonth11: moment().tz(timeZone).add(11, 'months'),
  selected: moment().tz(timeZone).startOf('day'),
  swiper: false,
  ulHeight: 28,
});

export const TableBookingViewContext = createContext([
  getDefaultState(defaultTimeZone),
  () => {},
]);

export default function TableBookingViewProvider({ children, timeZone }) {
  const value = useReducer(
    (s, a) => ({ ...s, ...a }),
    getDefaultState(timeZone ?? defaultTimeZone)
  );
  
  useEffect(() => {
    if (!timeZone || timeZone === defaultTimeZone) return;

    value[1]({ ...getDefaultState(timeZone) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeZone]);

  return (
    <TableBookingViewContext.Provider value={value}>
      {children}
    </TableBookingViewContext.Provider>
  );
}
