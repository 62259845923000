import ActionTypes from './constants';

const initialState = {
  allShifts: [],
};

const orderReducer = (state = initialState, { type, ...payload }) => {
  switch (type) {
    case ActionTypes.InitializeOrder:
      return {
        ...state,
        ...payload,
      };
    case ActionTypes.FetchAllShifts:
      return {
        ...state,
        allShifts: payload.allShifts,
      };
    case ActionTypes.FetchAllCloseDates:
      return {
        ...state,
        closeDates: payload.closeDates,
      };
    default:
      return state;
  }
};

export default orderReducer;
