import { createSelector } from 'reselect';

export const homeSelector = (state) => state.homeReducer;

export const selectOrderSetup = createSelector(
  [homeSelector],
  (home) => home.orderSetup
);

export const selectIsEnabledGoogleReview = createSelector(
  selectOrderSetup,
  (orderSetup) => orderSetup?.isEnableGoogleReview || false
);
