import React, { useReducer } from 'react';

import './loyalty.css';
import { ORDER_STAGE_TYPES } from 'helpers/enums';
import { LOGIN_FROM_TYPE } from '../../helpers/enums';
import { PersonThirdCircleIcon } from 'nexticons/solid';
import {
  setState as setPropsState,
  toggleMobileCart,
} from 'containers/Home/actions';
import { useDispatch } from 'react-redux';
import { defaultHomePropsState } from 'constants/common';
import { BackButtonCWUI } from '@next-order/next-order-components';

export default function LoyaltyDesktop({
  userDetails,
  updateOrderStage,
  currentUser,
  offers,
  pathwayToOffers,
  storeConfig,
  sidebarAnimationClass,
}) {
  const dispatch = useDispatch();
  const [localState, setLocalState] = useReducer((s, a) => ({ ...s, ...a }), {
    selectedCategory: 'offers',
  });

  const handleCategory = (catgeory) => {
    setLocalState({
      selectedCategory: catgeory,
    });
  };

  const handleSignIn = () => {
    if (
      currentUser &&
      currentUser.phoneNumber &&
      userDetails &&
      userDetails.firstName
    ) {
      return;
    }

    updateOrderStage(ORDER_STAGE_TYPES.NAME_FORM, LOGIN_FROM_TYPE.LOYALTY);
  };

  const getSizeString = (offer) => {
    return offer.type === '1'
      ? ` ${offer.sizes
          .filter((f) => f.isActive)
          .map((s) => s.name)
          .join(' or ')} `
      : ` `;
  };

  const getOfferReward = (offer) => {
    let returnString = [];
    let count = 0;
    if (pathwayToOffers) {
      let matchingProgress = pathwayToOffers.filter((f) => {
        return f.offerId === offer._id && f.isUnlocked === true;
      });
      if (matchingProgress.length > 0) count = matchingProgress.length;
      else count = 1;
    } else count = 1;
    offer.rewardItems.forEach((m) => {
      let p =
        Number(m.discountedPrice) <= 0
          ? ` ${count} free`
          : ` ${count} ${storeConfig.currency}${m.discountedPrice}`;
      let sizes = m.sizes.filter((f) => f.isActive).map((m) => m.name);
      sizes = [...new Set(sizes)];
      p = p + ` ${sizes.join(' or ')} ${m.categoryDisplayName}`;
      returnString.push(p);
    });
    return offer.isAnyOneItemAvailable
      ? returnString.join(' or ')
      : returnString.join(' and ');
  };

  const getOfferUnlockStatus = (offer) => {
    if (pathwayToOffers) {
      let matchingProgress = pathwayToOffers.filter((f) => {
        return f.offerId === offer._id;
      });
      if (matchingProgress.length > 0) {
        let orderCount = matchingProgress.reduce((total, obj) => {
          return (total = total + Number(obj.orderCount));
        }, 0);
        if (
          orderCount >=
          (offer.type === '1'
            ? Number(offer.quantity)
            : Number(offer.noOfOrders))
        ) {
          return true;
        } else return false;
      } else return false;
    } else return false;
  };

  const getProgressCount = (offer) => {
    if (pathwayToOffers) {
      let matchingProgress = pathwayToOffers.filter((f) => {
        return f.offerId === offer._id;
      });
      if (matchingProgress.length > 0) {
        let orderCount = matchingProgress.reduce((total, obj) => {
          return (total = total + Number(obj.orderCount));
        }, 0);
        return offer.type === '1'
          ? Number(offer.quantity) - orderCount
          : Number(offer.noOfOrders) - orderCount;
      } else
        return offer.type === '1'
          ? Number(offer.quantity)
          : Number(offer.noOfOrders);
    } else
      return offer.type === '1'
        ? Number(offer.quantity)
        : Number(offer.noOfOrders);
  };

  const getProgressLength = (offer) => {
    if (pathwayToOffers) {
      let matchingProgress = pathwayToOffers.filter((f) => {
        return f.offerId === offer._id;
      });
      if (matchingProgress.length > 1) {
        return '100%';
      } else if (matchingProgress.length === 1) {
        return `${parseInt(
          (matchingProgress[0].orderCount /
            (offer.type === '1'
              ? Number(offer.quantity)
              : Number(offer.noOfOrders) || 1)) *
            100,
          10
        )}%`;
      } else {
        return '0%';
      }
    }
  };

  const onClose = () => {
    dispatch(toggleMobileCart(false));
    dispatch(setPropsState({ ...defaultHomePropsState }));
  };

  return (
    <div
      className={`${sidebarAnimationClass} wrapper loyalty-desktop`}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <header id='header' className='gold'>
        <div className='loyalty-container'>
          <div className='top-box mb-3'>
            <BackButtonCWUI onClick={onClose} />
            <div className='top-nav absolute top-2 right-5 '>
              <a onClick={handleSignIn}>
                <PersonThirdCircleIcon
                  className='relative -top-0.5 inline-block mr-1.25'
                  width={22}
                />
                {currentUser &&
                currentUser.phoneNumber &&
                userDetails &&
                userDetails.firstName
                  ? userDetails.firstName
                  : 'Account'}
              </a>
            </div>
          </div>
          <h1 className='header-title'>
            Loyalty <span>(Online Only)</span>
          </h1>
          <div className='bottom-box'>
            <div className='mobile-nav'>
              <ul>
                <li>
                  <a
                    title='Offers'
                    className={
                      localState.selectedCategory === 'offers' ? 'active' : ''
                    }
                    onClick={() => handleCategory('offers')}
                  >
                    <span className='icon_loy-circle_precent'></span>
                    Offers
                  </a>
                </li>
                {/* <li>
                      <a
                        title='Membership'
                        className={
                          localState.selectedCategory === 'membership'
                            ? 'active'
                            : ''
                        }
                        onClick={() => handleCategory('membership')}
                      >
                        <span className='icon_loy-circle_star'></span>Membership
                      </a>
                    </li> */}
              </ul>
            </div>
          </div>
        </div>
      </header>
      <main id='main-content'>
        <section className='section-discount-cards'>
          <div className='loyalty-container loyalty-container-overflow'>
            <React.Fragment>
              {offers && (
                <ul
                  className='offer-card-list'
                  style={{
                    display:
                      localState.selectedCategory === 'offers'
                        ? 'block'
                        : 'none',
                  }}
                >
                  {offers.map((offer, m) => (
                    <li key={m}>
                      <div
                        className='offer-card-box'
                        style={{ backgroundImage: `url(${offer.urlS3})` }}
                      >
                        <div className='inner-table'>
                          <div className='column'>
                            <h2 className='offer-title'>{offer.name}</h2>
                          </div>
                        </div>
                      </div>
                      {currentUser &&
                      currentUser.phoneNumber &&
                      userDetails &&
                      userDetails.firstName ? (
                        <div className='next-card-level'>
                          <div className='card-progress'>
                            <div
                              className='progress-line'
                              style={{
                                width: getProgressLength(offer),
                              }}
                            ></div>
                          </div>
                          {getOfferUnlockStatus(offer) ? (
                            <p className='text'>
                              Congratulation! You have unlocked
                              <span>{getOfferReward(offer)}</span>. Just add to
                              cart and claim
                            </p>
                          ) : (
                            <p className='text'>
                              {`You’re`}
                              {getProgressCount(offer) < 5
                                ? ` only ${getProgressCount(offer)}`
                                : ` ${getProgressCount(offer)}`}
                              <span>{getSizeString(offer)}</span>
                              <span>{`${
                                offer.type === '1'
                                  ? offer.categoryDisplayName
                                  : 'orders'
                              } `}</span>
                              away from a<span>{getOfferReward(offer)}</span>
                            </p>
                          )}
                        </div>
                      ) : (
                        <div className='card-nav-box'>
                          <div className='card-nav-table'>
                            <div className='inner-box'>
                              <p className='text'>View your progress</p>
                            </div>
                            <div className='inner-box'>
                              <a
                                className='btn'
                                title='Sign In'
                                onClick={handleSignIn}
                              >
                                Sign In
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              )}
              <ul
                className='discount-card-list'
                style={{
                  display:
                    localState.selectedCategory === 'membership'
                      ? 'block'
                      : 'none',
                }}
              >
                <li>
                  <div className='discount-card-box bronze'>
                    <div className='top-box'>
                      <div className='card-logo-box'>
                        <img
                          src='https://pics.nextorder.co/assets/logo.svg'
                          alt=''
                        />
                      </div>
                      <span className='privileges'>5% Discount</span>
                    </div>
                    <div className='bottom-box'>
                      <div className='inner-table'>
                        <div className='column'>
                          <div className='card-info'>
                            <span className='member-name'>
                              Johnny Appleseed
                            </span>
                            <span className='member-status'>
                              Member Since 10/18
                            </span>
                          </div>
                        </div>
                        <div className='column col-name'>
                          <span className='card-name'>Bronze</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className='discount-card-box gold'>
                    <div className='top-box'>
                      <div className='card-logo-box'>
                        <img
                          src='https://pics.nextorder.co/assets/logo.svg'
                          alt=''
                        />
                      </div>
                      <span className='privileges'>
                        10% Discount & Free Delivery
                      </span>
                    </div>
                    <div className='bottom-box'>
                      <div className='inner-table'>
                        <div className='column'>
                          <div className='card-info'>
                            <span className='member-name'>
                              Johnny Appleseed
                            </span>
                            <span className='member-status'>Future Member</span>
                          </div>
                        </div>
                        <div className='column col-name'>
                          <span className='card-name'>Gold</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='next-card-level'>
                    <div className='card-progress'>
                      <div
                        className='progress-line'
                        style={{ width: '71%' }}
                      ></div>
                      <div
                        className='progress-line end'
                        style={{ width: '30%' }}
                      ></div>
                    </div>
                    <p className='text'>
                      Only <span>4 orders</span> away from
                      <span>gold membership!</span>
                    </p>
                  </div>
                </li>

                <li>
                  <div className='discount-card-box platinum'>
                    <div className='top-box'>
                      <div className='card-logo-box'>
                        <img
                          src='https://pics.nextorder.co/assets/logo.svg'
                          alt=''
                        />
                      </div>
                      <span className='privileges'>
                        10% Discount & Free Delivery
                      </span>
                    </div>
                    <div className='bottom-box'>
                      <div className='inner-table'>
                        <div className='column'>
                          <div className='card-info'>
                            <span className='member-name'>
                              Johnny Appleseed
                            </span>
                            <span className='member-status'>Future Member</span>
                          </div>
                        </div>
                        <div className='column col-name'>
                          <span className='card-name'>Platinum</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </React.Fragment>
          </div>
        </section>
      </main>
      <footer id='footer'></footer>
    </div>
  );
}
