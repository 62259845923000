/* eslint-disable eqeqeq */
import { OrderUI } from '@next-order/next-order-components';
import OrderPreView from 'components/OrderPreView';
import { toggleMobileCart } from 'containers/Home/actions';
import { selectStoreConfig } from 'containers/Layout/selectors';
import { MENU_ORDER_TYPE, ORDER_STAGE_TYPES } from 'helpers/enums';
import { ArrowRightIcon } from 'nexticons/outline';
import { useDispatch, useSelector } from 'react-redux';
import OrderCartPreFooter from './OrderCartPreFooter';
import { isMobile } from 'helpers/isMobile';
import classNames from 'classnames';
import { useEffect } from 'react';
import moment from 'moment';

export default function Cart({
  incrementItemQuantity,
  decrementItemQuantity,
  incrementExtraQuantity,
  decrementExtraQuantity,
  updateOrderDeliveryAddress,

  updateDeliveryPickupTime,
  cartState,
  setCartState,
  toggleErrorStateForFutureTimeSelection,
  toggleErrorStateForTableSelection,
  dineInObjects,
  floors,
  addTableToOrder,
  switchSections,
  updateOrderStageHandler,
  onClickItemHandler,
  getStoreStatus,
  getMinOrderPrice,
  isSoldOutPresent,
  isOutOfSchedulePresent,
  isOtherThanSelectedOrderTypePresent,
  removeItemFromOrder,
  deliveryAddressMissing,
  updateVoucher,
  getDiscountedAmount,
  removeVoucher,
}) {
  const { userDetails, orderSetup, currentOrder } = useSelector(
    (state) => state.homeReducer
  );
  const order = useSelector((state) => state.orderReducer);
  const storeConfig = useSelector(selectStoreConfig);

  const dispatch = useDispatch();

  const goToNextStage = () => {
    // Check if user is not logged in
    if (userDetails == null) {
      updateOrderStageHandler(ORDER_STAGE_TYPES.NAME_FORM);
    } else {
      updateOrderStageHandler(ORDER_STAGE_TYPES.REVIEW);
    }
  };

  const isOrderSetupLoading =
    !orderSetup || Object.keys(orderSetup).length === 0;
  const isDineIn =
    orderSetup?.isDineInOn && window.location.pathname.includes('/dine-in');

  const isDisabled =
    isSoldOutPresent ||
    isOutOfSchedulePresent ||
    isOtherThanSelectedOrderTypePresent ||
    !currentOrder?.isAllowToConfirm ||
    !getStoreStatus()?.isDisplay;

  useEffect(() => {
    const availableTimes =
      currentOrder.orderType === MENU_ORDER_TYPE.delivery
        ? cartState?.deliveryTimes
        : cartState?.pickUpTimes;
    if (
      availableTimes?.length > 0 &&
      availableTimes[0].times &&
      availableTimes[0].times.length > 0
    ) {
      let today = moment(
        moment().tz(storeConfig.timeZone).format('YYYY-MM-DD HH:mm'),
        'YYYY-MM-DD HH:mm'
      );
      if (currentOrder.orderType === MENU_ORDER_TYPE.pickup) {
        today = today.add(
          orderSetup?.pickUpTime ? Number(orderSetup.pickUpTime) : 0,
          'minutes'
        );
      } else {
        today = today.add(
          orderSetup?.deliveryTime ? Number(orderSetup.deliveryTime) : 0,
          'minutes'
        );
      }

      const timeObj = availableTimes[0].times[0];
      const deliveryDate = moment(
        `${timeObj.timeObjFinal} ${timeObj.displayForUse}`,
        'YYYY-MM-DD HH:mm A'
      );
      if (deliveryDate.isBefore(today)) {
        updateDeliveryPickupTime(deliveryDate, timeObj.shiftId, false, 0, true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderSetup?.pickUpTime,
    orderSetup?.deliveryTime,
    storeConfig?.timeZone,
    currentOrder?.orderType,
    cartState?.deliveryTimes,
    cartState?.pickUpTimes,
  ]);

  return (
    <>
      <OrderUI
        hideScrollbar={navigator.userAgent.indexOf('Win') !== -1}
        actionGroup={
          <OrderUI.SwitchList
            activeID={currentOrder.orderType}
            setActiveID={(e, id) => switchSections(e, id)}
            switchList={
              !isOrderSetupLoading
                ? currentOrder.orderType !== MENU_ORDER_TYPE.dinein && !isDineIn
                  ? [
                      ...(orderSetup?.isPickupOn
                        ? [{ id: MENU_ORDER_TYPE.pickup, title: 'Pickup' }]
                        : []),
                      ...(orderSetup?.isDeliveryOn
                        ? [{ id: MENU_ORDER_TYPE.delivery, title: 'Delivery' }]
                        : []),
                    ]
                  : [
                      ...(orderSetup?.isDineInOn
                        ? [{ id: MENU_ORDER_TYPE.dinein, title: 'Dine In' }]
                        : []),
                    ]
                : []
            }
          />
        }
        footer={
          <>
            <OrderUI.FooterButton
              rightIcon={<ArrowRightIcon strokeWidth={3} width={16} />}
              onClick={goToNextStage}
              extendedClassName={classNames({ 'opacity-75': isDisabled })}
            >
              {getStoreStatus()?.text}
              {getStoreStatus()?.isDisplay ? (
                <span className='visible-xs visible-sm'>
                  {getMinOrderPrice() == 0
                    ? ` ${storeConfig.currency}${currentOrder.payableAmount}`
                    : ''}
                </span>
              ) : (
                ''
              )}
            </OrderUI.FooterButton>
          </>
        }
        ocCloseOrderBar={() => dispatch(toggleMobileCart(false))}
        preFooter={
          <OrderCartPreFooter onClickItemHandler={onClickItemHandler} />
        }
        title={isMobile() ? '' : 'Order'}
      >
        {currentOrder.stage <= ORDER_STAGE_TYPES.PAYMENT &&
          currentOrder.orderType &&
          orderSetup &&
          ((currentOrder.orderType !== MENU_ORDER_TYPE.dinein &&
            (orderSetup.isPickupOn || orderSetup.isDeliveryOn)) ||
            (currentOrder.orderType === MENU_ORDER_TYPE.dinein &&
              orderSetup?.isDineInOn)) && (
            <OrderPreView
              incrementItemQuantity={incrementItemQuantity}
              decrementItemQuantity={decrementItemQuantity}
              incrementExtraQuantity={incrementExtraQuantity}
              decrementExtraQuantity={decrementExtraQuantity}
              updateOrderDeliveryAddress={updateOrderDeliveryAddress}
              allSuburbs={order.allSuburbs}
              cartState={cartState}
              setCartState={setCartState}
              updateDeliveryPickupTime={updateDeliveryPickupTime}
              toggleErrorStateForFutureTimeSelection={
                toggleErrorStateForFutureTimeSelection
              }
              toggleErrorStateForTableSelection={
                toggleErrorStateForTableSelection
              }
              dineInObjects={dineInObjects}
              floors={floors}
              addTableToOrder={addTableToOrder}
              isDineIn={isDineIn}
              removeItemFromOrder={removeItemFromOrder}
              onClickItemHandler={onClickItemHandler}
              deliveryAddressMissing={deliveryAddressMissing}
              updateVoucher={updateVoucher}
              getDiscountedAmount={getDiscountedAmount}
              removeVoucher={removeVoucher}
            />
          )}
      </OrderUI>
    </>
  );
}
