import { Map as MapGL, Marker } from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { MAPTILER_CUSTOM_STYLE, MAPTILER_KEY } from 'configuration/maptiler';
import { useEffect, useRef } from 'react';

const Map = ({
  lat,
  long,
  zoom = 17,
  customMarker,
  markerOptions = { height: 32.5, width: 30 },
  ...restProps
}) => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (lat && long) {
      mapRef.current?.flyTo({ center: [long, lat], duration: 1000 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, long]);

  if (!lat || !long) {
    return <></>;
  }
  return (
    <MapGL
      ref={mapRef}
      mapLib={maplibregl}
      initialViewState={{
        latitude: lat,
        longitude: long,
        zoom,
      }}
      onIdle={(e) => e.target?.resize()}
      mapStyle={`${MAPTILER_CUSTOM_STYLE}?key=${MAPTILER_KEY}`}
      {...restProps}
    >
      <Marker
        latitude={lat}
        longitude={long}
        anchor={customMarker ? 'bottom' : 'center'}
      >
        {customMarker && (
          <img
            src={customMarker}
            height={markerOptions?.height || 35.25}
            width={markerOptions?.width || 30}
            alt='map-pin'
          />
        )}
      </Marker>
    </MapGL>
  );
};

export default Map;
