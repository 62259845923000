import { BackButtonCWUI } from '@next-order/next-order-components';
import React from 'react';
import HalfBox from '../../components/HalfBox';
import { styled } from '@stitches/react';

const Div = styled('div', {
  paddingTop: 20,
  paddingLeft: 20,
  position: 'absolute',
  zIndex: 100,
  '@media all and (max-width: 1023px)': {
    paddingTop: 26,
  },
});

export default function HalfHalfView({
  halfItem,
  selectedHalfItems,
  halfFilter,
  toggleAnimation,
  hideOrder,
  addHalfHalfToOrder,
  clearItemsNotAvailableForVariants,
  whichHalf,
  menuItems,
  clearOrChangeSize,
  sidebarAnimationClass,
}) {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
        position: 'relative',
      }}
      className={`${sidebarAnimationClass} halfView active`}
    >
      <Div>
        <BackButtonCWUI
          extendedClassName='relative -top-1.5'
          onClick={hideOrder}
        />
      </Div>
      <HalfBox
        key={String(toggleAnimation)}
        addHalfHalfToOrder={addHalfHalfToOrder}
        selectedHalfItems={selectedHalfItems}
        halfItem={halfItem}
        halfFilter={halfFilter}
        clearItemsNotAvailableForVariants={clearItemsNotAvailableForVariants}
        whichHalf={whichHalf}
        menuItems={menuItems}
        clearOrChangeSize={clearOrChangeSize}
      />
    </div>
  );
}
