import ActionTypes from './constants';

export function creditCardFetched(cards) {
  return (dispatch) => {
    dispatch(fetchedCreditCard(cards));
  };
}

function fetchedCreditCard(cards) {
  return {
    type: ActionTypes.FetchedCreditCards,
    cards,
  };
}
