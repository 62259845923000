import { useEffect, useRef } from 'react';

export default function useSlider({
  allRefs,
  scrollContainerRef,
  activeTabID,
  setActiveTabID,
  marginTop = 0,
  rootMargin,
  isOrderAgainAvailable,
  orderStage,
}) {
  const timer = useRef(null);
  const observerActive = useRef(true);

  useEffect(() => {
    if (allRefs.length === 0 || !allRefs?.[0]?.current || orderStage !== 0)
      return;

    const root = scrollContainerRef?.current || window?.body;

    const settings = {
      root,
      rootMargin,
      thresholds: [0],
    };

    const indexAddition = isOrderAgainAvailable ? 0 : 1;
    let prevIndex = activeTabID ?? 0 + indexAddition;

    const observer = new IntersectionObserver((entries) => {
      if (!observerActive.current) return;
      outer: for (let e = 0; e < entries.length; e++) {
        const entry = entries[e];
        for (let i = 0; i < allRefs.length; i++) {
          const ref = allRefs[i];
          if (entry.target === ref?.current) {
            if (
              !entry.isIntersecting &&
              entry.rootBounds &&
              entry.rootBounds.top >= entry.boundingClientRect.top
            ) {
              const index = i + indexAddition;
              setActiveTabID(index);
              prevIndex = index;
            } else if (i > 0 && entry.isIntersecting && entry.rootBounds) {
              const index =
                i === prevIndex ||
                entry.rootBounds.bottom >= entry.boundingClientRect.bottom
                  ? i - 1 + indexAddition
                  : prevIndex + indexAddition;
              setActiveTabID(index);
              prevIndex = index;
            }

            break outer;
          }
        }
      }
    }, settings);

    scrollToCategory(prevIndex);
    allRefs.forEach((ref) => {
      if (ref.current) observer.observe(ref.current);
    });

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollContainerRef, allRefs, orderStage]);

  useEffect(() => {
    const handlePageReload = () => {
      const root = scrollContainerRef?.current || window;
      root.scrollTo({
        top: 0,
        behavior: 'auto',
      });
    };

    // Reset the scroll position to the top when the page is reloaded
    window.addEventListener('beforeunload', handlePageReload);

    return () => {
      window.removeEventListener('beforeunload', handlePageReload);
    };
  }, [scrollContainerRef]);

  const scrollToCategory = (index) => {
    setActiveTabID(index);
    clearTimeout(timer.current);
    observerActive.current = false;

    let refIndex = isOrderAgainAvailable ? index : index - 1;
    if (refIndex < 0) refIndex = 0;
    const node = allRefs[refIndex]?.current;
    const top = (node?.offsetTop ?? 0) + marginTop;
    const root = scrollContainerRef?.current || window;

    root.scrollTo({
      top,
      behavior: index === activeTabID ? 'instant' : 'smooth',
    });

    timer.current = setTimeout(() => {
      observerActive.current = true;
    }, 1000);
  };

  return scrollToCategory;
}
