import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Swiper from 'react-id-swiper';
import { findDOMNode } from 'react-dom';
import debounce from 'lodash/debounce';
import { setNotes } from 'containers/Home/actions';

import Select from '../../components/Select';
import { getOfferUnlockedMessage } from '../../helpers/getOfferUnlockedMessage';
import { fetchVoucher, validateVoucher } from './voucherHelper';
import { selectStoreConfig } from '../../containers/Layout/selectors';
import { firebaseAnalyticsNO } from '../../firebase';
import { eventNames } from 'configuration/eventNames';
import titleCase from 'helpers/titleCase';
import {
  MENU_ORDER_TYPE,
  MENU_ORDER_TYPE_MAPPINGS,
  ORDER_STAGE_TYPES,
} from 'helpers/enums';
import { BackButtonCWUI, OrderUI } from '@next-order/next-order-components';
import { ArrowRightIcon } from 'nexticons/outline';
import classNames from 'classnames';
import { canSkipPaymentMethod } from 'helpers/canSkipPaymentMethod';

class OrderReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vCode: '',
      apartment: '',
      timeSlot: false,
      selectedTime: '',
      widthS: 0,
      leftS: 15,
      active: 0,
      addNotes: false,
      orderNotes: '',
      focused: false,
      keyBoard: false,
    };
    this.set = 0;
    this.params = {
      slidesPerView: 'auto',
      spaceBetween: 0,
      freeMode: true,
      observer: true,
    };
    this.params2 = {
      speed: 500,
      slidesPerView: 1,
      spaceBetween: 0,
      autoHeight: true,
      observer: true,
      on: {
        slideChange: this.slideChange,
      },
    };

    this.reviewBarMRef = React.createRef(null);
    this.totalBottomRef = React.createRef(null);
  }

  componentDidMount() {
    if (
      this.props.currentOrder.unit &&
      this.props.currentOrder.unit.length > 0
    ) {
      this.setState({ apartment: this.props.currentOrder.unit });
    }
    window.addEventListener('click', this.handleOutSideTime.bind(this));
    if (
      this.props.currentOrder.notes &&
      this.props.currentOrder.notes.length > 0
    ) {
      this.setState({ orderNotes: this.props.currentOrder.notes });
    }
    window.navigator.userAgent.match(/android/i) &&
      window.addEventListener(
        'resize',
        this.onElementHeightChange.bind(this, window.innerHeight)
      );

    this.calculateReviewboxHeight();
    this.receiveProps(this.props);
  }

  componentDidUpdate() {
    setTimeout(() => {
      this.calculateReviewboxHeight();
    }, 500);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.receiveProps(props);
  }

  componentWillUnmount() {
    window.navigator.userAgent.match(/android/i) &&
      window.removeEventListener(
        'resize',
        this.onElementHeightChange.bind(this)
      );
    window.removeEventListener('click', this.handleOutSideTime.bind(this));
  }

  receiveProps = (props) => {
    //Item groupings for review order
    this.getItemGroupingsForReviewOrder(props.currentOrder);

    //Set Voucher if already defined
    if (
      this.props.currentOrder &&
      this.props.currentOrder.voucherName &&
      this.props.currentOrder.voucherName !== '' &&
      this.set === 0
    ) {
      this.setState({ vCode: this.props.currentOrder.voucherName });
      this.set = 1;
    } else {
      this.setState({
        vCode: '',
        selectedVoucher: '',
        voucherState: '',
        voucherErrorMessage: undefined,
      });
      this.set = 0;
    }
    if (
      this.state.addNotes !== false &&
      this.props.history &&
      this.props.history.action === 'POP'
    ) {
      this.closeNotes(true);
    }
    if (props?.currentOrder?.notes !== this.state.orderNotes) {
      this.setState({ orderNotes: props.currentOrder.notes });
    }
    this.updateSelectedTimeIfItIsInPast(
      props.currentOrder,
      props.currentOrder.orderType === MENU_ORDER_TYPE.delivery
        ? props.deliveryTimes
        : props.pickUpTimes
    );
  };

  calculateReviewboxHeight = () => {
    let target_element = document.getElementsByClassName('reviewOrderWrap')[0];
    const interim = this.reviewBarMRef?.current?.offsetHeight || 0;
    if (document.querySelector('.reviewOrderWrap')) {
      let elem_height =
        window.innerHeight -
        document.querySelector('.reviewOrderWrap').offsetTop -
        this.totalBottomRef?.current?.clientHeight -
        interim;

      target_element.style.height = elem_height + 'px';
    }
  };

  updateSelectedTimeIfItIsInPast = (order, availableTimes) => {
    const { storeConfig } = this.props;
    if (
      order &&
      order.deliveryDate &&
      availableTimes &&
      this.props.orderSetup
    ) {
      let today = moment(
        moment().tz(storeConfig.timeZone).format('YYYY-MM-DD HH:mm'),
        'YYYY-MM-DD HH:mm'
      );
      if (order.orderType === MENU_ORDER_TYPE.pickup)
        today.add(
          this.props.orderSetup && this.props.orderSetup.pickUpTime
            ? Number(this.props.orderSetup.pickUpTime)
            : 0,
          'minutes'
        );
      else
        today.add(
          this.props.orderSetup && this.props.orderSetup.deliveryTime
            ? Number(this.props.orderSetup.deliveryTime)
            : 0,
          'minutes'
        );
      let delDate = moment(order.deliveryDate, 'YYYY-MM-DD HH:mm');

      //Detect if update required
      if (today.isAfter(delDate)) {
        if (
          availableTimes.length > 0 &&
          availableTimes[0].times &&
          availableTimes[0].times.length > 0
        ) {
          let timeObj = availableTimes[0].times[0];
          let deliveryDate = moment(
            `${timeObj.timeObjFinal} ${timeObj.displayForUse}`,
            'YYYY-MM-DD HH:mm A'
          );
          if (deliveryDate.isAfter(today)) {
            this.props.updateDeliveryPickupTime(
              deliveryDate,
              timeObj.shiftId,
              false,
              0,
              false
            );
          } else {
            this.props.updateDeliveryPickupTime(
              today,
              timeObj.shiftId,
              false,
              0,
              false
            );
          }
        } else {
          // No time slot available
        }
      }
    }
  };

  onElementHeightChange = (height) => {
    let lastHeight = height;
    let newHeight = window.innerHeight;
    if (lastHeight - newHeight > 125) {
      this.setState({ keyBoard: true, focused: true });
    } else {
      if (this.state.keyBoard === true && lastHeight - newHeight === 0) {
        this.setState({ keyBoard: false, focused: false });
      }
    }
  };

  slideChange = () => {
    let timeValue =
      this.props.pickUpTimes[this.swiper.activeIndex].times[0].displayForUse;
    let timeObj = [];
    if (this.props.currentOrder.orderType === MENU_ORDER_TYPE.pickup) {
      timeObj = this.props.pickUpTimes[this.swiper.activeIndex].times.filter(
        (r) => {
          return r.displayForUse === timeValue;
        }
      );
    } else if (this.props.currentOrder.orderType === MENU_ORDER_TYPE.delivery) {
      timeObj = this.props.deliveryTimes[this.swiper.activeIndex].times.filter(
        (r) => {
          return r.displayForUse === timeValue;
        }
      );
    }
    if (timeObj.length > 0) {
      let deliveryDate = moment(
        `${timeObj[0].timeObjFinal} ${timeObj[0].displayForUse}`,
        'YYYY-MM-DD HH:mm A'
      );
      let isFutureDeliveryDate =
        timeObj[0].alternateDisplay === 'ASAP' ? false : true;
      let shiftIdLater = timeObj.length > 0 ? timeObj[0].shiftId : '';
      this.setState({
        selectedTime: timeValue,
        deliveryDateLater: deliveryDate,
        isFutureDeliveryDate,
        shiftIdLater,
      });
    }

    if (
      this.refs[this.swiper.activeIndex + 'LA'].offsetLeft +
        this.refs[this.swiper.activeIndex + 'LA'].offsetWidth >
      window.innerWidth
    ) {
      this.swiper1.setTranslate(
        window.innerWidth -
          (this.refs[this.swiper.activeIndex + 'LA'].offsetLeft +
            this.refs[this.swiper.activeIndex + 'LA'].offsetWidth) -
          15
      );
    } else {
      this.swiper1.setTranslate(0);
    }
    this.setState({
      active: this.swiper.activeIndex,
      widthS: this.refs[this.swiper.activeIndex + 'LA'].offsetWidth,
      leftS: this.refs[this.swiper.activeIndex + 'LA'].offsetLeft,
    });
  };

  changeNotes = (e) => {
    let defineString;
    if (e.target.value.length > 0) {
      let character = e.target.value.charAt(0);
      if (character !== character.toUpperCase()) {
        defineString = titleCase(e.target.value);
      } else {
        defineString = e.target.value;
      }
    }
    this.setState({ orderNotes: defineString });
  };

  submitNotes = () => {
    window.navigator.userAgent.match(/android/i) && this.refs.addNotes.blur();
    this.setState({ addNotes: false });
    this.props.setNotes(this.state.orderNotes);
  };

  handleOutSideTime = (e) => {
    const aNode = findDOMNode(this.refs.timeSlot1);
    if (aNode && aNode.classList.contains('active')) {
      if (!aNode || !aNode.contains(e.target)) {
        this.setState({ timeSlot: false });
      }
    }
  };

  getItemGroupingsForReviewOrder = (currentOrder) => {
    if (!currentOrder || !currentOrder.menuItems) return;

    let selectedOrder = JSON.parse(JSON.stringify(currentOrder));
    let items = [];
    selectedOrder.menuItems.forEach((item) => {
      if (item.isHalf) {
        let betterHalfs = currentOrder.menuItems.filter((obj) => {
          return obj.halfIndex === item.halfIndex && obj._id !== item._id;
        });
        let xBetterHalf = items.filter((obj) => {
          return obj._idHalf === item._id;
        });
        if (betterHalfs && betterHalfs.length > 0 && xBetterHalf.length === 0) {
          item.urlHalf = betterHalfs[0].urlS3;
          item._idHalf = betterHalfs[0]._id;
          item.nameHalf = betterHalfs[0].name;
          item.itemTypeHalf = betterHalfs[0].itemType;
          item.baseModifierIdHalf = betterHalfs[0].baseModifierId;
          item.selectedSizesHalf = betterHalfs[0].selectedSizes;
          item.selectedModifiersHalf = betterHalfs[0].selectedModifiers;
          item.removedIngredientsHalf = betterHalfs[0].removedIngredients;
          item.selectedIngredientsHalf = betterHalfs[0].selectedIngredients;
          item.selectedExtraIngredientsHalf =
            betterHalfs[0].selectedExtraIngredients;
          items.push(item);
        }
      } else {
        items.push(item);
      }
      return item;
    });
    this.setState({ menuItems: items });
  };

  changeUnit = (e) => {
    this.setState({ apartment: e.target.value }, () => {
      this.props.saveUnit(this.state.apartment);
    });
  };

  keyPress13 = (e) => {
    if (e.keyCode === 13) {
      this.refs.flatRef.blur();
    }
  };

  getDiscountedItemsDescription = (voucher) => {
    if (voucher.type === '3' && voucher.selectedCategories) {
      let string = '';
      voucher.selectedCategories.forEach((cat, i) => {
        i === 0 ? (string = cat.name) : (string = `${string}, ${cat.name}`);
        string = `${string} (${cat.menuSizes
          .filter((s) => s.isActive)
          .map((s) => s.name)
          .join(',')})`;
        return cat;
      });
      return string;
    } else return 'N/A';
  };

  onVoucherChange = (e) => {
    e.persist();
    this.setState({
      vCode: e.target.value,
      voucherState: 'loading',
      isVoucherTrue: false,
    });
    this.fetchVoucher(e.target.value);
  };

  fetchVoucher = debounce((text) => {
    const { storeConfig } = this.props;
    let validateString = text.toUpperCase();
    if (validateString.length >= 3) {
      fetchVoucher(storeConfig, {
        storeId: storeConfig.storeId,
        voucherCode: validateString,
      }).then((result) => {
        if (result.success === true) {
          this.setState({
            isVoucherTrue: true,
          });
          let selectedVoucher = result.voucher;
          let response = validateVoucher(
            storeConfig,
            selectedVoucher,
            this.props.currentOrder,
            this.props.currentOrder.payableAmount,
            this.props.userDetails
          );
          if (response.success) {
            if (selectedVoucher.type !== '3') {
              selectedVoucher.voucherDiscount = Number(
                this.getDiscountedAmount(selectedVoucher)
              ).toFixed(2);
              this.setState(
                {
                  voucherState: 'match',
                  selectedVoucher,
                },
                () => {
                  this.updateVoucher(selectedVoucher);
                }
              );
            } else {
              let discountObj = this.props.getDiscountedAmount(selectedVoucher);
              if (discountObj.discount !== 0) {
                selectedVoucher.voucherDiscount = Number(
                  discountObj.discount
                ).toFixed(2);

                this.setState(
                  {
                    voucherState: 'match',
                    selectedVoucher,
                  },
                  () => {
                    this.updateVoucher(selectedVoucher);
                  }
                );
              } else {
                // Case When Voucher is Expired
                this.setState({
                  voucherState: 'nomatch',
                  selectedVoucher,
                  voucherErrorMessage: `Discounted items for voucher are not present in cart or already discounted with a special`,
                });
              }
            }
          } else {
            this.setState({
              voucherState: 'nomatch',
              selectedVoucher: selectedVoucher,
              voucherErrorMessage: response.voucherErrorMessage,
            });
          }
        } else {
          if (this.state.isVoucherTrue !== true) {
            this.setState({
              voucherState: 'nomatch',
              selectedVoucher: {},
              voucherErrorMessage: '',
            });
          }
        }
      });
    } else {
      this.setState({
        voucherState: '',
        voucherErrorMessage: '',
        isVoucherTrue: false,
      });
    }
  }, 300);

  getItemCustomizedPrice = (itemObj, sizeObj) => {
    const { halfHalfSetup } = this.props;
    let itemPrice = 0;
    let quantity = 0;
    let halfQuantity = 0;
    let extraPrice = 0;
    let extraPriceHalf = 0;
    let currentItem = JSON.parse(JSON.stringify(itemObj));
    if (!currentItem.itemType || currentItem.itemType === 0) {
      currentItem.selectedSizes?.forEach((item) => {
        if (item._id === sizeObj._id) {
          if (item.quantity > 0) {
            quantity = quantity + item.quantity;
            itemPrice = itemPrice + item.quantity * Number(item.price);
          }

          item.variants?.forEach((v) => {
            if (v.quantity && Number(v.quantity) > 0)
              itemPrice = itemPrice + item.quantity * Number(v.price);
          });
        }
      });
      if (quantity > 0) {
        currentItem.selectedExtraIngredients?.forEach((item) => {
          if (item.quantity > 0) {
            extraPrice =
              extraPrice + Number(item.quantity) * Number(item.price);
          }
        });
        itemPrice =
          extraPrice *
            (halfHalfSetup?.chargeFullHalfExtraPrice
              ? Math.max(quantity, 1)
              : quantity) +
          itemPrice;
      }
    } else {
      currentItem.selectedModifiers?.forEach((m) => {
        m.subModifiers.forEach((s) => {
          if (m._id === currentItem.baseModifierId) {
            quantity = s.quantity;
            itemPrice = itemPrice + Number(s.quantity) * Number(s.price);

            s.variants?.forEach((v) => {
              itemPrice = itemPrice + Number(v.quantity) * Number(v.price);
            });
          } else {
            s.prices?.forEach((p) => {
              itemPrice = itemPrice + Number(p.quantity) * Number(p.price);

              p.variants?.forEach((v) => {
                itemPrice = itemPrice + Number(v.quantity) * Number(v.price);
              });
            });
          }
        });
      });
      if (quantity > 0) {
        currentItem.selectedExtraIngredients?.forEach((item) => {
          if (item.quantity > 0) {
            extraPrice =
              extraPrice + Number(item.quantity) * Number(item.price);
          }
        });
        itemPrice =
          extraPrice *
            (halfHalfSetup?.chargeFullHalfExtraPrice
              ? Math.max(quantity, 1)
              : quantity) +
          itemPrice;
      }
    }
    if (
      currentItem.isHalf &&
      (!currentItem.itemTypeHalf || currentItem.itemTypeHalf === 0)
    ) {
      currentItem.selectedSizesHalf?.forEach((item) => {
        if (item.quantity > 0) {
          halfQuantity = halfQuantity + item.quantity;
          itemPrice = itemPrice + item.quantity * Number(item.price);
        }

        item.variants?.forEach((v) => {
          if (v.quantity && Number(v.quantity) > 0)
            itemPrice = itemPrice + item.quantity * Number(v.price);
        });
      });
      if (halfQuantity > 0) {
        currentItem.selectedExtraIngredientsHalf?.forEach((item) => {
          if (item.quantity > 0) {
            extraPriceHalf = extraPriceHalf + item.quantity * item.price;
          }
        });
        itemPrice = extraPriceHalf * halfQuantity + itemPrice;
      }
    } else if (currentItem.isHalf && currentItem.itemTypeHalf === 1) {
      currentItem.selectedModifiersHalf?.forEach((m) => {
        m.subModifiers.forEach((s) => {
          if (m._id === currentItem.baseModifierIdHalf) {
            halfQuantity = s.quantity;
            itemPrice = itemPrice + Number(s.quantity) * Number(s.price);

            s.variants?.forEach((v) => {
              itemPrice = itemPrice + Number(v.quantity) * Number(v.price);
            });
          } else {
            s.prices?.forEach((p) => {
              itemPrice = itemPrice + Number(p.quantity) * Number(p.price);

              p.variants?.forEach((v) => {
                itemPrice = itemPrice + Number(v.quantity) * Number(v.price);
              });
            });
          }
        });
      });
      if (halfQuantity > 0) {
        currentItem.selectedExtraIngredientsHalf?.forEach((item) => {
          if (item.quantity > 0) {
            extraPriceHalf = extraPriceHalf + item.quantity * item.price;
          }
        });
        itemPrice = extraPriceHalf * halfQuantity + itemPrice;
      }
    }
    if (currentItem.isHalf)
      itemPrice =
        itemPrice +
        (this.props.halfHalfSetup
          ? Number(this.props.halfHalfSetup.halfHalfAdditionalCharges)
          : 0) *
          (quantity + halfQuantity);
    return itemPrice.toFixed(2);
  };

  clearVoucher = () => {
    this.setState({ voucherState: '', vCode: '' });
  };

  getDiscountedAmount = (selectedVoucher) => {
    if (this.props.currentOrder && selectedVoucher) {
      if (selectedVoucher.voucherType === '1')
        if (
          this.props.currentOrder.voucherId &&
          this.props.currentOrder.voucherDiscount
        ) {
          let dscAmt = (
            ((Number(this.props.currentOrder.payableAmount) +
              Number(this.props.currentOrder.voucherDiscount)) *
              Number(selectedVoucher.discount)) /
            100
          ).toFixed(2);
          if (
            Number(this.props.currentOrder.payableAmount) +
              Number(this.props.currentOrder.voucherDiscount) >
            dscAmt
          )
            return dscAmt;
          else
            return (
              Number(this.props.currentOrder.payableAmount) +
              Number(this.props.currentOrder.voucherDiscount)
            );
        } else {
          let dscAmt = (
            (Number(this.props.currentOrder.payableAmount) *
              Number(selectedVoucher.discount)) /
            100
          ).toFixed(2);
          if (this.props.currentOrder.payableAmount > dscAmt) return dscAmt;
          else return this.props.currentOrder.payableAmount;
        }
      else {
        if (
          this.props.currentOrder.voucherId &&
          this.props.currentOrder.voucherDiscount
        ) {
          let dscAmt = selectedVoucher.discount;
          if (
            Number(this.props.currentOrder.payableAmount) +
              Number(this.props.currentOrder.voucherDiscount) >
            dscAmt
          )
            return dscAmt;
          else
            return (
              Number(this.props.currentOrder.payableAmount) +
              Number(this.props.currentOrder.voucherDiscount)
            );
        } else {
          let dscAmt = Number(selectedVoucher.discount);
          if (this.props.currentOrder.payableAmount > dscAmt) return dscAmt;
          else return this.props.currentOrder.payableAmount;
        }
      }
    }
    return 0;
  };

  openNotes = () => {
    this.setState({ addNotes: true });
    window.navigator.userAgent.match(/android/i) && this.refs.addNotes.focus();
    this.props.history.push('/add-notes');
  };

  closeNotes = () => {
    window.navigator.userAgent.match(/android/i) && this.refs.addNotes.blur();
    this.setState({ addNotes: false });
  };

  addVoucher = () => {
    this.setState({
      voucher: !this.state.voucher,
      voucherErrorMessage: '',
      vCode: '',
      voucherState: '',
    });
    this.set = 0;
    this.refs.voucherRef.focus();
  };

  enter = (e) => {
    if (e.keyCode === 13 && this.state.voucherState === 'match') {
      this.props.updateVoucher(this.state.selectedVoucher);
      this.setState({ voucher: false, voucherState: '' });
    }
  };

  sliderGo = (e, n) => {
    this.setState({
      widthS: e.currentTarget.offsetWidth,
      leftS: e.currentTarget.offsetLeft,
      active: n,
    });
    if (this.swiper) {
      this.swiper.slideTo(n);
    }
  };

  handleChange = (timeObj) => {
    let deliveryDate = moment(
      `${timeObj.timeObjFinal} ${timeObj.displayForUse}`,
      'YYYY-MM-DD HH:mm A'
    );
    if (deliveryDate !== this.state.deliveryDateLater) {
      this.setState({
        selectedTime: timeObj.displayForUse,
        deliveryDateLater: deliveryDate,
        isFutureDeliveryDate:
          timeObj.alternateDisplay === 'ASAP' ? false : true,
        shiftIdLater: timeObj.shiftId || '',
      });
    }
  };

  openTimeBar = (e) => {
    let timeG = '';
    if (
      this.props.pickUpTimes &&
      this.props.pickUpTimes.length > 0 &&
      this.props.deliveryTimes &&
      this.props.deliveryTimes.length > 0 &&
      this.state.active
    ) {
      if (
        this.props.currentOrder &&
        this.props.currentOrder.orderType === MENU_ORDER_TYPE.pickup
      )
        timeG =
          this.props.pickUpTimes[this.state.active].times[0].displayForUse;
      else if (
        this.props.currentOrder &&
        this.props.currentOrder.orderType === MENU_ORDER_TYPE.delivery
      )
        timeG =
          this.props.deliveryTimes[this.state.active].times[0].displayForUse;

      if (this.state.active !== 0) {
        this.setState({
          widthS: this.refs[this.state.active + 'LA'].offsetWidth,
          timeSlot: true,
          selectedTime: timeG,
        });
      } else if (this.refs[0 + 'LA'] !== undefined) {
        this.setState({
          widthS: this.refs[0 + 'LA'].offsetWidth,
          timeSlot: true,
          selectedTime: timeG,
        });
      }
      e.stopPropagation();
    }
  };

  setDeliveryTime = (e) => {
    this.setState({ timeSlot: false }, () => {
      if (this.state.deliveryDateLater && this.state.shiftIdLater)
        this.props.updateDeliveryPickupTime(
          this.state.deliveryDateLater,
          this.state.shiftIdLater,
          false,
          0,
          this.state.isFutureDeliveryDate
        );
    });
    e.stopPropagation();
  };

  getTimeToDisplay = () => {
    const { storeConfig } = this.props;
    if (this.props.currentOrder && this.props.currentOrder.deliveryDate) {
      let today = moment(
        moment().tz(storeConfig.timeZone).format('YYYY-MM-DD hh:mm A'),
        'YYYY-MM-DD hh:mm a'
      );
      let delDate = moment(
        this.props.currentOrder.deliveryDate,
        'YYYY-MM-DD HH:mm'
      );
      if (delDate.isSame(today, 'day')) {
        return `${moment(
          this.props.currentOrder.deliveryDate,
          'YYYY-MM-DD HH:mm'
        ).format('hh:mm a')}`;
      } else if (delDate.isSame(today.add(1, 'day'), 'day')) {
        return `Tmr, ${moment(
          this.props.currentOrder.deliveryDate,
          'YYYY-MM-DD HH:mm'
        ).format('hh:mm a')}`;
      } else
        return moment(
          this.props.currentOrder.deliveryDate,
          'YYYY-MM-DD HH:mm'
        ).format('ddd, hh:mm a');
    } else return 'N/A';
  };

  updateVoucher = (voucher) => {
    this.setState({ voucher: false, voucherState: '' }, () => {
      this.props.updateVoucher(voucher);
    });
  };

  focusReview = () => {
    this.setState({ focused: true });
  };

  blurReview = () => {
    this.setState({ focused: false, keyBoard: false });
  };

  updateOrderStageHandler = () => {
    if (
      this.props.currentOrder.isAllowToConfirm &&
      !this.props.isSoldOutPresent &&
      !this.props.isOutOfSchedulePresent &&
      !this.props.isOtherThanSelectedOrderTypePresent
    ) {
      const canSkipPaymentScreen = canSkipPaymentMethod({
        orderSetup: this.props.orderSetup,
        currentOrder: this.props.currentOrder,
      });

      if (canSkipPaymentScreen) {
        this.props.updateOrderStage(ORDER_STAGE_TYPES.PAYMENT_CARD);
      } else {
        this.props.updateOrderStage(ORDER_STAGE_TYPES.PAYMENT);
      }
    }
  };
  render() {
    const { storeConfig, sidebarAnimationClass } = this.props;

    const isDisabled =
      this.props.isOutOfSchedulePresent ||
      this.props.isOtherThanSelectedOrderTypePresent ||
      this.props.isSoldOutPresent ||
      !this.props.currentOrder.isAllowToConfirm;

    return (
      <div
        className={
          `${sidebarAnimationClass} detailsConfirm detailFinal active specialsA voucher true1`
          // this.props.currentOrder.specials &&
          // this.props.currentOrder.specials.length > 0
          //   ? this.props.currentOrder.voucherId
          //     ? this.state.addNotes
          //       ? 'detailsConfirm detailFinal active specialsA voucher true1'
          //       : 'detailsConfirm detailFinal active specialsA voucher'
          //     : this.state.addNotes
          //     ? 'detailsConfirm detailFinal active specialsA true1'
          //     : 'detailsConfirm detailFinal active specialsA'
          //   : this.props.currentOrder.voucherId
          //   ? this.state.addNotes
          //     ? 'detailsConfirm detailFinal active voucher true1'
          //     : 'detailsConfirm detailFinal active voucher'
          //   : this.state.addNotes
          //   ? 'detailsConfirm detailFinal active true1'
          //   : 'detailsConfirm detailFinal active'
        }
      >
        <div className='fixedDiv'>
          <BackButtonCWUI onClick={this.props.updateOrderStageBack} />

          <div
            className='detailsTitle'
            style={{ marginTop: 0, display: 'flex', paddingRight: 0 }}
          >
            <div style={{ display: 'inline-block', flexGrow: 1 }}>
              {this.state.voucher ? 'Enter Voucher' : 'Review'}
            </div>
            {/* <span
              style={{ height: 25 }}
              className={this.state.voucher ? 'active voucher' : 'voucher'}
              onClick={this.addVoucher.bind(this)}
            >
              {this.props.currentOrder.voucherId &&
              this.props.currentOrder.voucherId !== '' ? (
                <img
                  src='https://pics.nextorder.co/assets/tick+(1).svg'
                  alt=''
                />
              ) : (
                <img
                  src='https://pics.nextorder.co/assets/plus-white.svg'
                  className='plus'
                  alt=''
                />
              )}
              <strong>{this.state.voucher ? 'Cancel' : 'Voucher'}</strong>
            </span> */}
          </div>
          <div
            className={this.state.voucher ? 'voucherBox active' : 'voucherBox'}
          >
            <input
              ref='voucherRef'
              autoComplete='off'
              onKeyUp={(e) => this.enter(e)}
              className={
                this.props.currentOrder.voucherId &&
                this.props.currentOrder.voucherId !== '' &&
                this.state.vCode === this.props.currentOrder.voucherName
                  ? `voucherInp match`
                  : `voucherInp ${this.state.voucherState} `
              }
              name='voucher'
              onChange={this.onVoucherChange}
              value={this.state.vCode}
            />
            <span>
              {this.state.voucherState === 'loading' ? (
                <img
                  src='https://pics.nextorder.co/assets/loading.svg'
                  alt=''
                />
              ) : (
                this.state.voucherState === 'nomatch' && (
                  <i
                    onClick={() => this.clearVoucher()}
                    className='thickcon-X'
                  />
                )
              )}
            </span>
          </div>
          {/* {this.state.selectedVoucher &&
              (!this.state.voucherErrorMessage ? (
                <div>
                  <p>
                    {this.state.selectedVoucher.voucherType === '1'
                      ? `${
                          this.state.selectedVoucher.discount
                        }% off your order!`
                      : `${storeConfig.currency}${
                          this.state.selectedVoucher.discount
                        } off your order!`}
                    <span>{`Save ${storeConfig.currency}${
                      this.state.selectedVoucher.discount
                    }`}</span>
                  </p>
                  <span
                    onClick={() =>
                      this.updateVoucher(this.state.selectedVoucher)
                    }
                    className='sucBtn'
                  >
                    Apply
                  </span>
                </div>
              ) : (
                <div>
                  <p>
                    <span>{this.state.voucherErrorMessage}</span>
                  </p>
                </div>
              ))} */}
          {this.state.voucherState === 'nomatch' &&
          this.state.voucherErrorMessage ? (
            <div className='codeSuccess match'>
              <p>
                <span>{this.state.voucherErrorMessage}</span>
              </p>
            </div>
          ) : (
            <div className={'codeSuccess'} />
          )}
          {this.props.currentOrder &&
            this.props.currentOrder.orderType === MENU_ORDER_TYPE.delivery && (
              <div
                className={
                  this.state.focused
                    ? 'visible-xs visible-sm addressMobileBar foucusedT'
                    : 'visible-xs visible-sm addressMobileBar'
                }
              >
                <div className='addB1'>
                  <i className='icon-Location-Pin' />
                  {this.props.currentOrder.address}
                </div>
                <div className='addB2'>
                  <i className='icon-Home' />
                  <input
                    type='text'
                    maxLength='140'
                    ref='flatRef'
                    id='apartment'
                    className='text-base'
                    name='flat'
                    onKeyUp={(e) => this.keyPress13(e)}
                    placeholder='Unit / Apartment / Suite Number'
                    onChange={(e) => this.changeUnit(e)}
                    onBlur={() => this.blurReview()}
                    onFocus={() => this.focusReview()}
                    value={this.state.apartment}
                  />
                </div>
              </div>
            )}
          {this.props.currentOrder &&
            this.props.currentOrder.orderType === MENU_ORDER_TYPE.pickup && (
              <div className='visible-xs visible-sm addressMobileBar'>
                <div className='addB1'>
                  <i className='icon-Store2' />
                  {storeConfig.name}
                </div>
                <div className='addB2 pick'>
                  <i className='icon-Location-Pin' />
                  {storeConfig.address}
                </div>
              </div>
            )}
          <div
            className={
              this.state.voucherState === 'match'
                ? 'reviewOrderWrap match'
                : 'reviewOrderWrap'
            }
          >
            {this.state.menuItems &&
              this.state.menuItems.map((item, index) => (
                <div key={index}>
                  {!item.isHalf &&
                    item.selectedSizes.map((size, index) => (
                      <div className='reviewItemO' key={index}>
                        <div className='revieLeft text-capitalize'>
                          {`${size.quantity} ${
                            !item.itemType || item.itemType === 0
                              ? `${size.name} ${item.name}`
                              : `${size.name}${
                                  size.cName && size.cName.length > 0
                                    ? `,${size.cName}`
                                    : ''
                                } ${item.name}`
                          } 
                          ${
                            size.variants
                              ? size.variants.filter((f) => f.quantity > 0)
                                  .length > 0
                                ? `(${size.variants
                                    .filter((f) => f.quantity > 0)
                                    .map((v) => v.name)
                                    .join(',')})`
                                : ''
                              : ''
                          } `}
                          {item.selectedExtraIngredients &&
                            item.selectedExtraIngredients.map(
                              (ingredient, i) => (
                                <span key={i}>{`Extra ${
                                  ingredient.name
                                    ? ingredient.name
                                    : ingredient.ingredientName
                                }`}</span>
                              )
                            )}
                          {item.removedIngredients &&
                            item.removedIngredients.map((ingredient, i) => (
                              <span key={i}>{`Remove ${
                                ingredient.name
                                  ? ingredient.name
                                  : ingredient.ingredientName
                              }`}</span>
                            ))}
                        </div>
                        <div className='revieRight'>{`${
                          storeConfig.currency
                        }${this.getItemCustomizedPrice(item, size)}`}</div>
                      </div>
                    ))}
                  {item.isHalf &&
                    item.selectedSizes.map((size, index) => (
                      <div key={index} className='reviewItemO'>
                        <div className='revieLeft text-capitalize'>
                          {`${size.quantity * 2} 
                          ${
                            !item.itemType || item.itemType === 0
                              ? `${size.name}`
                              : `${size.name} ${
                                  size.cName && size.cName.length > 0
                                    ? `,${size.cName}`
                                    : ''
                                }`
                          }
                          /
                          ${
                            !item.itemTypeHalf || item.itemTypeHalf === 0
                              ? `${item.selectedSizesHalf
                                  .filter((s) => Number(s.quantity) > 0)
                                  .map((m) => m.name)
                                  .join(' ')}`
                              : `${item.selectedSizesHalf
                                  .filter((s) => Number(s.quantity) > 0)
                                  .map((m) => m.name)
                                  .join(' ')} ${item.selectedSizesHalf
                                  .filter((s) => Number(s.quantity) > 0)
                                  .map((m) => m.cName)
                                  .join(' ')}`
                          } 
                          ${item.name}/${item.nameHalf} 
                          ${
                            size.variants
                              ? size.variants.filter((f) => f.quantity > 0)
                                  .length > 0
                                ? `(${size.variants
                                    .filter((f) => f.quantity > 0)
                                    .map((v) => v.name)
                                    .join(',')})`
                                : ''
                              : ''
                          } `}
                          <div>
                            {((item.selectedExtraIngredients &&
                              item.selectedExtraIngredients.length > 0) ||
                              (item.removedIngredients &&
                                item.removedIngredients.length > 0)) && (
                              <span className='halfItem'>{item.name}</span>
                            )}
                            {item.selectedExtraIngredients &&
                              item.selectedExtraIngredients.map(
                                (ingredient, i) => (
                                  <span key={i}>{`Extra ${
                                    ingredient.name
                                      ? ingredient.name
                                      : ingredient.ingredientName
                                  }`}</span>
                                )
                              )}
                            {item.removedIngredients &&
                              item.removedIngredients.map((ingredient, i) => (
                                <span key={i}>{`Remove ${
                                  ingredient.name
                                    ? ingredient.name
                                    : ingredient.ingredientName
                                }`}</span>
                              ))}
                          </div>
                          <div className='halfItem'>
                            {((item.selectedExtraIngredientsHalf &&
                              item.selectedExtraIngredientsHalf.length > 0) ||
                              (item.removedIngredientsHalf &&
                                item.removedIngredientsHalf.length > 0)) && (
                              <span>{item.nameHalf}</span>
                            )}
                            {item.selectedExtraIngredientsHalf &&
                              item.selectedExtraIngredientsHalf.map(
                                (ingredient, i) => (
                                  <span key={i}>{`Extra ${
                                    ingredient.name
                                      ? ingredient.name
                                      : ingredient.ingredientName
                                  }`}</span>
                                )
                              )}
                            {item.removedIngredientsHalf &&
                              item.removedIngredientsHalf.map(
                                (ingredient, i) => (
                                  <span key={i}>{`Remove ${
                                    ingredient.name
                                      ? ingredient.name
                                      : ingredient.ingredientName
                                  }`}</span>
                                )
                              )}
                          </div>
                        </div>
                        <div className='revieRight'>{`${
                          storeConfig.currency
                        }${this.getItemCustomizedPrice(item, size)}`}</div>
                      </div>
                    ))}
                </div>
              ))}
            {this.props.currentOrder.publicHolidaySurcharge &&
            Number(this.props.currentOrder.publicHolidaySurcharge) !== 0 ? (
              <div className='reviewItemO visible-xs visible-sm'>
                <div className='revieLeft text-capitalize'>
                  Public Holiday Surcharge
                  {`${
                    this.props.currentOrder &&
                    this.props.currentOrder.publicHolidaySurchargePer &&
                    Number(
                      this.props.currentOrder.publicHolidaySurchargePer
                    ) !== 0
                      ? `(${this.props.currentOrder.publicHolidaySurchargePer}%)`
                      : null
                  }`}
                </div>
                <div className='revieRight'>{`${storeConfig.currency}${this.props.currentOrder.publicHolidaySurcharge}`}</div>
              </div>
            ) : (
              <div />
            )}
            {this.props.currentOrder.rewards &&
              this.props.currentOrder.rewards.length > 0 && (
                <div className='reviewItemO visible-xs visible-sm'>
                  <div className='revieLeft text-capitalize'>Loyalty</div>
                  <div className='revieRight'>{`- ${storeConfig.currency}${this.props.currentOrder.loyaltyDiscount}`}</div>
                </div>
              )}
            {this.props.currentOrder.specials &&
              this.props.currentOrder.specials.length > 0 && (
                <div className='reviewItemO visible-xs visible-sm'>
                  <div className='revieLeft text-capitalize'>Specials</div>
                  <div className='revieRight'>{`- ${storeConfig.currency}${this.props.currentOrder.specialDiscount}`}</div>
                </div>
              )}
            {this.props.currentOrder.voucherId &&
              this.props.currentOrder.voucherId !== '' &&
              Number(this.props.currentOrder.voucherDiscount) !== 0 && (
                <div className='reviewItemO visible-xs visible-sm'>
                  <div className='revieLeft'>Voucher</div>
                  <div className='revieRight'>{`- ${storeConfig.currency}${this.props.currentOrder.voucherDiscount} `}</div>
                </div>
              )}
            {this.props.currentOrder.isDisplayGST &&
              !this.props.currentOrder.isPriceIncludesGST &&
              Number(this.props.currentOrder.gst) !== 0 && (
                <div className='reviewItemO visible-xs visible-sm'>
                  <div className='revieLeft'>GST</div>
                  <div className='revieRight'>{`${storeConfig.currency}${this.props.currentOrder.gst} `}</div>
                </div>
              )}
            {this.props.currentOrder.address &&
              this.props.currentOrder.deliveryCost &&
              this.props.currentOrder.deliveryCost > 0 && (
                <div className='reviewItemO'>
                  <div className='revieLeft'>Delivery Fee</div>
                  <div className='revieRight'>{`${storeConfig.currency}${Number(
                    this.props.currentOrder.deliveryCost
                  ).toFixed(2)} `}</div>
                </div>
              )}
          </div>
          <div
            className={classNames('visible-xs visible-sm reviewBarM', {
              message: this.props.currentOrder.isFreeItemsAvailable,
            })}
            ref={this.reviewBarMRef}
          >
            {!this.props.isSoldOutPresent &&
              !this.props.isOutOfSchedulePresent &&
              !this.props.isOtherThanSelectedOrderTypePresent && (
                <React.Fragment>
                  <div className='comM notesM' onClick={() => this.openNotes()}>
                    <i className='icon-Compose-Document' />
                    {this.state.orderNotes && this.state.orderNotes.length > 0
                      ? `${this.state.orderNotes.substring(0, 9)}...`
                      : 'Add Order Notes'}
                  </div>
                  <div
                    className='comM timeRM'
                    onClick={(e) => this.openTimeBar(e)}
                  >
                    <i className='icon-Clock' />
                    {this.getTimeToDisplay()}
                  </div>
                </React.Fragment>
              )}
          </div>
          <div
            className={
              this.state.addNotes &&
              !this.props.isSoldOutPresent &&
              !this.props.isOutOfSchedulePresent
                ? 'visible-xs visible-sm notesOverlay active'
                : 'visible-xs visible-sm notesOverlay'
            }
          >
            <BackButtonCWUI
              extendedClassName='absolute top-3.25 left-5 sm:top-3.5'
              onClick={() => {
                this.submitNotes();
                this.closeNotes();
              }}
            />
            <div className='detailsTitle' style={{ marginTop: 0 }}>
              Add Notes
            </div>
            <textarea
              ref='addNotes'
              name='addNotes'
              onChange={(e) => this.changeNotes(e)}
              value={this.state.orderNotes}
              placeholder='Type whatever you want to say...'
            />
            <div className='totalbottom'>
              <div className='totalInner'>
                <OrderUI.FooterButton
                  rightIcon={<ArrowRightIcon strokeWidth={3} width={16} />}
                  onClick={() => {
                    this.submitNotes();
                  }}
                >
                  Add Notes
                </OrderUI.FooterButton>
              </div>
            </div>
          </div>

          {this.props.currentOrder.orderType === MENU_ORDER_TYPE.delivery ? (
            <div
              ref='timeSlot1'
              className={
                this.state.timeSlot
                  ? 'visible-xs visible-sm timeSlots active'
                  : 'visible-xs visible-sm timeSlots'
              }
            >
              <div className='swiperWeek'>
                <Swiper
                  {...this.params}
                  ref={(node) => {
                    if (node) this.swiper1 = node.swiper;
                  }}
                >
                  {this.props.deliveryTimes &&
                    this.props.deliveryTimes.map((d, index) => (
                      <div
                        ref={index + 'LA'}
                        key={index}
                        onClick={(e) => this.sliderGo(e, Number(index))}
                        className={
                          this.state.active === Number(index)
                            ? 'active tabSUL'
                            : 'tabSUL'
                        }
                      >
                        {d.display}
                      </div>
                    ))}
                  <span
                    className='slidebarExtras'
                    style={{ width: this.state.widthS, left: this.state.leftS }}
                  />
                </Swiper>
              </div>
              <div className='swiperTime'>
                <Swiper
                  {...this.params2}
                  ref={(node) => {
                    if (node) this.swiper = node.swiper;
                  }}
                >
                  {this.props.deliveryTimes &&
                    this.props.deliveryTimes.map((item, index) => (
                      <div className='subTimeXS' key={index}>
                        <Select
                          handleChange={this.handleChange}
                          key={`picker${index}`}
                          data={item}
                          index={index}
                        />
                      </div>
                    ))}
                </Swiper>
              </div>
              <div
                className='selectTime'
                onClick={(e) => this.setDeliveryTime(e)}
              >
                Set Delivery Time
                <span>
                  {this.state.selectedTime !== ''
                    ? this.refs[this.state.active + 'LA'] &&
                      this.refs[this.state.active + 'LA'].textContent !==
                        'Today'
                      ? ` - ${
                          this.refs[this.state.active + 'LA'].textContent
                        }, ${this.state.selectedTime}`
                      : ` - ${this.state.selectedTime}`
                    : ''}
                </span>
              </div>
            </div>
          ) : (
            <div
              ref='timeSlot1'
              className={
                this.state.timeSlot
                  ? 'visible-xs visible-sm timeSlots active'
                  : 'visible-xs visible-sm timeSlots'
              }
            >
              <div className='swiperWeek'>
                <Swiper
                  {...this.params}
                  ref={(node) => {
                    if (node) this.swiper1 = node.swiper;
                  }}
                >
                  {this.props.pickUpTimes &&
                    this.props.pickUpTimes.map((d, index) => (
                      <div
                        ref={index + 'LA'}
                        key={index}
                        onClick={(e) => this.sliderGo(e, Number(index))}
                        className={
                          this.state.active === Number(index)
                            ? 'active tabSUL'
                            : 'tabSUL'
                        }
                      >
                        {d.display}
                      </div>
                    ))}
                  <span
                    className='slidebarExtras'
                    style={{ width: this.state.widthS, left: this.state.leftS }}
                  />
                </Swiper>
              </div>
              <div className='swiperTime'>
                <Swiper
                  {...this.params2}
                  ref={(node) => {
                    if (node) this.swiper = node.swiper;
                  }}
                >
                  {this.props.pickUpTimes &&
                    this.props.pickUpTimes.map((item, index) => (
                      <div className='subTimeXS' key={index}>
                        <Select
                          handleChange={this.handleChange}
                          key={`picker${index}`}
                          data={item}
                          index={index}
                        />
                      </div>
                    ))}
                </Swiper>
              </div>
              <div
                className='selectTime'
                onClick={(e) => this.setDeliveryTime(e)}
              >
                Set Pickup Time
                <span>
                  {this.state.selectedTime !== ''
                    ? this.refs[this.state.active + 'LA'].textContent !==
                      'Today'
                      ? ` - ${
                          this.refs[this.state.active + 'LA'].textContent
                        }, ${this.state.selectedTime}`
                      : ` - ${this.state.selectedTime}`
                    : ''}
                </span>
              </div>
            </div>
          )}
          <div
            className={
              this.state.timeSlot
                ? 'overlayTime visible-xs visible-sm active'
                : 'overlayTime visible-xs visible-sm'
            }
          />
          <div className='totalbottom' ref={this.totalBottomRef}>
            <div className='totalInner'>
              {this.props.isOutOfSchedulePresent && (
                <div
                  className='soldOutTxt spWrap'
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '14px',
                  }}
                >
                  <div style={{ marginRight: '10px' }}>
                    Sorry, some menu items are not available for the selected
                    order time.
                  </div>
                  <div
                    className='soldoutOk'
                    onClick={() => this.props.removeOutOfScheduleItems()}
                  >
                    <div className='soldoutOkContent'>Modify Cart</div>
                  </div>
                </div>
              )}
              {this.props.isOtherThanSelectedOrderTypePresent && (
                <div
                  className='soldOutTxt spWrap'
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '14px',
                  }}
                >
                  <div style={{ marginRight: '10px' }}>
                    Sorry, some menu items are not available for the{' '}
                    {
                      MENU_ORDER_TYPE_MAPPINGS[
                        Number(this.props.currentOrder.orderType)
                      ]
                    }{' '}
                    order.
                  </div>
                  <div
                    className='soldoutOk'
                    onClick={() =>
                      this.props.removeOtherThanSelectedOrderTypeItems()
                    }
                  >
                    <div className='soldoutOkContent'>Modify Cart</div>
                  </div>
                </div>
              )}
              {this.props.isSoldOutPresent && (
                <div
                  className='soldOutTxt spWrap'
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '14px',
                  }}
                >
                  <div style={{ marginRight: '10px' }}>
                    Sorry, some items have been marked as sold out.
                  </div>
                  <div
                    className='soldoutOk'
                    onClick={() => this.props.removeSoldOutItems()}
                  >
                    <div className='soldoutOkContent'>Modify Cart</div>
                  </div>
                </div>
              )}
              {this.props.currentOrder.isFreeItemsAvailable && (
                <div
                  className='soldOutTxt spWrap'
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '14px',
                  }}
                >
                  <div style={{ marginRight: '10px' }}>
                    {getOfferUnlockedMessage(
                      storeConfig,
                      this.props.currentOrder
                    )}
                  </div>
                </div>
              )}
              {this.props.currentOrder.publicHolidaySurcharge &&
              Number(this.props.currentOrder.publicHolidaySurcharge) !== 0 ? (
                <div className='spWrap'>
                  <span className='spName'>
                    Public Holiday Surcharge
                    {`${
                      this.props.currentOrder &&
                      this.props.currentOrder.publicHolidaySurchargePer &&
                      Number(
                        this.props.currentOrder.publicHolidaySurchargePer
                      ) !== 0
                        ? `(${this.props.currentOrder.publicHolidaySurchargePer}%)`
                        : null
                    }`}
                  </span>
                  <span className='spPrice'>{`${storeConfig.currency}${this.props.currentOrder.publicHolidaySurcharge}`}</span>
                </div>
              ) : (
                <div />
              )}
              {this.props.currentOrder.rewards &&
              this.props.currentOrder.rewards.length > 0 ? (
                <div className='spWrap'>
                  <span className='spName'>Loyalty</span>
                  <span className='spPrice'>{`- ${storeConfig.currency}${this.props.currentOrder.loyaltyDiscount}`}</span>
                </div>
              ) : (
                <div />
              )}
              {this.props.currentOrder.specials &&
              this.props.currentOrder.specials.length > 0 &&
              Number(this.props.currentOrder.specialDiscount) > 0 ? (
                <div className='spWrap'>
                  <span className='spName'>Specials</span>
                  <span className='spPrice'>{`- ${storeConfig.currency}${this.props.currentOrder.specialDiscount}`}</span>
                </div>
              ) : (
                <div />
              )}
              {this.props.currentOrder.voucherId &&
              this.props.currentOrder.voucherId !== '' &&
              Number(this.props.currentOrder.voucherDiscount) !== 0 ? (
                <div className='spWrap'>
                  <span className='spName'>Voucher</span>
                  <span className='spPrice'>{`- ${storeConfig.currency}${this.props.currentOrder.voucherDiscount} `}</span>
                </div>
              ) : (
                <div />
              )}
              {this.props.currentOrder.isDisplayGST &&
              !this.props.currentOrder.isPriceIncludesGST &&
              Number(this.props.currentOrder.gst) !== 0 ? (
                <div className='spWrap'>
                  <span className='spName'>GST</span>
                  <span className='spPrice'>{` ${storeConfig.currency}${this.props.currentOrder.gst} `}</span>
                </div>
              ) : (
                <div />
              )}
              <OrderUI.FooterButton
                rightIcon={<ArrowRightIcon strokeWidth={3} width={16} />}
                onClick={() => {
                  firebaseAnalyticsNO.logEvent(
                    eventNames.SELECTED_CONFIRM_ORDER
                  );
                  this.updateOrderStageHandler();
                  this.props.history.push('/payment');
                }}
                disabled={isDisabled}
              >
                {this.props.currentOrder &&
                this.props.currentOrder.payableAmount
                  ? `Proceed to payment ${storeConfig.currency}${this.props.currentOrder.payableAmount}`
                  : `Confirm Order ${storeConfig.currency}0.00`}
              </OrderUI.FooterButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OrderReview.propTypes = {
  currentOrder: PropTypes.any,
  updateOrderStage: PropTypes.any,
  allVouchers: PropTypes.any,
  updateVoucher: PropTypes.any,
  history: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    storeConfig: selectStoreConfig(state),
    halfHalfSetup: state.layoutReducer.halfHalfSetup,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setNotes: (notes) => dispatch(setNotes(notes)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderReview);
