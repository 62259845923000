import { parsePhoneNumber } from 'libphonenumber-js/max';

export const isNumberMobileAndValid = (number, countryIdentifier) => {
  const returnObj = {};
  try {
    const numberObj = parsePhoneNumber(number, countryIdentifier);
    if (numberObj) {
      if (numberObj.isValid()) {
        if (numberObj.getType() === 'MOBILE') {
          returnObj.number = numberObj.number;
        } else {
          returnObj.error = 'The number should be a mobile number';
        }
      } else {
        returnObj.error = 'Invalid Mobile Number';
      }
    }
    return returnObj;
  } catch (e) {
    if (number !== '' || number !== '0') {
      returnObj.error = 'Invalid Mobile Number';
      return { error: 'Invalid Mobile Number' };
    }
    return returnObj;
  }
};
