import { _firestore } from '../../firebase';
import { getTimestampStartOfTheDay } from '../../helpers/timeHelper';

function parseMenu(querySnapshot) {
  return querySnapshot.docs
    ? querySnapshot.docs.map((doc) => ({ _id: doc.id, ...doc.data() }))
    : [];
}

function resolveParseMenu(resolve) {
  return function (data) {
    resolve(parseMenu(data));
  };
}

export function getCloseDatesPromise(storeId, timeZone) {
  return new Promise((resolve, _reject) => {
    let timeStamp = getTimestampStartOfTheDay(timeZone);
    _firestore
      .collection('CloseDates')
      .where('storeId', '==', storeId)
      .where('closeDateTimestamp', '>=', timeStamp)
      .onSnapshot(resolveParseMenu(resolve));
  });
}

export function getAllSuburbsPromise(storeId) {
  return new Promise((resolve, _reject) => {
    _firestore
      .collection('Suburbs')
      .where('storeId', '==', storeId)
      .onSnapshot(resolveParseMenu(resolve));
  });
}

export function getAllShiftsPromise(storeId) {
  return new Promise((resolve, _reject) => {
    _firestore
      .collection('Shifts')
      .where('storeId', '==', storeId)
      .get()
      .then(resolveParseMenu(resolve));
  });
}

export function getIngredientCategoriesPromise(restaurantId) {
  return new Promise((resolve, _reject) => {
    _firestore
      .collection('IngredientCategories')
      .where('restaurantId', '==', restaurantId)
      .get()
      .then(resolveParseMenu(resolve));
  });
}

export function getUserLastOrderId(userId, restaurantId) {
  return async (dispatch) => {
    _firestore
      .collection('Orders')
      .where(
        'dateTimestamp',
        '>',
        '' + new Date(new Date().getTime() - 600000).getTime()
      )
      .where('userId', '==', userId)
      .where('restaurantId', '==', restaurantId)
      .get()
      .then((snapshot) => {
        let collection = [];
        snapshot.forEach((doc) => {
          const _id = doc.id;
          const data = doc.data();

          collection.push({ _id, ...data });
        });

        collection = collection.sort(
          (a, b) => new Date(b.dateTimestamp) - new Date(a.dateTimestamp)
        );

        if (collection.length > 0) {
          dispatch({ lastOrderId: collection[0]._id });
        } else {
          console.log('last order not found');
        }
      });
  };
}
