import { selectStoreConfig } from 'containers/Layout/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import HalfHalfViewProvider from './HalfHalfViewProvider';
import TableBookingViewProvider from './TableBookingViewProvider';
import UserLoginRegistrationProvider from './UserLoginRegistrationProvider';

export default function SidebarProviders({
  updateOrderStage,
  updateCurrentUser,
  initiateDesktopLoyaltyFlow,
  loyalty,
  halfView,
  children,
}) {
  const { orderSetup } = useSelector((state) => state.homeReducer);
  const storeConfig = useSelector(selectStoreConfig);
  return (
    <TableBookingViewProvider timeZone={storeConfig?.timeZone}>
      <HalfHalfViewProvider halfView={halfView}>
        <UserLoginRegistrationProvider
          updateOrderStage={updateOrderStage}
          updateCurrentUser={updateCurrentUser}
          initiateDesktopLoyaltyFlow={initiateDesktopLoyaltyFlow}
          loyalty={loyalty}
          orderSetup={orderSetup}
          storeConfig={storeConfig}
        >
          {children}
        </UserLoginRegistrationProvider>
      </HalfHalfViewProvider>
    </TableBookingViewProvider>
  );
}
