import { CloseButtonCWUI, TabsNavUI } from '@next-order/next-order-components';
import classNames from 'classnames';
import ButtonGroup from 'components/ButtonGroup';
import useSlider from 'helpers/hooks/useSlider';
import React, { createRef, useMemo, useRef, useState } from 'react';

function Ingredient({
  item,
  getExtraPrice,
  currency,
  decrementExtraItem,
  incrementExtrasItem,
}) {
  const extraPrice = getExtraPrice(item, true);
  if (extraPrice === 'na') {
    return null;
  }
  return (
    <li>
      <div className='text-capitalize'>
        {item?.name ?? item?.ingredientName}
      </div>
      <div>{Number(extraPrice) >= 0 && `${currency} ${extraPrice}`}</div>
      <ButtonGroup
        _id={item._id}
        disable={Number(extraPrice) < 0}
        operation='addExtra'
        maxQuantity='2'
        quantity={item?.quantity}
        clickedItem={item}
        decreaseBtnHandler={decrementExtraItem}
        increaseBtnHandler={incrementExtrasItem}
      />
    </li>
  );
}

const getFilteredIngredients = ({
  ingredients,
  itemType,
  sBaseSubModifier,
  sSize,
}) => {
  const clonedIngredients = JSON.parse(JSON.stringify(ingredients));
  if ((!itemType || itemType === 0) && sSize) {
    clonedIngredients?.forEach((item) => {
      item.extraList = item?.extraList
        ?.filter(({ finalPrices }) => {
          const filteredFinalPrices = finalPrices?.filter(({ _id }) => {
            return _id === sSize?._id;
          });

          return filteredFinalPrices?.length;
        })
        ?.sort((a, b) =>
          (a?.name?.toLowerCase() || a?.ingredientName?.toLowerCase()) >
          (b?.name?.toLowerCase() || b?.ingredientName?.toLowerCase())
            ? 1
            : -1
        );

      if (!item.extraList?.length) {
        item.extraList = [];
      }
    });
    return clonedIngredients.filter((item) => item.extraList?.length);
  } else if (sBaseSubModifier) {
    clonedIngredients?.forEach((item) => {
      item.extraList = item?.extraList
        ?.filter(({ finalPrices }) => {
          const filteredFinalPrices = finalPrices?.filter(({ _id, _vId }) => {
            return (
              _id === sBaseSubModifier?._id && _vId === sBaseSubModifier?._vId
            );
          });

          return filteredFinalPrices?.length;
        })
        ?.sort((a, b) =>
          (a?.name?.toLowerCase() || a?.ingredientName?.toLowerCase()) >
          (b?.name?.toLowerCase() || b?.ingredientName?.toLowerCase())
            ? 1
            : -1
        );
      if (!item.extraList?.length) {
        item.extraList = [];
      }
    });
    return clonedIngredients.filter((item) => item.extraList?.length);
  } else {
    clonedIngredients?.forEach((item) => {
      item.extraList = item?.extraList?.sort((a, b) =>
        (a?.name?.toLowerCase() || a?.ingredientName?.toLowerCase()) >
        (b?.name?.toLowerCase() || b?.ingredientName?.toLowerCase())
          ? 1
          : -1
      );
    });
    return clonedIngredients;
  }
};

export default function AddExtrasSection({
  toggleTransition,
  toggleExtrasSection,
  isExtraChanged,
  ingredientCategories,
  popularIngredients,
  isTabTransition,
  storeConfig,
  extraPrice,
  closeExtraSection,
  getExtraPrice,
  addExtasInItem,
  decrementExtraItem,
  incrementExtrasItem,
  sBaseSubModifier,
  sSize,
  itemType,
  openedFrom,
}) {
  const [activeTabID, setActiveTabID] = useState(0);

  const addButtonRef = useRef(null);

  const scrollContainerRef = useRef(null);

  const activeIngredientCategories = useMemo(() => {
    const populars = [
      {
        name: 'popular',
        extraList: popularIngredients,
      },
    ];
    const ingredients = populars.concat(ingredientCategories ?? []);
    const filteredIngredients = getFilteredIngredients({
      ingredients,
      itemType,
      sBaseSubModifier,
      sSize,
    });

    return filteredIngredients;
  }, [
    ingredientCategories,
    popularIngredients,
    sBaseSubModifier,
    sSize,
    itemType,
  ]);

  const activeIngredientCategoriesRefs = useMemo(
    () => activeIngredientCategories.map(() => createRef()),
    [activeIngredientCategories]
  );

  const allTabs = useMemo(
    () =>
      activeIngredientCategories
        .map((category) => category.name)
        .map((title, id) => ({ id, title })),
    [activeIngredientCategories]
  );

  const scrollToCategory = useSlider({
    allRefs: activeIngredientCategoriesRefs,
    scrollContainerRef,
    activeTabID,
    setActiveTabID,
    marginTop: -50,
    rootMargin: '-35px',
  });

  const containerHeight = `calc(100% - ${
    isExtraChanged ? (addButtonRef.current?.clientHeight ?? 0) - 20 : 0
  }px)`;

  return (
    <div
      className={
        toggleTransition
          ? toggleExtrasSection
            ? 'extraScreen active'
            : 'extraScreen'
          : toggleExtrasSection
          ? 'extraScreen active'
          : 'extraScreen'
      }
    >
      <CloseButtonCWUI
        onClick={closeExtraSection}
        extendedClassName='ml-5 mt-3.5'
      />
      <h2>Add Extras</h2>
      <div
        className={isExtraChanged ? 'tabWrapCate scroll' : 'tabWrapCate'}
        style={{
          position: 'relative',
          flex: 1,
        }}
      >
        {activeIngredientCategories?.length > 0 ? (
          <React.Fragment>
            <div
              style={{
                paddingTop: '19px',
                backgroundColor: '#fff',
                border: '0 none',
                boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
              }}
            >
              <TabsNavUI
                fontSize='custom'
                activeTabID={activeTabID}
                setActiveTabID={(_, id) => scrollToCategory(id)}
                tabsList={allTabs}
                extendedClassName='!mx-0 !px-0 !text-primary-title'
                extendedBtnClassName='!pt-0 !pb-0.5 text-3.75'
                style={{ '--color-primary': '229 35 31' }}
              />
            </div>

            <div
              className={classNames('tabContentInner tabsSlider2--tab', {
                'tabsSlider2--tab-transition': isTabTransition,
              })}
            >
              <ul
                className='extrasList'
                ref={scrollContainerRef}
                style={{
                  height: containerHeight,
                }}
              >
                {activeIngredientCategories.map((item, i) => (
                  <React.Fragment key={`category-${i}`}>
                    <h3
                      className='extras-title'
                      ref={activeIngredientCategoriesRefs[i]}
                    >
                      {item.name}
                    </h3>
                    {item.extraList.map((extra, index) => (
                      <Ingredient
                        key={`${i}-extra-${index}`}
                        item={extra}
                        getExtraPrice={getExtraPrice}
                        currency={storeConfig.currency}
                        decrementExtraItem={decrementExtraItem}
                        incrementExtrasItem={incrementExtrasItem}
                      />
                    ))}
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </React.Fragment>
        ) : (
          <div>
            <span className='ingView ml-5 mt-5'>
              No extras available for the selected size
            </span>
          </div>
        )}
      </div>
      <div
        className={isExtraChanged ? 'proceedWrap active ex' : 'proceedWrap ex'}
        ref={addButtonRef}
      >
        <div className={isExtraChanged ? 'proceedOrder' : 'proceedOrder zero'}>
          <div className='orderPrice'>
            {storeConfig?.currency}
            {extraPrice}
          </div>
          <div className='proceedBtn' onClick={addExtasInItem}>
            Add to item
          </div>
        </div>
      </div>
    </div>
  );
}
