import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import classNames from 'classnames';
import TimeAgo from 'react-timeago';
import {
  setState as setPropsState,
  toggleMobileCart,
} from 'containers/Home/actions';

import { _firestore } from '../../firebase';
import './desktopBooking.css';
import Ratings from '../../components/Ratings';
import starGray from '../../assets/images/star-gray.svg';
import starYellow from '../../assets/images/star-yellow.svg';
import { selectStoreConfig } from '../../containers/Layout/selectors';
import { selectIsEnabledGoogleReview } from '../../containers/Home/selectors';
import Map from 'components/Map';
import { defaultHomePropsState } from 'constants/common';
import {
  BackButtonCWUI,
  ScrollContainerUI,
} from '@next-order/next-order-components';

import parsePhoneNumber from 'libphonenumber-js';
import { ORDER_STAGE_TYPES } from 'helpers/enums';
import getOpeningHours from 'helpers/getOpeningHours';

export default function AboutDesktop({
  userDetails,
  allSuburbs,
  allShifts,
  currentShift,
  reviewStats,
  reviewStatsByRating,
  isDeliveryOn,
  sidebarAnimationClass,
  deleteAccount,
}) {
  const isEnableGoogleReview = useSelector(selectIsEnabledGoogleReview);
  const storeConfig = useSelector(selectStoreConfig);
  const aboutOpen = useSelector((state) => state.homeReducer.aboutOpen);
  const dispatch = useDispatch();
  const aboutRef = useRef(null);
  const { currentUser, currentOrder } = useSelector(
    (state) => state.homeReducer
  );

  const showDeleteAccountButton =
    (currentUser?.phoneNumber || userDetails?.mobileNumber) &&
    userDetails?.firstName &&
    currentOrder?.stage < ORDER_STAGE_TYPES.REVIEW;

  const [localState, setLocalState] = useReducer((s, a) => ({ ...s, ...a }), {
    displayDeliveryAreas: false,
    displayStoreTimings: false,
    displayReviewScreen: false,
    showScroll: true,
    allLoaded: false,
    mapLoaded: false,
  });
  const [aboutHeight, setAboutHeight] = useState(0);

  // On Mount
  useEffect(() => {
    if (!storeConfig.storeId) return;

    setLocalState({ showLoader: true });

    let nextRef = _firestore
      .collection('Reviews')
      .where('storeId', '==', storeConfig.storeId)
      .where('isVisible', '==', true)
      .orderBy('dateTimestamp', 'desc')
      .limit(25);

    nextRef.get().then((querySnapshot) => {
      const dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });

      setLocalState({
        allReviews: dataArray,
        isLoading: false,
        showLoader: false,
      });
    });
  }, [storeConfig.storeId]);

  useEffect(() => {
    if (userDetails && userDetails.mobileNumber && localState.allowNewBooking) {
      setLocalState({ showConfirmationScreen: true });
    }
    // eslint-disable-next-line
  }, [userDetails?.mobileNumber, localState.allowNewBooking]);

  useEffect(() => {
    if (showDeleteAccountButton)
      setAboutHeight(aboutRef.current?.clientHeight - 55);
    else setAboutHeight(aboutRef.current?.clientHeight);
  }, [aboutRef, showDeleteAccountButton]);

  const loadReviews = (totalReviews) => {
    setLocalState({ showLoader: true });

    let limit =
      localState.allReviews.length + 10 < totalReviews
        ? localState.allReviews.length + 10
        : totalReviews;

    let nextRef = _firestore
      .collection('Reviews')
      .where('storeId', '==', storeConfig.storeId)
      .orderBy('dateTimestamp', 'desc')
      .limit(limit);

    nextRef.get().then((querySnapshot) => {
      const dataArray = [];
      querySnapshot.forEach((doc) => {
        dataArray.push({ _id: doc.id, ...doc.data() });
      });

      setLocalState({
        allReviews: dataArray,
        showLoader: false,
        ...(dataArray.length === totalReviews ? { allLoaded: true } : {}),
      });
    });
  };

  const getReviewPercentage = (rating) => {
    if (reviewStatsByRating && reviewStatsByRating.length > 0 && reviewStats) {
      let rList = reviewStatsByRating.filter((f) => {
        return Number(f.rating) === rating;
      });
      if (rList.length > 0)
        return (rList[0].totalReviews * 100) / reviewStats.totalReviews;
      else return 0;
    }
    return 0;
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (
      reviewStats &&
      reviewStats.totalReviews &&
      localState.allReviews.length < reviewStats.totalReviews
    ) {
      if (bottom) {
        loadReviews(reviewStats.totalReviews);
      }
    }
  };

  const openDeliveryAreas = () => {
    setLocalState({
      displayDeliveryAreas: true,
      showAnimation: false,
    });
  };

  const openStoreTimings = () => {
    setLocalState({
      displayStoreTimings: true,
      showAnimation: false,
    });
  };

  const showAnimationBeforeClose = () => {
    setLocalState({
      showAnimation: true,
      showScroll: false,
    });
    setTimeout(() => {
      setLocalState({
        displayDeliveryAreas: false,
        displayStoreTimings: false,
        displayReviewScreen: false,
      });
    }, 300);
  };

  const renderDeliveryAreasScreen = () => {
    let deliveryAreasClass = classNames({
      'delivery-areas-container true about-slide-in-animation':
        localState.displayDeliveryAreas,
      'delivery-areas-container': !localState.displayDeliveryAreas,
      'about-slide-out-animation': localState.showAnimation,
    });

    return (
      <div className={deliveryAreasClass}>
        <BackButtonCWUI
          extendedClassName='absolute top-3.25 left-5 sm:top-3.5'
          onClick={showAnimationBeforeClose}
        />
        <div className='detailsTitle oH' style={{ marginTop: 0 }}>
          Delivery Areas
        </div>
        <ScrollContainerUI
          scrollShadow
          isBottomShadow={false}
          extendedInnerClassName='px-4.5'
          extendedWrapClassName='-mx-4.5  !w-[calc(100%_+_36px)] h-[calc(100%_-_106px)]'
        >
          <ul>
            {allSuburbs?.map((item, index) => (
              <li key={index} style={{ minHeight: 65, paddingRight: '4px' }}>
                {item.name}
                <span>
                  {storeConfig.currency} {item.deliveryCost}
                </span>
              </li>
            ))}
          </ul>
        </ScrollContainerUI>
      </div>
    );
  };

  const getOpeningHoursLocal = (day) => {
    return getOpeningHours(day, allShifts);
  };

  const renderStoreTimingsScreen = () => {
    let openHoursClass = classNames({
      'delivery-areas-container true about-slide-in-animation':
        localState.displayStoreTimings,
      'delivery-areas-container': !localState.displayStoreTimings,
      'about-slide-out-animation': localState.showAnimation,
    });

    const day =
      moment().tz(storeConfig.timeZone).weekday() === 0
        ? 7
        : moment().tz(storeConfig.timeZone).weekday();
    return (
      <div className={openHoursClass}>
        <BackButtonCWUI
          extendedClassName='absolute top-3.25 left-5 sm:top-3.5'
          onClick={showAnimationBeforeClose}
        />
        <div className='detailsTitle oH' style={{ marginTop: 0 }}>
          Opening Hours
        </div>
        <ScrollContainerUI
          scrollShadow
          isBottomShadow={false}
          extendedInnerClassName='px-4.5'
          extendedWrapClassName='-mx-4.5  !w-[calc(100%_+_36px)] h-[calc(100%_-_106px)]'
        >
          <ul>
            <li>
              {day === 1 ? <strong>Monday</strong> : 'Monday'}
              {day === 1 ? (
                <span>
                  <strong>{getOpeningHoursLocal(1)}</strong>
                </span>
              ) : (
                <span>{getOpeningHoursLocal(1)}</span>
              )}
            </li>
            <li>
              {day === 2 ? <strong>Tuesday</strong> : 'Tuesday'}
              {day === 2 ? (
                <span>
                  <strong>{getOpeningHoursLocal(2)}</strong>
                </span>
              ) : (
                <span>{getOpeningHoursLocal(2)}</span>
              )}
            </li>
            <li>
              {day === 3 ? <strong>Wednesday</strong> : 'Wednesday'}
              {day === 3 ? (
                <span>
                  <strong>{getOpeningHoursLocal(3)}</strong>
                </span>
              ) : (
                <span>{getOpeningHoursLocal(3)}</span>
              )}
            </li>
            <li>
              {day === 4 ? <strong>Thursday</strong> : 'Thursday'}
              {day === 4 ? (
                <span>
                  <strong>{getOpeningHoursLocal(4)}</strong>
                </span>
              ) : (
                <span>{getOpeningHoursLocal(4)}</span>
              )}
            </li>
            <li>
              {day === 5 ? <strong>Friday</strong> : 'Friday'}
              {day === 5 ? (
                <span>
                  <strong>{getOpeningHoursLocal(5)}</strong>
                </span>
              ) : (
                <span>{getOpeningHoursLocal(5)}</span>
              )}
            </li>
            <li>
              {day === 6 ? <strong>Saturday</strong> : 'Saturday'}
              {day === 6 ? (
                <span>
                  <strong>{getOpeningHoursLocal(6)}</strong>
                </span>
              ) : (
                <span>{getOpeningHoursLocal(6)}</span>
              )}
            </li>
            <li>
              {day === 7 ? <strong>Sunday</strong> : 'Sunday'}
              {day === 7 ? (
                <span>
                  <strong>{getOpeningHoursLocal(7)}</strong>
                </span>
              ) : (
                <span>{getOpeningHoursLocal(7)}</span>
              )}
            </li>
          </ul>
        </ScrollContainerUI>
      </div>
    );
  };

  const renderDisplayReviewScreen = () => {
    let reviewsClass = classNames({
      'delivery-areas-container  true about-slide-in-animation':
        localState.displayReviewScreen,
      'delivery-areas-container': !localState.displayReviewScreen,
      'about-slide-out-animation': localState.showAnimation,
    });

    return (
      <React.Fragment>
        <div className={reviewsClass}>
          <button
            className='card__cntrl'
            onClick={showAnimationBeforeClose}
          ></button>
          <div className='detailsTitle oH' style={{ marginTop: 0 }}>
            Reviews
          </div>
          <div className='uRstarC'>
            <div className='uRleftC'>
              <div>
                {reviewStats?.averageRating
                  ? Number(reviewStats.averageRating).toFixed(1)
                  : 'N/A'}
              </div>
              <div>out of 5</div>
            </div>
            <div className='uRrightC'>
              <div className='uRstarMain'>
                <div className='urRow'>
                  <div className='urLeftSec'>
                    <img src={starYellow} alt='' />
                    <img src={starYellow} alt='' />
                    <img src={starYellow} alt='' />
                    <img src={starYellow} alt='' />
                    <img src={starYellow} alt='' />
                  </div>
                  <div className='urRightSec'>
                    <div className='progressBar'>
                      <div
                        className='prgFill'
                        style={{ width: `${getReviewPercentage(5)}%` }}
                      />
                    </div>
                  </div>
                </div>
                <div className='urRow'>
                  <div className='urLeftSec'>
                    <img src={starYellow} alt='' />
                    <img src={starYellow} alt='' />
                    <img src={starYellow} alt='' />
                    <img src={starYellow} alt='' />
                  </div>
                  <div className='urRightSec'>
                    <div className='progressBar'>
                      <div
                        className='prgFill'
                        style={{ width: `${getReviewPercentage(4)}%` }}
                      />
                    </div>
                  </div>
                </div>
                <div className='urRow'>
                  <div className='urLeftSec'>
                    <img src={starYellow} alt='' />
                    <img src={starYellow} alt='' />
                    <img src={starYellow} alt='' />
                  </div>
                  <div className='urRightSec'>
                    <div className='progressBar'>
                      <div
                        className='prgFill'
                        style={{ width: `${getReviewPercentage(3)}%` }}
                      />
                    </div>
                  </div>
                </div>
                <div className='urRow'>
                  <div className='urLeftSec'>
                    <img src={starYellow} alt='' />
                    <img src={starYellow} alt='' />
                  </div>
                  <div className='urRightSec'>
                    <div className='progressBar'>
                      <div
                        className='prgFill'
                        style={{ width: `${getReviewPercentage(2)}%` }}
                      />
                    </div>
                  </div>
                </div>
                <div className='urRow'>
                  <div className='urLeftSec'>
                    <img src={starYellow} alt='' />
                  </div>
                  <div className='urRightSec'>
                    <div className='progressBar'>
                      <div
                        className='prgFill'
                        style={{ width: `${getReviewPercentage(1)}%` }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='uRRev'>
                {`${
                  reviewStats && reviewStats.totalReviews
                    ? reviewStats.totalReviews
                    : 0
                }
                 Reviews`}
              </div>
            </div>
          </div>
          <div
            id='reviewListWrap'
            onScroll={handleScroll}
            className={
              localState.showLoader
                ? 'reviewListWrap loading'
                : 'reviewListWrap'
            }
            style={{ overflowY: 'auto', height: 'calc(100vh - 300px)' }}
          >
            {localState.allReviews &&
              !localState.isLoading &&
              localState.allReviews.map((r, i) => (
                <React.Fragment key={i}>
                  {r.isVisible === true && (
                    <div
                      key={i}
                      className={`${
                        localState.allLoaded === true
                          ? `reviewListItem allReviewsLoaded`
                          : `reviewListItem`
                      }`}
                    >
                      <div className='rlTitleWrap'>
                        <div className='rlTitle'>
                          {r.firstName ? r.firstName : r.userName}
                        </div>
                        <div className='rlrating'>
                          {new Array(Number(r.rating)).fill().map((_, p) => (
                            <img key={p} src={starYellow} alt='' />
                          ))}
                          {new Array(5 - Number(r.rating))
                            .fill()
                            .map((_, p) => (
                              <img key={p} src={starGray} alt='' />
                            ))}
                        </div>
                      </div>
                      <div className='rlDate'>
                        <TimeAgo date={r.dateTimestamp} live={false} />
                      </div>
                      <div className='rlRating'>{r.comment && r.comment}</div>
                    </div>
                  )}
                </React.Fragment>
              ))}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const openReviewScreen = () => {
    setLocalState({
      displayReviewScreen: true,
      showAnimation: false,
    });
  };

  const onClose = () => {
    dispatch(toggleMobileCart(false));
    dispatch(setPropsState({ ...defaultHomePropsState }));
  };

  const phoneNumber = parsePhoneNumber(
    storeConfig.phoneNumber || storeConfig.phoneNumberDisplay,
    storeConfig.countryIdentifier
  );
  const formattedPhoneNumber =
    phoneNumber?.formatNational() || storeConfig.phoneNumberDisplay;

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
      className={sidebarAnimationClass}
    >
      {localState.displayDeliveryAreas === true && renderDeliveryAreasScreen()}
      {localState.displayStoreTimings === true && renderStoreTimingsScreen()}
      {localState.displayReviewScreen === true && renderDisplayReviewScreen()}
      <aside className='sidebar'>
        <div className='sidebar__wrapper'>
          <div className='card__about'>
            <div className='card__header'>
              <BackButtonCWUI
                extendedClassName='absolute top-3.25 left-5 sm:top-3.5'
                onClick={onClose}
              />
              <div className='card__name'>{storeConfig.name}</div>
              <div className='flex items-center justify-between mt-4'>
                <div className='card__category'>{storeConfig.type}</div>
                <div className='card__rating'>
                  {/*Reviews Section */}
                  {reviewStats?.averageRating && (
                    <Ratings
                      rating={
                        reviewStats.averageRating
                          ? Number(reviewStats.averageRating).toFixed(1)
                          : 0
                      }
                    />
                  )}
                </div>
              </div>
            </div>

            {aboutOpen && storeConfig?.addressLocation && (
              <div className='card__map'>
                <Map
                  lat={storeConfig.addressLocation.lat}
                  long={storeConfig.addressLocation.long}
                />
              </div>
            )}

            <ul
              className='card__info'
              ref={aboutRef}
              style={{ height: aboutHeight ? `${aboutHeight}px` : 'auto' }}
            >
              <li className='card__info-item'>
                <div>{storeConfig.address}</div>
              </li>
              {formattedPhoneNumber || storeConfig.emailAddress ? (
                <li className='card__info-item'>
                  <div>
                    {formattedPhoneNumber || storeConfig.emailAddress}
                    <a
                      className='card__call'
                      href={
                        formattedPhoneNumber
                          ? `tel:${storeConfig.phoneNumber}`
                          : `mailto:${storeConfig.emailAddress}`
                      }
                      title='Contact restaurant'
                    >
                      {formattedPhoneNumber ? 'Call' : 'Mail'}
                    </a>
                  </div>
                </li>
              ) : null}

              <li className='card__info-item' onClick={openStoreTimings}>
                <a className='card__link'>
                  <div>
                    {currentShift &&
                    !currentShift?.noPickup &&
                    currentShift.isPickupOpen
                      ? 'Open'
                      : 'Closed'}
                    <span className='card__time'></span>
                  </div>
                </a>
              </li>

              {!isEnableGoogleReview ? (
                <li className='card__info-item' onClick={openReviewScreen}>
                  <a className='card__link'>
                    <div>
                      {reviewStats && reviewStats.totalReviews ? (
                        <React.Fragment>{`${reviewStats.totalReviews} `}</React.Fragment>
                      ) : null}
                      Reviews
                    </div>
                  </a>
                </li>
              ) : null}
              {isDeliveryOn === true && allSuburbs?.length > 0 && (
                <li className='card__info-item' onClick={openDeliveryAreas}>
                  <a className='card__link'>
                    <div>Delivery Areas</div>
                  </a>
                </li>
              )}
              <li className='card__info-item'>
                <a
                  className='card__link_terms'
                  rel='noopener noreferrer'
                  title='Opens in a new window'
                  target='_blank'
                  href='https://pics.nextorder.co/assets/Terms+and+Conditions+Next+Order.pdf'
                >
                  <div>Terms &amp; Conditions</div>
                </a>
              </li>
              {showDeleteAccountButton && (
                <li
                  className='card__info-item card__info-delete'
                  onClick={deleteAccount}
                >
                  <div>Delete account</div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </aside>
    </div>
  );
}
