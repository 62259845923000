export const MENU_ITEM_TYPE = {
  simple: 0,
  complex: 1,
};

export const MENU_ORDER_FROM = {
  web: '1',
  pos: '2',
  deliverect: '3',
  hubrise: '4',
  doordash: '5',
  kiosk: '7',
};

export const MENU_ORDER_TYPE_MAPPINGS = {
  0: 'Walk-in',
  1: 'Pickup',
  2: 'Delivery',
  3: 'Dine-in',
  4: 'Drive Thru',
  11: 'Restaurant Reseravtion',
  12: 'Other Delivery',
};

export const MENU_ORDER_TYPE = {
  walkin: '0',
  pickup: '1',
  delivery: '2',
  dinein: '3',
  driveThru: '4',
  restaurant_reseravtion: '11',
  other_delivery: '12',
};

export const MENU_ORDER_STATUS = {
  unknown: '-1',
  unconfirmed: '0',
  placed: '1',
  in_kitchen: '2',
  on_the_way: '3',
  delivered: '4',
  cancelled: '11',
  arriving: '12',
  arrived: '13',
  out_for_delivery: '25',
};

export const ORDER_PAYMENT_TYPE = {
  unpaid: '0',
  cash: '1',
  card_online: '2',
  card_in_store: '3',
  mobile_terminal: '4',
  custom: '11',
};

export const API_STATUS = {
  IDLE: 'idle',
  FETCHING: 'fetching',
  RESOLVED: 'resolved',
  FAILED: 'failed',
};

export const NOTIFICATION_STREAM_STATUS = {
  IDLE: 'idle',
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
  CANCELLED: 'cancelled',
};

export const TERMINAL_PAYMENT_PAYOUT_STATUS = {
  Pending: 0,
  Initiated: 100,
  WayToBank: 200,
  Settled: 300,
};

export const CATEGORY_DISPLAY_TYPE = {
  GRID: '0',
  LIST: '1',
};
export const CATEGORY_DISPLAY_TYPE_VALUE = {
  [CATEGORY_DISPLAY_TYPE.GRID]: 'grid',
  [CATEGORY_DISPLAY_TYPE.LIST]: 'list',
};

export const MENU_ITEM_VIEW_TYPE = {
  halfhalfitem: 'halfhalfitem',
  specialitem: 'specialitem',
};
export const MENU_ITEM_DATA_TYPE = {
  popular: 'popular',
  special: 'specials',
  menuitem: 'menuitem',
};
export const MENU_ITEM_OBJ_TYPE = {
  ...MENU_ITEM_DATA_TYPE,
  halfhalf: 'halfhalf',
};

export const SOLD_OUT_ITEM_PRICE = 'Sold Out';

export const VISIBILITY_INDEX_TYPE = {
  NONE: 0,
  ORDER_VIEW: 1,
  SPECIAL_ORDER_VIEW: 2,
  SPECIAL_VIEW: 3,
  HALF_HALF_VIEW: 4,
  HALF_ORDER_VIEW: 5,
  VOUCHER_VIEW: 6,
  VOUCHER_ORDER_VIEW: 7,
};

export const ORDER_STAGE_TYPES = {
  NONE: 0,
  NAME_FORM: 1,
  MOBILE_FORM: 1.1,
  VERIFY_FROM: 2,
  REVIEW: 3,
  PAYMENT: 4,
  PAYMENT_CARD: 4.1,
  ALL_DONE: 5,
};

export const LOGIN_FROM_TYPE = {
  BOOKING: 'booking',
  LOYALTY: 'loyalty',
  MAIN: 'main',
};

export const BREAKPOINT_VALUES = {
  BIG_SCREEN: 1500,
  DESKTOP: 1024,
  TABLET: 992,
  MOBILE: 767,
  MINI: 340,
};
