import removeTruthyProperties from './removeTruthyProperties';

const detectOutOfScheduleItemsInCartFun = (currentOrder, menuItemSchedules) => {
  let notAvailableMenuItems = {};
  menuItemSchedules?.forEach(({ menuItemId, isAvailableNow }) => {
    if (!notAvailableMenuItems[menuItemId]) {
      notAvailableMenuItems[menuItemId] = isAvailableNow;
    }
  });
  notAvailableMenuItems = removeTruthyProperties(notAvailableMenuItems);
  return currentOrder?.menuItems?.some(({ _id }) => {
    return notAvailableMenuItems[_id];
  });
};

export const detectOutOfScheduleItemsInCart = detectOutOfScheduleItemsInCartFun;
