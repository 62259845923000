export const eventNames = {
  HOME_PAGE_VISITED: 'homepage_visited',
  MENU_ITEM_OPENED: 'menuitem_opened',
  SEARCH_MENU_ITEM_OPENED: 'search_menuitem_opened',
  HALF_HALF_MENU_ITEM_OPENED: 'half_half_menuitem_opened',
  SPECIALS_MENU_ITEM_OPENED: 'specials_menuitem_opened',
  MENU_ITEM_CUSTOMIZED: 'menuitem_customized',
  SELECTED_ORDER_TYPE: 'selected_order_type',
  SELECTED_TIME_OF_COLLECTION: 'selected_time_of_collection', //
  SELECTED_ORDER_BUTTON: 'selected_order_button',
  ENTERED_FIRST_LAST_NAME: 'entered_first_last_name',
  ENTERED_OPT: 'entered_otp',
  SELECTED_CONFIRM_ORDER: 'selected_confirm_order',
  SELECTED_PAYMENT_METHOD: 'selected_payment_method',
  PAID_FOR_ORDER: 'paid_for_order',
};
