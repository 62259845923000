import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Minus } from '../../assets/images/minus.svg';
import { ReactComponent as Plus } from '../../assets/images/plus.svg';
import isEqual from 'react-fast-compare';

class TableBookingButton extends Component {
  constructor(props) {
    super(props);
    this.state = { decState: true };
  }
  UNSAFE_componentWillReceiveProps(props) {
    if (props.quantity !== undefined) {
      if (Number(props.quantity) <= 0) this.setState({ decState: true });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(isEqual(this.props, nextProps) && isEqual(this.state, nextState));
  }

  incrementQuantity() {
    this.setState({ decState: false }, () => {
      this.props.increseQuantity();
    });
  }
  decrementQuantity() {
    if (this.props.quantity <= 0) {
      this.setState({ decState: true });
      return this.props.quantity;
    } else {
      if (Number(this.props.quantity === 0)) {
        this.setState({ decState: true });
      }
      this.props.decreseQuantity();
    }
  }
  render() {
    return (
      <div className='noOfPep'>
        <div
          className={this.state.decState ? 'pepDesc disabled' : 'pepDesc'}
          onClick={this.decrementQuantity.bind(this)}
        >
          <span
            className='icon-button-group to-right'
            style={{ top: '5px', right: '5px' }}
          >
            <Minus />
          </span>
        </div>
        <div className='pepCount'>{this.props.quantity}</div>
        <div className='pepInc' onClick={this.incrementQuantity.bind(this)}>
          <span
            className='icon-button-group to-right'
            style={{ top: '5px', right: '5px' }}
          >
            <Plus />
          </span>
        </div>
      </div>
    );
  }
}

TableBookingButton.propTypes = {
  quantity: PropTypes.any,
  increseQuantity: PropTypes.any,
  decreseQuantity: PropTypes.any,
};

export default TableBookingButton;
