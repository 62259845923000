import React from 'react';
import PropType from 'prop-types';
import isEqual from 'react-fast-compare';

class Ingredients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      removed: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(isEqual(this.props, nextProps) && isEqual(this.state, nextState));
  }

  removeMe = () => {
    if (this.props.removed) {
      this.props.addRemoveIngredient('removed', this.props.item);
    } else {
      this.props.addRemoveIngredient('added', this.props.item);
    }
  };
  render() {
    return (
      <li>
        <span>
          {this.props.item.name
            ? this.props.item.name
            : this.props.item.ingredientName}
        </span>
        <span
          className={
            this.props.removed
              ? 'iconWala thickcon-Checkmark active'
              : 'iconWala thickcon-Checkmark'
          }
          onClick={() => this.removeMe()}
        />
      </li>
    );
  }
}

Ingredients.propType = {
  addRemoveIngredient: PropType.any,
  item: PropType.any,
  removed: PropType.any,
};

export default Ingredients;
