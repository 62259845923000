import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import Geosuggest from 'react-geosuggest';
import { setNotes } from 'containers/Home/actions';

import { _auth } from '../../firebase';
import DriverTracker from '../../components/DriverTracker';
import DatePickerWrap from '../../components/DatePicker';
import { getCurrentMomentDate } from '../../helpers/timeHelper';
import { getCardType } from '../../helpers/paymentHelper';
import { creditCardFetched } from './actions';
import { ReactComponent as Delivery } from '../../assets/images/delivery.svg';
import deliveryWhite from '../../assets/images/delivery-white.svg';
import { selectStoreConfig } from '../Layout/selectors';
import titleCase from 'helpers/titleCase';
import {
  MENU_ORDER_STATUS,
  MENU_ORDER_TYPE,
  ORDER_STAGE_TYPES,
} from 'helpers/enums';

export default function ReviewOrder({
  updateOrderDeliveryAddress,
  // updateDeliveryPickupTime,
  saveUnit,
}) {
  const dispatch = useDispatch();
  const autoSuggestRef = useRef(null);
  const order = useSelector((state) => state.orderReducer);
  const home = useSelector((state) => state.homeReducer);
  const storeConfig = useSelector(selectStoreConfig);
  const { currentOrder } = home;

  const [state, setLocalState] = useReducer((s, a) => ({ ...s, ...a }), {
    searchBar: false,
    address: '21A Gheringhap Street, Geelong',
    start: false,
    apartment: '',
    orderNotes: '',
    datebar: false,
    overflow: false,
  });
  const isExpiryValid = useCallback(
    (month, year) => {
      let currentDate = getCurrentMomentDate(storeConfig.timeZone);
      let currentMonth = parseInt(currentDate.format('MM'));
      let currentYear = parseInt(currentDate.format('YYYY'));
      if (year > currentYear) {
        return true;
      } else if (year === currentYear) {
        if (month >= currentMonth) return true;
        else return false;
      } else {
        return false;
      }
    },
    [storeConfig.timeZone]
  );

  const fetchCreditCards = useCallback(
    (stripeCode) => {
      _auth.currentUser.getIdToken(true).then((accessToken) => {
        fetch(`${storeConfig.cloudFunctionsUrl}/listPaymentMethods`, {
          method: 'post',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            customerId: stripeCode,
            isProductionEnv:
              storeConfig?.environment?.toLowerCase() === 'production',
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            let cards = [];
            if (result.success) {
              result.paymentMethods.data.forEach((p) => {
                let cardObj = getCardType(p.card.brand);
                let card = {
                  cardNumber: `XXXX - XXXX - XXXX - ${p.card.last4}`,
                  expiryMonth: p.card.exp_month,
                  country: p.card.country,
                  expiryYear: p.card.exp_year,
                  cardType: cardObj.type,
                  cardDescription: cardObj.name,
                  token: p.id,
                };
                if (card.expiryMonth && card.expiryYear) {
                  if (isExpiryValid(card.expiryMonth, card.expiryYear))
                    cards.push(card);
                }
              });
            }
            dispatch(creditCardFetched(cards));
          });
      });
    },
    [
      dispatch,
      isExpiryValid,
      storeConfig?.cloudFunctionsUrl,
      storeConfig?.environment,
    ]
  );

  useEffect(() => {
    setLocalState({
      ...(currentOrder.notes && currentOrder.notes.length > 0
        ? { orderNotes: currentOrder.notes || '' }
        : {}),
      ...(currentOrder.unit && currentOrder.unit.length > 0
        ? { apartment: currentOrder.unit }
        : {}),
    });
    // eslint-disable-next-line
  }, [currentOrder.notes, currentOrder.unit]);

  const fetchCardCalled = useRef(false);
  useEffect(() => {
    if (
      home.userDetails &&
      home.userDetails.stripeCode &&
      !fetchCardCalled.current
    ) {
      fetchCardCalled.current = true;
      fetchCreditCards(home.userDetails.stripeCode);
    }
  }, [fetchCreditCards, home.userDetails]);

  const showSearch = () => {
    setLocalState({ searchBar: true });
    setTimeout(() => {
      autoSuggestRef.current?.focus();
    }, 100);
  };

  const hideSearch = () => {
    setLocalState({ searchBar: false, start: false });
    autoSuggestRef.current?.clear();
  };

  const hideCalendar = () => {
    setLocalState({ datebar: false, overflow: false });
  };

  const changeUnit = (e) => {
    const apartment = e.target.value;
    setLocalState({ apartment });
    saveUnit(apartment);
  };

  const changeNotes = (e) => {
    let orderNotes = '';
    if (e.target.value.length > 0) {
      let character = e.target.value.charAt(0);
      if (character !== character.toUpperCase()) {
        orderNotes = titleCase(e.target.value);
      } else {
        orderNotes = e.target.value;
      }
    }

    setLocalState({ orderNotes });
    dispatch(setNotes(orderNotes));
  };

  const startCase = () => {
    setLocalState({ start: true });
  };

  const updateDeliveryAddress = (addObj) => {
    if (typeof addObj === 'undefined') {
      setLocalState({ star: false });
    } else {
      let sub =
          addObj.label.split(',')[1] &&
          addObj.label.split(',')[1].trim().toLowerCase(),
        area = '',
        areaCode = '';
      if (
        addObj.gmaps &&
        addObj.gmaps.address_components &&
        addObj.gmaps.address_components.length > 0
      ) {
        addObj.gmaps.address_components.map((addressComponent) => {
          if (addressComponent.types && addressComponent.types.length > 0) {
            addressComponent.types.forEach((type) => {
              if (type === 'postal_code') {
                areaCode = addressComponent.long_name
                  ? addressComponent.long_name
                  : 0;
              }
              if (type === 'locality') {
                area = addressComponent.long_name
                  ? addressComponent.long_name
                  : '';
              }
            });
          }
          return addressComponent;
        });
      }
      let supportedSubs = order.allSuburbs.filter((s) => {
        return sub === s.name.toLowerCase();
      });
      if (supportedSubs && supportedSubs.length > 0) {
        setLocalState({
          deliveryAddrError: false,
          searchBar: false,
          start: false,
        });
        autoSuggestRef.current?.clear();
        updateOrderDeliveryAddress(addObj, supportedSubs[0], areaCode, area);
      } else {
        setLocalState({ deliveryAddrError: true });
      }
    }
  };

  const getTimeToDisplay = () => {
    let today = moment(
      moment().tz(storeConfig.timeZone).format('YYYY-MM-DD HH:mm'),
      'YYYY-MM-DD HH:mm'
    );
    if (currentOrder && currentOrder.deliveryDate) {
      let delDate = moment(currentOrder.deliveryDate, 'YYYY-MM-DD HH:mm');
      if (delDate.isSame(today, 'day')) {
        return `Today, ${delDate.format('hh:mm A')}`;
      } else if (delDate.isSame(today.add(1, 'day'), 'day')) {
        return `Tomorrow, ${delDate.format('hh:mm A')}`;
      } else return delDate.format('Do MMM, hh:mm A');
    }
  };

  const getOrderStatusDisplayText = () => {
    if (currentOrder) {
      if (currentOrder.orderStatus === MENU_ORDER_STATUS.unconfirmed) {
        return 'Order is waiting for confirmation.';
      } else if (
        currentOrder.orderStatus === MENU_ORDER_STATUS.placed ||
        currentOrder.orderStatus === MENU_ORDER_STATUS.in_kitchen
      ) {
        return 'Your order is confirmed!';
      } else if (
        currentOrder.orderStatus === MENU_ORDER_STATUS.out_for_delivery
      ) {
        return 'Your order is out for the delivery!';
      } else if (currentOrder.orderStatus === MENU_ORDER_STATUS.on_the_way) {
        return 'Your order is on the way!';
      } else if (currentOrder.orderStatus === MENU_ORDER_STATUS.delivered) {
        return 'Your order is delivered!';
      } else if (currentOrder.orderStatus === MENU_ORDER_STATUS.cancelled) {
        return 'We regret to inform you that we are unable to process your order at the moment.';
      }
    }
  };

  const getOrderTimeDisplayText = () => {
    if (
      currentOrder &&
      currentOrder.orderStatus === MENU_ORDER_STATUS.unconfirmed
    ) {
      return `${moment(currentOrder.deliveryDate, `YYYY-MM-DD HH:mm`).format(
        'Do MMM, ddd, hh:mm A'
      )}`;
    } else return '';
  };

  const getLinelength = () => {
    let percentage = 0;
    if (
      storeConfig &&
      storeConfig.addressLocation &&
      currentOrder &&
      currentOrder.orderType === MENU_ORDER_TYPE.delivery &&
      currentOrder.orderStatus === MENU_ORDER_STATUS.on_the_way &&
      currentOrder.addressLocation &&
      state.driver &&
      state.driver.currentLocation
    ) {
      var l1 = new window.google.maps.LatLng(
        Number(storeConfig.addressLocation.lat),
        Number(storeConfig.addressLocation.long)
      );
      var l2 = new window.google.maps.LatLng(
        Number(currentOrder.addressLocation.lat),
        Number(currentOrder.addressLocation.lng)
      );
      var l3 = new window.google.maps.LatLng(
        Number(state.driver.currentLocation.lat),
        Number(state.driver.currentLocation.long)
      );
      let distance =
        window.google.maps.geometry.spherical.computeDistanceBetween(l1, l2);
      let distanceTravlled =
        window.google.maps.geometry.spherical.computeDistanceBetween(l1, l3);
      percentage = parseInt((distanceTravlled * 100) / distance, 10);
      return percentage;
    } else if (
      currentOrder &&
      currentOrder.orderType === MENU_ORDER_TYPE.delivery &&
      currentOrder.orderStatus === MENU_ORDER_STATUS.delivered
    ) {
      percentage = 100;
      return percentage;
    } else {
      return percentage;
    }
  };

  const skipSuggest = (suggest) => {
    try {
      let suburbWithState = suggest.description.split(',')[1];
      let arrElms = suburbWithState.split(' ');
      let state = arrElms[arrElms.length - 1];
      if (state !== storeConfig.stateIdentifier) return true;
      else return false;
    } catch (err) {
      return false;
    }
  };

  return (
    <div className='mapAbs'>
      {window.innerWidth > 991 && (
        <DriverTracker
          currentOrder={currentOrder}
          store={storeConfig}
          driver={state.driver}
        />
      )}
      <div
        className={
          currentOrder.stage >= ORDER_STAGE_TYPES.ALL_DONE
            ? 'deliveryWrapFinal active'
            : 'deliveryWrapFinal'
        }
      >
        <div className='oneDel'>
          <p>{getOrderStatusDisplayText()}</p>
          <p>
            {currentOrder.orderStatus !== MENU_ORDER_STATUS.cancelled && (
              <i className='icon-Clock' />
            )}
            {getOrderTimeDisplayText()}
          </p>
        </div>
        {currentOrder && currentOrder.orderType !== MENU_ORDER_TYPE.dinein && (
          <div className='twoDel'>
            <p>
              <i className='icon-Location-Pin' />
              {currentOrder.orderType === MENU_ORDER_TYPE.pickup
                ? storeConfig
                  ? `${storeConfig.street},${storeConfig.city}`
                  : 'N/A'
                : currentOrder.address
                ? currentOrder.unit
                  ? `${currentOrder.unit}/${currentOrder.address}`
                  : currentOrder.address
                : 'N/A'}
            </p>
          </div>
        )}
        <div className='threeDel hide'>
          <span>Contact Store</span>
          <span>View Order</span>
        </div>
        {currentOrder &&
          (currentOrder.orderType === MENU_ORDER_TYPE.pickup ||
            currentOrder.orderType === MENU_ORDER_TYPE.dinein) && (
            <div className='progressIcons'>
              <div
                className={
                  currentOrder &&
                  currentOrder.orderStatus === MENU_ORDER_STATUS.placed
                    ? 'store pickup active'
                    : 'pickup store'
                }
              >
                <i className='icon-Store2' />
              </div>
            </div>
          )}
        {currentOrder &&
          currentOrder.orderType === MENU_ORDER_TYPE.delivery && (
            <div className='progressIcons'>
              <div
                className={
                  currentOrder &&
                  currentOrder.orderStatus === MENU_ORDER_STATUS.in_kitchen
                    ? `store active del${currentOrder.orderStatus}`
                    : `store del${currentOrder.orderStatus}`
                }
              >
                <i className='icon-Store2' />
              </div>
              <div
                className={
                  currentOrder &&
                  (currentOrder.orderStatus === MENU_ORDER_STATUS.on_the_way ||
                    currentOrder.orderStatus === MENU_ORDER_STATUS.delivered)
                    ? 'moped active'
                    : 'moped'
                }
              >
                <i className='icon-Moped' />
              </div>
              <span
                className={
                  currentOrder &&
                  (currentOrder.orderStatus === MENU_ORDER_STATUS.on_the_way ||
                    currentOrder.orderStatus === MENU_ORDER_STATUS.delivered)
                    ? 'progressbar active'
                    : 'progressbar'
                }
              >
                <i style={{ width: `${getLinelength()}%` }} />
              </span>
              {currentOrder &&
              currentOrder.orderStatus === MENU_ORDER_STATUS.delivered ? (
                <div className='delivered active'>
                  <i className='delivery '>
                    <img src={deliveryWhite} alt='' />
                  </i>
                </div>
              ) : (
                <div className='delivered'>
                  <i className='delivery'>
                    <Delivery className='delivery-svg' />
                  </i>
                </div>
              )}
            </div>
          )}
        <div className='re-center hide'>
          <i className='icon-Location-Arrow' />
        </div>
      </div>
      <div
        className={
          currentOrder.stage >= ORDER_STAGE_TYPES.ALL_DONE
            ? state.datebar
              ? state.overflow
                ? 'deliveryInfo dateActive true delivery'
                : 'deliveryInfo dateActive delivery'
              : 'deliveryInfo delivery'
            : state.datebar
            ? state.overflow
              ? 'deliveryInfo dateActive true'
              : 'deliveryInfo dateActive'
            : 'deliveryInfo'
        }
      >
        {currentOrder && currentOrder.orderType === MENU_ORDER_TYPE.pickup && (
          <div className='testify'>
            <p className='titleInfo'>Pickup</p>
            <ul>
              <li className='searchAdress'>
                <i className='icon-Location-Pin' />
                {storeConfig && `${storeConfig.address}`}
              </li>
              <li className='dateEdit'>
                <i className='icon-Clock' />
                <strong>{getTimeToDisplay()}</strong> Pickup Time
              </li>
              <li className='oNotes'>
                <i className='icon-Write-Message' />
                <input
                  type='text'
                  name='notes'
                  maxLength='140'
                  onChange={(e) => changeNotes(e)}
                  placeholder='Order Notes'
                  value={state.orderNotes}
                />
              </li>
            </ul>
          </div>
        )}
        {currentOrder &&
          currentOrder.orderType === MENU_ORDER_TYPE.delivery && (
            <div className='testify'>
              <p className='titleInfo'>Delivery</p>
              <ul>
                <li onClick={showSearch} className='searchAdress'>
                  <i className='icon-Location-Pin' />
                  {currentOrder.address}
                </li>
                <li className='apartment'>
                  <i className='icon-Home' />
                  <input
                    type='text'
                    name='apartment'
                    maxLength='140'
                    onChange={(e) => changeUnit(e)}
                    placeholder='Apartment / Suite / Floor'
                    value={state.apartment}
                  />
                </li>
                <li className='dateEdit'>
                  <i className='icon-Clock' />
                  <strong>{getTimeToDisplay()}</strong> Arrival Time
                </li>
                <li className='oNotes'>
                  <i className='icon-Write-Message' />
                  <input
                    type='text'
                    name='notes'
                    maxLength='140'
                    onChange={(e) => changeNotes(e)}
                    placeholder='Order Notes'
                    value={state.orderNotes}
                  />
                </li>
              </ul>
            </div>
          )}
        {currentOrder && currentOrder.orderType === MENU_ORDER_TYPE.dinein && (
          <div className='testify'>
            <p className='titleInfo'>Dine-in</p>
            <ul>
              <li className='oNotes'>
                <i className='icon-Write-Message' />
                <input
                  type='text'
                  name='notes'
                  maxLength='140'
                  onChange={(e) => changeNotes(e)}
                  placeholder='Order Notes'
                  value={state.orderNotes}
                />
              </li>
            </ul>
          </div>
        )}
        <div className={state.searchBar ? 'searchBar active' : 'searchBar'}>
          <span className='icon-Search' />
          <Geosuggest
            ref={autoSuggestRef}
            onKeyPress={startCase}
            inputClassName='searchReview'
            country='au'
            types={['geocode']}
            className='deliverySearchBox'
            placeholder='Enter delivery address'
            suggestsClassName={
              state.start
                ? 'autoSuggestionWrap removeStar'
                : 'autoSuggestionWrap'
            }
            minLength={3}
            highlightMatch={true}
            skipSuggest={skipSuggest}
            renderSuggestItem={(suggest) => (
              <div>
                {suggest.description
                  .substring(0, suggest.matchedSubstrings.length)
                  .replace(', Australia', '')
                  .replace(/Street /gi, 'St ')
                  .replace(/Court /gi, 'Crt ')}
                <span>
                  {suggest.description
                    .substring(suggest.matchedSubstrings.length)
                    .replace(', Australia', '')
                    .replace(/QLD$/, '')
                    .replace(/VIC$/, '')
                    .replace(/NSW$/, '')
                    .replace(/TAS$/, '')
                    .replace(/WA$/, '')
                    .replace(/SA$/, '')
                    .replace(/NT$/, '')
                    .replace(/Street /gi, 'St ')
                    .replace(/Court /gi, 'Crt ')}
                </span>
              </div>
            )}
            onSuggestSelect={(suggest) => updateDeliveryAddress(suggest)}
            onSuggestNoResults={() => setLocalState({ start: false })}
            getSuggestLabel={(suggest) =>
              suggest.description
                .replace(', Australia', '')
                .replace(/QLD$/, '')
                .replace(/VIC$/, '')
                .replace(/NSW$/, '')
                .replace(/TAS$/, '')
                .replace(/WA$/, '')
                .replace(/SA$/, '')
                .replace(/NT$/, '')
                .replace(/Street /gi, 'St ')
                .replace(/Court /gi, 'Crt ')
            }
          />
          <span className='icon-X' onClick={hideSearch} />
          {state.deliveryAddrError && (
            <span>{`We do not delivery to this address.`}</span>
          )}
        </div>
        <div className={state.datebar ? 'dateBar active' : 'dateBar'}>
          <span className='icon-Calendar' />
          <DatePickerWrap storeConfig={storeConfig} />
          <span className='icon-X' onClick={hideCalendar} />
        </div>
      </div>
    </div>
  );
}
