import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/performance';

//Next order Development
var dev = {
  apiKey: 'AIzaSyAubC4ZX6lpf5EGEI_ki11sdwOyNw8c_C4',
  authDomain: 'next-order-devenv.firebaseapp.com',
  databaseURL: 'https://next-order-devenv.firebaseio.com',
  projectId: 'next-order-devenv',
  storageBucket: 'next-order-devenv.appspot.com',
  messagingSenderId: '356504613086',
  appId: '1:356504613086:web:36b46ba799050839bad24f',
};

//Next order production
var prod = {
  apiKey: 'AIzaSyBhCdHsG0sNgxnmDPyi4zBs-5PWZdYlgTY',
  authDomain: 'next-order-prodenv.firebaseapp.com',
  databaseURL: 'https://next-order-prodenv.firebaseio.com',
  projectId: 'next-order-prodenv',
  storageBucket: 'next-order-prodenv.appspot.com',
  messagingSenderId: '81438711702',
  appId: '1:81438711702:web:bb52f7f4538e7a568987b7',
  measurementId: 'G-4NH00ESYXH',
};

const config = process.env.REACT_APP_ENV === 'production' ? prod : dev;

firebase.initializeApp(config);
firebase.performance();

export const firebaseAnalyticsNO = firebase.analytics();

export const _firestore = firebase.firestore(); //Realtime database

// this improves firestore responses in test environment
// if (window.Cypress) {
// const settings = { experimentalForceLongPolling: true };
// _firestore.settings(settings);
// }

export const _auth = firebase.auth();
