export const getPlatformAndCardCharges = (orderObj) => {
  let cardChargesTitle = '';
  let cardCharges = '';
  if (!orderObj) {
    return { cardChargesTitle, cardCharges };
  } else if (
    orderObj?.creditCardCharges &&
    Number(orderObj.creditCardCharges) !== 0 &&
    (orderObj?.isPlatformSurchargeLevied || orderObj?.gfoSurchargeEnabled) &&
    (orderObj?.platformSurchargeAmt &&
      Number(orderObj.platformSurchargeAmt)) !== 0
  ) {
    cardChargesTitle = 'Platform & Credit Card Charges';
    cardCharges =
      Number(orderObj.creditCardCharges) +
      Number(orderObj.platformSurchargeAmt);
  } else if (
    orderObj?.creditCardCharges &&
    Number(orderObj.creditCardCharges) !== 0
  ) {
    cardChargesTitle = 'Credit Card Charges';
    cardCharges = Number(orderObj.creditCardCharges);
  } else if (
    (orderObj?.isPlatformSurchargeLevied || orderObj?.gfoSurchargeEnabled) &&
    (orderObj?.platformSurchargeAmt &&
      Number(orderObj.platformSurchargeAmt)) !== 0
  ) {
    cardChargesTitle = 'Platform Charges';
    cardCharges = Number(orderObj.platformSurchargeAmt);
  }

  if (cardCharges) {
    cardCharges = cardCharges?.toFixed(2);
  }

  return { cardChargesTitle, cardCharges };
};
