import ItemView from '../../components/ItemView';

// .example1
export default function OrderView({
  rightSideViewVisibilityIndex,
  itemInProgress,
  ingredientCategories,
  orderItem,
  openedItemId,
  closeOrder,
  addToOrder,

  sidebarAnimationClass,
}) {
  const renderIt = () => {
    return (
      <ItemView
        ingredientCategories={ingredientCategories}
        orderItem={orderItem}
        name={orderItem && orderItem.name ? orderItem.name : ''}
        imageUrl={orderItem && orderItem.urlS3 ? orderItem.urlS3 : ''}
        description={
          orderItem && orderItem.description ? orderItem.description : ''
        }
        itemInProgress={itemInProgress}
        closeItem={closeOrder}
        addToOrder={addToOrder}
        rightSideViewVisibilityIndex={rightSideViewVisibilityIndex}
        openedFrom={'orderView'}
      />
    );
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
      className={sidebarAnimationClass}
    >
      {!!openedItemId && renderIt()}
    </div>
  );
}
