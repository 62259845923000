import moment from 'moment-timezone';
import sortBy from 'lodash/sortBy';
// import storeConfig from '../configuration/storeConfig';

const convertSecondsTo12Hour = (timeStamp) => {
  let hours = timeStamp / 3600;
  let minutes = (timeStamp / 60) % 60;
  let ampm = '';
  if (hours === 24) {
    hours = 23;
    minutes = 59;
  }
  hours >= 12 ? (ampm = 'PM') : (ampm = 'AM');
  return moment(`${hours}:${minutes} ${ampm}`, 'HH:mm A').format('hh:mm A');
};

const getCurrentShiftFun = (storeConfig, allShifts, closeDates) => {
  let currentShift = {
    isPickupOpen: false,
    isDeliveryOpen: false,
    isRestaurantOpen: false,
    pickUpTimeSlot: '',
    deliverySlot: '',
    restaurantSlot: '',
    closes: '',
    pickup24: false,
    delivery24: false,
    restaurant24: false,
    endTimeStampP: 0,
    endTimeStampD: 0,
    endTimeStampR: 0,
    isNBShift: false,
    nbTimeSlot: '',
  };
  let today = moment().tz(storeConfig.timeZone);
  var day = today.format(`E`);

  let timeStamp =
    Number(today.format('HH')) * 60 * 60 +
    Number(today.format('mm')) * 60 +
    Number(today.format('ss'));
  allShifts.map((obj) => {
    if (
      obj.day === day &&
      obj.openingStartDisplay &&
      obj.openingEndDisplay &&
      !obj.isOrderingPaused
    ) {
      if (
        timeStamp >= Number(obj.openingStart) &&
        timeStamp <= Number(obj.openingEnd)
      ) {
        currentShift.isPickupOpen = true;
        currentShift.pickUpTimeSlot = `${obj.openingStartDisplay} - ${obj.openingEndDisplay}`;
        currentShift.closes = obj.openingEndDisplay;
        currentShift.endTimeStampP = obj.openingEnd;
      } else if (
        timeStamp < Number(obj.openingStart) &&
        timeStamp < Number(obj.openingEnd)
      ) {
        currentShift.opens = obj.openingStartDisplay;
      }
      currentShift.pickup24 = obj.pickup24;
      currentShift.delivery24 = obj.delivery24;
      currentShift.restaurant24 = obj.restaurant24;
      currentShift.noPickup = obj.noPickup;
      currentShift.noDelivery = obj.noDelivery;
      currentShift.noDineIn = obj.noDineIn;
    }
    if (
      obj.day === day &&
      obj.deliveryStartDisplay &&
      obj.deliveryEndDisplay &&
      !obj.isOrderingPaused
    ) {
      if (
        timeStamp >= Number(obj.deliveryStart) &&
        timeStamp <= Number(obj.deliveryEnd)
      ) {
        currentShift.isDeliveryOpen = true;
        currentShift.deliverySlot = `${obj.deliveryStartDisplay} - ${obj.deliveryEndDisplay}`;
        currentShift.closes = obj.deliveryEndDisplay;
        currentShift.endTimeStampD = obj.deliveryEnd;
      }
      currentShift.pickup24 = obj.pickup24;
      currentShift.delivery24 = obj.delivery24;
      currentShift.restaurant24 = obj.restaurant24;
      currentShift.noPickup = obj.noPickup;
      currentShift.noDelivery = obj.noDelivery;
      currentShift.noDineIn = obj.noDineIn;
    }
    if (
      obj.day === day &&
      obj.restaurantStartDisplay &&
      obj.restaurantEndDisplay
    ) {
      if (
        timeStamp >= Number(obj.restaurantStart) &&
        timeStamp <= Number(obj.restaurantEnd)
      ) {
        currentShift.isRestaurantOpen = true;
        currentShift.restaurantSlot = `${obj.restaurantStartDisplay} - ${obj.restaurantEndDisplay}`;
        currentShift.closes = obj.restaurantEndDisplay;
        currentShift.endTimeStampR = obj.restaurantEnd;
      }
      currentShift.pickup24 = obj.pickup24;
      currentShift.delivery24 = obj.delivery24;
      currentShift.restaurant24 = obj.restaurant24;
      currentShift.noPickup = obj.noPickup;
      currentShift.noDelivery = obj.noDelivery;
      currentShift.noDineIn = obj.noDineIn;
    }
    return obj;
  });
  if (currentShift.endTimeStampR === '86400') {
    var dayA =
      moment().add(1, 'day').tz(storeConfig.timeZone).weekday() === 0
        ? '7'
        : moment().tz(storeConfig.timeZone).weekday().toString();
    allShifts.map((obj) => {
      if (
        obj.day === dayA &&
        obj.restaurantStartDisplay &&
        obj.restaurantEndDisplay &&
        obj.restaurantStart === '0'
      ) {
        currentShift.isNBShift = true;
        currentShift.nbTimeSlot = `${obj.openingStartDisplay} - ${obj.openingEndDisplay}`;
        currentShift.closes = obj.restaurantEndDisplay;
      }
      return obj;
    });
  }
  let tcDates = [];
  if (closeDates.length > 0) {
    tcDates = closeDates.filter((m) => {
      return m.closeDate === today.format('YYYY-MM-DD');
    });
  }
  if (tcDates.length > 0) {
    let dateObjClosed = tcDates[0];
    if (dateObjClosed.isDeliveryClosed) currentShift.isDeliveryOpen = false;
    if (dateObjClosed.isAllClosed) {
      currentShift.isDeliveryOpen = false;
      currentShift.isPickupOpen = false;
      currentShift.isRestaurantOpen = false;
    }
  }
  return currentShift;
};

const getRestauranTimeArraysFun = (storeConfig, allShifts, closeDates) => {
  let reservationTimes = [];
  for (let i = 0; i < 7; i++) {
    if (i === 0) {
      reservationTimes.push({
        date: moment().tz(storeConfig.timeZone).add(i, 'd'),
        dateString: moment()
          .tz(storeConfig.timeZone)
          .add(i, 'd')
          .format('YYYY-MM-DD'),
        isToday: true,
      });
      reservationTimes.push({
        date: moment().tz(storeConfig.timeZone).add(i, 'd'),
        dateString: moment()
          .tz(storeConfig.timeZone)
          .add(i, 'd')
          .format('YYYY-MM-DD'),
        isToday: false,
      });
    } else {
      reservationTimes.push({
        date: moment().tz(storeConfig.timeZone).add(i, 'd'),
        dateString: moment()
          .tz(storeConfig.timeZone)
          .add(i, 'd')
          .format('YYYY-MM-DD'),
        isToday: false,
      });
    }
  }
  reservationTimes.map((obj, i) => {
    obj.display = obj.date.format('dddd');
    obj.day = obj.date.day().toString();
    let todaysShifts = allShifts.filter((s) => {
      return Number(s.day) === Number(obj.date.isoWeekday());
    });
    obj.id = i;
    let times = [],
      timeGroup = [];
    todaysShifts.map((t) => {
      if (!t.noDineIn) {
        if (!t.restaurant24) {
          if (t.openingEnd && t.openingStart) {
            if (obj.isToday) {
              let shiftStartObj = moment(
                `${obj.date.format('YYYY-MM-DD')} ${convertSecondsTo12Hour(
                  t.restaurantStart
                )}`,
                'YYYY-MM-DD hh:mm A'
              );
              let shiftEndObj = moment(
                `${obj.date.format('YYYY-MM-DD')} ${convertSecondsTo12Hour(
                  t.restaurantEnd
                )}`,
                'YYYY-MM-DD hh:mm A'
              );
              let today = moment(
                moment().tz(storeConfig.timeZone).format('YYYY-MM-DD hh:mm A'),
                'YYYY-MM-DD hh:mm A'
              );
              if (shiftEndObj.isBefore(today)) {
                //No actions required as shift is finished
              } else if (
                shiftStartObj.isAfter(today) &&
                shiftEndObj.isAfter(today)
              ) {
                //Normal default time operation as shift is yet to start
                let tempTime = shiftStartObj;
                while (tempTime.isSameOrBefore(shiftEndObj)) {
                  let time = {
                    display: tempTime.format('hh:mm A'),
                    timeStamp: t.openingStart,
                    displayForUse: tempTime.format('hh:mm A'),
                    shiftId: t._id,
                    timeObjFinal: tempTime.format('YYYY-MM-DD'),
                    mTimeStamp: tempTime.valueOf(),
                  };
                  times.push(time);
                  timeGroup.push(tempTime.format('hh:mm A'));
                  tempTime.add(15, 'minutes');
                }
              } else if (
                shiftStartObj.isBefore(today) &&
                shiftEndObj.isAfter(today)
              ) {
                let tempTime = today.add(15, 'minutes');
                const roundedUp = Math.ceil(tempTime.minute() / 15) * 15;
                tempTime = tempTime.minute(roundedUp).second(0);
                while (tempTime.isSameOrBefore(shiftEndObj)) {
                  let time = {
                    display: tempTime.format('hh:mm A'),
                    timeStamp: t.openingStart,
                    displayForUse: tempTime.format('hh:mm A'),
                    shiftId: t._id,
                    timeObjFinal: tempTime.format('YYYY-MM-DD'),
                    mTimeStamp: tempTime.valueOf(),
                  };
                  times.push(time);
                  timeGroup.push(tempTime.format('hh:mm A'));
                  tempTime.add(15, 'minutes');
                }
              }
            } else {
              let shiftStartObj = moment(
                `${obj.date.format('YYYY-MM-DD')} ${convertSecondsTo12Hour(
                  t.restaurantStart
                )}`,
                'YYYY-MM-DD hh:mm A'
              );
              let shiftEndObj = moment(
                `${obj.date.format('YYYY-MM-DD')} ${convertSecondsTo12Hour(
                  t.restaurantEnd
                )}`,
                'YYYY-MM-DD hh:mm A'
              );
              //Normal default time operation as shift is yet to start
              let tempTime = shiftStartObj;
              while (tempTime.isSameOrBefore(shiftEndObj)) {
                let time = {
                  display: tempTime.format('hh:mm A'),
                  timeStamp: t.openingStart,
                  displayForUse: tempTime.format('hh:mm A'),
                  shiftId: t._id,
                  timeObjFinal: tempTime.format('YYYY-MM-DD'),
                  mTimeStamp: tempTime.valueOf(),
                };
                times.push(time);
                timeGroup.push(tempTime.format('hh:mm A'));
                tempTime.add(15, 'minutes');
              }
            }
            return t;
          }
        } else {
          if (obj.isToday) {
            let shiftStartObj = moment(
              `${obj.date.format('YYYY-MM-DD')} 00:00 AM}`,
              'YYYY-MM-DD hh:mm A'
            );
            let shiftEndObj = moment(
              `${obj.date.format('YYYY-MM-DD')} 11:59 PM`,
              'YYYY-MM-DD hh:mm A'
            );
            let today = moment(
              moment().tz(storeConfig.timeZone).format('YYYY-MM-DD HH:mm A'),
              'YYYY-MM-DD hh:mm A'
            );
            if (shiftEndObj.isBefore(today)) {
              //No actions required as shift is finished
            } else if (
              shiftStartObj.isAfter(today) &&
              shiftEndObj.isAfter(today)
            ) {
              //Normal default time operation as shift is yet to start
              let tempTime = shiftStartObj;
              while (tempTime.isSameOrBefore(shiftEndObj)) {
                let time = {
                  display: tempTime.format('hh:mm A'),
                  timeStamp: t.openingStart,
                  displayForUse: tempTime.format('hh:mm A'),
                  shiftId: t._id,
                  timeObjFinal: tempTime.format('YYYY-MM-DD'),
                  mTimeStamp: tempTime.valueOf(),
                };
                times.push(time);
                timeGroup.push(tempTime.format('hh:mm A'));
                tempTime.add(15, 'minutes');
              }
            } else if (
              shiftStartObj.isBefore(today) &&
              shiftEndObj.isAfter(today)
            ) {
              let tempTime = today.add(15, 'minutes');
              const roundedUp = Math.ceil(tempTime.minute() / 15) * 15;
              tempTime = tempTime.minute(roundedUp).second(0);
              while (tempTime.isSameOrBefore(shiftEndObj)) {
                let time = {
                  display: tempTime.format('hh:mm A'),
                  timeStamp: t.openingStart,
                  displayForUse: tempTime.format('hh:mm A'),
                  shiftId: t._id,
                  timeObjFinal: tempTime.format('YYYY-MM-DD'),
                  mTimeStamp: tempTime.valueOf(),
                };
                times.push(time);
                timeGroup.push(tempTime.format('hh:mm A'));
                tempTime.add(15, 'minutes');
              }
            }
          } else {
            let shiftStartObj = moment(
              `${obj.date.format('YYYY-MM-DD')} 00:00 AM}`,
              'YYYY-MM-DD hh:mm A'
            );
            let shiftEndObj = moment(
              `${obj.date.format('YYYY-MM-DD')} 11:59 PM`,
              'YYYY-MM-DD hh:mm A'
            );
            let tempTime = shiftStartObj;
            while (tempTime.isSameOrBefore(shiftEndObj)) {
              let time = {
                display: tempTime.format('hh:mm A'),
                timeStamp: t.openingStart,
                displayForUse: tempTime.format('hh:mm A'),
                shiftId: t._id,
                timeObjFinal: tempTime.format('YYYY-MM-DD'),
                mTimeStamp: tempTime.valueOf(),
              };
              times.push(time);
              timeGroup.push(tempTime.format('hh:mm A'));
              tempTime.add(15, 'minutes');
            }
          }
        }
      }
      return t;
    });
    obj.times = times;
    obj.timeGroup = { displayTime: timeGroup };
    obj.valueGroup = { displayTime: times.length > 0 ? times[0].display : '' };
    return obj;
  });
  // if (closeDates.length > 0) {
  //   reservationTimes = reservationTimes.filter(obj => {
  //     let tcDate = closeDates.filter(m => {
  //       return m.closeDate === obj.dateString && m.isAllClosed;
  //     });
  //     if (tcDate.length > 0) return false;
  //     else return true;
  //   });
  // }
  reservationTimes = reservationTimes.filter((p) => {
    return p.times.length !== 0;
  });
  return reservationTimes;
};

const getPickupTimeArraysFun = (
  storeConfig,
  allShifts,
  orderSetup,
  closeDates
) => {
  let pickUpTimes = [];
  pickUpTimes.push({
    date: moment().tz(storeConfig.timeZone),
    dateString: moment().tz(storeConfig.timeZone).format('YYYY-MM-DD'),
  });
  let preOrderingDays = orderSetup.preOrderingDays
    ? orderSetup.preOrderingDays
    : 5;
  if (orderSetup.isPreOrderingEnabled) {
    for (let i = 1; i < preOrderingDays; i++) {
      pickUpTimes.push({
        date: moment().tz(storeConfig.timeZone).add(i, 'd'),
        dateString: moment()
          .tz(storeConfig.timeZone)
          .add(i, 'd')
          .format('YYYY-MM-DD'),
      });
    }
  }

  pickUpTimes.map((obj, i) => {
    if (i === 0) obj.display = 'Today';
    else if (i === 1) obj.display = 'Tomorrow';
    else if (i < 7 && i > 1) obj.display = obj.date.format('dddd');
    else obj.display = obj.date.format('MMM DD');

    obj.day = obj.date.day().toString();
    let todaysShifts = allShifts.filter((s) => {
      return Number(s.day) === Number(obj.date.isoWeekday());
    });
    obj.id = i;
    let times = [],
      timeGroup = [];
    todaysShifts.map((t) => {
      if (!t.isOrderingPaused && !t.noPickup) {
        if (!t.pickup24) {
          if (t.openingEnd && typeof t.openingStart === `number`) {
            let shiftStartObj = moment(
              `${obj.date.format('YYYY-MM-DD')} ${convertSecondsTo12Hour(
                t.openingStart
              )}`,
              'YYYY-MM-DD hh:mm A'
            );
            let shiftEndObj = moment(
              `${obj.date.format('YYYY-MM-DD')} ${convertSecondsTo12Hour(
                t.openingEnd
              )}`,
              'YYYY-MM-DD hh:mm A'
            );
            let today = moment(
              moment().tz(storeConfig.timeZone).format('YYYY-MM-DD hh:mm A'),
              'YYYY-MM-DD hh:mm A'
            );
            if (shiftEndObj.isBefore(today)) {
              //No actions required as shift is finished
            } else if (
              shiftStartObj.isAfter(today) &&
              shiftEndObj.isAfter(today) &&
              orderSetup.isPreOrderingEnabled
            ) {
              //Normal default time operation as shift is yet to start
              let tempTime = shiftStartObj;
              if (shiftStartObj.format('hh:mm A').toString() === '12:00 AM') {
                tempTime = shiftStartObj;
              } else {
                tempTime = shiftStartObj.add(
                  Number(orderSetup.defaultPickUpTime),
                  'minutes'
                );
                const roundedUp = Math.ceil(tempTime.minute() / 15) * 15;
                tempTime = tempTime.minute(roundedUp).second(0);
              }
              while (
                orderSetup && orderSetup.isOrderAllowedTillClosing
                  ? tempTime.isSameOrBefore(shiftEndObj)
                  : tempTime.isBefore(shiftEndObj)
              ) {
                let time = {
                  display: tempTime.format('hh:mm A'),
                  timeStamp: t.openingStart,
                  displayForUse: tempTime.format('hh:mm A'),
                  shiftId: t._id,
                  timeObjFinal: tempTime.format('YYYY-MM-DD'),
                  mTimeStamp: tempTime.valueOf(),
                };
                times.push(time);
                timeGroup.push(tempTime.format('hh:mm A'));
                tempTime.add(15, 'minutes');
              }
            } else if (
              shiftStartObj.isBefore(today) &&
              shiftEndObj.isAfter(today)
            ) {
              let todayTT = moment(
                moment().tz(storeConfig.timeZone).format('YYYY-MM-DD hh:mm A'),
                'YYYY-MM-DD hh:mm A'
              ).add(Number(orderSetup.pickUpTime), 'minutes');
              let todayTTWithoutPickup = moment(
                moment().tz(storeConfig.timeZone).format('YYYY-MM-DD hh:mm A'),
                'YYYY-MM-DD hh:mm A'
              );
              // Add current time, rounding off time and then go with default time
              let tempTime = today.add(
                Number(orderSetup.pickUpTime),
                'minutes'
              );
              const displayTime = `${orderSetup.pickUpTime} mins`;
              let timeD = {
                display: displayTime,
                alternateDisplay: 'ASAP',
                timeStamp: t.openingStart,
                displayForUse: todayTT.format('hh:mm A'),
                shiftId: t._id,
                timeObjFinal: todayTT.format('YYYY-MM-DD'),
                mTimeStamp: todayTT.valueOf(),
              };
              if (
                orderSetup && orderSetup.isOrderAllowedTillClosing
                  ? todayTTWithoutPickup.isSameOrBefore(shiftEndObj)
                  : todayTT.isBefore(shiftEndObj)
              ) {
                times.push(timeD);
                timeGroup.push(displayTime);
              }
              if (orderSetup.isPreOrderingEnabled) {
                const roundedUp = Math.ceil(tempTime.minute() / 15) * 15;
                tempTime = tempTime.minute(roundedUp).second(0);
                if (
                  orderSetup &&
                  orderSetup.isOrderAllowedTillClosing &&
                  tempTime.isAfter(shiftEndObj)
                ) {
                  let time = {
                    display: shiftEndObj.format('hh:mm A'),
                    timeStamp: t.openingStart,
                    displayForUse: shiftEndObj.format('hh:mm A'),
                    shiftId: t._id,
                    timeObjFinal: shiftEndObj.format('YYYY-MM-DD'),
                    mTimeStamp: shiftEndObj.valueOf(),
                  };
                  times.push(time);
                  timeGroup.push(tempTime.format('hh:mm A'));
                }
                while (
                  orderSetup && orderSetup.isOrderAllowedTillClosing
                    ? tempTime.isSameOrBefore(shiftEndObj)
                    : tempTime.isBefore(shiftEndObj)
                ) {
                  let time = {
                    display: tempTime.format('hh:mm A'),
                    timeStamp: t.openingStart,
                    displayForUse: tempTime.format('hh:mm A'),
                    shiftId: t._id,
                    timeObjFinal: tempTime.format('YYYY-MM-DD'),
                    mTimeStamp: tempTime.valueOf(),
                  };
                  times.push(time);
                  timeGroup.push(tempTime.format('hh:mm A'));
                  tempTime.add(15, 'minutes');
                }
              }
            }
            return t;
          }
        } else {
          let shiftStartObj = moment(
            `${obj.date.format('YYYY-MM-DD')} 00:00 AM}`,
            'YYYY-MM-DD hh:mm A'
          );
          let shiftEndObj = moment(
            `${obj.date.format('YYYY-MM-DD')} 11:59 PM`,
            'YYYY-MM-DD hh:mm A'
          );
          let today = moment(
            moment().tz(storeConfig.timeZone).format('YYYY-MM-DD HH:mm A'),
            'YYYY-MM-DD hh:mm A'
          );
          if (shiftEndObj.isBefore(today)) {
          } else if (
            shiftStartObj.isAfter(today) &&
            shiftEndObj.isAfter(today)
          ) {
            //Normal default time operation as shift is yet to start
            let tempTime = shiftStartObj;
            const roundedUp = Math.ceil(tempTime.minute() / 15) * 15;
            tempTime = tempTime.minute(roundedUp).second(0);
            while (tempTime.isBefore(shiftEndObj)) {
              let time = {
                display: tempTime.format('hh:mm A'),
                timeStamp: t.openingStart,
                displayForUse: tempTime.format('hh:mm A'),
                shiftId: t._id,
                timeObjFinal: tempTime.format('YYYY-MM-DD'),
                mTimeStamp: tempTime.valueOf(),
              };
              times.push(time);
              timeGroup.push(tempTime.format('hh:mm A'));
              tempTime.add(15, 'minutes');
            }
          } else if (
            shiftStartObj.isBefore(today) &&
            shiftEndObj.isAfter(today)
          ) {
            let todayTT = moment(
              moment().tz(storeConfig.timeZone).format('YYYY-MM-DD hh:mm A'),
              'YYYY-MM-DD hh:mm A'
            ).add(Number(orderSetup.pickUpTime), 'minutes');
            let tempTime = today.add(Number(orderSetup.pickUpTime), 'minutes');

            // Add current time, rounding off time and then go with default time
            const displayTime = `${orderSetup.pickUpTime} mins`;
            let timeD = {
              display: displayTime,
              alternateDisplay: 'ASAP',
              timeStamp: t.openingStart,
              displayForUse: todayTT.format('hh:mm A'),
              shiftId: t._id,
              timeObjFinal: todayTT.format('YYYY-MM-DD'),
              mTimeStamp: todayTT.valueOf(),
            };
            times.push(timeD);
            timeGroup.push(displayTime);

            const roundedUp = Math.ceil(tempTime.minute() / 15) * 15;
            tempTime = tempTime.minute(roundedUp).second(0);
            while (tempTime.isBefore(shiftEndObj)) {
              let time = {
                display: tempTime.format('hh:mm A'),
                timeStamp: t.openingStart,
                displayForUse: tempTime.format('hh:mm A'),
                shiftId: t._id,
                timeObjFinal: tempTime.format('YYYY-MM-DD'),
                mTimeStamp: tempTime.valueOf(),
              };
              times.push(time);
              timeGroup.push(tempTime.format('hh:mm A'));
              tempTime.add(15, 'minutes');
            }
          }
        }
      }
      return t;
    });
    obj.times = times;
    obj.timeGroup = { displayTime: timeGroup };
    obj.valueGroup = { displayTime: times.length > 0 ? times[0].display : '' };
    return obj;
  });

  if (closeDates.length > 0) {
    pickUpTimes = pickUpTimes.filter((obj) => {
      let tcDate = closeDates.filter((m) => {
        return m.closeDate === obj.dateString && m.isAllClosed;
      });
      if (tcDate.length > 0) return false;
      else return true;
    });
  }

  pickUpTimes = pickUpTimes.filter((p) => {
    return p.times.length !== 0;
  });
  pickUpTimes =
    pickUpTimes.length > preOrderingDays
      ? pickUpTimes.slice(0, preOrderingDays)
      : pickUpTimes;
  pickUpTimes = pickUpTimes.map((p) => {
    p.times = sortBy(p.times, 'mTimeStamp');
    return p;
  });
  return pickUpTimes;
};

const getDeliveryTimeArraysFun = (
  storeConfig,
  allShifts,
  orderSetup,
  closeDates
) => {
  let deliveryTimes = [];
  deliveryTimes.push({
    date: moment().tz(storeConfig.timeZone),
    dateString: moment().tz(storeConfig.timeZone).format('YYYY-MM-DD'),
  });
  let preOrderingDays = orderSetup.preOrderingDays
    ? orderSetup.preOrderingDays
    : 5;
  if (orderSetup.isPreOrderingEnabled) {
    for (let i = 1; i < preOrderingDays; i++) {
      deliveryTimes.push({
        date: moment().tz(storeConfig.timeZone).add(i, 'd'),
        dateString: moment()
          .tz(storeConfig.timeZone)
          .add(i, 'd')
          .format('YYYY-MM-DD'),
      });
    }
  }
  deliveryTimes.map((obj, i) => {
    if (i === 0) obj.display = 'Today';
    else if (i === 1) obj.display = 'Tomorrow';
    else if (i < 7 && i > 1) obj.display = obj.date.format('dddd');
    else obj.display = obj.date.format('MMM DD');

    obj.day = obj.date.day().toString();
    let todaysShifts = allShifts.filter((s) => {
      return Number(s.day) === Number(obj.date.isoWeekday());
    });
    obj.id = i;
    let times = [],
      timeGroup = [];
    todaysShifts.map((t) => {
      if (!t.isOrderingPaused && !t.noDelivery) {
        if (!t.delivery24) {
          if (t.deliveryEnd && typeof t.deliveryEnd === `number`) {
            let shiftStartObj = moment(
              `${obj.date.format('YYYY-MM-DD')} ${convertSecondsTo12Hour(
                t.deliveryStart
              )}`,
              'YYYY-MM-DD hh:mm A'
            );
            let shiftEndObj = moment(
              `${obj.date.format('YYYY-MM-DD')} ${convertSecondsTo12Hour(
                t.deliveryEnd
              )}`,
              'YYYY-MM-DD hh:mm A'
            );
            let today = moment(
              moment().tz(storeConfig.timeZone).format('YYYY-MM-DD hh:mm A'),
              'YYYY-MM-DD hh:mm A'
            );
            if (shiftEndObj.isBefore(today)) {
              //No actions required as shift is finished
            } else if (
              shiftStartObj.isAfter(today) &&
              shiftEndObj.isAfter(today) &&
              orderSetup.isPreOrderingEnabled
            ) {
              //Normal default time operation as shift is yet to start
              let tempTime = shiftStartObj;
              if (shiftStartObj.format('hh:mm A').toString() === '12:00 AM') {
                tempTime = shiftStartObj;
              } else {
                tempTime = shiftStartObj.add(
                  Number(orderSetup.defaultDeliveryTime),
                  'minutes'
                );
                const roundedUp = Math.ceil(tempTime.minute() / 15) * 15;
                tempTime = tempTime.minute(roundedUp).second(0);
              }
              while (
                orderSetup && orderSetup.isOrderAllowedTillClosing
                  ? tempTime.isSameOrBefore(shiftEndObj)
                  : tempTime.isBefore(shiftEndObj)
              ) {
                let time = {
                  display: tempTime.format('hh:mm A'),
                  timeStamp: t.openingStart,
                  displayForUse: tempTime.format('hh:mm A'),
                  shiftId: t._id,
                  timeObjFinal: tempTime.format('YYYY-MM-DD'),
                  mTimeStamp: tempTime.valueOf(),
                };
                times.push(time);
                timeGroup.push(tempTime.format('hh:mm A'));
                tempTime.add(15, 'minutes');
              }
            } else if (
              shiftStartObj.isBefore(today) &&
              shiftEndObj.isAfter(today)
            ) {
              // Add current time, rounding off time and then go with default time
              let todayTT = moment(
                moment().tz(storeConfig.timeZone).format('YYYY-MM-DD hh:mm A'),
                'YYYY-MM-DD hh:mm A'
              ).add(Number(orderSetup.deliveryTime), 'minutes');
              let todayTTWithoutDeliveryTime = moment(
                moment().tz(storeConfig.timeZone).format('YYYY-MM-DD hh:mm A'),
                'YYYY-MM-DD hh:mm A'
              );
              let tempTime = today.add(
                Number(orderSetup.deliveryTime),
                'minutes'
              );
              const displayTime = `${orderSetup.deliveryTime} mins`;
              let timeD = {
                display: displayTime,
                alternateDisplay: 'ASAP',
                timeStamp: t.openingStart,
                displayForUse: todayTT.format('hh:mm A'),
                shiftId: t._id,
                timeObjFinal: todayTT.format('YYYY-MM-DD'),
                mTimeStamp: todayTT.valueOf(),
              };
              if (
                orderSetup && orderSetup.isOrderAllowedTillClosing
                  ? todayTTWithoutDeliveryTime.isSameOrBefore(shiftEndObj)
                  : todayTT.isBefore(shiftEndObj)
              ) {
                times.push(timeD);
                timeGroup.push(displayTime);
              }
              if (orderSetup.isPreOrderingEnabled) {
                const roundedUp = Math.ceil(tempTime.minute() / 15) * 15;
                tempTime = tempTime.minute(roundedUp).second(0);
                if (
                  orderSetup &&
                  orderSetup.isOrderAllowedTillClosing &&
                  tempTime.isAfter(shiftEndObj)
                ) {
                  let time = {
                    display: shiftEndObj.format('hh:mm A'),
                    timeStamp: t.openingStart,
                    displayForUse: shiftEndObj.format('hh:mm A'),
                    shiftId: t._id,
                    timeObjFinal: shiftEndObj.format('YYYY-MM-DD'),
                    mTimeStamp: shiftEndObj.valueOf(),
                  };
                  times.push(time);
                  timeGroup.push(tempTime.format('hh:mm A'));
                }
                while (
                  orderSetup && orderSetup.isOrderAllowedTillClosing
                    ? tempTime.isSameOrBefore(shiftEndObj)
                    : tempTime.isBefore(shiftEndObj)
                ) {
                  let time = {
                    display: tempTime.format('hh:mm A'),
                    timeStamp: t.openingStart,
                    displayForUse: tempTime.format('hh:mm A'),
                    shiftId: t._id,
                    timeObjFinal: tempTime.format('YYYY-MM-DD'),
                    mTimeStamp: tempTime.valueOf(),
                  };
                  times.push(time);
                  timeGroup.push(tempTime.format('hh:mm A'));
                  tempTime.add(15, 'minutes');
                }
              }
            }
            return t;
          }
        } else {
          let shiftStartObj = moment(
            `${obj.date.format('YYYY-MM-DD')} 00:00 AM}`,
            'YYYY-MM-DD hh:mm A'
          );
          let shiftEndObj = moment(
            `${obj.date.format('YYYY-MM-DD')} 24:00 PM`,
            'YYYY-MM-DD hh:mm A'
          );
          let today = moment(
            moment().tz(storeConfig.timeZone).format('YYYY-MM-DD HH:mm A'),
            'YYYY-MM-DD hh:mm A'
          );
          if (shiftEndObj.isBefore(today)) {
          } else if (
            shiftStartObj.isAfter(today) &&
            shiftEndObj.isAfter(today)
          ) {
            //Normal default time operation as shift is yet to start
            let tempTime = shiftStartObj;
            const roundedUp = Math.ceil(tempTime.minute() / 15) * 15;
            tempTime = tempTime.minute(roundedUp).second(0);
            while (tempTime.isBefore(shiftEndObj)) {
              let time = {
                display: tempTime.format('hh:mm A'),
                timeStamp: t.openingStart,
                displayForUse: tempTime.format('hh:mm A'),
                shiftId: t._id,
                timeObjFinal: tempTime.format('YYYY-MM-DD'),
                mTimeStamp: tempTime.valueOf(),
              };
              times.push(time);
              timeGroup.push(tempTime.format('hh:mm A'));
              tempTime.add(15, 'minutes');
            }
          } else if (
            shiftStartObj.isBefore(today) &&
            shiftEndObj.isAfter(today)
          ) {
            let todayTT = moment(
              moment().tz(storeConfig.timeZone).format('YYYY-MM-DD hh:mm A'),
              'YYYY-MM-DD hh:mm A'
            ).add(Number(orderSetup.deliveryTime), 'minutes');
            let tempTime = today.add(
              Number(orderSetup.deliveryTime),
              'minutes'
            );

            // Add current time, rounding off time and then go with default time
            const displayTime = `${orderSetup.deliveryTime} mins`;
            let timeD = {
              display: displayTime,
              alternateDisplay: 'ASAP',
              timeStamp: t.openingStart,
              displayForUse: todayTT.format('hh:mm A'),
              shiftId: t._id,
              timeObjFinal: todayTT.format('YYYY-MM-DD'),
              mTimeStamp: todayTT.valueOf(),
            };
            times.push(timeD);
            timeGroup.push(displayTime);

            const roundedUp = Math.ceil(tempTime.minute() / 15) * 15;
            tempTime = tempTime.minute(roundedUp).second(0);
            while (
              orderSetup && orderSetup.isOrderAllowedTillClosing
                ? tempTime.isSameOrBefore(shiftEndObj)
                : tempTime.isBefore(shiftEndObj)
            ) {
              let time = {
                display: tempTime.format('hh:mm A'),
                timeStamp: t.openingStart,
                displayForUse: tempTime.format('hh:mm A'),
                shiftId: t._id,
                timeObjFinal: tempTime.format('YYYY-MM-DD'),
                mTimeStamp: tempTime.valueOf(),
              };
              times.push(time);
              timeGroup.push(tempTime.format('hh:mm A'));
              tempTime.add(15, 'minutes');
            }
          }
        }
      }
      return t;
    });
    obj.times = times;
    obj.timeGroup = { displayTime: timeGroup };
    obj.valueGroup = { displayTime: times.length > 0 ? times[0].display : '' };
    return obj;
  });
  if (closeDates.length > 0) {
    deliveryTimes = deliveryTimes.filter((obj) => {
      let tcDate = closeDates.filter((m) => {
        return (
          m.closeDate === obj.dateString &&
          (m.isAllClosed || m.isDeliveryClosed)
        );
      });
      if (tcDate.length > 0) return false;
      else return true;
    });
  }

  deliveryTimes = deliveryTimes.filter((p) => {
    return p.times.length !== 0;
  });
  deliveryTimes =
    deliveryTimes.length > preOrderingDays
      ? deliveryTimes.slice(0, preOrderingDays)
      : deliveryTimes;
  deliveryTimes = deliveryTimes.map((p) => {
    p.times = sortBy(p.times, 'mTimeStamp');
    return p;
  });
  return deliveryTimes;
};

export const getCurrentShift = getCurrentShiftFun;
export const getPickupTimeArrays = getPickupTimeArraysFun;
export const getDeliveryTimeArrays = getDeliveryTimeArraysFun;
export const getRestauranTimeArrays = getRestauranTimeArraysFun;
