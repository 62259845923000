import React from 'react';
import DatePicker from 'react-datepicker';

import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import Select from '../../components/Select';
import isEqual from 'react-fast-compare';

export default class DatePickerWrap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: undefined,
      time: '12:00 AM',
      data: {
        times: [
          {
            display: '12:00 AM',
          },
          {
            display: '12:15 AM',
          },
          {
            display: '12:30 AM',
          },
          {
            display: '12:45 AM',
          },
          {
            display: '01:00 AM',
          },
          {
            display: '01:15 AM',
          },
          {
            display: '01:30 AM',
          },
          {
            display: '01:45 AM',
          },
        ],
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(isEqual(this.props, nextProps) && isEqual(this.state, nextState));
  }

  getPlaceholder = () => {
    if (this.props.booking) return 'Select Booking Date';
    else return 'Select Delivery Date';
  };
  handleTimeChange = (obj) => {
    this.setState({ time: obj.display });
  };
  handleChange(date) {
    const { storeConfig } = this.props;
    if (this.props.timeArray) {
      var day =
        moment().tz(storeConfig.timeZone).weekday() === 0
          ? '7'
          : moment().tz(storeConfig.timeZone).weekday().toString();
      let availableSlots = this.props.timeArray.filter((f) => {
        return f.day === day;
      });
      if (availableSlots.length > 0) {
        this.setState({
          startDate: date,
          data: { times: availableSlots[0].times },
        });
      }
    }
  }

  setAll = () => {
    let reservationDateDisplay = moment(
      `${this.state.startDate.format('YYYY-MM-DD')} ${this.state.time}`,
      'YYYY-MM-DD hh:mm A'
    );
    this.props.setAll(reservationDateDisplay.format('YYYY-MM-DD HH:mm'));
  };

  render() {
    return (
      <div>
        <input
          placeholder={this.getPlaceholder()}
          className='inpC'
          value={
            this.state.startDate !== undefined
              ? this.state.startDate.format('DD MMM')
              : ''
          }
          readOnly
        />
        <DatePicker
          inline
          selected={this.state.startDate}
          minDate={moment()}
          onChange={this.handleChange}
          onSelect={this.props.selectTime}
          dateFormat='DD/MM/YYYY'
          calendarClassName='laCampaPicker'
        />
        <div
          className={
            this.props.openTime ? 'active timeSelection' : 'timeSelection'
          }
        >
          <Select
            handleChange={this.handleTimeChange}
            data={this.state.data}
            height={46}
          />
          <div className='selectTime confirmBtn' onClick={this.setAll}>
            Select time - {this.state.time}
          </div>
          <div className='mouseWheel' />
        </div>
      </div>
    );
  }
}
