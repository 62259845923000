export const isMenuItemSoldOut = (item, soldOutMenuItems) => {
  if (item.itemType === 1) {
    return item.selectedModifiers
      .filter((f) => f?.subModifiers?.length > 0)
      .some((j) =>
        j.subModifiers.every((sm) => {
          const basesSoldOut =
            soldOutMenuItems.findIndex(
              (k) =>
                k.smId === sm._id &&
                k.menuItemId === item._id &&
                k.variantId === null
            ) !== -1;

          const variants = sm.variants || [];

          const variantsSoldOut =
            variants?.length === 0
              ? false
              : (sm.variants || []).every(
                  (v) =>
                    soldOutMenuItems.findIndex(
                      (l) =>
                        l.smId === sm._id &&
                        l.menuItemId === item._id &&
                        l.variantId === v._id
                    ) !== -1
                );

          return variants.length > 0
            ? basesSoldOut || variantsSoldOut
            : basesSoldOut;
        })
      );
  } else {
    return item.selectedSizes.some((s) => {
      const basesSoldOut =
        soldOutMenuItems.findIndex(
          (k) =>
            k.smId === s._id &&
            k.menuItemId === item._id &&
            k.variantId === null
        ) !== -1;

      const variants = s.variants || [];

      const variantsSoldOut =
        variants.length === 0
          ? false
          : (s.variants || []).every(
              (v) =>
                soldOutMenuItems.findIndex(
                  (k) =>
                    k.smId === s._id &&
                    k.menuItemId === item._id &&
                    k.variantId === v._id
                ) !== -1
            );

      return variants.length > 0
        ? basesSoldOut || variantsSoldOut
        : basesSoldOut;
    });
  }
};

const detectSoldOutItemsInCartFun = (currentOrder, soldOutMenuItems = []) => {
  return currentOrder?.menuItems?.some((item) => {
    return isMenuItemSoldOut(item, soldOutMenuItems);
  });
};

export const detectSoldOutItemsInCart = detectSoldOutItemsInCartFun;
