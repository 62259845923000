const actionTypes = {
  FetchUserByUidLoading: 'FETCH_USER_BY_UID_LOADING',
  FetchUserByUid: 'FETCH_USER_BY_UID',
  FetchedReservations: 'FETCH_RESERVATIONS',
  InitializeHome: 'INITIALIZE_HOME',
  LoadCurrentOrder: 'LOAD_CURRENT_ORDER',
  FilterView: 'FILTER_VIEW',
  SetSlideIndex: 'SET_SLIDE_INDEX',
  ToggleMobileCart: 'TOGGLE_MOBILE_CART',
  SetLayoutFlow: 'SET_LAYOUT_FLOW',
  SetNotes: 'SET_NOTES',
  SetBookingNotes: 'SET_BOOKING_NOTES',
  SetMenuLoading: 'SET_MENU_LOADING',
  UpdateMenuItemSchedules: 'UPDATE_MENU_ITEM_SCHEDULES',
  FilterVoucherView: 'FILTER_VOUCHER_VIEW',
  SetUserSelectedVoucherItems: 'SET_USER_SELECTED_VOUCHER_ITEMS',
  UpdateSoldOutMenuItems: 'UPDATE_SOLD_OUT_MENU_ITEMS',
};

export default actionTypes;

export const SIDEBAR_IN_CLS = 'about-slide-in-animation';
export const SIDEBAR_OUT_CLS = 'about-slide-out-animation';
