import { eventNames } from 'configuration/eventNames';
import getSpecialAvailability from 'helpers/getSpecialsAvailability';
import React from 'react';
import { CustomerMenuItemUI } from '@next-order/next-order-components';
import { SOLD_OUT_ITEM_PRICE } from 'helpers/enums';

const eventName = eventNames.SPECIALS_MENU_ITEM_OPENED;

function SpecialsItem({ menuItem, currency, count, onClickItemHandler }) {
  const item = {
    bgImgSrc: menuItem.bgImgSrc,
    lazyLoad: menuItem.lazyLoad,
    imgSrc: menuItem.imgSrc,
    price: menuItem.price,
    productName: menuItem.productName ?? menuItem.name,
    showName: menuItem.showName,
    ingredients: menuItem.ingredients,
    itemType: menuItem.itemType,
    item: menuItem.item,
  };

  const isSoldOut = item.price === SOLD_OUT_ITEM_PRICE;
  const price = item.price && item.item.type !== '2' ? item.price : undefined;
  const curr = currency && price && !isSoldOut ? currency : '';
  const specialAvailability =
    item && item.itemType && getSpecialAvailability(item.item);
  const opacity = isSoldOut ? 0.5 : 1;

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClickItemHandler(item.itemType, item.item, eventName);
  };

  return (
    <CustomerMenuItemUI
      title={item.productName}
      desc={item.ingredients}
      specialAvailability={specialAvailability}
      currency={price?.includes(' ') ? '' : curr}
      price={price}
      displayType='special'
      imgSrc={item.bgImgSrc}
      onClick={onClick}
      count={count}
      style={{
        pointerEvents: item.price === SOLD_OUT_ITEM_PRICE ? 'none' : 'initial',
        opacity,
      }}
    />
  );
}

// export default memo(
//   CustomOldSpecialsItem,
//   (prevProps, nextProps) => prevProps.item?._id === nextProps.item?._id
// );
export default SpecialsItem;
