// import storeConfig from '../configuration/storeConfig';

// let colorCode = storeConfig.storeColorCode || '#008000';
const getIcon = (colorCode) => {
  let svg = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  width="612px" height="792px" viewBox="0 0 612 792" enable-background="new 0 0 612 792" xml:space="preserve">
<g>
 <g>
     
         <image overflow="visible" width="540" height="687" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAhwAAAKvCAYAAAAhoBanAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAATZxJREFUeNrs3YtyGkkXrukFku37
v9xtWzCzY5r506mVh0Ig6vC8EYRst9vtRlD18uU6RAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOBbOHkK
ADz5enD11AJ49xQA5GGFH0BICuCiBGAD793Tg/6spdeI64Mk4vrFfw6AcAB4ogh85de/40jlulAg
lv46GQEIB4AHSEJPEE4L/8zTwn/3UaKxVECuCwXmHkkBQDiA3b/nZsXhNPFrp8n/1tJ/71HC0fo6
Kyazvzb6s0kIQDiAw8jFjDjMfM1+vPS/dXrSNaGUghnpuN75785+HUkJCQEIB7C599NXbviZSGQ/
7/2er/y3Hy0bmTgsFY36x7O/byQtJAQgHMAmBWPJDb4nEkv++SOF4xHXhqUCsURSrh3pWPrrM3+X
TDgICEA4gJcLxkguvuMx+3d+RQ3HTNKwRDge8ViSiJAP4A4M/gIeLxhLHueFv35PwjEjHOWPrw9+
/r5SuxEPEo7L5K/NpiO954mAABIO4MuSsUQwzhM/nvn5I0Sj9/95mrxhXhdeR1p/7r1tsTNCMCMc
ly+IyFIJIR8A4QCmJGNJenFe+LUlF+eYLxKNgXBkN8De+/6euoV75oHcO3X0K0cumWhcGr+n/r0j
SRnVg5APuNB6CuC1v0gyzgPBmPnxKM2ITnKxZFjX7BHAKH24LnxOR7NDHnFN+uoRzGUgI5fOjy8T
ScnM809AQDiAg0rG7efngWT0pKInGaOjkojHtah+pUjzHtmIwd//EbUlj3oOZkSkFouPSjhav++e
oxfiAcIBHFwyRmIxKxzlnxlx/+yL2U/KvVqHmUTg0cKxNKWZOS46Db6/M8/fSEIujcdH559dJwSk
9VyTDxAO4GCS0ZOL80A6eknGvWIxKw4j2RiJxcxQrtnnfSQNp4nvz2nBPxv9d04LnvNe4vHREY+P
ieTjIvmACzOwr9fzPZLRe7zFfF3GSDJmk4aZY4CIuSmcS4Vj6c3vK9NM7xmENvo9EV87tppJOz4m
k49R/UdPQMgHCAewAcmI6NdhzMhFSzaWtqbeMxFztkUzviAZj5CNe6WjlYrcO1n1ETNN6r9TL+0Y
PT4mfs9S+SAeIBzAi167rRtW1kkyIxlv0T82eYRgzHRKPEo4Ih636OwR0nGPnMwmGDOzTpa0KPcS
j9axyUg86h9n6cft14J8gHAA60szsptPLRtvk5LxFu3Cz3sFo1dQOJoJsVQ2IpbtB1myxOze71Pv
xz05mWkXvkcyZjqNWonHrEi2xKP1tSUeUg8QDmBFaUYvyXgbSMZb9LtLesOrlgjGPQWFS9ssl6YV
33HjmmlxnUlGTgMhmZ2ZMpt6nSZfBz0RyYpOl4hH67Uy+l4DhAP4gmgsOTJ5G3zNRKP8Wv53e0Wd
l4Fg3DM0ammasTSleMX69dOdv75EREapx5I6npmanVgopDNJx6x83LPjBSAcwELRmJGM7MejI5P6
PTFKMFpCMZKMmQmVS1KMr0jEdcXXnK8cwYwE9Z60Y3Y0fa/rpXw9fHSSjxkJIR4gHMATRONeyXi7
QzJiIrW4LPj1JZLxFcG45yZz3cA15xHHMKPX1D0D3npD30bFp706j1o26sdMpwvxAOEAvigaPcHo
pRr3SEZv6NOsYNwz7vqeBWqvEog1XKdmlsfNdL4sFZAlxzK9WSyt11kpGX+jX/9BPEA4gAeIRiYW
bw3RGKUZM582rw25GA12WtpZ0pOLRyUYR76OLU1A7hWQXn3QPcKbJR61dHxE+7iFeIBwAA8SjfeG
cIw+VUbMj60ejbF+ZorhpvC869vMcrmlAvI2SOB69SDRSD9a0jF6ZCPWiQcIB4jGpGi8d2SjTjSy
DpPRmOpMLJa0KI66SUYXeRf+7QnIV7qjRu23WfrWq+v4SzxAOIDHi8Z7chEfTYYcjZ6ekYyeaCwR
DBf3dV8DlwrISDxmW7NH9R6t1+7fgYAQDxAOEI07RSO7QGcX56VzD0aicc9RiYv4vgXkq/LRO35p
JR6lRI+KS4kHCAeIxoNEYyZ+ri/IS45NWnstCAYBybpfznHfkUur+PnUSD1aw8SIBwgHiEbcX6NR
y0ZLNHothb2K/lnJIBgYCcgS+Ri1d/cKoWv5KEWbeIBw4DCvo9MTRKN3oV1SyT9qHyQZ+A75GA2v
WzpThniAcOBwqUZrY+s9olF3nbRE4xKPmVNAMvBs+ZgdaNebOZON5/8O8bCdFoQDqxCNiH50/FXR
mGkRHLUGjkSDZOAV8jFz3DIadvcs8VjyXgEIB576mpk5OnkffB1dNHsjn2cSjWvj4hmxbEcJ8Ij3
S/m+iQeJx0jW7xGP3qC7IB4gHPjOVCM7OmklGu/FY1QIGg9KM5Z8QnPRxKvlY8n+oHtTwuw99Xfw
dUmNB0A48FDRiAWJxnv021uXFr61PoX1Po1JM7AV8YiOyNfy8YjjyUzk/ybS0Us8pB2Y5s1TgMGF
MYt+64veTSx+/Pf4WT1+FI+6+6S+CNYXvv/7+FM86otia3W3NANbovVazTqpWtuMe0WevaLu3qCy
bOz6zLI8gHDgrlSjd2Ty3pCMWjbe4/N00PJieROIP4lo/E2EoycZRANbTj0yARntBJoRj2i8p0/R
3/UyEo/WV4BwYCrVaLW3ZolGlmyUopHtPLkkicafgWhkacbs8Qmw1dQjIt/bs0Q86j9vZiR7SzZO
E6kG6QDhwHSqkSUb79E+PvmVyMZbkmrUolGLxaxotC6qRAN7ez+OUo4Z8bg03hutI5aWiJykHSAc
eESqEY1UoxaN+tjkVyEat983Eo1aMlqikVXLX8KxCY4nHtEQkFggHr1jllHqcYr+mHVpBwgHplON
Okq9HYf8iM9HKPXxSdmRcvv3Iz7XaWSi8adKO2rRUAgKfOYe8Ri1tGaJRy/9ODU+sEg7QDjQlI0l
qcavhmyUF6S69e5P55F1noxEg2QAy8SjN7L8Gv2liy0JGR2xSDtAOIhG8+w2SzV+TqYavTqN2+N3
QzSyzhOiAXxNPEZ1H0vkozekrHXEIu0A4ZBqTHegzKYaEZ9nafyZTDZmZmgQDeA+8bhOiEdEv502
E4xR0iHtAOEgG03ZmGlzzYZ39eo0fg9Eox5FHmFsMvBo8YjJhKPXRhvRb5s/TYqHtINwYOei0Zou
mNVq9GZqvEf7+KQWi/r4JGtzbYkG2QAeKx/ZXI+ZI5ZMPkZFpSPZkHYQDhwg1SjHk2cDvHqTQnup
xp8FqcZHjEcxA3jMe//aSD2ud8hHb+dL9oFmiXyQDsKBHclGq1ajlWr8TFKN28XnI0k1fldfs1TD
wC7g9eIRMV/fsaSNtlXnMZpWOjPPA4QDK77IjCaG/rwz1ahl43cj2aiPT8oLWYQ6DWBNiUc0xCMa
HxBOE0nHaGbH6O8KwoGNiEZrtsaPKtn4FZ8LQ2+yUS9aux2NlJLROkIZHZ8QDeB14hExbqG9p312
ZjLpTG0H6SAc2MCFZKZeo7XZtUw1yovQRyUbv5N0Y9Tm6vgEWDez8ztGH3bOk/JxGlzHiAfhwAZk
o1ev0avVKGXjMpFqlKJRyobjE2A7140lxyyzrbO9ZXBLOlhIB+HAyi4ao3qNUapRF4ZmHSiZcNz+
+SjVIBrA+sUjIj9maQlIJh29IWEzBaQtySAdhAMrEY3TQDRuX39Vv1bKRsTnaaF/G6nGH6kGcAiW
DAjrpR0z00kj2kctpINw4MWfSEb1Gq1UIysMzXag/I72EYqiUGC/15be/I5eAtL7QLS0g0U9B+HA
ymRjtl6jlo3eEUqrC+UmGxepBnCI60zEXF1H7885DQSkNTp99u8HwoFvko3efI2s5TVrd21tdq1l
Q60GcGyukxLSko5zzG2cVUxKOPBi2chSjbf4XK/xK+ZaXj8S2aiPUdRqAK49mXi0jlpmr2EzScfo
70E6CAeeJBpZJ0pvF0opG6Ptri3R+Bv/bnSVagASjlbqkf24vpbNFJFmKcdV0kE48PxPFjPDvMpU
41f06zVaqUY2LbQUjYtUA3BNashGRL+w9DS4rp1jvoaDdBAOPFE2suLQOs2oazbqI5Q61RjtQJFq
AJi5ufdmdpS/pyUbp/j6dlnSQTjwINk4d2SjTDdaspFteO3N1dDuCmDEtSMfo/bZ08RjJBM2zRIO
PEk2ypqNkWyU8zV6XSj1aPKsXoNoAJhJO1rXi1ZNx6x8LJUK8kE48A2ycU5k45ZgtKaFZvUaZAPA
V2/u107y0bv2zR6vaJslHHiibLSWsGXFoXUXSks2dKEAeAS9hCNiPBJ99phldmYH6SAcmJSNc/zb
idKTjfoYpbV47W+Mi0OJBoBHph3X6A8NOy1IO0bzOkgH4cCdstFbLd8qDm2lGrfHRyfZIBsAHikd
o+2z2TWn1TJ7b+ss6SAc3piTsvEj+jtRWrLxuyEbrU4UsgHgmUlHS0Ja152ZNfcRyztaQDjIRiUb
75Vk3H5cH6HUsjEaUa5eA8B30UszsqLSLOmoUw5JB+HAg2XjR7TXyt+SjdnJoWWyUU8NJRoAvjvt
GBWYZglHdrxyrv471zv/PiAch5eNH5GPKe8lG1k3yiU+H6NINQC8Qjqug59HtJe9nRuycRr8eaSD
cJCNSdl4m0w26jHlrWMUsgFgTUnHSDx6CYf9Kyvn3VOwCdnIjlBae1Fmd6KQDQDfzUyqMTuf4yuL
3srj5JNrIeEgG59l41QIQ2svyk0+tL0C2IJ4zFybWnM5ZpONK+l4PY5UtiUbtzfJRyfV0PYKYGvX
xtnfe/v95xhvm+1Jh2sg4dj1m6q39XXUiVKOKr/Jxt9OsqHtFcDepGMm5RgtetORRzgOJxvZUK8y
3RjJRnaMUneiaHsFsHXpyI46TjE3+jwbCDZbMwLCsWnZOBUS0ZogWqcbs8coikMB7D3puE4mHqOj
lfJDWHbMonOFcGz2zdM7RsnGlc/IRjY5lGwAOKJ0jBKPexIN0kE4Ni8brRXzrWTjOiEbOlEAHEk6
Rtfclnz0Eo7smkk6CMemZOMU/QLR8tEbV54ViJINAKSjnXicBn/WzLWSdBCOzcnGWyIbvyIvEiUb
AHD/1NCZwtFWIf2VdBCOLb1B6o6UrGbjVyfZyI5RMuEgGwBIR1s6Itr7Va6VfLh2Eo7Ny8bbQDZ+
LJCNbCcK2QBAOubkI0sxemkHCMfqZePW/loXiWay8Z7IRjmuXDcKANzfMrvk3yEhhGMzb4RW+2tW
IFrWbYxko561QTYAkI55gXjEn2FGB+FYrWzUyUZv1kav9TUTDbIBgHS0peA68e+O/pyZRXIgHC+R
jVH766/i57djlNu/X3ejZLKRLWIjGwBIRz+huFbX6eza3SosnZEYEI5ve9Hf2/5a7kcpRaOWjb9h
4ysAzEjH7JHKzOjzlniAcLxcNs4D2fgZj9uP4sUPAM/ZvZL9GcSDcLz8Rb5k1kbd/nqTjT9VulF3
pdSi4QUPAPPS0fqwGJGvt7/9+qh1VhEp4XiJbGR1G7/i36OU1qyNvzHfjaJNCwDuk45WunGO5ccr
X5UewoFFdpwVif6M5bM2MtnoCQcA4Gs3/a+usnc9JhzfLhvnaM/a+BXt9tfWrI1R+ysA4PFJxznG
m2UjFO0Tjm9+Ic/Wbdx+re5ImRnspf0VAB4nHacJ6RilHa10o/x1RyuE46HpRq8jpR7u1dr++jsR
DiPLAeC5Sccpuab3ZOOUXINHxyqkg3A8RDZa8zZ+VKJR123U7a+lcPRqNsgGAHxdOk6DtOOeNln1
HITjaS/a2xlfPba8JxunyGdt/CYbAPCypKOXdszWc4RrNeF4xot11AJbPnqTRLMi0WySqBcwADzu
Oj5KO1rpxjnykefXaE9+Np+DcHzphVq3wPYWsr3Hv5NE61kbtXBkHSlkAwC+V0ZmEo5TQy5cswnH
Q9KNc/S7Unp1G/VCttkiUQDA91zjTxOPc4y7VsgH4fjyC3FWNt6KF2Vr3by6DQB47XU9+3lPPs7R
7loZHatolSUcUy/MJV0p9VK2emx5b7gX2QCA10hH67pffthcmnJolSUcd8vGKN14j8/zNlpHKYpE
AWAd0nHqJB+trpWIz3Ud10HqAcIxfCH2ZOP29fbPyhbYWjbqSaKKRAFgfUnH7Mjz1myO8CGScNyb
brzF56OUnmxkS9laRaJEAwDWew+IWD6FVKss4bhLNsqZG70BX7ejlNGelHpHyoUFA8BqUo7ZY5XW
KvtRLYfrPOH49GLLjlJa6UZZt1F3pbTqNrwIAWCd1/4Z8ci6VmrxMPaccCxKN2aOUt4asmG4FwBs
M+nIROQrG2UdrRCO9IVVD/gaHaWU00Q/or9unmgAwHbTj/pYJTta6XWruPYTji8fpfRWzqvbAIDt
pRyjFfa1eEQsP1o57ECwowvHV45SLh3ZULcBANv8ABqR13O0ikfvHXt+OOl4O/iLa7QrpfxaH6X0
6jayaaJkAwC2kXS0Ug5HK4Tj7nTjXKUbWe1G7yil1wLrBQYA+7pn1AlH62ilJRqHPlp588L55xjl
PfItsL2jlPLRkg3SAQDbSjlGS91mjlbKx+E3g78d9MWU1W28x+djlNZRSi/dUCQKAPu4T5wavzZz
tHKtPqhGHDz5PqJw1OPL666UbJroTTY+GunGTULUbADAvpKOaCQd5+h3rdT3gmxp56Fmc7wd8AVU
Foq2ulLqtfPlUcqfcJQCAEcRjlar7EzXyqh49FD3iKMJx+za+V66UXemOEoBgP1KR+topU43agGJ
jmwccjbH24FeOKOZG6VwvFXpxkeSapTzNnSlAMC+k47W/eTcSDuiIR2HPXo/mnBksnETjl/xv6OU
9/h8lPK3IR0GfAHA/oVjVDjako6sbuOQszneDvKC6R2n3GSjrN3obYI14AsAjiUdrW6VXtrRG3le
3zcO8UH1SMLROkqpZaMuFC0TjdauFKIBAMcSkJZsZNJxbUjHoT6ovh3ohVGnGz+rx3uRbtxeAKOj
lEs4SgGAI0hG796SzeQ4DxKO3pK3XRaQHkU46nSjLhItj1LKQtG/g3TDjHwAOFayMfpQO+pYuSTS
cQlHKrtNN+oFbeVRSkR75oajFADAzOTRrGMlG3d+SRKOXbbJHkE4ep0pWboxmrnxN8zcAACi0f5w
2zpWaUnHIdLytx2/KEa1Gz8m043fhWiUtRtEAwCOKx31vWZmx0pEu1PluveUY+/C0Uo3StnI0o06
2TBRFAAQ0a/j6NVzLC0glXDsIN2oj1PqQtGPyI9R/kbeOw0AOJ50zBaQZntWrknaUW6VjT2mHHsW
jpnajdG+FG2wAICZxKM3gTTrWrlOPCQcG083Wm2wWWdKb18K2QAAolGnFy3hqBOPVsqx28VuexWO
UbpR1m700g1HKQCAkXTU4jFKOqKRcux6Vcbbzr7xs+nGj4XphkJRAEAv5WhJx9KjlcteU47zTl8A
5Te3TDrKtfPlN/ujSDjKI5RSNogGACAqEYhGSpHdUy7V/fdc3J/Ke1SdhjhS2XC6US9o+6iSDW2w
AICvphzn5ANwPYU0SzZ2mXKcd/iNX5JulBb6Uf34It0AAHwx5agf5QfYU+M+9Raf53c4UllZunEa
fAPLNtjbC+MjkY5L5IWipAMA0BKPTDayY5Vr9SF5Rjo2Lx57Sjh66cZ79c2LSjZa0qEFFgAwm3LM
iseMdJzj33qOzaccW6/h+Orcjax2I7NQ6QYA4N770ykRiFaL7CXylH3zH37PO/rGLk03Lo10o7dM
BwCAmcRj5nhl5milNyKdcLzIHutv2Fu0W4x6xTyXJN0AAGBWNmJCNuok/dy4h7USkc3xvpN0I5ON
XrpRJhx/o10oKt0AACyRjXpseSYereSiTjk+/vvxJdqr7iUc3ywc9XHK+2S60bJNkgEAeFbK0Zrz
tOuUY6vCMXucco68diM7UmklHAAALJWN0fTRVsfKbIvs5mo5tp5w1B0qrfqNU+Ob/pFYJskAADwr
5RgNApudyyHheEG6UcrG+Y50IysUJR0AgGelHJl01B+is7kcmz5W2UuXSivhmDlKKQ2TdAAAnp1y
ZEcqrZRjN8cqWxeObPbGuZNu9PalEA0AwHemHNl0696H6LqzRcLxTanGqFi0XtKW1W606jdIBwDg
0eJxif7Rfi/lOMfGB4FtvUvl3Ek56rkbvUFfikUBAM+WjWzKdTaW4dSRjnPoUvn2dOPc+WaUnSnX
4pvZkw2tsACA7045/g5SjtmjldXLx9a7VFrSUT7xo2JRY8wBAN8hG7MNDNf4vPBt07KxJeE4VbJx
HiQcS1thpRsAgO+Qj9YwsJkW2U0Xj27tSCX7JtTfjFP0t8KWW/pIBgDgO0VjNPF61CJ73mrSsdUu
lVHCsSTdCNIBAPhm+bhMiEdPOjbXqbIF4Wgdp7SmsM2kG1phAQBrSTkuMW6RHTVJrJ4t1XDMTBZt
1W9cwnI2AMA6U45RwnGKvITgHJ+PU1YrH1ur4ZgtFm0lHIpFAQBrSTpmhoBFcX9rpRubKCBdu3Bk
5tYqoDlJNwAAK5eNlnTURyyZdNTLSjfVrbKVGo5TZXJ1ylE/2b2YSrEoAGAtScfoflXfBzPp2MSx
ytZqOEaGFx1rbCUcpAMA8N2i0Ttauf24bpE9Ne5/m0g61iwcreOUUZw0k26QDADAGuQjO0oZdav0
Frmtli3UcPSOU2rZaO1OsTcFALDmpGOmDGDTxypbOlI5dx6nO2wRAIBXyUZEfwhYee+6TtwLV9+t
slbhqId91SnHkr0pH6ENFgCw/qRjpltlJB1qOO6UjtbAr9Hcjew4xWRRAMBak47Z7sp6PER2T1zl
scpW2mJbtRu3r9k5WEs0AABYk2zMJBxZt0qW+K826dhCwtE6SqkTjtm9KcQDALA2+WjtV2kdq5yi
XcOxStYoHPWq3dZ51Sn5ZrXmb5i9AQBYq2gsGXfeuy9mLbKrEZAt1XD0TG5UvyHZAABsKeVofXCO
wQfx1a6sX3OXymky4WhFUa1kg3gAANYqGzPSMZo6qi12UjTqn49mb0TjG3QNtRsAgPXLRv11VDha
3x/fOikH4ViQbsy2w16jH0EBALCFpKO1X6XXrdKSjdWIx9prOGYKYmaLRckHAGBLspHd07IP6JsY
ArbmhOMc8x0qrW+MlAMAsAXZmBGPVsIxk3QQjkQ0llhb6xtzDXtTAADbTjmug5RjdKyyKrZQwzEa
bNJLOELCAQDYqHjMtMfOfDhfhYCssUtlth02Yn7QV5AOAMBG0o0svb/emXI4UklEo/75zDa80XGK
dAMAsFUByUSjl3DUdR2rko411nAsqbqdiZwAANhq0nFpfLC+JvfOVddxrLWGY6ZDZWYam5QDALAH
6egNANtEHccaE46WbGTpxsyEUQAAtiQavXvcrHQ4UumIxsjUZlpitcMCAPaYdMzUcZxixXUcW6rh
6BWLXhPZkHAAALYuGtfkPrd0zPkq2EoNx5LjFO2wAIA9Ssem6zjW1BY7m3DMVO5KNwAAWxaN3ofr
nnT0HhKO5MkaFb1k0qF+AwCw56Sj9cG6N53bkUr1JJU/P3fEI6J/tqV+AwCwN9G4Zx7H6gpH136k
Mko36keE+g0AwH4Tjt6Ay3NHNg6fcLSkYyQbrRYh9RsAgL2lG62ko044ItrJxssLR9c6h2N0nGKc
OQDgSALS2xc2O+bckUosq6qdSTkAANiDaNzTHru6DpVXC0dWMDqyspnjlCAeAICdi8c1NtYeu6Yj
ldG5U8vwHKcAAPYsGxHt+o3r4IP7atpj13qkMlpHfx08AADYQ6oxko6IdsLROi14iYCsaQ7HPR0q
Bn4BAI4kIL0P2a0mDKPNG0/KzA6V7AwrSAcA4ACiMSopMNq8YWER44LR3uCvLHIiHgCAowjIjGwc
vi229YSM0o3a6ggGAOBIkjE6Wik/wEfy9XDCUT85o9nvS55wAAD2IBoz98DRB/mXS8ca5nDMVNSS
DADA0eVjiXTEQDoOJRwt8ahjoPoJtz8FAEA2+ve+nnREvKCmY601HEsXt9W/BwCAvUnH0pRjVcWj
rxCO7H92FPuMnlySAQA4kniMdqpEaItdbF+zy9pIBwBg76Ixe6xil0rjSYnoF7VcB6JBNgAAexaN
lnhE9NfU61JpyEb2xLQmrBENAMDR5KMnHtk9djXS8eojlYj27PfM8HSoAACOLBtZ4hGRTxxtScdL
eOVo89qwvjroi3gAAI4kHV8Zcx7fLSFrSjhOyT+beWJJBgDg6NIRgw/wL+e7haM1eGQm8iEdAADM
ScfoPnuYGo6edMxIhg4VAMDRpePenSovYQ1zOGLiSZFqAABIxvLx5napDCxs9gkmHQAAjIeARdgW
O/U/PupOAQBg7zIx+hDeu8cedg5H9j/eW0s/Ix3EAwBwNAmZKTVofcDf/bbY0+QTEQObC5IBADiY
ZET0u1SunXvu4bbF9v7HZ6Sj9cQDAEA6Pt9Te+nGtwnIdwpHr0p2tJY+Gr9GNgAAR5eOkXiMPtR/
C1tMOMgGAIB0tD+Yl7/eqpk8lHBk8jESDbIBAEAuHatMNl4pHL0q2VPyREaYvQEAQOv+mH1QXx2v
TjhaK+qj82Su+gkFAOCbpePe++Lu22Jrwej9z2dnVEQDAIB28egqWUPCMTOHo2d0AAAcXTpWz6u7
VDLp6O1TAQAAYwlZ3T1zLaPNZ+1N4SgAABvklV0qrYQjk43WALDVmhwAAHidcMzsUel1qUg4AAAg
HIvEY0Y0RkNNiAcAAITjk2BkYtH7Z5lsaI8FAIBwLBaRGEiGDbEAABCOu0UjKx4dLaEhGwAAzN9j
Dy8c2ZMTHdEgGwAAzN8/V8d5pX+vUYEo8QAAkI3P0rFaAXl1W+xXxQMAgCPLRvnze+6xuxWOe58I
RyoAAHyWjUeIx+GF46UmBgDARsRjyRLUl/FOLAAA2LxsZP/8FPkMq5fcZ8++ZwAAbFY2asFYbdJB
OAAA2K5s9CRjVQtQCQcAANuSjWjIRm/reikXjlQAAMCUeDxi07q2WAAA8EkwMrHo1W5ke8leNmaC
cAAAsE0BGRWJRiIgL5GN/8v7Sp9IAABwH9fG42Wy8X/ZUsJBRAAAR6bXBjtzpKItdvKJJR0AANLx
tXvhYbpUrg94YkkHAIB45OnGqRKLVsKhaHRSNEgHAODIstGTjlWyFuG4Thgc0QAAkI38a+/+uoo6
jlcJR6sneLSEhnQAAMjG56+9KaMvH2v+SuGIxpMQyZOneBQAQDbakjHao1LKxe4TjuvAtHr9wa1j
FdIBADiqfMyUHPSOUg5TNDpTMXuKcVxEOgAAR5CLTDRm5m9ErGCs+SuE43qneMRAPAAAOLqUzB6t
vIRXJBy9c6Vr50lspRsEBABwJKmYaYVd1VjzVwnHPaa1qV5jAABeIB8t2Xj5WPNXCkctHat7YgAA
WKlsRNyXcrz0HruGttglRyqtNlkAAMjGij+4r2Hw171POtkAABxdNpZsiq3rOb5VSl5ZNBqDJ2OT
s+IBAHiybGS/nt1n1XAsfJJnB5wAALB36Yj4epfK4Wo4lhSxkA4AwBElYyQa9c+va5SN7xaOmfHm
tXi05m+QDgDA0cRjdg7HqrpTXiEcvSckBuZlkRsAgHj8f49z8chmcESscNTEq4tGY+IJ6Rkd2QAA
HEU0evfEmcFfh9gW25KO3vCvmSc5+2YAALAn2YiJ+2DrHts6TTjMtthYaFuGfwEAjiwds3UcPdk4
bA1HNJ6U68InGgCAvUrGzL2wdz899LbY+om5RzqCbAAADiYesx++L/89ViMbrxSO3rTRWGB0Ug4A
wJHE495x5oebNHqN+dHmS55s0gEAOJJoLJWOl7O29fT1P4uYSzkAANijbGT3wnNy/17dsraa9xXI
RnTEY8m51Sn5MwEA2Lp01LJxz/yNeLV4rCnhaElHDJ5odRwAgD1KRiz40F0LxiVsi+1KR9bCk9nd
mWgAAA4iHktqOC6JbNT33EMKRyYd2ZPSi5GIBwDgiPJR30t1qXREY+aJ6aUbikcBAEeRjN7StogV
d6i8SjiyJ2A0glWnCgDgaLIxc/8bfYCPkHAMn6QlSUf9ewAA2KN0nBsftmfupYfcFtuTjnoka/3k
61QBAOxZMrIP0llJwcx99NBHKj0ba7XxWOAGADiieMx+0M5EQ9FoQz4uMe4dtlMFAHBE6SjF4xzL
B369nLXWcNRR0DXmJq2RDgDA3kQjk43Wscol7FJJRSN7okbT0XSqAAD2Khr1j3sftqPxYf0SKxpp
/mrhyORjpuBFpwoA4AjpRjTud+fY2Fr6tQhH1q6zNOFoxUsAAGxdPM4xn3C0ShIOv56+Z2fZ0crs
xFEAALYuGq17XivhmClJOLxw9Npi63kcM2t6iQcAYA/icU4e5b1upvHCkUqMC0dL2cikI4uWsoIb
AAC2kmxEjFOOU+dD+yoLRteScETcN9689Q2QcgAAti4eMxNGZ+6hq2FNRyoR7ZSjfuLO0T5aAQBg
D9LRO1LJ7pmrXNq2JuEYGVqrjqN1pkU8AABbFo3Z45SIDdRurE04RoNLWjtVzsk3AwCArYlG/ePT
xIfq0S4yRyqJaNQ/vyTpRi0drTGvkg4AwFbTjYh8/sbsccolVlgwuqaEI6J9lHJ7MrNvTOtYBQCA
rYrHOb6+kt6Ryh2y0TpWqWXDIDAAwNYTjpn6jTL5bzVZOFJZIB+9ThXjzQEAexaPUXp/Se6TlzUm
G2sUjmssn8cxmsJGQAAAW0w2Zuo3lmxaJxwxnsMx2hyrjgMAsMd0Y1S/MbpXmsOxIOloPYnqOAAA
e045Zod9rb5YdI3CMVo+MzsAzDwOAMBWxaO+n2VzprKjFEcqD5CObJFba/BXbyYHAABrTzZOHdk4
TSYcq0w51tYWG9E/m7oMEo7e+FcAANYuH71GiOzD+SbqN9aYcLSezMzc6m/OW7RrOQAAWHvKUd7D
3qJdw7GpZGOtwjGaNloLxznGrbHlNxUAgLWIRks6Wve1CEcqT5eOLCrqpRzaZAEAW0s3zhPpRsTG
5m+sUTiys6eWycWClAMAgC2IxyjdiMlkQ5fKHQLSa/lZIh3EAwCwlYRj1A7bKjlYNVs6UukNAKuP
VayrBwBsQTTqx1vnQ/Nm6zfWnHDcIx2OVQAAW5WP0T1s07KxRuFYMo/jWv1/ZN8oxyoAgLWnHL0h
lrP3w1XXb2wh4bhMGF3vDEx7LABgbaLRko5R/cbtHvgR7RpHCccXxKN8Qj8aKUfWUvQWikcBAOtN
NyLmj1NaycYmCka3kHDMrOCtpcMsDgDAVsRjdJzSSzfUcDxANuqvl2hHSZFYYiYdkXwFAOBVCUdL
Ns4T6Ua22DRCl8pDko5MOnrtsbpWAABrFI2Z2o1WwehHdQ9cfbKxBeG4Dp7omToOogEAWHvKcU+x
6GVr0rHmGo5RyjHbraI9FgCwxpSjV79ximV1jBFqOB6acrSe+GikHNk3kHgAAF4hGi3pmE04ZtZ9
EI4HiUercHRmeyzJAACsKd2YbYcdJfybYCs1HEumrDlWAQBsVTpOyT1ws9NFt5ZwzE4dLb+Zs5tj
iQcA4Dsko/6aDasczd/4iI1NF92acNRP/MzU0VOYOgoAWF+ycY7x3Ki6YLTVnbIptpRwtOZxfMTc
MjeyAQBYi3SM7k8z3SkSjgfLRv11tLzmtOCbSj4AAM+WjPrreZBwzNYtrn666NYSjjrpyCaOfkS+
V6X8ZupYAQC8Otno3Z9Og3veppa1bVU4eoWjvRbZt5hb6EY+AADfJR2jtRvX5EP15tbRb1E4Zo9V
Lp2Eo3y0FroBAPBoyai/zhynXCbuc5s6TtlSwpElHV/dHutYBQDwncnGV45TLltNNrYoHEuOVcpv
8lvk7bF1jEU+AADPlo6lo8x3cZyyJeFY0q0ye6yiUwUA8EzJqL+2hn3VxynX6G9H39xxytYSjjrp
yKTjI/q1HK0dKwAAPDPZGB3ztxKOXRynbFE4eudaH9Heq9I6Vjk1TBQAgEdLx2GPU7YmHEu7VTLp
eIu5liQAAL4qGa1BlNmH3zK9391xyhYTjl7SMdMiW3+j1XAAAL4z3egNobzETo9Ttioco26VrI5j
FGcpIAUAPEoyopFw9GZC7XLY15aFY3Ss0utY6R2rEA0AwDOk4xzj+o26GWJ3xylbTTiiIxuZdGTf
9NHKevIBAPiqdMymGxHtNfS7OE7ZsnDMrKxvtchm33CjzgEAj5SMXhtsnayP0no1HC+UjZiUjV4t
h+JRAMB3pBvZAMrRKPP6Q/Omj1O2nHBkSUfvaCWqlGNUMUw+AABLJSMmRKO1rC0rFs32hEk4Xpx0
9L5ZveLRXh0HAADPkI5T/Dt7ozc1+xpqOFYjG61R56Pi0Z5xAgBwr3TMJBxRfWieaYVVw7EC+Rh1
rLS6VepjFTM5AAD3SkarWHQ0e6M3f2M3bP1IZZRw9FKOXsJBNAAA94jHOea2ldfC0dp+vovjlL0k
HNk3rdXHHDEXdUk5AACzkhHRnrvxvjDhaNUgquFYWdIxc6wySjmIBgDgXuk4L0g3epNFd5Ns7EU4
rpPS8ZWUI8gHAGBCOupOyCXpRut+5UhlxUnHPVNHe90qZAMAMJKMpelG1lEp4diIaLSGgNXfyIj2
fPu6R5psAABmxOPcuae0lrXNLB8lHCuWj0v0V9Zfi//3mZkc5AMAkElGK+VYkm60ZGN3KcceheOr
xaO9bX4AAGTSsSTdyFphdzt/Y2/CMTuTo5SO8gUy07Ei5QAAZNKRDfx6byQco3vTLus39phwZCnH
zEyOnpVKOQAALcnoFYv2ajey45Tdzd7Yq3CMikd7LbJZC1NrdT35AABEzI9YiFhWu7FLzjv9/2q1
x7Y2yGZT4eodK2QDAHBPsegp+kf9dY2hGo6Nph0zMzlGczlOO36uAAD3S8e5ul9k949R6p7Vbqjh
2JBotLbwjbbI9qbDKR4FAMQg3ZidKto66t8le/7U/tUNsrbIAgBakrG0WLS8D/2N/v4URyobTTlm
pCMr/JFyAABa4vHVYtHWUEoJx4blY6ZIp54+aossAKCXbtw7WfRvtJsYHKnsJO3opRwR4/0qtsgC
ALJ7xXmhbHwcUTqO0KWSycbfWFY8aossABxbMiLG9RtLikV3vajtiAlHxHhtfS0drYSjNFfiAQDH
lY7W8Xu5bbxMN/52Puzuvlj0CMIxm3JkHSsnKQcAYEG6seQ4JVu1IeHYCZeYqxSOQcrRKiAlHgCw
f+lodaa8V+lGxLLjFF0qO0o66m/+pWOcrZQja5MlGwBwzHSjvDfUCbjajQMKRykbrRdAKR11i6yU
AwBIR0T7GGV0nPK3eGQfcB2p7FQ+7ulYeRuIB9kAgOOkG6MPoktW0B9i/saNtwO+gOoX0Tl51AO+
suLTQ529AcBB7xen+Fyv8eP/ffz87+uP+N+xSnmccks1/vz3yNJ0Ryo7TzlG7bGXKuHIFroZdw4A
x0w33uNzsejM7I3yw+ruV9EfWTha+1VmjlVqs23tWAEA7E86stqN945sXBsfZj+OKhpHTTjuTTmW
LHQjHwCwz3SjlI36ftBLN7JBX4eTjiMWjd5bPPrekQ4pBwDsN92YkY1I7imtNfSH5HzQ/+/euPNL
9Gs5MunQIgsA+003etJRjjK/TqYbh2w2OOqRSkR/Lkc9lCUrGMqkg2wAwP7SjffkMRplXn+APXS6
cUThqGVjdqlbq/+6PlIhHgCwr3SjbofNPmzWxaLZkK/Dj1I48pFKZqT1Rr/WUrdeiyzZAID9pBut
hGPUCpsN+jo0Rz9SmWmRrVfX1y/E3sAwAMC+0o3WcUqrDfbQrbASjn/lIztSKZOO3rjz94F4kA8A
2Fe6YdAX4VgsGl9tkx11rAAAtplu9I5SauFo1QAeftBXzbvXWlM26hqN8gWZbQwsE476vM6uFQDY
TrrRa4PNVtD/jXEb7OHvBUc+UhklHHXScamkY2Z1ff1iBgBsM91oTRXNBn1l9RuH5+wp+Ec+Ri+e
3ur691BECgBHSDcixsfwWmEJRyob5YtnRjrKY5XeQjfTRwFgO+nGaIx5NuTr70A2QDj+kY2sRfZv
9WK6RH+TbCvlIBsAsI10Y6YzJZONkXQQD8IxTDn+Rr+WI0s56henlAMA9pVufCTCkRWMgnB0U47e
XI6P+LxjpX6BZkWkZAMAtpFuZCMPekcprXQjSMe/aIvNxeP2gjrH/1pky86Vc2LF75FPmitF5lb/
4UUIAOuQjnrUQVaXVxaKzqYbWmEr3jwFn1545c/Pyde3yJe1XQcPAMB6rvVZQv1/x5f//O/x47/H
WyUcf/57/C4epXhINyQc0wlHKQ+tlKMeBtZKOd7+e/Gdi3QjvBgB4GWyMUo3ymOVUyPd+CPdkHB8
R8rxVrw465QjBgmHtAMA1plu/CwSjnJR26n4APr3v0TjT/FVuiHheFrKUaYdt5SjnDz3XpnwLeW4
JELjRQkA35tunKK/pqIu+M8KRf9INwjHo+XjJgnZyPObRNT1HTfh+BGfC03VdADA66UjE4165kb9
wbOWjTrZkG4McKQyfnHWVnyOz8O9Wscql8ZXAMBrRKP+gFgeo5RHKaV03Oo2bscofxrCId0gHF8S
jYjPtRyZdNRzNrI5+lldh/kcAPA91/SZ2o0f8e9U0Y8i2agfhn0RjqemHHWy8RafJ4pmW2iNuQWA
16Yb5THKj0bCUR6pXIp0I0s46sWe0g3C8dSU4xT9Y5VRwnGVcgDA06/ldfvrj+jP3ajTjUw2PsLM
JcLx5JQjSzrO8XlnSpZwOOcDgO+9dme7UkrZ+NlJN+rjlGyhp2s54XhqynGKfE5HL+W4SDkA4CXp
xlsn3fgZ/+tQiWjXbigUJRwvSTnOSdrRquXo1XF4kQLA89ONcrP3TTZ+RV67MSscruGE4+kpR/ZC
rgUkGklHebRS/x4pBwA85rod8bkFtuxM+RX/ThUt74fSDcKxqhdxVkR6rn4tEw6L3QDg+9KNcrhX
7yilrN342xCOD+kG4fhu0ciGyNSy0avnuJAOAHjq9bo35Kse9JXVbpR7U1qdKaRjAUabz3E77ijH
nZ/i856VbLFbPfI8G49+Kf58AMDjpKMuGC0fZbIR8XlvSnat9kFRwvGtKUeddvSOVkazObRVAcBz
ReNHI+EoR5jXbbDZGHNDvgjHt7+YoyEdI+GIgWwYeQ4AX7tG3zvk6xL/1m38jv7cDbJBOF6WcsxI
x6hN1gsYAL4mG6MhX2Xtxu33t9KNbAU9CMfLUo6IfCZHq4DUyHMAeN6HwboNtkw3fsW/RylZulEn
G9INwrEK4egtdru1Y9UpSC0dhoEBwOPSjawN9kf0h3xlRym9BW0gHN/+4q5/XhePZlNIoyMdhoEB
wH0fAust3q19Kb0R5nW6YUEb4Vjli322luMU88cqAIC5D4DZ+PJeoWjE50LRetCXBW2EY3UpxynG
I88z6YgwDAwAvnodnt0G22uDNcKccGwy5RhNII1KNlpFo9pkAWB83e0VipYjzFuFor0R5j4AEo5V
ikZv5HlLOmq5yGZzAADa6cY9haKjdOMjDPkiHCuVjvoN0KvnKKWjlWpckxe7lAMA7i8ULdONPw3Z
0AZLODaRckT0R56/JWlIr4iUVQNAO90YFYrW22Bv19ZsG6w2WMKxuZQjGilHvdDNNlkAuF82lhaK
3j7czR6luP4SjtULR28YWKtNNpMOBaQAkF9rlxSK9valZOkG0SAcm7Hu+ufnmF/udnuBX5K0I7wB
ALjO3l0oemuDHU0Udc0lHJu18CzpOMVcq2yraEkBKYAjy8Y9haK91fPaYAnHplOO2Y6V0Qp7BaQA
XFs/f3hbUig6c5RyCW2whGPjb5CI+WFgWceKAlIArqd5oWjrKKW3L0WhKOHYpYnfs2dlJuVQQArg
aNfUc5Vu3MSiPkop96UoFCUch0s3WrM5RrUcEQpIAbieZnUb74VkjFbP97bBKhQlHLsRjkw6WmnH
ktkc5e+RcgDYs2y02mDvWT1/eygUJRy7e6P03jwKSAGg/8EtG/BVH6XUMzci/j1KqWVDoSjhOMQb
RwEpACxLN94m0o1s5oZCUcJxWNFYknIoIAVANvKZGyaKEg50pCN7E40KSUvhiFBACuBYH9RaMzdM
FCUcGAjHkrTjngJSANhbujFazvZWXDPro5TfxdfW6nkQjl2+gXpvqkcUkDpaAbAn2TjH52OU8ms2
vvyjkIxWG6xCUcJxqMRjtoD0VMlGRH604k0DYC/Xxvoo5T0RjtuvjwpFyzZYhfeE45CisSTlOEW7
ayXCbA4A+0s3sqOUOt0oj1LqNtjf8XnmBtEgHIeTjuzNVYtHlnJElXJEqOcAsD/ZqI9SytqN8iil
XM5WHqW0Zm4oFCUchxSOmbSjJx29BwBs7bo4Okopd6WURynl+PLf8XmEuXSDcBze5GOQdtTHKxFz
XSsRZnMA2G660etKKWdu3K51rbqNslDUpGbC4Y1WveFaBaSzw8C8oQBsWTayrpT6KOU9/h1fXgtH
nW44SiEcRCOeM4FUZAhga9fB7CglWztfH6V8DJINRymEA/G8FfZmcwDYYroxe5Ryu5Z9RL56Phtf
HtINwkE4ctuPyItGR8PA9JgD2KJs9I5SypkbrU2wo/HlroeEw5tukHRkXSu9uRx1suENBmCtH7Zm
jlKWDPj6G8aXEw7cnXi0ZONcSUeEsecAtplujI5SbvtS6qOU3iZY48sJBwa2f2r8WqtzpWYkHwCw
huvdOeaPUupNsFlHyp8q3XD9IxyYTDci+rM5TgPpaM3kAIA1fLha0pXyVsjGaBOsrpQV8+4pWA2l
IFyqN1fWIlt+rf+9+nF785VHMCdvSAAvkI46tX2rxOM98hbYcnx5qw3WzA0JBxba/2ky5ThHfhRj
PgeANV7fZo5Sbr9W70opW2Czug3pBuHAHW/K1qeCXtLRa5cNb0IAK/gwNdqV8rOSjdu1q0w2fheP
7CglpBuEA8uEYzT2vFfTUUtGFjHqWgHwXde1ma6Uum7jFJ93pZSyYeYG4cCD3pyZdMyMPo/IZ3Q4
WgHwatmYXTtfD/j625ENRymEA094w47EY7RrJbwpAXzjtSvivrXzp/hct1HLhqMUwoEHvlFbb9zR
RtnRGvsIRysAvlc2WsO96iLR8ijlI4wvJxz4tmSjlXScY3y0UkpHNgzHmxPAM69frbqNH5HXbdTj
y+tCUZtgCQe+MfHobZQtpSOiXcsR3qQAvkk2bsKR1W2UtRutXSmjoxQzNwgHHigap8aPe90qM1tl
IxytAHjONauu28iKRLO6jXpXSn2UUg81JBuEA09IN1qfIno1HSWtYxVvVgCPTjfeYryYrW6BzY5S
WovZXLsIB54oHL2lbq0ppK2Uo3W0cpVyAPiibJR1G70W2Hp8ea8r5SOMLycc+LY3ciYd2Rs9O1rJ
JKP3piUdAJZ+MBoVibYWs7UGfGmBJRxY6aeKLPVoiYejFQDPkI1zRzZ6RymZbGiBJRx44Zs6on+0
Mtqzck3EI8LRCoCvf+ipt7/2jlLKaaLZFljTRAkHVvDGzqQjOklHRH8Caa+1jHQAmJWNXrpRd6VE
kVr8aaQbLdkgHYQDK3njt2o5Tp1kw9EKgKXXnIh2C+zPePxRCtkgHHjBmzw6yUZrOFiNgWAAHvEh
J2uBbS1mO8fnFtjeFlgfhAgHVvBGn5GOrHC0tcY+iywNBAPQk42ZFtjyKKU1b6PuSqmLRKUbhAMr
vhC0OlbqlCMmEw7SAaCedFzLRusopVzM1qvbIBuEAyt907dSjuxTSG8g2CjpAIBeC+zoKGVp3Ya6
MsKBlb75R5tle0cskQgH2QCQXXOyFtjeUrZ76jbqQlEQDqws6WilHffUc7QSkOy/CeB4spF1pWRr
58ujlCV1G+GDD+HAeoWjJxvn6I8+700i9cYHXGciPtdt1OnGr8hbYHsr51tbYB2lEA5sIOXIRKSW
jdklb0QDcH2JaA/3qo9R6rqNiP/VbfyNdt1GXbsBwoEVXxRmWmWXjj7v/djRCnCca0urbiOTjlvd
RhQy0TtKsZiNcGCHF4x678qSo5XrIEkBsG/Z6E0TLZONtyLZ+CjkwlEK4cCOLg4xSDmyhCNLOiJM
IgVcT/p1G6MW2CzdGLXAgnBgYxeJ0RHLqIi0t2sFwHGuI726jV/xb+3GUtmwK4VwYEdJRzSSjlMn
6Yj4fLRSXwi0ygLH+NByq8fo1W2U8zYi8q6Ucu18vQU2yAbhwPaFYyQfo9Hno5TDBQLY3/Wjtycl
q9t4j891G3+TdKMsElW3QTiww08p2aeW3jTSc/SPVogGcAzZODdkoz5KqYd7fTSSDXUbhAMHSDp6
0tEqHq1rOlqbZMuvjlaA7X9IyTpSWjM37ikStZiNcOAgF5Mlk0hLtMoCx5GNeinbryLdKAd8lcO9
Zuo2yAbhwMFSjkgEZLaeo/4qFgW2f53Ihnu9R75uvh7udY326HJ1GyAcB7yYLEk7Wh0rmWz4lALs
RzbOE7KRLWXLikTVbYBwuMAM6zmyAtJ6BHqva0U9B7CNa0HEeClb1gJ7k41ymmiWbqjbAOE46IWl
9cmmV8+RyUaWdKjnALZ3TZhZyvYr/m2Bres2smRD3QYIh2Tjkwj00o6YTDlG4gFgnbIxM9yrLBLt
DfdStwHCgekLTy0b2djzaKQdPr0A2/rw0Rvu9SvyPSm39/hHjFfOEw0QDoLRlY2ZGR0R7X0r2T93
tAKsUzZaw71ml7L9js/TRNVtgHDgk3TMiEhrwdspuYio5wDW/95fWiT6Hv0iUXUbIBwYfsqJmNsq
e4r+KvsI9RzAFmSjVSSaDfe6yUZWt/E7EY6PULcBwoE7RaT3yIQjfKoBVi0bdZHoe7SPUt7i81K2
Otn43Uk2vP9BOPDPRSiiP4G0HgamngPY5geJuki0HO6VpRujpWy/49+OlDrd8KEDhAPpJ59Z8ejV
c7Tkoyc7AL5PNmaKRHsbYLNJojfhaH3QAAgHmjf/r9RztOZz1Bcg0gF8z3t7VCRat8C+Rb6ULetI
+VukGo5TQDjwpU9GWdKxpJ7DJx3gdbIxUyTa2pNy73AvgHBgKuW4t57jRDqA1X1gyDbAjuZtRCER
mWyYtwHCgYd9IpoVj17a0br41L/uaAV4vmzURaKj4V61bBjuBcKBpyUdmYgsqec4RV7HMfvfBfB1
2ciOUlotsPW6+WxsueFeIBz4lotYJBezc7S7V66dry5OwHM+MMxugK3XzUcY7gXCgRelHKNjlVPM
d65EQz4APFc23qN9jDK7AdZwLxAOPP3i1ROPVvFoa9+KoWDAc9+vrSLR3rr5siMlmyRquBcIB74t
6Whd2EbyUf57s7tWSAew/L3aKhLNajbKo5Tb+7Gu28gGfBnuBcKBl1zcMtkYzei4hiVvwDNlo1W3
8Sv6HSmtseV/qlTDcQoIB56eciyp5xjtWwkXLOChsjEzSfT2a7flbXX765+GbLS6UgDCgadIx8x8
jl7HSq+ewwUMuP992Wt/bclGxOci0V5HSpZOAoQDT006ZhKP3gTSmBAPQ8GA+2TjPfo7UmrZ+JPI
xt9ojy4nGyAcePmFb7R3JRpJx+gCRjqA9nsua3/9kchG2f5aHqX8bchGJhpkA4QD355ynDoXwJ5w
tBa9tbbLkg7g83uhN7a8JRv1cK9MNrI9KY4+QTjwcumIRroxSjwy4QgXNGCxbNyE40e0N8C2dqRk
slEnGzpSQDiwioteT0BGicdIOlzcgLZstDpSbrLxK/4tEm0lG9lCNh0pIBzYVPIxU8/RG38+8+vA
0d5fswvZykmi2Y6UvzG3kE2RKAgHVicY2a+P5nS0pKN1xKJzBWRjviOlHFt+LYQia3/VkQLCgc1I
x5Ii0tacjmiIxhLhAfYqG7MdKT8j35HSkw0dKSAc2GzS0Uo5WsveIpGOCJ0r8N7q7UiZLRLtzdto
HaWQDRAOrPLCuGQE+jk+TySNCekAjvi+Kt8zre2vtWwsHVuuIwWEA7tJP7LjlNbCt0w6iAeOLhu9
seU/CxmZ6UgZFYkChAO7kI5RTcft9/YSDhdGHEE2yvbXt2jvSLnJxsyOFGPLQTiwO7mofzwqHO3V
c+hcwZHeQ62OlNYk0bf4XCTakw1FoiAc2JV0zHx6O1cX15iQjnv/28AWZaNONup5G+8LZcPYchAO
7OrCOSoijRivtG/Vc+hcwZ5lI2t/7clG1pHS25FibDkIBw6VfpzivmVvzplxFNl4S2TjV0c2atHI
Zm0YWw7CgUNeXGvxOA/EI5MNF0zsTTbOC2Tj3Eg26lSjdYziPQTCgV0mGtFILXqdKxE6V7Dv98hM
+2u9I6VeyNaSjWy4l44UEA7sXjpGQrJ0u6zOFexVNrJ0o5SNc/Faz6aI1pNEdaSAcOBQF9Ze2rFk
GFg0RGOp8ABrkI1TLNv+WstGOdwra3/VkQLCAUReIHqO5ZNIda5gS6/5mEw2MtnIikRbHSnGloNw
AJ2kI2J558roQko6sGbZeItx++usbNSioSMFhAOHFoyYlI3WVtlTknLc+98Hvls2WrM2yimiZbLx
1pGNVoFo1v5KOkA4cOhUI5OBpTM6ekWkwFplYybZGA32ahWJan8F4YCL7wIhWdq94sKKLcjGeSAb
PxuysSTZ0P4KwgHEXOfKbNIRHfFwwcXaZaPX/noqXr91R0o92KtONwg4CAdwh4xkNR0R41kdEWZ0
YJ2ykW1/zWZtXCrZmD1GcbQIwgFMXKRbF+17k46Z/xbwDHGebX/9WfyzctbGpZKNXrKh/RWEA1h4
0++1zJ5j3MnSSjfM6MArZaO1ar5MNt6K12tv82tv1gZAOIBJuWjJxinGC98ikQ4zOvAK2TgVElHK
RiYcb9GftdETDe2vIBzAF5OO1kV8ZlbHkgsv6cAjX8e9AtGWbPTaX+ux5WZtgHAAD/hkOJKAJfM5
IuZndJAOPEs2ZlbNl9tfW3M2zNoA4QC+OQW5Z7vszAWZdOCrsnE7RjnHv90o2aPXkWLWBggH8OIL
+kg6iANeKRv1FNGfSbJRDvaalQ2zNkA4gCcmGbMX+ZF09FIP4FmykdVsZMnGrUi0lAyzNrAr3j0F
WCHX+F/x3O2TXy/VyET6dvF/XyAaV0KCL8pGb7hXXbNRy8bsFFGzNiDhAJ6YdMwMBOslHqdB0mEa
KR4hGy3RyGTjFPkyttvXUjTM2gDhAL5ROmZ+7z1bZh/13wfZaA32am1/bbW+zgz2Ih0gHMATLvB1
2nGdTD7OxafJc4w3zJpGiiVi25KNHw3ZqI9RPiJvff0zSDbIBggH8M1cO7KRScepk3CYRopHy8aP
SjZGszZ+kw0QDmB7N4NWwtEbf24wGGZeXzMjyzPZyJax1d0orSJRsgHCAXzjBX/J7zvHePz50gs5
6Tj2a2/pyPKWbGStr+VRSi0aZAOEA1iBdPQ2yy7pVJkVENJBNkZr5svtr0tlozVvAyAcwMqSjtmR
56eGXFwf8HfA/mTjFHnra/ao96Nkq+Zni0TJBggHsCLpaCUbMfh9X5EJ0nFM2ZgZWV52pPRGls90
pACEA1hp0jH69dOCf4d0kI1e3cY9y9iyza86UkA4gI1KxxLxOE38mpoOsjEjG9msjWyKaNmRQjZA
OIAN3SCywtEZSWgJxtKts6Rj/7LRmyLa24/SG1l+IRsgHMA+uA7kYOkodNJxPNm4LQHMOlJ+xOea
jVZHSjay/INsgHAA+5OOa7RbaFvzOlrSsbR+BPuQjaWzNowsBwgHdnzTmEk6shtMfaPJJpPGxI2B
dOxXNkZr5ltTRI0sB+HwFOBA0hET6UZLOE4d+SAd+5ONt4Fs3LpR6sFeH5EP9jKyHIfn3VOAnXGN
f7tKLhM3nNaSt2wfy4hL4++BbcnGW5Fi/Kyko042buJwE4pW3YaR5ZBwAAdMOmaPVGrZWJJySDq2
m2zUicZoGdtHkWTUomFkOUA4QDqmpaO1bZZ07E826m6UX/G5biPb/PqnSjf+hpHlwP+PIxXsmdnj
lVGyca9oOF7Zhmxka+brZKNVIJrN2pidIgpIOIADJB29WRxZ7Ua23l7Ssc3XQp1svEc+1KvejzIq
EK1l428Y7AUQDpCO5BNvdMTj1BEP0rFd2chqNn5VsvGjIRutZWxkAyAccMPpJhy9xOOrbbKkYz2y
cYp/t75msvEr/i0SbSUbZAMgHMBdN/tT4wY1MwKddKz7e95KNn4myUbdkXKThdYxiv0owABFozga
5Xjz2yfW0383ilNDOOr3zLm4CS1FIem6ZGO0jO1UfN8+Iu9IKWXDFFGAcAD/SEcmAbMJBOnYnmxk
XSmjZWwzslF3pJANgHAATem4VDepXoFp+c9Jx3Zko9f+WspGfYwym2w4RgEIBzAlHjcB+Fj475OO
bcjGaEfK7dfKbpRrfB5VXm9//Qhr5gHCAUzIxmgw2ExxJ+lYv2xkdRsj2aiHetXL2FrjyskGQDiA
u6RD0rF92ViabPRko1e3QTYAwgGQjgPKxswxStn2WsrGbRHb//nv8XsgGmQDIBwA6TiobGSikW19
jUIg6rqN/xOfZ27Y/AoQDoB0kI1/ZCNLNt4aslEP9moVidr8ChAOYFPScSUd3y4b2VCv1ubXGdkA
MIHR5kD/Zrb035kZfX5KpKNXcGgM+tdk42fkdRutZWy1bGTzNnSkAIQDWK10jFbbXx/8dyEbn2Xj
LT5vfq1rNkbDvcgGQDiAVUlHxPyyt+uT/i5ko1+38VGIRZ1qjI5RyAZAOIBVSEc0ko1e0nF9wt+F
bPwrG9kxSms3CtkACAeweukYJRy9pMPxyvfKRl2v8TfsRwEIB7AR6RilHCPJIB1kAyAcAOlY/PtH
6QbpIBsA4QAwfaO/Ttw8Z/6smTkcpwM8z2QDIBwA6VgoHV/9b5w68kI2yAZAOICDSsf1Thmp6zxO
D/j7kA2yARAOYIfS0dsiel0gGqcF/83TTp5PsgEQDgATN/lMNlpfa7mYHZEeC36NbJANgHAAB0k6
WqvMr40bbnnjbdVuXO/4+5ANsgEQDmCH0nHtiEfNORGNmYFhW5YOsgEQDgBflI6sdqNON06dhGMk
HVtvmyUbAOEA8A1JRyYdp0HSsVQkTit/nsgGQDgAPIBesehswtFKOkY38rUKB9kACAeAbxaR7Ea8
pJZjJCBrSzvIBkA4ADzxBrvk98+2yC5pl12DdJANgHAAWJF0xEAwviofpxc+B2QDIBwAViYdrRt2
LRjn5NdOT/77kA0AhAPYmHTM7lzpHbGcY52zOsgGAMIBrFQ6RjfIXkFp64il171yevL/K9kACAeA
lUnHaOdKDBKOe4Xi9KT/R7IBgHAAK5KO1kbZbAz6qIj0tDDheLR0kA0AhAPYSNLR+/kS4TjFsmFg
jyxuJRsACAewQlrTSHs399HulZnk41F1HWQDAOEANiwg98jHzIr7R7bOkg0AhAPYCKeF6cdpIBzn
WHbEcm9dB9kAQDiAnUjHkuOW2QVws4PCZpfFkQ0AhAPYuHRcJwQkkpt//TVrn723roNsACAcwE6T
jlI4WjfbUyzvYImYr+s4kQ0AhAPYv3T0hoO1WmZnOlgi+vM7epJx/u+aQjYAEA5g48xMIR2lHefo
d7D0jlJahalvxeO9EAyyAYBwADsTkVYxaauTZXYPS6ve41zJRikaZAMA4QA2zEyL6ld2sMw+ylqN
8hilFg2yAYBwADuTjt7it2uMZ3b0BKTscnmLz/UaPxrpBtkAQDiAHUnHdYF8ZInHSDLOHdnIhKNM
NzLZ+FMIB9kADsC7pwDYJNfiJn7jUsnGNfl5/XivxCIaknETjI/ivxPxuWj0vfjxufgzM9n4XSQc
ZAMgHABWLB3Zz7Ov1+Km35KOc+QdLDeh+ChEIBI5eUv+HLIBgHAAOxSPS3Wjj4Zo1Df19/++3hKJ
Ou34aIhAa5pp+fe5FELxp3qQDYBwANiYdJRHLJfqn/VSjttNvi7wPFdJRy0CZcpRDwu7kg0AJYpG
gX3RKiZtFZXOzO9o1XTUtRonyQYAwgEcVzp6AnJdIB+jPSxlqvHRkI3bzz/IBkA4AOyH0Qj0a+fH
LfnI/pxLRzb+xudk44NsAIQDwH4Yrblv1Xdcot/ZUtd/fBQi8Sf6RyhkAzggikaBfZMVk54S2SjF
4SP+N3OjHPRVt8yWf2b5735U6cbf+PcI5SMRGbIBEA4AO5OOa0c43qrEoi4MrYtDy3+/Fo7y60fk
rbVkAyAcAHYmHSV10nGuxONcyUadcGTCUUtHKRkfkR/NkA3gIJw8BcBh3/etDbC9R0s4aum4JKJx
ibn9LgAIB4AdSkdEvrAt+5oJR8TnTpVsmqlUA3DRAUA8uslHJhuldIymmQbZAFxsALgOzApI/ftH
g8SIBgDCAWAoHtEQjYyeYJANwAUGAIbXhtlrBckAkGLSKIBHQjIAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AAAAAAAAYNf8PwIMANFK5oa2Qfv6AAAAAElFTkSuQmCC" transform="matrix(1 0 0 1 37 53)">
     </image>
 </g>
 <path fill='${colorCode}' d="M314.097,699.886c-2.827,2.637-9.103,2.92-12.182,0.583C201.93,624.582,65.736,443.999,75.101,311
     C82.725,187.667,179.799,90.72,307.529,90.72S536.285,192.396,539.668,311C534.861,442.667,460.527,558.333,314.097,699.886z"/>
 <path fill="#FFFFFF" d="M206.006,458.73c0,9.611,9.981,11.829,13.678,11.829c3.697,0,173.746,0,178.921,0
     c5.176,0,11.83-4.806,11.83-17.005s0-120.513,0-120.513s34.276-17.201,12.939-55.081c-17.887-30.496-26.539-44.477-29.352-48.956
     c-0.588-0.935-1.966-1.689-3.07-1.689H227.338c-1.104,0-2.481,0.754-3.075,1.685c-5.517,8.659-30.539,48.015-34.153,55.244
     c-4.066,8.133-8.502,40.294,15.896,48.797C206.006,333.041,206.006,449.118,206.006,458.73z"/>
 <path fill='${colorCode}' d="M378.386,322.371c-5.027,7.313-13.692,12.146-23.546,12.146c-9.865,0-18.539-4.845-23.563-12.171
     c-4.777,7.606-13.282,12.671-22.984,12.671c-9.719,0-18.237-5.082-23.009-12.71h-0.05c-5.012,7.058-13.545,11.706-23.233,11.706
     c-9.688,0-18.221-4.648-23.233-11.706h-0.185c-3.099,6.327-9.865,11.302-17.734,11.302c-1.688,0-3.316-0.223-4.878-0.603v78.75
     h185.342v-78.585c-1.355,0.264-2.758,0.406-4.197,0.406C388.702,333.579,381.482,329.128,378.386,322.371z"/>
</g>
</svg>`;
  var icon = {
    url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg),
    scaledSize: new window.google.maps.Size(40, 50),
  };
  return icon;
};

export default getIcon;
