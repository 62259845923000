const { orderBy } = require('lodash');

const getOpeningHours = (day, allShifts) => {
  let time = '';
  if (allShifts) {
    let todaysShifts = allShifts.filter((s) => {
      return (
        Number(s.day) === day && (!s.noDineIn || !s.noPickup || !s.noDelivery)
      );
    });
    todaysShifts = todaysShifts.map((t) => {
      t.openingStart = Number(t.openingStart);
      return t;
    });
    todaysShifts = orderBy(todaysShifts, 'openingStart', 'asc');
    todaysShifts.map((a, i) => {
      i === 0
        ? (time =
            time +
            (a.openingStart === 0 ? '12:00 AM' : a.openingStartDisplay) +
            ' - ' +
            a.openingEndDisplay)
        : (time =
            time +
            ', ' +
            (a.openingStart === 0 ? '12:00 AM' : a.openingStartDisplay) +
            ' - ' +
            a.openingEndDisplay);
      return i;
    });
  }
  if (time === '') {
    time = 'Closed';
  }
  return time;
};

export default getOpeningHours;
