import { API_URL } from 'configuration/urls';

export const trimAddress = ({ mainAddress, secondryAddress, storeConfig }) => {
  if (!secondryAddress) {
    return mainAddress
      .replace(/Street /gi, 'St ')
      .replace(/Court /gi, 'Crt ')
      .replace(`, ${storeConfig.country}`, '')
      .replace(`, ${storeConfig.countryAbv}`, '')
      .replace(`${storeConfig.state}`, '')
      .replace(`${storeConfig.stateIdentifier}`, '');
  } else {
    const main = mainAddress
      .replace(/Street /gi, 'St ')
      .replace(/Court /gi, 'Crt ');
    const secondry = secondryAddress
      .replace(/Street /gi, 'St ')
      .replace(/Court /gi, 'Crt ')
      .replace(`, ${storeConfig.city}`, '')
      .replace(`, ${storeConfig.country}`, '')
      .replace(`, ${storeConfig.countryAbv}`, '')
      .replace(`${storeConfig.state}`, '')
      .replace(`${storeConfig.stateIdentifier}`, '');

    return `${main}${secondry ? `, ${secondry}` : ''}`;
  }
};

const checkIfInsideRadiusApi = async ({ storeId, locationCoordinates }) => {
  const URL = `${API_URL}deliveryLocations/v1/checkIfInsideRadius`;

  const requestObj = {
    storeId,
    destinationLat: locationCoordinates.lat,
    destinationLong: locationCoordinates.lng,
  };
  const urlParams = new URLSearchParams(requestObj);
  const response = await fetch(`${URL}?${urlParams.toString()}`);
  const result = await response.json();
  return result;
};

export const getDeliveryCostFromApi = async (storeId, locationCoordinates) => {
  const result = await checkIfInsideRadiusApi({ storeId, locationCoordinates });
  const { data, errors = [] } = result;
  if (errors.length > 0) {
    return false;
  }
  const { deliveryInfo } = data;
  const { deliveryCost, isDeliverable, minOrderValue } = deliveryInfo;
  return { isDeliverable, deliveryCost, minOrderValue };
};
