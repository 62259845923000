import { orderBy } from 'lodash';
import {
  MENU_ITEM_DATA_TYPE,
  MENU_ITEM_OBJ_TYPE,
  MENU_ITEM_TYPE,
} from './enums';
import getPriceForDisplay from './getPriceForDisplay';

export default function getSimpleAndSpecialMenuItems({
  menuItems,
  halfHalfs,
  halfHalfPlaceholderImage,
  isSpecialFilterApplied,
  soldOutMenuItems,
  spMenuSizes,
  itemType,
  isSpecial = false,
  _id,
  sortingType,
  orderType,
}) {
  // Using Mutation here: Update Availability of menuitems to mark sold out items
  const filteredSoldOutMenuItems = soldOutMenuItems ?? [];
  menuItems?.forEach((item) => {
    if (item.itemType === MENU_ITEM_TYPE.simple) {
      // let count = 0;
      item.selectedSizes?.forEach((selectedSize) => {
        filteredSoldOutMenuItems.forEach((soldOutItem) => {
          if (item._id === soldOutItem.menuItemId) {
            if (
              soldOutItem.smId === selectedSize._id &&
              soldOutItem.variantId === null
            ) {
              selectedSize.availability = false;
              // count++;
            }
            if (
              soldOutItem.smId === selectedSize._id &&
              soldOutItem.variantId !== null
            ) {
              selectedSize.variants?.forEach((variant) => {
                if (variant._id === soldOutItem.variantId) {
                  variant.availability = false;
                }
              });
            }
          }
        });
      });
      // return count !== item.selectedSizes?.length;
      // } else if (item.itemType === MENU_ITEM_TYPE.complex) {
      // let count = 0;
      // item.selectedModifiers.forEach((modifier) => {
      //   modifier.subModifiers.forEach((subModifier) => {
      //     count++;

      //     subModifier.variants?.forEach(() => {
      //       count++;
      //     });
      //   });
      // });
      // let filteredItemExists = filteredSoldOutMenuItems.some(
      //   (soldOutItem) => soldOutItem.menuItemId === item._id
      // );

      // return !(count === filteredItemExists && count !== 0);
    }
  });

  // =====================><=====================
  let newList = [];
  if (_id && halfHalfs?.length > 0) {
    const matchingHalfCategory = halfHalfs.find((item) =>
      item.categories.some((c) => c._id === _id)
    );
    if (matchingHalfCategory) {
      let obj = {};

      obj.itemType = MENU_ITEM_OBJ_TYPE.halfhalf;
      obj.item = matchingHalfCategory;
      obj.displayOrder = -1;
      obj.totalRevenue = 1000000000000;
      obj.lazyLoad = false;
      obj.imgSrc = isSpecial
        ? `https://pics.nextorder.co/assets/spPlaceholder.jpg`
        : `https://pics.nextorder.co/assets/placeholder.jpg`;
      obj.bgImgSrc = halfHalfPlaceholderImage
        ? halfHalfPlaceholderImage
        : `https://pics.nextorder.co/assets/placeholder.jpg`;
      obj.key = `halhalk23set`;
      obj.showName = true;
      obj.productName = 'HALF / HALF';
      obj.ingredients = matchingHalfCategory.categories
        .map((c) => c.name)
        .join('/');
      newList.push(obj);
    }

    menuItems?.forEach((item, index) => {
      let obj = {};

      obj.itemType = itemType;
      obj.item = item;
      obj.displayOrder = item.displayOrder ? item.displayOrder : 0;
      obj.totalRevenue = item.totalRevenue ? item.totalRevenue : 0;
      obj.lazyLoad = false;

      obj.imgSrc = isSpecial
        ? `https://pics.nextorder.co/assets/spPlaceholder.jpg`
        : `https://pics.nextorder.co/assets/placeholder.jpg`;
      obj.bgImgSrc = item.urlS3 ? item.urlS3 : item.url;
      obj.key = index;
      obj.price = getPriceForDisplay(
        item,
        isSpecialFilterApplied,
        soldOutMenuItems,
        spMenuSizes,
        { orderType }
      );
      obj.showName = !item.isNameHidden;
      obj.productName = item.name;
      obj.ingredients = item.displayName ? item.displayName : item.description;
      newList.push(obj);
    });
  } else {
    menuItems?.forEach((item, index) => {
      let obj = {};

      obj.itemType = itemType;
      obj.item = item;
      obj.displayOrder = item.displayOrder;
      obj.totalRevenue = item.totalRevenue;
      obj.lazyLoad = false;
      obj.imgSrc = isSpecial
        ? `https://pics.nextorder.co/assets/spPlaceholder.jpg`
        : `https://pics.nextorder.co/assets/placeholder.jpg`;
      obj.bgImgSrc = item.urlS3 ? item.urlS3 : item.url;
      obj.key = index;
      obj.showName = !item.isNameHidden;
      obj.price = getPriceForDisplay(
        item,
        isSpecialFilterApplied,
        soldOutMenuItems,
        spMenuSizes,
        { orderType }
      );
      obj.productName = item.name;
      obj.ingredients = item.displayName ? item.displayName : item.description;
      newList.push(obj);
    });
  }

  // ===================== SORTING =====================
  if (sortingType === '2' || itemType === MENU_ITEM_DATA_TYPE.special) {
    newList = orderBy(newList, ['displayOrder'], ['asc']);
  } else {
    newList = orderBy(newList, ['totalRevenue'], ['desc']);
  }

  return newList;
}
