import { TableIcon } from 'nexticons/outline';
import {
  LayoutPartUI,
  ScrollableBoxUI,
  ModalCWShellUI,
} from '@next-order/next-order-components';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

const SelectTable = ({ floors, hideModal, addTableToOrder, selectedTable }) => {
  const [localSelectedTable, setLocalSelectedTable] = useState(
    selectedTable ?? null
  );

  useEffect(() => {
    setLocalSelectedTable(selectedTable ?? null);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ModalCWShellUI onOpenChange={hideModal} open={true} zIndex={100}>
        <ModalCWShellUI.CloseButton onClick={hideModal} />
        <ModalCWShellUI.Header title={'We couldn’t find your table'} />

        <ModalCWShellUI.Body>
          <ScrollableBoxUI
            isBottomShadow
            paddingX='none'
            extendedClassName='pt-2 pb-5 -2xs:px-5'
          >
            <LayoutPartUI.Row>
              <p className='text-lg leading-tight tracking-tight font-light text-primary-text'>
                Please select your table below.
              </p>
            </LayoutPartUI.Row>
            <LayoutPartUI.Row extendedClassName='relative'>
              <div className='overflow-hidden relative w-full bg-white divide-y divide-gray-200  border border-gray-300 rounded-2.75 shadow-sm'>
                {Object.keys(floors)?.map((floorId) => {
                  const { name, tables } = floors[floorId];
                  return (
                    <div key={floorId} className='divide-y divide-gray-200'>
                      <div className='overflow-hidden bg-gray-50 flex items-center py-2.5 px-3.5 leading-none tracking-wide truncate text-gray-500 text-sm uppercase'>
                        {name}
                      </div>
                      <ul className='divide-y divide-gray-200'>
                        {tables?.map((table) => {
                          const { id, name, number, floorId } = table;
                          const isSelected =
                            localSelectedTable?.floorId === floorId &&
                            localSelectedTable?.id === id;
                          return (
                            <li
                              key={id}
                              className={classNames(
                                'overflow-hidden h-13 px-3.5 py-1 grid grid-cols-32px-1 gap-x-3.5 items-center justify-start cursor-pointer',
                                { 'bg-blue-500': isSelected }
                              )}
                              onClick={() => {
                                setLocalSelectedTable(table);
                              }}
                            >
                              <span className='inline-flex items-center justify-start'>
                                {
                                  <TableIcon
                                    width={25}
                                    className={classNames('text-gray-500', {
                                      'text-white': isSelected,
                                    })}
                                  />
                                }
                              </span>

                              <span
                                className={classNames(
                                  'block text-4.25 leading-tight font-normal',
                                  {
                                    'text-gray-900': !isSelected,
                                    'text-white': isSelected,
                                  }
                                )}
                              >
                                {name || `Table ${number}`}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </LayoutPartUI.Row>
          </ScrollableBoxUI>
        </ModalCWShellUI.Body>

        <ModalCWShellUI.Footer>
          <ModalCWShellUI.Button
            disabled={!localSelectedTable}
            onClick={() => addTableToOrder(localSelectedTable)}
          >
            Confirm Table{' '}
            {localSelectedTable?.name || localSelectedTable?.number}
          </ModalCWShellUI.Button>
        </ModalCWShellUI.Footer>
      </ModalCWShellUI>
    </>
  );
};

export default SelectTable;
