import React from 'react';
import Collapsible from 'react-collapsible';

const Accordion = ({ children, transitionTime, easing }) => {
  return children.map((nodes, index) => {
    const node = nodes?.props?.children[0];
    const emptyNode = nodes?.props?.children[1];
    const triggerWhenOpen = node.props['data-trigger-when-open']
      ? node.props['data-trigger-when-open']
      : node.props['data-trigger'];
    const triggerDisabled = node.props['data-trigger-disabled'] || false;
    const className = node?.props?.className;
    const isOpen = node.props['data-trigger-isopen'];
    const open = isOpen || (typeof isOpen === 'undefined' && index === 0);
    const handleTriggerClick = emptyNode.props?.handleTriggerClick;

    return (
      <Collapsible
        key={'Collapsible' + index}
        handleTriggerClick={handleTriggerClick}
        trigger={node.props['data-trigger']}
        triggerWhenOpen={triggerWhenOpen}
        triggerDisabled={triggerDisabled}
        transitionTime={transitionTime}
        easing={easing}
        accordionPosition={index}
        open={open}
        className={className}
        openedClassName={className}
      >
        {node}
      </Collapsible>
    );
  });
};

export default Accordion;
