const removeTruthyProperties = (obj) => {
  const cleanedObject = obj;

  Object.keys(cleanedObject).forEach((key) => {
    if (cleanedObject[key]) {
      delete cleanedObject[key];
    } else {
      cleanedObject[key] = true;
    }
  });
  return cleanedObject;
};

export default removeTruthyProperties;
