import PropTypes from 'prop-types';
import { useGlobalModalContext } from 'modal/useGlobalModalContext';
import { ReactComponent as Close } from 'assets/images/icon-close.svg';

const GlobalModalInner = ({
  title,
  message,
  actionButtonLabel,
  actionButtonHandler,
  dismissButtonLabel,
  dismissButtonHandler,
}) => {
  const { handleGlobalModal } = useGlobalModalContext();

  const handleActionButton = () => {
    actionButtonHandler();

    handleGlobalModal(false);
  };

  const handleDismiss = () => {
    // check if custom dismiss actions is passed
    dismissButtonHandler?.();

    handleGlobalModal(false);
  };

  const handleClose = () => {
    handleGlobalModal(false);
  };

  return (
    <div className='global-modal'>
      <Close className='global-modal__close' onClick={handleClose} />

      <div className='global-modal__header'>
        <h3 className='global-modal__title'>{title}</h3>
        <div className='global-modal__desc'>
          <p>{message}</p>
        </div>
      </div>
      <div className='global-modal__footer'>
        <div className='global-modal__actions-group'>
          {actionButtonHandler && actionButtonLabel && (
            <button
              className='global-modal-btn'
              title={actionButtonLabel}
              type='button'
              onClick={handleActionButton}
            >
              {actionButtonLabel}
            </button>
          )}
          {dismissButtonHandler && dismissButtonLabel && (
            <button
              className='global-modal-btn'
              title={dismissButtonLabel || 'Dismiss'}
              type='button'
              onClick={handleDismiss}
            >
              {dismissButtonLabel || 'Dismiss'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

GlobalModalInner.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  actionButtonLabel: PropTypes.string,
  actionButtonHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  dismissButtonLabel: PropTypes.string,
  dismissButtonHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

GlobalModalInner.defaultProps = {
  title: '',
  message: '',
  actionButtonLabel: '',
  dismissButtonLabel: '',
  actionButtonHandler: false,
  dismissButtonHandler: false,
};

export default GlobalModalInner;
