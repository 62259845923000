import { ORDER_PAYMENT_TYPE } from 'helpers/enums';
import { getPlatformAndCardCharges } from 'helpers/getPlatformAndCardCharges';

const getItemCustomizedPrice = ({
  item,
  size,
  halfHalfSetup,
  orderTracker,
}) => {
  if (!item) {
    return 0;
  }
  let itemPrice = 0;
  let quantity = 0;
  let halfQuantity = 0;
  let extraPrice = 0;
  let extraPriceHalf = 0;
  let currentItem = JSON.parse(JSON.stringify(item));
  if (!currentItem.itemType || currentItem.itemType === 0) {
    currentItem.selectedSizes &&
      currentItem.selectedSizes.map((item) => {
        if (item._id === size._id) {
          if (item.quantity > 0) {
            quantity = quantity + item.quantity;
            itemPrice = itemPrice + item.quantity * Number(item.price);
          }
          if (item.variants) {
            item.variants.map((v) => {
              if (v.quantity && Number(v.quantity) > 0)
                itemPrice = itemPrice + item.quantity * Number(v.price);
              return v;
            });
          }
        }
        return item;
      });
    if (quantity > 0) {
      currentItem.selectedExtraIngredients &&
        currentItem.selectedExtraIngredients.map((item, index) => {
          if (item.quantity > 0) {
            extraPrice =
              extraPrice + Number(item.quantity) * Number(item.price);
          }
          return item;
        });
      itemPrice =
        extraPrice *
          (halfHalfSetup?.chargeFullHalfExtraPrice
            ? Math.max(quantity, 1)
            : quantity) +
        itemPrice;
    }
  } else {
    currentItem.selectedModifiers &&
      currentItem.selectedModifiers.forEach((m) => {
        m.subModifiers.forEach((s) => {
          if (m._id === currentItem.baseModifierId) {
            quantity = s.quantity;
            itemPrice = itemPrice + Number(s.quantity) * Number(s.price);
            if (s.variants) {
              s.variants.forEach((v) => {
                itemPrice = itemPrice + Number(v.quantity) * Number(v.price);
              });
            }
          } else {
            if (s.prices) {
              s.prices.forEach((p) => {
                itemPrice = itemPrice + Number(p.quantity) * Number(p.price);
                if (p.variants) {
                  p.variants.forEach((v) => {
                    itemPrice =
                      itemPrice + Number(v.quantity) * Number(v.price);
                  });
                }
              });
            }
          }
        });
      });
    if (quantity > 0) {
      currentItem.selectedExtraIngredients &&
        currentItem.selectedExtraIngredients.map((item) => {
          if (item.quantity > 0) {
            extraPrice =
              extraPrice + Number(item.quantity) * Number(item.price);
          }
          return item;
        });
      itemPrice =
        extraPrice *
          (halfHalfSetup?.chargeFullHalfExtraPrice
            ? Math.max(quantity, 1)
            : quantity) +
        itemPrice;
    }
  }
  if (
    currentItem.isHalf &&
    (!currentItem.itemTypeHalf || currentItem.itemTypeHalf === 0)
  ) {
    currentItem.selectedSizesHalf &&
      currentItem.selectedSizesHalf.map((item) => {
        if (item.quantity > 0) {
          halfQuantity = halfQuantity + item.quantity;
          itemPrice = itemPrice + item.quantity * Number(item.price);
        }
        if (item.variants) {
          item.variants.map((v) => {
            if (v.quantity && Number(v.quantity) > 0)
              itemPrice = itemPrice + item.quantity * Number(v.price);
            return v;
          });
        }
        return item;
      });
    if (halfQuantity > 0) {
      currentItem.selectedExtraIngredientsHalf &&
        currentItem.selectedExtraIngredientsHalf.map((item) => {
          if (item.quantity > 0) {
            extraPriceHalf = extraPriceHalf + item.quantity * item.price;
          }
          return item;
        });
      itemPrice = extraPriceHalf * halfQuantity + itemPrice;
    }
  } else if (currentItem.isHalf && currentItem.itemTypeHalf === 1) {
    currentItem.selectedModifiersHalf &&
      currentItem.selectedModifiersHalf.forEach((m) => {
        m.subModifiers.forEach((s) => {
          if (m._id === currentItem.baseModifierIdHalf) {
            halfQuantity = s.quantity;
            itemPrice = itemPrice + Number(s.quantity) * Number(s.price);
            if (s.variants) {
              s.variants.forEach((v) => {
                itemPrice = itemPrice + Number(v.quantity) * Number(v.price);
              });
            }
          } else {
            if (s.prices) {
              s.prices.forEach((p) => {
                itemPrice = itemPrice + Number(p.quantity) * Number(p.price);
                if (p.variants) {
                  p.variants.forEach((v) => {
                    itemPrice =
                      itemPrice + Number(v.quantity) * Number(v.price);
                  });
                }
              });
            }
          }
        });
      });
    if (halfQuantity > 0) {
      currentItem.selectedExtraIngredientsHalf &&
        currentItem.selectedExtraIngredientsHalf.map((item) => {
          if (item.quantity > 0) {
            extraPriceHalf = extraPriceHalf + item.quantity * item.price;
          }
          return item;
        });
      itemPrice = extraPriceHalf * halfQuantity + itemPrice;
    }
  }
  if (currentItem.isHalf)
    itemPrice =
      itemPrice +
      (orderTracker?.halfHalfSetup?.halfHalfAdditionalCharges
        ? Number(orderTracker.halfHalfSetup.halfHalfAdditionalCharges)
        : 0) *
        (quantity + halfQuantity);
  return itemPrice.toFixed(2);
};

const getViewOrderData = ({
  menuItems,
  currency,
  halfHalfSetup,
  orderTracker,
}) => {
  const data = {
    items: [],
    additional: [],
    notes: '',
    paymentStatusDisplay: '',
    paymentBy: '',
    total: '',
  };
  const { cardChargesTitle, cardCharges } = getPlatformAndCardCharges(
    orderTracker?.orderObj
  );

  menuItems?.forEach((item) => {
    const itemData = {
      name: '',
      size: '',
      count: 0,
      price: '',
      extras: [],
      remove: [],
    };
    !item?.isHalf &&
      item?.selectedSizes?.forEach((size) => {
        itemData.count = size.quantity;
        itemData.size = size.name;

        itemData.name =
          !item.itemType || item.itemType === 0
            ? item.name
            : `${item.name}${
                size.cName && size.cName.length > 0 ? `, ${size.cName}` : ''
              }`;
        itemData.name += size.variants
          ? size.variants.filter((f) => f.quantity > 0).length > 0
            ? `(${size.variants
                .filter((f) => f.quantity > 0)
                .map((v) => v.name)
                .join(', ')})`
            : ''
          : '';

        let extrasData = { name: '', items: [] };
        let removeData = { name: '', items: [] };
        item?.selectedExtraIngredients?.forEach((ingredient) => {
          const extra = ingredient.name
            ? ingredient.name
            : ingredient.ingredientName;
          extrasData.items.push(extra);
        });

        item?.removedIngredients?.forEach((ingredient) => {
          const remove = ingredient.name
            ? ingredient.name
            : ingredient.ingredientName;
          removeData.items.push(remove);
        });

        if (extrasData.items.length > 0) {
          itemData.extras.push(extrasData);
        }
        if (removeData.items.length > 0) {
          itemData.remove.push(removeData);
        }

        itemData.price = `${currency}${getItemCustomizedPrice({
          item,
          size,
          halfHalfSetup,
          orderTracker,
        })}`;
      });

    item?.isHalf &&
      item?.selectedSizes.map((size) => {
        itemData.count = size.quantity * 2;
        itemData.size =
          !item.itemType || item.itemType === 0
            ? `${size.name}`
            : `${size.name} ${
                size.cName && size.cName.length > 0 ? `,${size.cName}` : ''
              }`;
        itemData.size += '/';
        itemData.size +=
          !item.itemTypeHalf || item.itemTypeHalf === 0
            ? `${item.selectedSizesHalf
                .filter((s) => Number(s.quantity) > 0)
                .map((m) => m.name)
                .join(' ')}`
            : `${item.selectedSizesHalf
                .filter((s) => Number(s.quantity) > 0)
                .map((m) => m.name)
                .join(' ')} ${item.selectedSizesHalf
                .filter((s) => Number(s.quantity) > 0)
                .map((m) => m.cName)
                .join(' ')}`;

        itemData.name += `${item.name}/${item.nameHalf}`;
        itemData.name += size.variants
          ? size.variants.filter((f) => f.quantity > 0).length > 0
            ? `(${size.variants
                .filter((f) => f.quantity > 0)
                .map((v) => v.name)
                .join(',')})`
            : ''
          : '';

        let extrasData = { name: '', items: [] };
        let removeData = { name: '', items: [] };
        extrasData.name =
          item?.selectedExtraIngredients?.length > 0 ||
          item?.removedIngredients.length > 0
            ? item.name
            : '';
        removeData.name = extrasData.name;
        item?.selectedExtraIngredients?.map((ingredient) => {
          const extra = ingredient.name
            ? ingredient.name
            : ingredient.ingredientName;
          extrasData.items.push(extra);
          return ingredient;
        });
        item?.removedIngredients.map((ingredient) => {
          const remove = ingredient.name
            ? ingredient.name
            : ingredient.ingredientName;
          removeData.items.push(remove);
          return ingredient;
        });

        if (extrasData.items.length > 0) {
          itemData.extras.push(extrasData);
        }
        if (removeData.items.length > 0) {
          itemData.remove.push(removeData);
        }

        extrasData = { name: '', items: [] };
        removeData = { name: '', items: [] };

        extrasData.name =
          item?.selectedExtraIngredientsHalf.length > 0 ||
          item?.removedIngredientsHalf.length > 0
            ? item.nameHalf
            : '';
        removeData.name = extrasData.name;

        item?.selectedExtraIngredientsHalf.map((ingredient) => {
          const extra = ingredient.name
            ? ingredient.name
            : ingredient.ingredientName;
          extrasData.items.push(extra);
        });

        item?.removedIngredientsHalf.map((ingredient) => {
          const remove = ingredient.name
            ? ingredient.name
            : ingredient.ingredientName;
          removeData.items.push(remove);
        });

        if (extrasData.items.length > 0) {
          itemData.extras.push(extrasData);
        }
        if (removeData.items.length > 0) {
          itemData.remove.push(removeData);
        }

        itemData.price = `${currency}${getItemCustomizedPrice({
          item,
          size,
          halfHalfSetup,
          orderTracker,
        })}`;
      });

    data.items.push(itemData);
  });

  if (
    orderTracker?.orderObj?.publicHolidaySurcharge &&
    Number(orderTracker?.orderObj?.publicHolidaySurcharge) !== 0
  ) {
    const publicHolidaySurcharge = {
      name: `Public Holiday Surcharge${
        Number(orderTracker?.orderObj?.publicHolidaySurchargePer) !== 0
          ? `(${orderTracker?.orderObj?.publicHolidaySurchargePer}%)`
          : ''
      }`,
      amount: `${currency}${orderTracker?.orderObj?.publicHolidaySurcharge}`,
    };

    data.additional.push(publicHolidaySurcharge);
  }

  if (
    orderTracker?.orderObj?.isDisplayGST &&
    !orderTracker?.orderObj?.isPriceIncludesGST &&
    Number(orderTracker?.orderObj?.gst) !== 0
  ) {
    const voucher = {
      name: 'GST',
      amount: `${currency}${orderTracker?.orderObj?.gst}`,
    };

    data.additional.push(voucher);
  }

  if (
    orderTracker?.orderObj?.address &&
    orderTracker?.orderObj?.deliveryCost &&
    Number(orderTracker?.orderObj?.deliveryCost) > 0
  ) {
    const delivery = {
      name: 'Delivery Fee',
      amount: `${currency}${Number(
        orderTracker?.orderObj?.deliveryCost
      ).toFixed(2)} `,
    };

    data.additional.push(delivery);
  }

  if (cardCharges) {
    const card = {
      name: cardChargesTitle,
      amount: `${currency}${cardCharges}`,
    };

    data.additional.push(card);
  }

  if (orderTracker?.orderObj?.specials?.length > 0) {
    const specials = {
      name: 'Specials',
      amount: `-${currency}${orderTracker?.orderObj?.specialDiscount}`,
    };

    data.additional.push(specials);
  }

  if (
    orderTracker?.orderObj?.voucherId !== '' &&
    this?.props?.orderTracker?.orderObj?.voucherDiscount &&
    Number(this.props?.orderTracker?.orderObj?.voucherDiscount) !== 0
  ) {
    const voucher = {
      name: 'Voucher',
      amount: `-${currency}${orderTracker?.orderObj?.voucherDiscount}`,
    };

    data.additional.push(voucher);
  }

  if (orderTracker?.orderObj?.rewards?.length > 0) {
    const loyalty = {
      name: 'Loyalty',
      amount: `-${currency}${orderTracker?.orderObj?.loyaltyDiscount}`,
    };

    data.additional.push(loyalty);
  }

  data.notes = orderTracker?.orderObj?.notes;
  data.paymentStatusDisplay = orderTracker?.orderObj?.paymentStatusDisplay;
  data.paymentBy =
    orderTracker?.orderObj?.paymentType === ORDER_PAYMENT_TYPE.cash
      ? 'Cash'
      : orderTracker?.orderObj?.paymentType === ORDER_PAYMENT_TYPE.card_online
      ? 'Card'
      : '';
  data.total = orderTracker?.orderObj?.payableAmount
    ? `${currency}${orderTracker?.orderObj?.payableAmount}`
    : '';

  return data;
};

export const getCustomisationsForMenuItem = (item) => {
  const itemData = {
    extras: [],
    remove: [],
  };

  !item?.isHalf &&
    item?.selectedSizes?.forEach((size) => {
      itemData.count = size.quantity;
      itemData.size = size.name;
      itemData.name =
        !item.itemType || item.itemType === 0
          ? item.name
          : `${item.name}${
              size.cName && size.cName.length > 0 ? `, ${size.cName}` : ''
            }`;
      itemData.name += size.variants
        ? size.variants.filter((f) => f.quantity > 0).length > 0
          ? `(${size.variants
              .filter((f) => f.quantity > 0)
              .map((v) => v.name)
              .join(', ')})`
          : ''
        : '';
      itemData.variant = size.variants
        ? size.variants.filter((f) => f.quantity > 0).length > 0
          ? `(${size.variants
              .filter((f) => f.quantity > 0)
              .map((v) => v.name)
              .join(', ')})`
          : ''
        : '';

      let extrasData = { name: '', items: [] };
      let removeData = { name: '', items: [] };
      item?.selectedExtraIngredients?.forEach((ingredient) => {
        const extra = ingredient.name
          ? ingredient.name
          : ingredient.ingredientName;
        extrasData.items.push(extra);
      });

      item?.removedIngredients?.forEach((ingredient) => {
        const remove = ingredient.name
          ? ingredient.name
          : ingredient.ingredientName;
        removeData.items.push(remove);
      });

      if (extrasData.items.length > 0) {
        itemData.extras.push(extrasData);
      }
      if (removeData.items.length > 0) {
        itemData.remove.push(removeData);
      }
    });

  item?.isHalf &&
    item?.selectedSizes?.forEach((size) => {
      itemData.count = size.quantity * 2;
      itemData.size =
        !item.itemType || item.itemType === 0
          ? `${size.name}`
          : `${size.name} ${
              size.cName && size.cName.length > 0 ? `,${size.cName}` : ''
            }`;
      itemData.size += '/';
      itemData.size +=
        !item.itemTypeHalf || item.itemTypeHalf === 0
          ? `${item.selectedSizesHalf
              .filter((s) => Number(s.quantity) > 0)
              .map((m) => m.name)
              .join(' ')}`
          : `${item.selectedSizesHalf
              .filter((s) => Number(s.quantity) > 0)
              .map((m) => m.name)
              .join(' ')} ${item.selectedSizesHalf
              .filter((s) => Number(s.quantity) > 0)
              .map((m) => m.cName)
              .join(' ')}`;

      itemData.name += `${item.name}/${item.nameHalf}`;
      itemData.name += size.variants
        ? size.variants.filter((f) => f.quantity > 0).length > 0
          ? `(${size.variants
              .filter((f) => f.quantity > 0)
              .map((v) => v.name)
              .join(',')})`
          : ''
        : '';
      itemData.variant = size.variants
        ? size.variants.filter((f) => f.quantity > 0).length > 0
          ? `(${size.variants
              .filter((f) => f.quantity > 0)
              .map((v) => v.name)
              .join(',')})`
          : ''
        : '';
      let extrasData = { name: '', items: [] };
      let removeData = { name: '', items: [] };
      extrasData.name =
        item?.selectedExtraIngredients?.length > 0 ||
        item?.removedIngredients.length > 0
          ? item.name
          : '';
      removeData.name = extrasData.name;
      item?.selectedExtraIngredients?.forEach((ingredient) => {
        const extra = ingredient.name
          ? ingredient.name
          : ingredient.ingredientName;
        extrasData.items.push(extra);
      });
      item?.removedIngredients.forEach((ingredient) => {
        const remove = ingredient.name
          ? ingredient.name
          : ingredient.ingredientName;
        removeData.items.push(remove);
      });

      if (extrasData.items.length > 0) {
        itemData.extras.push(extrasData);
      }
      if (removeData.items.length > 0) {
        itemData.remove.push(removeData);
      }

      extrasData = { name: '', items: [] };
      removeData = { name: '', items: [] };

      extrasData.name =
        item?.selectedExtraIngredientsHalf.length > 0 ||
        item?.removedIngredientsHalf.length > 0
          ? item.nameHalf
          : '';
      removeData.name = extrasData.name;

      item?.selectedExtraIngredientsHalf.forEach((ingredient) => {
        const extra = ingredient.name
          ? ingredient.name
          : ingredient.ingredientName;
        extrasData.items.push(extra);
      });

      item?.removedIngredientsHalf.forEach((ingredient) => {
        const remove = ingredient.name
          ? ingredient.name
          : ingredient.ingredientName;
        removeData.items.push(remove);
      });

      if (extrasData.items.length > 0) {
        itemData.extras.push(extrasData);
      }
      if (removeData.items.length > 0) {
        itemData.remove.push(removeData);
      }
    });
  let add = '';
  itemData.extras?.forEach((extraItem) => {
    add += extraItem?.items?.join(', ') + ' ';
  });

  let remove = '';
  itemData.remove?.forEach((removeItem) => {
    remove += removeItem?.items?.join(', ') + ' ';
  });

  return { itemData, add, remove };
};

export default getViewOrderData;
