import starGray from '../../assets/images/star-gray.svg';
import starYellow from '../../assets/images/star-yellow.svg';

const Ratings = ({ rating = 0 }) => {
  const renderStars = () => {
    const intRating = Math.floor(rating);
    const decimalRating = rating - Math.floor(rating).toFixed(1);
    const emptyRating = 5 - Math.ceil(rating);
    const stars = [];

    if (intRating) {
      for (let index = 0; index < intRating; index++) {
        stars.push(
          <div key={`StarRating-${index}`}>
            <img src={starYellow} alt='' />
          </div>
        );
      }
    }
    if (decimalRating) {
      const looper = decimalRating * 100;
      stars.push(
        <div key='DecimalStarRating'>
          <img src={starGray} alt='' />
          <div
            style={{
              width: `${looper}%`,
              backgroundImage: `url(${starYellow})`,
            }}
            className='ratingStars-looper'
          />
        </div>
      );
    }
    if (emptyRating) {
      for (let index = 0; index < emptyRating; index++) {
        stars.push(
          <div key={`EmptyStarRating-${index}`}>
            <img src={starGray} alt='' />
          </div>
        );
      }
    }
    return <>{stars}</>;
  };

  return <div className='ratingStars mt-3px'>{renderStars()}</div>;
};

export default Ratings;
