export default function getSpecialAvailability(item) {
    let returnString = '';
    let finalString = '';
    let dayStringArray = [];
    if (item.isAvailableDelivery && item.isAvailablePickup) returnString = '';
    else if (!item.isAvailableDelivery && item.isAvailablePickup)
      returnString = '(Pickup only';
    else if (item.isAvailableDelivery && !item.isAvailablePickup)
      returnString = '(Delivery only';
  
    if (item.Monday)
      dayStringArray.push({ index: 1, isAvailable: true, display: 'Mon' });
  
    if (item.Tuesday)
      dayStringArray.push({ index: 2, isAvailable: true, display: 'Tue' });
  
    if (item.Wednesday)
      dayStringArray.push({ index: 3, isAvailable: true, display: 'Wed' });
  
    if (item.Thursday)
      dayStringArray.push({ index: 4, isAvailable: true, display: 'Thu' });
  
    if (item.Friday)
      dayStringArray.push({ index: 5, isAvailable: true, display: 'Fri' });
  
    if (item.Saturday)
      dayStringArray.push({ index: 6, isAvailable: true, display: 'Sat' });
  
    if (item.Sunday)
      dayStringArray.push({ index: 7, isAvailable: true, display: 'Sun' });
  
    let expected;
    let dayString = '';
    dayStringArray.forEach((dayObj, index) => {
      if (index === 0) {
        dayString = dayString + dayObj.display;
        expected = dayObj.index + 1;
        return;
      }
  
      if (dayObj.index === expected) {
        expected = dayObj.index + 1;
        dayString = dayString + '-' + dayObj.display;
      } else {
        expected = dayObj.index + 1;
        dayString = dayString + ',' + dayObj.display;
      }
    });
    let newDayString = '';
    let dayStringArr = dayString.split(',');
    if (dayStringArray.length !== 7) {
      if (dayStringArr.length > 1) {
        dayStringArr.forEach((dayStringElem) => {
          if (dayStringElem.indexOf('-') !== -1) {
            let dashArray = dayStringElem.split('-');
            newDayString = dashArray[0] + '-' + dashArray[dashArray.length - 1];
          } else {
            newDayString = newDayString + ',' + dayStringElem;
          }
        });
      } else {
        let dashArray = dayStringArr[0].split('-');
        newDayString = dashArray[0] + '-' + dashArray[dashArray.length - 1];
      }
    }
    if (returnString.length > 0 && newDayString.length > 0)
      finalString = returnString + ',' + newDayString + ')';
    else if (returnString.length > 0 && newDayString.length === 0)
      finalString = returnString + ')';
    else if (returnString.length === 0 && newDayString.length > 0)
      finalString = '(' + newDayString + ')';
    return finalString.length > 10
      ? finalString.substr(0, 10) + '..'
      : finalString;
  };