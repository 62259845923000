import {
  ModalCWShellUI,
  OrderUI,
  RowUI,
  SelectUI,
} from '@next-order/next-order-components';
import { MenuContext } from 'contexts/MenuProvider';
import { BREAKPOINT_VALUES, MENU_ORDER_TYPE } from 'helpers/enums';
import moment from 'moment';
import { CalendarModernIcon, ClockNineIcon } from 'nexticons/outline';
import {
  createRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import Swiper from 'react-id-swiper';
import { useSelector } from 'react-redux';
import Select from '../../components/Select';

function tConvert(time) {
  if (!time || time?.includes('mins')) return time;
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  let newTime = time.join(''); // return adjusted time or original string
  if (newTime.length === 7) newTime = '0' + newTime;

  return newTime;
}

const params = {
  slidesPerView: 'auto',
  spaceBetween: 0,
  freeMode: true,
  observer: true,
};
const params2 = {
  speed: 500,
  slidesPerView: 1,
  spaceBetween: 0,
  autoHeight: true,
  observer: true,
};
export default function ChangeTimeActionButton({
  timesArr,
  selectTime,
  type,
  defaultDateStr,
  cartState,
  setCartState,
  updateDeliveryPickupTime,
}) {
  const { currentOrder } = useSelector((state) => state.homeReducer);
  const [open, setOpen] = useState(false);
  const [selectedDeliveryTimeId, setSelectedDeliveryTimeId] = useState(null);
  const [selectedTimeId, setSelectedTimeId] = useState(null);
  const swiper1Ref = useRef(null);
  const swiperRef = useRef(null);
  const orderTypeRefs = useMemo(
    () =>
      currentOrder.orderType === '2'
        ? (cartState.deliveryTimes ?? []).map(() => createRef())
        : (cartState.pickUpTimes ?? []).map(() => createRef()),
    [currentOrder.orderType, cartState.deliveryTimes, cartState.pickUpTimes]
  );

  const { breakPoint } = useContext(MenuContext);

  const [defaultDateId, defaultTimeDisplay] = defaultDateStr?.split(' ') ?? [];
  const defaultTimeDisplayForUse = tConvert(defaultTimeDisplay);

  useEffect(() => {
    setSelectedDeliveryTimeId(defaultDateId);
    setSelectedTimeId(defaultTimeDisplayForUse);
  }, [defaultDateId, defaultTimeDisplayForUse]);

  const dateOptions = useMemo(
    () =>
      (timesArr ?? []).map((deliveryTime) => ({
        label: deliveryTime.display,
        value: deliveryTime.dateString,
      })),
    [timesArr]
  );

  const timeOptions = useMemo(() => {
    const options =
      timesArr
        ?.find(
          (deliveryTime) => deliveryTime.dateString === selectedDeliveryTimeId
        )
        ?.times?.map((time, index) => ({
          label: time.display,
          value:
            index === 0 && defaultTimeDisplayForUse
              ? defaultTimeDisplayForUse
              : time.displayForUse,
        })) ?? [];

    // filter repeated values
    const filteredOptions = options.filter(
      (option, index, self) =>
        self.findIndex((o) => o.value === option.value) === index
    );
    return filteredOptions;
  }, [selectedDeliveryTimeId, defaultTimeDisplayForUse, timesArr]);

  const onSelectDeliveryTime = (value) => {
    if (value === selectedDeliveryTimeId) return;
    setSelectedDeliveryTimeId(value);
    setSelectedTimeId(null);
  };

  const onSubmit = () => {
    const time = timesArr
      ?.find(
        (deliveryTime) => deliveryTime.dateString === selectedDeliveryTimeId
      )
      .times?.find((time) => time.displayForUse === selectedTimeId);
    if (!!time) {
      selectTime(time);
    }

    setOpen(false);
  };

  const sliderGo = (e, n) => {
    setCartState({
      widthS: e.currentTarget.offsetWidth,
      leftS: e.currentTarget.offsetLeft,
      active: n,
    });
    if (swiperRef.current?.swiper) {
      swiperRef.current?.swiper?.slideTo(n);
    }
  };

  const handleChange = (timeObj) => {
    let deliveryDate = moment(
      `${timeObj.timeObjFinal} ${timeObj.displayForUse}`,
      'YYYY-MM-DD HH:mm A'
    );
    if (deliveryDate !== cartState.deliveryDateLater) {
      setCartState({
        selectedTime: timeObj.displayForUse,
        deliveryDateLater: deliveryDate,
        isFutureDeliveryDate:
          timeObj.alternateDisplay === 'ASAP' ? false : true,
        shiftIdLater: timeObj.shiftId || '',
      });
    }
  };

  const setDeliveryTime = (e) => {
    e.stopPropagation();

    setCartState({
      showErrorInTimeSelector: false,
    });
    setOpen(false);
    if (cartState.deliveryDateLater && cartState.shiftIdLater) {
      updateDeliveryPickupTime(
        cartState.deliveryDateLater,
        cartState.shiftIdLater,
        false,
        0,
        cartState.isFutureDeliveryDate
      );
    }
  };

  const slideChange = () => {
    if (!swiperRef.current?.swiper) return;
    let timeValue =
      cartState.pickUpTimes[swiperRef.current.swiper?.activeIndex].times[0]
        .displayForUse;
    let timeObj = [];
    if (currentOrder.orderType === '1') {
      timeObj = cartState.pickUpTimes[
        swiperRef.current.swiper?.activeIndex
      ].times.filter((r) => {
        return r.displayForUse === timeValue;
      });
    } else if (currentOrder.orderType === '2') {
      timeObj = cartState.deliveryTimes[
        swiperRef.current.swiper?.activeIndex
      ].times.filter((r) => {
        return r.displayForUse === timeValue;
      });
    }
    if (timeObj.length > 0) {
      let deliveryDate = moment(
        `${timeObj[0].timeObjFinal} ${timeObj[0].displayForUse}`,
        'YYYY-MM-DD HH:mm A'
      );
      let isFutureDeliveryDate =
        timeObj[0].alternateDisplay === 'ASAP' ? false : true;
      let shiftIdLater = timeObj.length > 0 ? timeObj[0].shiftId : '';
      setCartState({
        selectedTime: timeValue,
        deliveryDateLater: deliveryDate,
        isFutureDeliveryDate,
        shiftIdLater,
      });
    }

    if (
      orderTypeRefs[swiperRef.current.swiper?.activeIndex]?.current
        ?.offsetLeft +
        orderTypeRefs[swiperRef.current.swiper?.activeIndex]?.current
          ?.offsetWidth >
      window.innerWidth
    ) {
      swiper1Ref.current?.swiper?.setTranslate(
        window.innerWidth -
          (orderTypeRefs[swiperRef.current.swiper?.activeIndex]?.current
            ?.offsetLeft +
            orderTypeRefs[swiperRef.current.swiper?.activeIndex]?.current
              ?.offsetWidth) -
          15
      );
    } else {
      swiper1Ref.current?.swiper?.setTranslate(0);
    }
    setCartState({
      active: swiperRef.current.swiper?.activeIndex,
      widthS:
        orderTypeRefs[swiperRef.current.swiper?.activeIndex]?.current
          ?.offsetWidth,
      leftS:
        orderTypeRefs[swiperRef.current.swiper?.activeIndex]?.current
          ?.offsetLeft,
    });
  };

  return (
    <>
      <OrderUI.ChangeBtn
        onClick={() => setOpen(true)}
        extendedClassName='pr-3.75'
      />
      {breakPoint >= BREAKPOINT_VALUES.DESKTOP ? (
        <ModalCWShellUI
          maxWidth={358}
          onOpenChange={() => setOpen(false)}
          open={open}
          zIndex={100}
        >
          <ModalCWShellUI.CloseButton onClick={() => setOpen(false)} />
          <ModalCWShellUI.Header title={`Select a ${type} Time`} />
          <ModalCWShellUI.Body>
            <RowUI>
              <SelectUI
                defaultValue={defaultDateId}
                icon={<CalendarModernIcon width={20} />}
                options={dateOptions}
                onChange={onSelectDeliveryTime}
                styleType='cw-list'
              />
            </RowUI>
            <RowUI>
              <SelectUI
                defaultValue={defaultTimeDisplayForUse}
                icon={<ClockNineIcon strokeWidth={1} width={20} />}
                onChange={setSelectedTimeId}
                options={timeOptions}
                styleType='cw-list'
                disabled={!selectedDeliveryTimeId}
              />
            </RowUI>
          </ModalCWShellUI.Body>
          <ModalCWShellUI.Footer>
            <ModalCWShellUI.Button
              onClick={onSubmit}
              disabled={!selectedDeliveryTimeId || !selectedTimeId}
            >
              Schedule {type} Time
            </ModalCWShellUI.Button>
          </ModalCWShellUI.Footer>
        </ModalCWShellUI>
      ) : (
        <ModalCWShellUI
          onOpenChange={() => setOpen(false)}
          open={open}
          zIndex={100}
        >
          <ModalCWShellUI.CloseButton onClick={() => setOpen(false)} />
          <ModalCWShellUI.Header title={`Select a ${type} Time`} />
          <ModalCWShellUI.Body extendedClassName='!px-0'>
            {currentOrder.orderType === MENU_ORDER_TYPE.pickup ? (
              <>
                <div className='swiperWeek'>
                  <Swiper {...params} ref={swiper1Ref}>
                    {cartState.pickUpTimes?.map((d, index) => (
                      <div
                        ref={orderTypeRefs[index]}
                        key={index}
                        onClick={(e) => sliderGo(e, Number(index))}
                        className={
                          cartState.active === Number(index)
                            ? 'active tabSUL'
                            : 'tabSUL'
                        }
                      >
                        {d.display}
                      </div>
                    ))}
                    <span
                      className='slidebarExtras'
                      style={{
                        width: cartState.widthS,
                        left: cartState.leftS,
                      }}
                    />
                  </Swiper>
                </div>
                <div className='swiperTime'>
                  <Swiper
                    {...params2}
                    on={{
                      slideChange,
                    }}
                    ref={swiperRef}
                  >
                    {cartState.pickUpTimes?.map((item, index) => (
                      <div className='subTimeXS' key={index}>
                        <Select
                          handleChange={handleChange}
                          key={`picker${index}`}
                          data={item}
                          index={index}
                        />
                      </div>
                    ))}
                  </Swiper>
                </div>
              </>
            ) : (
              <>
                <div className='swiperWeek'>
                  <Swiper {...params} ref={swiper1Ref}>
                    {cartState.deliveryTimes?.map((d, index) => (
                      <div
                        ref={orderTypeRefs[index]}
                        key={index}
                        onClick={(e) => sliderGo(e, Number(index))}
                        className={
                          cartState.active === Number(index)
                            ? 'active tabSUL'
                            : 'tabSUL'
                        }
                      >
                        {d.display}
                      </div>
                    ))}
                    <span
                      className='slidebarExtras'
                      style={{
                        width: cartState.widthS,
                        left: cartState.leftS,
                      }}
                    />
                  </Swiper>
                </div>
                <div className='swiperTime'>
                  <Swiper
                    {...params2}
                    on={{
                      slideChange,
                    }}
                    ref={swiperRef}
                  >
                    {cartState.deliveryTimes?.map((item, index) => (
                      <div className='subTimeXS' key={index}>
                        <Select
                          key={`picker${index}`}
                          handleChange={handleChange}
                          data={item}
                          index={index}
                        />
                      </div>
                    ))}
                  </Swiper>
                </div>
              </>
            )}
          </ModalCWShellUI.Body>
          <ModalCWShellUI.Footer>
            <ModalCWShellUI.Button onClick={(e) => setDeliveryTime(e)}>
              Set {type} Time{' '}
              {cartState.selectedTime !== ''
                ? orderTypeRefs[cartState.active]?.current &&
                  orderTypeRefs[cartState.active]?.current?.textContent !==
                    'Today'
                  ? ` - ${
                      orderTypeRefs[cartState.active]?.current?.textContent
                    }, ${cartState.selectedTime}`
                  : ` - ${cartState.selectedTime}`
                : ''}
            </ModalCWShellUI.Button>
          </ModalCWShellUI.Footer>
        </ModalCWShellUI>
      )}
    </>
  );
}
